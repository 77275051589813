interface Process {
  browser: boolean;
}
declare let process: Process;
export const isBrowser: boolean = (() => {
  try {
    return (
      (typeof window !== 'undefined' && !!window) ||
      (typeof process !== 'undefined' && process.browser)
    );
  } catch (_) {
    console.error(_);
    return false;
  }
})();
