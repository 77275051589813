import React from 'react';

import { truncate } from '../../../../utils/string';
import { domain } from '../../../../utils/web';

export const EmbedContent: React.FC<{
  media?: React.ReactNode;
  source?: string;
  title: React.ReactNode;
  description?: string;
  provider?: string;
}> = ({ media, source, title, description, provider }) => {
  return (
    <div className="flex justify-center overflow-hidden items-stretch _sm:flex-col space-x-2 _sm:space-y-2">
      {media ||
        (source && (
          <div className="flex justify-center items-center rounded-lg w-auto sm:w-1/3 bg-black shrink-0">
            <img className="sm:w-full" alt="" src={source} />
          </div>
        )) ||
        null}
      <div className="flex flex-1 flex-col overflow-hidden whitespace-normal">
        <div className="prose-main">{title}</div>
        {description && (
          <div className="text-xs leading-5 text-gray-medium pt-1">
            {truncate(description, 200)}
          </div>
        )}
        {provider && <div className="text-right mt-auto pt-2">{domain(provider)}</div>}
      </div>
    </div>
  );
};
