import Head from 'next/head';
import React from 'react';

export const MetaStatic: React.FC<{ jsonLds: Record<string, unknown>[] }> = ({ jsonLds }) => (
  <Head>
    {jsonLds.map((jsonLd, index) => (
      <script
        // eslint-disable-next-line react/no-array-index-key
        key={`json-lds-${index}`}
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
      />
    ))}
  </Head>
);
