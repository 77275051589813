/**
 * Custom config: `noError` to avoid showing the error toast.
 *
 * @deprecated
 * The usage a global instance of axios is a super bad idea.
 * This is a temporary solution to get the data from the server.
 * It is not recommended to use a custom instance by process/request...
 * But the whole api file should then grouped to be generated at the same time than axios.
 */
import type { AxiosError, AxiosInterceptorManager } from 'axios';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import type { GraphLoginAsync } from '../../../graphdebate/src/components/auth/LoadGraphLogin';
import { apiUrl } from '../config';
import type { GenerateAuthLinkArgs } from './apollo/links/authLink';

export type GCApiError = AxiosError<{ msg: string; gcCode: number }>;

axios.defaults.timeout = 20 * 1000;

const graphCommentInstance = axios.create({
  baseURL: apiUrl,
});

export const setGCHeader = (name: string, value: string) => {
  graphCommentInstance.defaults.headers[name] = value;
};

const defaultGetToken = () => Promise.resolve('');

let _getToken: GraphLoginAsync['getToken'] = defaultGetToken;
let _getPublicKey: () => string = () => '';
let _triggerRefreshToken: (() => Promise<void>) | undefined = undefined;

const addInterceptor = (
  type: 'request' | 'response',
  ...args: Parameters<AxiosInterceptorManager<any>['use']>
) => {
  const interceptor = graphCommentInstance.interceptors[type].use(...args);
  return () => graphCommentInstance.interceptors[type].eject(interceptor);
};

export const applyErrorHandler = (
  handler: (err: GCApiError | Error) => GCApiError | Error | void,
) => addInterceptor('response', r => r, handler);

let prevToken: string | undefined = undefined;

graphCommentInstance.interceptors.request.use(async req => {
  let token = await _getToken?.();
  const website = _getPublicKey?.();

  if (!website) return req;

  //
  // TODO to simulate 401 on the topic favorite route (on topic page, click the star on top of the topic).
  //
  if (req.url?.startsWith('/api/page/5ee8ddfa418496c7bea6dbcd/favorite')) {
    let tokenChangedFromSecondReq = !!prevToken && token !== prevToken;
    prevToken = token;
    // console.log({ token });
    if (!tokenChangedFromSecondReq) {
      // token = token + '__invalid';
    }
  }

  return Object.assign(req, {
    headers: {
      ...req.headers,
      ...(token ? { Authorization: `JWT ${token}` } : {}),
      website,
    },
  });
});

const refreshAuthLogic = async (_failedRequest: AxiosError) => {
  await _triggerRefreshToken?.();
};

// useRefreshToken takes care of watching when the current token expires to renew it in the background.
// The below interceptor takes care of intercepting 401, refreshing the token and retrying the original request.
// The same must be done for Apollo queries.
createAuthRefreshInterceptor(graphCommentInstance, refreshAuthLogic);

export const gcAuthInterceptor = ({
  getToken: getTokenArgs,
  getPublicKey: getPublicKeyArgs,
  triggerRefreshToken,
}: GenerateAuthLinkArgs): (() => void) => {
  if (getTokenArgs) {
    _getToken = getTokenArgs;
  }
  _getPublicKey = getPublicKeyArgs || (() => '');
  _triggerRefreshToken = triggerRefreshToken;

  return () => {
    _getToken = defaultGetToken;
    _getPublicKey = () => '';
    _triggerRefreshToken = undefined;
  };
};

export default graphCommentInstance;
