import type { PointerEvent, PropsWithChildren } from 'react';
import React, { useCallback } from 'react';

import type { GraphModalInstance } from '../../../../components/GraphModal/GraphModal';
import { GraphModal } from '../../../../components/GraphModal/GraphModal';
import { html } from '../../../../utils/dom';
import { listEmojis } from '../../../../utils/emoji';
import { isBrowser } from '../../../../utils/web';

export const EmojisPopover: React.FC<PropsWithChildren<{ onAdd: (e: string) => void }>> = ({
  onAdd,
  children,
}) => {
  const preventDefault = useCallback(
    (e: PointerEvent<HTMLButtonElement>) => e.preventDefault(),
    [],
  );

  return (
    <GraphModal
      className="tippy-popover-emojis"
      maxWidth={202}
      theme="light-border"
      placement="top-start"
      zIndex={750}
      appendTo={isBrowser ? document.body : 'parent'}
      forcePopover
      lazyContent={(tip: GraphModalInstance) => (
        <ul className="comment-emoji-list flex flex-wrap gap-2">
          {listEmojis().map(emoInfos => (
            <li key={emoInfos.emoji}>
              <button
                type="button"
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  tip?.hide();
                  onAdd(emoInfos.emoji);
                }}
                onPointerDown={preventDefault}
              >
                {html(emoInfos.html)}
              </button>
            </li>
          ))}
        </ul>
      )}
    >
      {children as React.ReactElement}
    </GraphModal>
  );
};
