import Image from 'next/image';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';
import { twMerge } from 'tailwind-merge';

import { debateConfig } from '../../../../graphdebate/config';
import { ButtonV3Icon } from '../../components/Button/ButtonV3Icon';
import CategoriesMenuModale from '../../components/Modal/CategoriesMenuModale';
import { useWebsite } from '../../hooks/useWebsite';
import IconCategoriesMenuLink from '../../icons/icon-menu-categories.svg';
import type { ListActionsProps } from './ListActions';
import ListActions from './ListActions';

type ContentHeaderProps = {
  contentTitle: string | ReactNode;
  listLength?: number;
  topicListActions?: ListActionsProps;
  withCategoriesMenu?: boolean;
  className?: string;
  titleClassName?: string;
  isResponsive?: boolean;
};

const ContentHeader: FC<ContentHeaderProps> = memo(function ContentHeader(props) {
  const {
    contentTitle,
    listLength,
    topicListActions,
    withCategoriesMenu,
    className,
    titleClassName = '',
    isResponsive,
  } = props;
  const { graphdebate } = useWebsite();

  const shouldDisplayActions = topicListActions && Object.keys(topicListActions).length;
  const shouldDisplayListLength = typeof listLength === 'number';

  // TODO: remove debateConfig test once allowBurgerMenuModal is implanted in BO
  const shouldDisplayBurgerMenu =
    withCategoriesMenu && (graphdebate?.allowBurgerMenuModal || debateConfig.SHOW_BURGER_MENU);

  return (
    <div className={twMerge('ContentHeader flex flex-col space-y-4 md:space-y-5', className)}>
      <div className="header-top flex gap-x-2">
        <div className="main flex-1 flex gap-x-2 items-center">
          {shouldDisplayBurgerMenu && (
            <CategoriesMenuModale
              poper={
                <ButtonV3Icon
                  variant="text"
                  radius="rounded-full"
                  icon={<Image src={IconCategoriesMenuLink} alt="" width={20} height={20} />}
                  noPadding
                  className="p-2.5 sm:p-2 fill-gray-medium hover:fill-gray-dark"
                />
              }
            />
          )}

          <h2
            className={twMerge(
              'title leading-tight',
              isResponsive && '_md:text-[24px]',
              titleClassName,
            )}
          >
            {contentTitle}
            {shouldDisplayListLength && (
              <span
                className={twMerge(
                  'content-length leading-loose md:leading-relaxed',
                  isResponsive && 'text-[15px] md:text-[20px]',
                  titleClassName,
                )}
              >{` (${listLength})`}</span>
            )}
          </h2>
        </div>
      </div>
      {shouldDisplayActions && <ListActions isResponsive={isResponsive} {...topicListActions} />}
    </div>
  );
});

export default ContentHeader;
