import React from 'react';

import { ReactComponent as IconHot } from '../../../icons/icon-hot.svg';
import { ReactComponent as IconSubjectReading } from '../../../icons/icon-subject-comm.svg';
import { ReactComponent as IconSubjectCreated } from '../../../icons/icon-subject-created.svg';
import { ReactComponent as IconSubjectFav } from '../../../icons/icon-subject-fav.svg';
import { ReactComponent as IconSubjectNew } from '../../../icons/icon-subject-new.svg';
import { ReactComponent as IconSubjectWaiting } from '../../../icons/icon-subject-no-comm.svg';
import {
  TOPIC_FILTER_CREATED,
  TOPIC_FILTER_FAVORITE,
  TOPIC_FILTER_NEW,
  TOPIC_FILTER_PARTICIPATED,
  TOPIC_FILTER_POPULAR,
  TOPIC_FILTER_READ,
  TOPIC_FILTER_UNANSWERED,
} from './constants';

export const MultiIcon: React.FC<{
  className: string;
  nb?: number;
}> = ({ className, nb = 5 }) => (
  <span className={className}>
    {Array(nb)
      .fill(0)
      .map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <span className={`path${index + 1}`} key={index} />
      ))}
  </span>
);

export const filterMainIcons = {
  [TOPIC_FILTER_FAVORITE]: <IconSubjectFav className="inline-block w-[13px] h-[13px]" />,
  [TOPIC_FILTER_NEW]: <IconSubjectNew className="inline-block w-[13px] h-[13px]" />,
  [TOPIC_FILTER_POPULAR]: <IconHot className="inline-block w-[13px] h-[13px]" />,
  [TOPIC_FILTER_PARTICIPATED]: (
    <span className="inline-block ml-[-1px] w-[14px] h-[13px] text-sub">🚩</span>
  ),
  [TOPIC_FILTER_CREATED]: <IconSubjectCreated className="inline-block w-[13px] h-[13px]" />,
  [TOPIC_FILTER_UNANSWERED]: <IconSubjectWaiting className="inline-block w-[13px] h-[13px]" />,
  [TOPIC_FILTER_READ]: <IconSubjectReading className="inline-block w-[13px] h-[13px]" />,
};
