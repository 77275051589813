import type { TippyProps } from '@tippyjs/react';
import React, { useCallback } from 'react';
import { twMerge } from 'tailwind-merge';

import { GraphModal } from '../../components/GraphModal/GraphModal';
import type { ModalPropTypes } from '../../components/GraphModal/Modal';
import type { PopoverPropTypes } from '../../components/GraphModal/Popover';
import { isBrowser } from '../../utils/web';
import type { RequestFriendCallback } from '../api/relation';
import { ProfilePopoverContent } from './ProfilePopoverContent';

export interface ProfilePopoverProps extends Partial<PopoverPropTypes & ModalPropTypes> {
  appendToBody?: boolean;
  placement?: TippyProps['placement'];
  userId: string | undefined;
  children: TippyProps['children'];
  inviteCallback?: RequestFriendCallback;
  delay?: [number, number];
}

export const ProfilePopover: React.FC<ProfilePopoverProps> = ({
  appendToBody,
  placement = 'bottom-start',
  userId,
  children,
  inviteCallback,
  className,
  delay = [0, 0],
  ...other
}) => {
  const customTrigger = other?.trigger;
  const isTriggeredByHover = customTrigger === 'mouseenter';

  const lazyContent = useCallback(
    controller => (
      <ProfilePopoverContent
        userId={userId}
        controller={controller}
        inviteCallback={inviteCallback}
        noCloseButton={isTriggeredByHover}
      />
    ),
    [userId, inviteCallback, isTriggeredByHover],
  );

  return (
    <GraphModal
      className={twMerge('tippy-no-padding rounded-lg overflow-hidden', className)}
      maxWidth={450}
      // distance={10}
      delay={delay}
      trigger="click"
      theme="light-border"
      placement={placement}
      lazyContent={lazyContent}
      appendTo={appendToBody && isBrowser ? document.body : 'parent'}
      hideOnClick={isTriggeredByHover ? false : true}
      {...other}
    >
      {children}
    </GraphModal>
  );
};
