import classnames from 'classnames';
import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Tooltip } from '../../components/GraphModal/Tooltip';
import { ReactComponent as StarIconFull } from '../../icons/icon-star-full.svg';
import { ReactComponent as StarIconOutlined } from '../../icons/icon-star-outlined.svg';
import { useAppDispatch } from '../../redux/hooks';
import type { Topic } from '../../types/api';
import { bookmarkTopic } from '../api/topic';
import { updateTopicInTagList } from '../ThreadMessage/topics-slice';

const messages = defineMessages({
  bookmark: { defaultMessage: 'Ajouter le sujet aux favoris', id: 'Z7nzYL' },
  unMark: { defaultMessage: 'Retirer le sujet des favoris', id: 'hO6ECB' },
});

/**
 *
 * @param pageId
 * @param isFavorite
 * @param props
 * @constructor
 */
export const BookmarkTopic = ({
  id: pageId,
  isFavorite,
  ...props
}: {
  id: string;
  isFavorite: boolean;
} & React.HTMLAttributes<HTMLDivElement>) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const toggle = useCallback(async () => {
    const newFavoriteValue = !isFavorite;
    await bookmarkTopic(pageId, newFavoriteValue);

    const topicUpdate: Partial<Topic> = {
      id: pageId,
      isFavorite: newFavoriteValue,
    };
    dispatch(updateTopicInTagList(topicUpdate));
  }, [pageId, isFavorite, dispatch]);

  const message = isFavorite ? messages.unMark : messages.bookmark;

  return (
    <div role="button" onClick={toggle} tabIndex={-1} {...props}>
      <Tooltip content={formatMessage(message)}>
        <div className={classnames('h-5 transition-colors')}>
          <span className="w-4 h-4">{isFavorite ? <StarIconFull /> : <StarIconOutlined />}</span>
        </div>
      </Tooltip>
    </div>
  );
};
