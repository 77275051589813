import type { HTMLAttributes, ReactNode } from 'react';
import { forwardRef } from 'react';

export type MenuElementProps = {
  icon: ReactNode;
  title: ReactNode;
  subtitle?: string;
};
export const PopupMenuElement = forwardRef<
  HTMLDivElement,
  MenuElementProps & Omit<HTMLAttributes<unknown>, 'title'>
>(({ icon, title, subtitle = '', ...props }, ref) => {
  return (
    <div
      className="flex items-center space-x-2 m p-2 pl-0 cursor-pointer hover:text-primary-base transition-colors duration-200"
      ref={ref}
      {...props}
    >
      {icon}
      <div className="flex-1">
        <span className="font-roboto whitespace-nowrap tracking-wide">{title}</span>
        {subtitle ? <div className="prose-caption text-gray-medium">{subtitle}</div> : null}
      </div>
    </div>
  );
});
