import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import type { ChildMessages, Message } from '../../../types/api';
import { uniqBy } from '../../../utils/common-utils';

export type ScoredReferenceMessageType = { ref: Message; result: number | undefined };
export const mergeRelevance = (
  existing: ChildMessages,
  incoming: ChildMessages,
  {
    args: { adding },
    readField,
  }: {
    args: { adding?: boolean };
    readField: ReadFieldFunction;
  },
) => {
  const relevanceLength = incoming.relevanceMessageOrder?.length ?? 0;
  const extra = adding ? relevanceLength : 0;
  const prevRelevanceMessageOrder = existing?.relevanceMessageOrder ?? [];
  const newRelevanceMessageOrder = incoming?.relevanceMessageOrder ?? [];

  return {
    ...existing,
    ...incoming,
    ...(adding
      ? {
          relevanceMessageOrder: uniqBy(
            [...newRelevanceMessageOrder, ...prevRelevanceMessageOrder],
            '__ref',
          )
            .map(
              // @TODO fix api removing maybe
              // @ts-ignore
              (ref: Message): ScoredReferenceMessageType => ({
                ref,
                result: readField('result', ref),
              }),
            )
            .sort(
              (a: ScoredReferenceMessageType, b: ScoredReferenceMessageType) =>
                (b?.result ?? 0) - (a?.result ?? 0),
            )
            .map((elt: ScoredReferenceMessageType) => elt.ref),
        }
      : null),
    meta: {
      ...existing?.meta,
      ...incoming?.meta,
      ...(adding ? { count: (existing?.meta?.count ?? 0) + extra } : null),
    },
  };
};
