import type { ComponentType, CSSProperties, ReactEventHandler, ReactNode } from 'react';
import { forwardRef, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

// h-touch was used in place of h-11, but it seems the initial height is bigger, causing a small layout shift.
const BUTTON_CLASSES = {
  common: 'items-center justify-center appearance-none',
  base: 'rounded-md h-11 text-white font-bold select-none text-base leading-6',
  secondary: 'border rounded-md h-11 text-gray-dark select-none text-remove12',
  outlined:
    'rounded-md h-11 text-primary-base border-2 border-primary-base select-none text-base leading-6 font-bold',
  link: 'select-none inline-block',
  icon: 'select-none inline-block py-4',
};

const ABLED_BUTTON_CLASSES = {
  common: 'cursor-pointer',
  base: 'focus:shadow',
  secondary: 'border-gray-light focus:shadow',
  outlined: 'hover:bg-primary-base hover:text-primary-text focus:shadow',
  link: 'hover:text-gray-dark focus:underline',
  icon: 'hover:text-gray-dark focus:underline',
};

const DISABLED_BUTTON_CLASSES = {
  common: 'cursor-not-allowed',
  base: 'text-grayToRemove-c1',
  secondary: 'text-gray-light',
  outlined: 'text-grayToRemove-e9 border-grayToRemove-e9',
  link: 'text-gray-light',
  icon: 'text-gray-light',
};

export type ButtonPropTypes = {
  variant?: keyof typeof BUTTON_CLASSES;
  disabled?: boolean;
  className?: string;
  maxWidth?: number | 'none';
  style?: CSSProperties;
  type?: 'submit' | 'button';
  children: ReactNode;
  as?:
    | keyof JSX.IntrinsicElements
    | ComponentType<{
        role: string;
        className: string;
        style: CSSProperties;
      }>;
  onClick?: ReactEventHandler;
  bgClass?: string;
  inline?: boolean;
  noPadding?: boolean;
};

export const Button = forwardRef<HTMLElement, ButtonPropTypes>(
  (
    {
      children,
      disabled,
      className,
      variant = 'base',
      style,
      as: RenderAs = 'button',
      onClick,
      type = 'button',
      bgClass,
      inline,
      noPadding,
      ...props
    },
    innerRef,
  ) => {
    const bgClass2 = bgClass
      ? bgClass
      : variant === 'base'
      ? disabled
        ? 'bg-grayToRemove-e9'
        : 'bg-primary-base'
      : variant === 'secondary'
      ? disabled
        ? 'bg-gray-lighter'
        : 'bg-gray-lightest'
      : variant === 'outlined'
      ? 'bg-transparent'
      : undefined;
    const computedClassName = useMemo(
      () =>
        twMerge([
          ...[BUTTON_CLASSES, disabled ? DISABLED_BUTTON_CLASSES : ABLED_BUTTON_CLASSES].map(elt =>
            [elt.common, elt[variant]].join(' '),
          ),
          inline ? 'inline-block' : 'flex',
          !noPadding && (variant === 'link' ? 'px-1' : variant === 'icon' ? 'px-4' : 'px-3'),
          bgClass2,
          className,
        ]),
      [disabled, inline, noPadding, variant, bgClass2, className],
    );
    return (
      // @TODO find a better solution
      // @ts-ignore
      <RenderAs
        // @ts-ignore
        className={computedClassName}
        // @ts-ignore
        ref={innerRef}
        tabIndex={disabled ? -1 : 0}
        onClick={onClick}
        onKeyPress={onClick}
        // @ts-ignore
        type={type}
        {...props}
      >
        {children}
      </RenderAs>
    );
  },
);
