import React, { cloneElement } from 'react';

import { IGNORE_PROP } from './common';
import { stringReplacer } from './stringFormatter';

export const generateHighlightWordFormatter = (wordsToHighlight: string) => {
  let escaped = wordsToHighlight;
  escaped = removeNonAlphanumericCharacters(escaped);
  // Escaping, just in case. But it shouldn't be required with the non-alphanum deletion above.
  escaped = escapeStrForRegExp(escaped);
  escaped = escaped.trim();
  const regex = new RegExp(
    escaped
      .split(/\s/)
      .map(value => value.trim())
      .filter(elt => elt)
      .join('|'),
    'gi',
  );

  return (str: string) => {
    if (!str || typeof str !== 'string') return str;

    return stringReplacer({
      str,
      regex,
      factory: (chunk: React.ReactNode) =>
        cloneElement(<span className="text-primary-base">{chunk}</span>, {
          [IGNORE_PROP]: true,
        }),
    });
  };
};

function removeNonAlphanumericCharacters(value: string) {
  return value.replace(/[\W_]+/g, ' ');
}

function escapeStrForRegExp(value: string) {
  // https://stackoverflow.com/a/6969486/4053349
  return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
