import type { PropsWithChildren } from 'react';
import React from 'react';

import { ChatIconButton } from '../../../../front/src/components/chat/ChatIconButton';
import { chatMessages } from '../../../../front/src/components/chat/chatMessages';
import { IconCloseCircle, IconMin } from '../../../../front/src/components/icons/Icons';

export const ChatActionsHeader: React.FC<
  PropsWithChildren<{
    reduce?: React.MouseEventHandler<Element>;
    close?: React.MouseEventHandler<Element>;
  }>
> = ({ reduce, close, children }) => {
  return (
    <div className="flex pr-1 py-2 items-center sm:rounded-t-lg sm:shadow">
      <div className="flex-1 pl-4 overflow-hidden">{children}</div>
      {reduce ? (
        <ChatIconButton
          onClick={reduce}
          icon={<IconMin className="w-[14px] h-[14px]" />}
          className="_sm:hidden shrink-0"
          arial={chatMessages.reduceInbox}
        />
      ) : null}
      <ChatIconButton
        onClick={close}
        icon={<IconCloseCircle className="w-[14px] h-[14px]" />}
        arial={chatMessages.closeInbox}
        className="shrink-0"
      />
    </div>
  );
};
