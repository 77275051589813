import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import type { Message, User } from '../../types/api';
import { truncateAround } from '../../utils/string';
import { MessageContent } from '../Content/MessageContent/MessageContent';

const messages = defineMessages({
  hasAnswered: {
    defaultMessage: '<emp>{pseudo}</emp> a répondu au sujet <emp>«{title}»</emp>',
    id: 'FFSeb2',
  },
});

interface MessageSuggestionProps {
  message: Message;
  wordsToHighlight?: string;
}

/**
 *
 * @param message
 * @param wordsToHighlight
 * @constructor
 */
export const MessageSuggestion: React.FC<MessageSuggestionProps> = ({
  message,
  wordsToHighlight,
}) => {
  return (
    <div className="flex flex-col gap-1">
      <span className="text-xs text-gray-medium">
        <MessageContent
          content={truncateAround(message.content as string, wordsToHighlight as string, 42)}
          wordsToHighlight={wordsToHighlight}
          status={message.status as string}
        />
      </span>
      <span className="text-xs text-gray-medium">
        <FormattedMessage
          {...messages.hasAnswered}
          values={{
            pseudo: (message?.author as User)?.profile?.pseudo ?? message?.author?.username,
            title: message?.topic?.subject,
            emp: (chunk: React.ReactNode) => <b>{chunk}</b>,
          }}
        />
      </span>
    </div>
  );
};
