import type { MutableRefObject, Ref, RefCallback } from 'react';
import { useCallback, useRef } from 'react';

export function useRefIntercept<T>(ref: Ref<T>) {
  const interceptedRef0 = useRef<T | null>(null);
  const isRefObj = ref && typeof ref === 'object';
  const newForwardedRef = useCallback<RefCallback<T>>(
    node => {
      interceptedRef0.current = node;
      // setIntercepted(node); // To re-render the component, replace interceptedRef with a state.
      if (typeof ref === 'function') {
        ref(node);
      } else if (isRefObj) {
        (ref as MutableRefObject<T | null>).current = node;
      }
    },
    [isRefObj, ref],
  );
  const refToForward = isRefObj ? ref : newForwardedRef;
  const interceptedRef = isRefObj ? ref : interceptedRef0;
  return [refToForward, interceptedRef] as const;
}
