import { useEffect, useMemo } from 'react';

import { useBodyModifier } from '../../../../front/src/hooks/useBodyModifier';
import { usePermissions } from '../../../../front/src/hooks/usePermissions';
import { useWindowSize } from '../../../../front/src/hooks/useWindowSize';
import { useAppDispatch, useAppSelector } from '../../../../front/src/redux/hooks';
import {
  cleanChatWindows,
  initStorageWindows,
  selectChatIsCreating,
  selectChatWindows,
} from '../../../../front/src/redux/slices/chat-slice';
import { ChatCreationWindow } from './ChatCreationWindow';
import { ChatWindow } from './ChatWindow';
import { ReducedChatWindow } from './ReducedChatWindow';

const useChatWindowNumber = (): {
  maxOpenedChatWindows: number;
  maxReducedChatWindows: number;
} => {
  const { width = 0, height = 0 } = useWindowSize();
  const maxOpenedChatWindows = Math.max(Math.floor(width / 380), 1);
  const maxReducedChatWindows = Math.max(Math.floor(height / 160), 1);

  return useMemo(
    () => ({ maxOpenedChatWindows, maxReducedChatWindows }),
    [maxOpenedChatWindows, maxReducedChatWindows],
  );
};

export const ChatWindows = () => {
  const { authUserId, isAuthDone } = usePermissions();
  const { maxOpenedChatWindows, maxReducedChatWindows } = useChatWindowNumber();
  const isCreating = useAppSelector(selectChatIsCreating);
  const chatWindows = useAppSelector(selectChatWindows);

  const maxToDisplay = maxOpenedChatWindows - (isCreating ? 1 : 0);
  const dispatch = useAppDispatch();

  const openedChatWindows = chatWindows.filter(elt => elt.status === 'opened');
  const reducedChatWindows = chatWindows.filter(elt => elt.status === 'reduced');
  const allChatWindows = [...openedChatWindows, ...reducedChatWindows];

  const openedWindows = openedChatWindows.slice(0, maxToDisplay);
  const reducedWindows = allChatWindows.slice(openedWindows.length);
  const hasOpenedDiscussion = isCreating || openedWindows.length > 0;

  const bodyModifier = useMemo(
    () => ({
      preventScrollMobile: hasOpenedDiscussion,
    }),
    [hasOpenedDiscussion],
  );

  useBodyModifier(bodyModifier);

  useEffect(() => {
    if (isAuthDone) {
      if (authUserId) {
        dispatch(initStorageWindows());
      } else {
        localStorage?.removeItem('chat_windows');
        dispatch(cleanChatWindows());
      }
    }

    return () => {
      dispatch(cleanChatWindows());
    };
  }, [authUserId, dispatch, isAuthDone]);

  return (
    <>
      {hasOpenedDiscussion ? (
        <div
          className={`fixed w-screen z-chat bottom-0 left-0 _sm:top-0 flex justify-end items-end sm:h-0 sm:pr-28 ${
            '' /* styles.topContainer */
          }`}
        >
          {isCreating && <ChatCreationWindow />}
          {openedWindows.map(chatWindow => (
            <ChatWindow key={chatWindow.threadId} threadId={chatWindow.threadId} />
          ))}
        </div>
      ) : null}

      <div className="fixed w-0 h-screen bottom-0 right-0 pointer-events-auto z-chat flex flex-col justify-end items-end _sm:hidden">
        {reducedWindows.slice(0, maxReducedChatWindows).map(chatWindow => (
          <ReducedChatWindow
            threadId={chatWindow.threadId}
            key={chatWindow.threadId}
            isAlreadyView={chatWindow.isAlreadyView}
          />
        ))}
      </div>
    </>
  );
};

export default ChatWindows;
