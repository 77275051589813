import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { getGlobalToken } from '../../services/storage';
import type { RootState } from '../store';

// If a state should not be reset on logout, check /front/src/redux/store.ts

export interface GLState {
  isSignedIn?: boolean;
  key?: string;
}

const initialState: GLState = {};

export const glSlice = createSlice({
  name: 'gl',
  initialState,
  reducers: {
    setIsSignedIn(state, { payload }: PayloadAction<boolean>) {
      state.isSignedIn = !!payload;
    },
    setGLKey(state, { payload: key }: PayloadAction<string>) {
      state.key = key;
      if (state.isSignedIn === undefined) {
        const storageToken = getGlobalToken(key);
        state.isSignedIn = !!storageToken;
      }
    },
  },
});

export const { setIsSignedIn, setGLKey } = glSlice.actions;

export const selectIsSignedInGL = (state: RootState) => state.gl.isSignedIn;
