import { useCallback, useEffect, useMemo, useState } from 'react';

import { computeImageSource } from '../utils/string';

const defaultImgPath = '/images/avatar_3.png';

export const useImageFallbackSrc = (rawSrc: unknown, fallback: string) => {
  const src = useMemo(() => computeImageSource(rawSrc) || fallback, [rawSrc, fallback]);
  const [hasFailed, setHasFailed] = useState(false);
  const onError = useCallback(() => {
    setHasFailed(true);
  }, [setHasFailed]);

  useEffect(() => {
    setHasFailed(false);
  }, [src]);

  return {
    src: rawSrc === defaultImgPath || hasFailed ? fallback : src,
    onError,
  };
};
