import React, { forwardRef, memo, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import type { TextareaAutosizeProps } from 'react-textarea-autosize';
import TextareaAutosize from 'react-textarea-autosize';

import { isCtrlOrMetaPressed } from '../../utils/web';
import { chatMessages } from './chatMessages';

type Props = {
  enter?: (value: string) => void;
  restricted?: boolean;
  large?: boolean;
} & TextareaAutosizeProps;

export const ChatInput = memo(
  forwardRef<HTMLTextAreaElement, Props>(
    ({ enter, restricted, placeholder, large, ...other }, ref) => {
      const { formatMessage } = useIntl();
      const [value, setValue] = useState('');
      const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value),
        [],
      );
      const handleKeyPress = useCallback(
        async (event: React.KeyboardEvent) => {
          if (enter) {
            if (event.key !== 'Enter') return;
            event.preventDefault();
            if (isCtrlOrMetaPressed(event)) {
              document.execCommand('insertText', false, '\n');
            } else {
              enter(other.value === undefined ? value : (other.value as string));
            }
          }
        },
        [enter, other.value, value],
      );
      return (
        <TextareaAutosize
          ref={ref}
          autoComplete="off"
          autoCorrect="on"
          maxRows={large ? undefined : 6}
          minRows={large ? 3 : 1}
          rows={large ? undefined : 1}
          spellCheck="false"
          value={value}
          onChange={handleChange}
          placeholder={
            placeholder
              ? placeholder
              : formatMessage(
                  restricted
                    ? chatMessages.invalidChatInputPlacholder
                    : chatMessages.chatInputPlacholder,
                )
          }
          onKeyPress={handleKeyPress}
          {...other}
        />
      );
    },
  ),
);
