import { gql, useApolloClient } from '@apollo/client';
import type { FC } from 'react';
import { memo, useCallback, useEffect, useRef } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ErrorBlock } from '../../../../front/src/components/ErrorBlock';
import { IfInViewPort } from '../../../../front/src/components/IfInViewPort';
import { LoaderBlock } from '../../../../front/src/components/LoaderBlock';
import { RefreshButton } from '../../../../front/src/components/RefreshButton';
import { failureToast, successToast } from '../../../../front/src/components/Toast';
import { frontConfig } from '../../../../front/src/config';
import { useAppDispatch, useAppSelector } from '../../../../front/src/redux/hooks';
import { readSelectorOnce } from '../../../../front/src/redux/redux.utils';
import type { RootState } from '../../../../front/src/redux/store';
import type { GroupMemberSearch, QueryType } from '../../../../front/src/types/api';
import { handleError } from '../../../../front/src/utils/common-utils';
import {
  addMembers,
  selectAllMembers,
  selectGroupAdminsOwners,
  selectGroupHasMembers,
  selectGroupMembersError,
  selectGroupMembersLoading,
  selectGroupMembersWithRole,
  selectGroupNbAdminsOwners,
  selectGroupNbMembers,
  selectHasGroupAdminPermissions,
  setCounts,
  setErrorMembers,
  setLoadingMembers,
  setNewMembers,
} from './group-members-slice';
import { GroupSummaryMembersCell } from './groupMembers/GroupMembersCell';
import { GroupMemberFragment } from './groupQuery';
import type { FetchMembersVariables } from './groupUtils';
import { useFetchGroup } from './groupUtils';

export const getGroupMembersQuery = gql`
  query GetGroupMembersQuery($groupId: IDOrString!, $offset: Int = 0, $status: String) {
    website {
      findGroup(groupId: $groupId) {
        # , role: "member"
        ownerCount: memberSearch(role: "owner") {
          meta {
            count
          }
        }
        adminCount: memberSearch(role: "admin") {
          meta {
            count
          }
        }
        memberCount: memberSearch(role: "member") {
          meta {
            count
          }
        }
        memberSearch(offset: $offset, limit: 100, status: $status) {
          group_members {
            ...GroupMemberFragment
          }
        }
      }
    }
  }
  ${GroupMemberFragment}
`;

const messages = defineMessages({
  refreshed: { defaultMessage: 'Cercle et membres actualisés', id: 'KgG432' },
});

interface Props {}

export const GroupMembers: FC<Props> = memo(function GroupMembers(props) {
  const {} = props;
  const client = useApolloClient();
  const dispatch = useAppDispatch();

  const loading = useAppSelector(selectGroupMembersLoading);
  const membersError = useAppSelector(selectGroupMembersError);
  const hasMembers = useAppSelector(selectGroupHasMembers);

  const fetchMembersWithOffset = useCallback(
    async (more?: boolean) => {
      const offset = more ? readSelectorOnce(selectAllMembers)?.length || 0 : 0;
      const reset = !offset;
      try {
        if (reset) {
          dispatch(setLoadingMembers(true));
        }
        // Big hack: consume the gql API as we would with a traditional REST API. In this project,
        // useQuery, the Apollo cache, data merge and the refetch have a highly unpredictable behavior.
        // To have a working "fetch more on scroll" feature, we bypass all of that and add the fetched
        // members to a map on redux.
        // When writing this comment, the API also has a bug: each request returns members in a random order.
        // Using a map in redux allows to dedupe.
        const groupId = readSelectorOnce((state: RootState) => state.groupMembers.group?.id);
        if (!groupId) throw new Error('Missing groupId (URL slug?), cannot update the group.');
        const variables: FetchMembersVariables = { groupId, offset };
        const isGroupAdmin = readSelectorOnce(selectHasGroupAdminPermissions);
        if (!isGroupAdmin) {
          variables.status = 'approved';
        }
        const { error, data } = await client.query<QueryType>({
          query: getGroupMembersQuery,
          variables,
        });
        if (error) throw error;
        const { memberCount, adminCount, ownerCount } = (data?.website?.findGroup || {}) as {
          memberCount: GroupMemberSearch;
          adminCount: GroupMemberSearch;
          ownerCount: GroupMemberSearch;
        };
        dispatch(
          setCounts({
            memberCount: memberCount.meta?.count,
            adminCount: adminCount.meta?.count,
            ownerCount: ownerCount.meta?.count,
          }),
        );
        dispatch(
          (reset ? setNewMembers : addMembers)(
            data?.website?.findGroup?.memberSearch?.group_members,
          ),
        );
      } catch (err) {
        handleError(err);
        dispatch(setErrorMembers(err));
        failureToast(
          "Argh, we couldn't load the members! Please try again later and let us know if the issue persists.",
        );
      }
    },
    [client, dispatch],
  );

  const initialFetchFnRef = useRef(fetchMembersWithOffset);

  useEffect(() => {
    initialFetchFnRef.current();
  }, []);

  if (loading && !hasMembers) return <LoaderBlock />;
  if (membersError || !hasMembers) return <ErrorBlock error={membersError} />;

  return <GroupMembersInner {...props} fetchMembersWithOffset={fetchMembersWithOffset} />;
});

interface GroupMembersInnerProps extends Props {
  fetchMembersWithOffset: (more?: boolean) => Promise<void>;
}

const GroupMembersInner: FC<GroupMembersInnerProps> = memo(function GroupMembersInner(props) {
  const { fetchMembersWithOffset } = props;

  const { refetch } = useFetchGroup(true);
  const refetchCb = useCallback(() => {
    refetch();
    fetchMembersWithOffset();
    successToast(<FormattedMessage {...messages.refreshed} />);
  }, [fetchMembersWithOffset, refetch]);

  const fetchMoreWithOffset = useCallback(
    () => fetchMembersWithOffset(true),
    [fetchMembersWithOffset],
  );

  const adminsOwners = useAppSelector(selectGroupAdminsOwners);

  // `!` because the parent component guarantees members is defined with hasMembers
  const members = useAppSelector(selectGroupMembersWithRole)!;
  let group_members = members;
  const membersCount2 = useAppSelector(selectGroupNbMembers);
  const adminsOwnersCount2 = useAppSelector(selectGroupNbAdminsOwners);

  return (
    <>
      {frontConfig.devTools && (
        <RefreshButton className="self-end my-2" refreshAction={refetchCb} />
      )}
      <>
        <GroupSummaryMembersCell groupMembers={adminsOwners} count={adminsOwnersCount2} isAdmin />
        <GroupSummaryMembersCell groupMembers={group_members} count={membersCount2} />
      </>
      <IfInViewPort whenVisible={fetchMoreWithOffset} />
    </>
  );
});
