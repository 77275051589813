import { useEffect, useRef } from 'react';

import type { GraphModalInstance } from '../../../../front/src/components/GraphModal/GraphModal';
import type { Refetch } from '../../../../front/src/types/common-models';

export function useRefetchOnOpenAndBg(tip: GraphModalInstance, refetch: Refetch) {
  const tipRef = useRef(tip);

  // Refetch when opening the popover
  useEffect(() => {
    tipRef.current = tip;
    if (tip) {
      refetch();
    }
  }, [refetch, tip]);

  // Refetch every minute
  useEffect(() => {
    const interval = setInterval(() => {
      const isPopoverClosed = !tipRef.current;
      if (isPopoverClosed && refetch) {
        refetch();
      }
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [refetch]);
}
