import classNames from 'classnames';
import type { MouseEvent, PointerEvent, ReactElement } from 'react';
import { forwardRef, useCallback } from 'react';
import type { MessageDescriptor } from 'react-intl';
import { useIntl } from 'react-intl';

export type ChatIconButtonPropType = {
  onClick?: (event: MouseEvent) => void;
  iconClassName?:
    | 'icon-close sm:text-sm'
    | 'icon-add-friend sm:text-sm'
    | 'icon-add-friend text-sm sm:text-xs'
    | 'icon-minus sm:text-sm'
    | 'icon-emoticon text-lg sm:text-md'
    | 'icon-image text-lg sm:text-md';
  icon?: ReactElement;
  className?: string;
  arial?: string | MessageDescriptor;
};

const baseClass =
  'flex justify-center items-center rounded-full text-gray-medium cursor-pointer outline-none w-11 h-11 sm:hover:bg-gray-lighter sm:hover:text-gray-dark sm:w-9 sm:h-9 border-2 border-transparent bg-clip-content';

export const ChatIconButton = forwardRef<HTMLDivElement, ChatIconButtonPropType>(
  function ChatIconButton({ onClick, iconClassName, icon, className, arial }, ref) {
    const { formatMessage } = useIntl();
    const buttonClassName = className ? classNames(baseClass, className) : baseClass;
    const arialLabel = typeof arial === 'object' ? formatMessage(arial) : arial;

    const preventDefault = useCallback((e: PointerEvent<HTMLDivElement>) => e.preventDefault(), []);

    return (
      <div
        role="button"
        onClick={onClick}
        onPointerDown={preventDefault}
        className={buttonClassName}
        tabIndex={0}
        ref={ref}
      >
        {icon || <i className={classNames('text-base', iconClassName)} arial-label={arialLabel} />}
      </div>
    );
  },
);
