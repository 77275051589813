import { gql, useQuery } from '@apollo/client';
import { useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { chatMessages } from '../../../../front/src/components/chat/chatMessages';
import { useAppApolloClient } from '../../../../front/src/hooks/useAppApolloClient';
import { usePermissions } from '../../../../front/src/hooks/usePermissions';
import { useAppDispatch, useAppSelector } from '../../../../front/src/redux/hooks';
import { openChatWindow } from '../../../../front/src/redux/slices/chat-service';
import type { FriendElementType } from '../../../../front/src/redux/slices/chat-slice';
import {
  selectChatCreationUsers,
  setChatCreationThread,
  stopCreatingChat,
} from '../../../../front/src/redux/slices/chat-slice';
import type { RootState } from '../../../../front/src/redux/store';
import { chatFragment } from '../../../../front/src/thread/api/inbox';
import type { QueryType } from '../../../../front/src/types/api';
import { ChatActionsHeader } from './ChatActionsHeader';
import { ChatField } from './ChatField';
import { ChatMessageList } from './ChatMessageList';
import { ChatUsers } from './ChatUsers';
import styles from './ChatWindow.module.scss';
import type { ChatMessageContentArgsType } from './createOrUpdateChatMessage';
import { createOrUpdateChatMessage } from './createOrUpdateChatMessage';
import { FriendsSelect } from './FriendsSelect';

const ChatCreationQuery = gql`
  query ChatCreationQuery($ids: [ID], $offset: Int!) {
    website {
      id
      findOrCreateInbox(userVisibility: $ids, offset: $offset, limit: 50) {
        ...chatFragment
        #note: sort = 'newest', force_sort = 'true' hardcoded in api
        childMessages(offset: $offset, limit: 50) {
          ...chatMessageFragment
        }
      }
    }
  }
  ${chatFragment}
`;

export const ChatCreationWindow = () => {
  const dispatch = useAppDispatch();
  const client = useAppApolloClient();
  const { authUserId } = usePermissions();
  const chatCreationUsersSelected = useAppSelector(selectChatCreationUsers);
  const chatCreationUsersRef = useRef(chatCreationUsersSelected);
  const [friends, setFriends] = useState<FriendElementType[]>(chatCreationUsersRef.current);
  const [withMenu, showMenu] = useState(true);
  const isEmpty = friends.length < 1;

  const ids: string[] = useMemo(
    () => [...friends.map(elt => elt.value), authUserId],
    [friends, authUserId],
  );

  // TODO: Keep this implementation here waiting for relevant performance tests
  // const { refetch, fetchMore } = useQueryNoRender(
  //   {
  //     query: ChatCreationQuery,
  //     variables: { ids, offset: 0 },
  //     skip: isEmpty,
  //   },
  //   ({ data }) => {
  //     const thread = data.website?.findOrCreateInbox;
  //     if (!thread) throw new Error('[useQueryNoRender] thread is undefined');
  //     dispatch(setChatCreationThread({ threadId: thread.id, thread }));
  //   },
  // );

  const { fetchMore } = useQuery<QueryType>(ChatCreationQuery, {
    variables: { ids, offset: 0 },
    skip: isEmpty,
    onCompleted: data => {
      const thread = data.website?.findOrCreateInbox;
      if (!thread) throw new Error('[useQueryNoRender] thread is undefined');
      if (!thread.id) throw new Error('[useQueryNoRender] thread.id is undefined');
      dispatch(setChatCreationThread({ threadId: thread.id, thread }));
    },
  });

  const thread = useAppSelector((state: RootState) =>
    state.chat.chatCreationThreadId
      ? state.chat.chats[state.chat.chatCreationThreadId]?.thread
      : undefined,
  );
  const loading = !thread;

  const close = useCallback(() => {
    dispatch(stopCreatingChat());
  }, [dispatch]);

  const sendMessage = useCallback(
    async (value: ChatMessageContentArgsType) => {
      if (!value.message && !value.file) {
        return;
      }
      const { id } = await createOrUpdateChatMessage(dispatch, authUserId, {
        ...value,
        threadId: thread?.id,
        recipientIds: ids,
      });

      await openChatWindow(client, {
        threadId: id,
        replaceCreation: true,
        authUserId,
        allowCache: true,
      })(dispatch);
    },
    [thread?.id, ids, dispatch, client, authUserId],
  );

  let listContent;

  if (withMenu) {
    listContent = null;
  } else if (isEmpty) {
    listContent = (
      <div className="h-full flex flex-col items-center justify-center px-5">
        <img src="/illustrations/icon-chatroom.svg" alt="chatroom" />
        <div className="text-center mt-5 mx-5 text-sm font-bold text-gray-dark leading-5">
          <FormattedMessage
            defaultMessage="ajouter au moins un ami pour commmencer une discussion"
            id="ChmpOK"
          />
        </div>
      </div>
    );
  } else if (loading) {
    listContent = (
      <div className="h-full flex items-center justify-center">
        <div className="loader" />
      </div>
    );
  } else if (thread) {
    listContent = <ChatMessageList thread={thread} fetchMore={fetchMore} />;
  } else {
    listContent = (
      <div className="h-full flex justify-center pt-10">
        <ChatUsers users={friends.map(elt => elt.label)} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <ChatActionsHeader close={close}>
        <div className="flex-1 text-base font-bold px-4">
          <FormattedMessage {...chatMessages.newInboxTitle} />
        </div>
      </ChatActionsHeader>
      <FriendsSelect value={friends} onChange={setFriends} showMenu={showMenu} />
      <div className="flex-1 flex flex-col overflow-hidden">{listContent}</div>
      {isEmpty || withMenu ? null : <ChatField sendMessage={sendMessage} />}
    </div>
  );
};
