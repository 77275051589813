import { createContext, useContext } from 'react';

export const AuthPromiseContext = createContext(async () => {});

export function useLoginWithPromise() {
  const fn = useContext(AuthPromiseContext);
  if (!fn)
    throw new Error(
      '[bug] useLoginWithPromise is called outside the context of AuthWithPromiseProvider.',
    );
  return fn;
}
