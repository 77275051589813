import type { TypePolicies } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';

import { childMessagesPolicy } from './childMessagesPolicy';
import { merge } from './merge';

const isNamedDomain = (value = '') =>
  typeof value === 'string' && !/^https?:\/\/(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/i.test(value.trim());

const filterOutFalsy = (stored: unknown[]) => (stored ?? []).filter((elt: unknown) => elt);

export const semiologicTypePolicies: TypePolicies = {
  Website: {
    fields: {
      getNotifications: offsetLimitPagination(),
      relationSearch: offsetLimitPagination(),
      getInboxList: offsetLimitPagination(),
      findMessage: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'Message',
            id: args?.messageId,
          });
        },
      },
      // categorySearch: {
      //   keyArgs: ['search', 'page', 'limit'],
      // },
      domainsWhiteList: {
        merge(_, incoming: string[]) {
          return (incoming ?? []).filter(isNamedDomain);
        },
      },
      graphdebate: {
        merge,
      },
      graphcomment: {
        merge,
      },
      custom: {
        merge,
      },
    },
  },
  Topic: {
    fields: {
      // findOrCreateThread: {
      //   keyArgs: ['sort', 'forceSort', 'childCommentLimit'],
      // },
      categories: {
        read: filterOutFalsy,
      },
    },
  },
  Graphdebate: {
    fields: {
      headings: {
        read: filterOutFalsy,
      },
    },
  },
  GraphdebateHeading: {
    fields: {
      children: {
        read: filterOutFalsy,
      },
    },
  },
  Thread: {
    fields: {
      childMessages: childMessagesPolicy,
    },
  },
  Message: {
    fields: {
      childMessages: childMessagesPolicy,
      hiddenEmbeds: {
        read(value) {
          if (typeof value === 'string') return JSON.parse(value) || [];
          return [];
        },
        merge(_, value) {
          return Array.isArray(value) ? JSON.stringify(value) : value;
        },
      },
    },
  },
  // QueryType: {
  //   fields: {
  //     profile: {
  //       keyArgs: ['pseudo', 'userId'],
  //     },
  //   },
  // },
};
