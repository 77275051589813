import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import { Loader } from '../Loader';

interface FloatingLoaderProps {
  extraContent?: ReactNode;
  hideByDefault?: boolean;
}

export const FloatingLoader: FC<FloatingLoaderProps> = memo(function FloatingLoader(props) {
  const { extraContent, hideByDefault } = props;

  return (
    <div
      className="fixed z-50 left-0 top-14 right-0 flex flex-col items-center space-y-2 w-max max-w-full mx-auto bg-blue-100/60 backdrop-blur rounded-lg text-center p-8"
      id="initialSpinnerOverlay"
      {...(hideByDefault && { style: { display: 'none' } })}
    >
      <Loader black />
      {extraContent}
    </div>
  );
});
