import classNames from 'classnames';
import type { FormApi } from 'final-form';
import type { FC, MouseEvent } from 'react';
import { memo, useCallback } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as IconSendOutlined } from '../../icons/icon-send-outlined.svg';
import type { ButtonV3Props } from '../Button/ButtonV3';
import { ButtonV3 } from '../Button/ButtonV3';
import type { IconButtonV3Props } from '../Button/ButtonV3Icon';
import { ButtonV3Icon } from '../Button/ButtonV3Icon';
import { Loader } from '../Loader';

export type SubmitButtonV3PropTypes = Omit<ButtonV3Props, 'onClick'> & {
  onClick?: (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>, form: FormApi) => void;
  variant?: ButtonV3Props['variant'];
  iconClassName?: string;
};

export const SubmitButtonV3: FC<SubmitButtonV3PropTypes> = memo(function SubmitButtonV3(props) {
  const { children, onClick: propsClick, className, variant, iconClassName, ...others } = props;
  const form = useForm() as FormApi;
  const { hasValidationErrors, submitting } = useFormState();

  const onClick = useCallback<NonNullable<ButtonV3Props['onClick']>>(
    event => {
      if (propsClick) {
        propsClick(event, form);
      }
      form.submit();
    },
    [propsClick, form],
  );

  return (
    <ButtonV3
      className={twMerge('SubmitButton min-w-40 relative md:pl-10', className)}
      color="primary"
      radius="rounded-md"
      variant={variant || 'outlined'}
      onClick={onClick}
      type="submit"
      disabled={hasValidationErrors || submitting}
      {...others}
    >
      <div className={classNames('flex items-center', submitting && 'gap-2')}>
        <span className="px-4 _md:pr-8 md:pl-0">{children}</span>
        {submitting && <Loader />}
      </div>
      <IconSendOutlined
        className={twMerge('absolute top-0 _md:right-0 md:left-0 h-10 w-10', iconClassName)}
      />
    </ButtonV3>
  );
});

interface SubmitButtonV3IconProps extends SubmitButtonV3PropTypes {
  icon: IconButtonV3Props['icon'];
  loading?: boolean;
}

export const SubmitButtonV3Icon: FC<SubmitButtonV3IconProps> = memo(function SubmitButtonV3Icon(
  props,
) {
  const { children, onClick: propsClick, className, icon, loading, ...others } = props;
  const form = useForm() as FormApi;
  const { hasValidationErrors, submitting } = useFormState();

  const onClick = useCallback<NonNullable<ButtonV3Props['onClick']>>(
    event => {
      if (propsClick) {
        propsClick(event, form);
      }
      form.submit();
    },
    [propsClick, form],
  );

  return (
    <ButtonV3Icon
      className={twMerge('SubmitButton min-w-40', className)}
      color="primary"
      onClick={onClick}
      type="submit"
      disabled={hasValidationErrors || submitting || loading}
      icon={submitting || loading ? <Loader black /> : icon}
      {...others}
    />
  );
});
