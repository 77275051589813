import { defineMessages, FormattedMessage } from 'react-intl';

import { failureToast } from '../../components/Toast';
import { compressImage } from '../../editor/_old/compress-image';
import { editorMessages } from '../../editor/_old/messages';
import { wait } from '../../utils/common-utils';
import { buildApiFileName } from '../../utils/web';
import axios from '../axios';

export const messages = defineMessages({
  imagesOnly: { defaultMessage: "L'upload est réservé aux images seulement", id: '9eX/pe' },
});

export async function uploadImage(file: File) {
  // only allow images
  if (!/image.*/.test(file.type)) {
    failureToast(<FormattedMessage {...messages.imagesOnly} />);
    throw new Error('Not an image');
  }

  return await uploadFile(file);
}

export async function uploadFile(file: File, isImage?: boolean) {
  if (!isImage && /image.*/.test(file.type)) {
    isImage = true;
  }

  if (isImage) {
    file = await compressImage(file);

    // max 2 Mo for images
    if (file.size > 1024 * 1024 * 2) {
      const key = 'maximum file length exceeded';
      failureToast(<FormattedMessage {...editorMessages[key]} />);
      throw new Error(key);
    }
  }

  const newFile = await uploadFileRaw(file);

  // It seems the API can answer 404. I add a safety delay (that may still be too short)
  // See https://trello.com/c/LTFBWORr/1058-jai-eu-un-404-une-fois-apr%C3%A8s-un-upload-dimage-est-ce-que-lupload-est-transactionnel-dans-lapi
  await wait(200);

  const { _id: newFileId } = newFile;
  const fileUrl = buildApiFileName(`${newFileId}.${newFile.metadata.extension}`);
  return fileUrl;
}

export const uploadFileRaw = async (
  file: File,
): Promise<{
  _id: string;
  metadata: { extension: string };
}> => {
  const formData = new FormData();
  formData.append('file', file);
  try {
    const res = await axios.post('/api/file', formData, {
      timeout: 2 * 60 * 1000,
    });

    if (!res || !res.data) {
      throw new Error('no data');
    }

    return Promise.resolve(res.data);
  } catch (error: any) {
    const response = error.response?.data;
    throw new Error(response?.err || error.message);
  }
};
