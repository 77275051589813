import type React from 'react';
import type { ToastOptions } from 'react-toastify';
import { Slide, toast } from 'react-toastify';

export { ToastContainer } from 'react-toastify';

const toastConfig: ToastOptions = {
  position: 'top-center',
  transition: Slide,
  bodyClassName: 'ml-2 text-center',
  autoClose: 4000,
  draggable: false,
  pauseOnFocusLoss: true,
} as const;

const commonClassNames = 'rounded-md border justify-around items-center m-2 select-none';
export const confirmationToast = (str: React.ReactNode, options: ToastOptions = {}) => {
  toast.info(str, {
    ...toastConfig,
    // className: `${commonClassNames} ${styles.container} bg-gray-dark text-white`,
    // progressStyle: { background: '#61BD6D' },
    ...options,
  });
};

export const failureToast = (str: React.ReactNode, options: ToastOptions = {}) => {
  toast.error(str, {
    ...toastConfig,
    // className: `${commonClassNames} ${styles.container} text-white bg-primary-base`,
    // progressStyle: { background: 'white' },
    ...options,
  });
};

export const successToast = (str: React.ReactNode, options: ToastOptions = {}) => {
  toast.success(str, {
    ...toastConfig,
    // className: `${commonClassNames} ${styles.container} text-white bg-success`,
    // progressStyle: { background: 'white' },
    ...options,
  });
};

export const warnToast = (str: React.ReactNode, options: ToastOptions = {}) => {
  toast.warn(str, { ...toastConfig, className: commonClassNames, ...options });
};
