import type { BanDateRange } from '../i18n/ban';

export const ONE_HOUR = 60 * 60 * 1000;
export const ONE_DAY = 24 * ONE_HOUR;
export const ONE_WEEK = 7 * ONE_DAY;

export const TWO_WEEKS = 2 * ONE_WEEK;

// Standard durations allow to check if the duration is standard or custom, for the initial form rendering.
const standardDurations = new Set([ONE_HOUR, ONE_DAY, ONE_WEEK, TWO_WEEKS]);

export const addToCurrentDate = (duration: number) => new Date(new Date().getTime() + duration);
export const datesToDuration = (duration: number) => {
  // TWO_WEEKS are not included: they are not a new unit, but use the same unit as ONE_WEEK, with a length of 2.
  const unit = [ONE_WEEK, ONE_DAY, ONE_HOUR, ONE_DAY].find(r => r / duration <= 1) as number;
  const length = Math.round(duration / unit);
  const duration2: BanDateRange = {
    unit,
    length,
  };
  return duration2;
};

export function checkIsCustom(duration: number) {
  return !standardDurations.has(duration);
}

export type Duration = { unit: number; length: number };
export const fillTimes = <T = string>(
  times: number,
  { length = 1, ...obj }: Partial<Duration> & { label: T } & Record<string, unknown>,
) =>
  Array(times)
    .fill(obj)
    .map((el, i) => ({ ...el, length: i + length }));

const durationToNumber = ({ unit = 0, length = 0 }: Duration) => unit * length;

export function durationsEqual(d1: Duration, d2: Duration) {
  return durationToNumber(d1) === durationToNumber(d2);
}

export const formatDate = (src: Date | unknown) => {
  const date = src instanceof Date ? src : new Date(src as string | number);
  return date.toLocaleDateString();
};

export const formatTime = (src: Date | unknown, withSeconds = true) => {
  const date = src instanceof Date ? src : new Date(src as string | number);
  return date.toLocaleTimeString().slice(0, withSeconds ? 8 : 5);
};

export const formatDateTime = (date: unknown, withSeconds = true) => {
  return `${formatDate(date)} ${formatTime(date, withSeconds)}`;
};

export const getLocaleDateDigits = (date: string | Date, locale: string) => {
  const dateObject = date instanceof Date ? date : new Date(date);
  return dateObject.toLocaleDateString(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};
