import type { HTMLReactParserOptions } from 'html-react-parser';
import Parser from 'html-react-parser';
import React, { Children } from 'react';

import { isBrowser } from './web';

export const ONCE_LOADED_ACTIONS_NAME = `__gc__once__loaded__actions__`;

export const onLoadPromise = new Promise<boolean>(res => {
  if (!isBrowser) return;

  window.onload = () => setTimeout(() => res(true));
});

(async () => {
  await onLoadPromise;

  if (!isBrowser) return;

  (window as any)[ONCE_LOADED_ACTIONS_NAME] = (window as any)[ONCE_LOADED_ACTIONS_NAME] || [];

  (window as any)[ONCE_LOADED_ACTIONS_NAME].forEach((fn: () => void) => fn());
})();

/*
 * Prints HTML directly inside jsx (same as dangerouslySetHtml)
 * dangerous: do not use it on user submitted data
 */
export const html = (
  htmlStr: string,
  opts: HTMLReactParserOptions = { htmlparser2: { decodeEntities: true } },
) => Parser(htmlStr || '', opts);

/**
 * Strip Html from a string, usage:
 *
 * stripHtml('<b>foo</b>')
 * => foo
 */
export const stripHtml = (str?: string | null) =>
  str
    ? str
        .replace(/<(p)>(?!<\/p>)(.*?)<\/\1>/g, '$2<br />')
        .replace(/<br\s?\/?>/g, '\n')
        .replace(/<\/[^>]*><[^>]*>/g, ' ')
        .replace(/<[^>]*>?/gm, '')
        // remove multiple spaces
        .replace(/ +(?= )/g, '')
        .trim()
    : '';

// Preserve line breaks: convert \n to <br />
export function textToHtml<T extends string | undefined>(
  str: T,
): T extends string ? string : undefined {
  if (!str) return str as unknown as T extends string ? string : undefined;
  return (str as string).replaceAll('\n', '<br />') as T extends string ? string : undefined;
}

export type ScrollAnchorArgType = {
  anchorName: string;
  options?: boolean | ScrollIntoViewOptions;
};

export const scrollToAnchor = ({ anchorName, options }: ScrollAnchorArgType) => {
  const doScroll = () => {
    const elt = document.querySelector(`#${anchorName}`);
    if (!elt) return;

    elt.scrollIntoView(options);
  };

  // With `options: { behavior: 'smooth' }`, sometimes, there is no scroll without the setTimeout, I don't know why.
  // The setTimeout is a hack, but it seems to work. To retest, the behavior changes over time (browsers updates?).
  // Also, Firefox and Safari first set the scroll to a weird, incorrect position, that overrides the immediate scroll above. I don't know why. As a workaround, I force re-set the scroll at the expected position.
  doScroll();
  setTimeout(doScroll, 100);
};

export function scrollBottomReached(elt: Element) {
  if (!elt) {
    throw new Error('No scrolling element to check scroll bottom reached.');
  }
  const { scrollTop, scrollHeight, clientHeight } = elt;

  // `<= 3` because `scrollTop`, `clientHeight` and `scrollHeight` are all rounded, which could lead to
  // a difference of 3 in the worst case, when the bottom is reached.
  // https://stackoverflow.com/questions/3898130/check-if-a-user-has-scrolled-to-the-bottom-not-just-the-window-but-any-element#comment92747215_34550171
  return Math.abs(scrollTop + clientHeight - scrollHeight) <= 3;
}

export const scriptLoader = async ({
  id,
  async = true,
  defer = true,
  beforeOnload,
  onload,
  attributes = {},
  src,
  ...other
}: {
  beforeOnload?: boolean;
  async?: boolean;
  defer?: boolean;
  src?: string;
  attributes?: Record<string, string>;
  onload?: (event: Event) => void;
  id?: string;
}) => {
  if (!beforeOnload) {
    await onLoadPromise;
  }
  if (id && document.getElementById(id)) return true;
  const script = document.createElement('script');
  script.id = id as string;
  script.type = 'text/javascript';
  script.async = async;
  script.defer = defer;

  Object.entries(attributes).forEach(([key, val]) => script.setAttribute(key, val));
  Object.entries(other).forEach(([key, val]) => script.setAttribute(key, val as any));

  const resultPromise = src
    ? new Promise<boolean>(res => {
        script.addEventListener(
          'load',
          (...arg) => {
            if (typeof onload === 'function') onload(...arg);
            res(true);
          },
          false,
        );
      })
    : true;

  if (src) script.src = src;
  document.body.appendChild(script);
  return resultPromise;
};

export const nest = (props: React.PropsWithChildren<unknown>) => (
  <>
    {Children.toArray(props.children)
      .reverse()
      .reduce(
        (child, parent) =>
          React.cloneElement(
            parent as React.ReactElement,
            (parent as React.ReactElement).props,
            child,
          ),
        null as React.ReactNode,
      )}
  </>
);

export const isClickAwayFromElement = (clickEvent: MouseEvent, element: HTMLElement) => {
  const { clientX, clientY } = clickEvent;
  const { top, bottom, right, left } = element.getBoundingClientRect();

  if (clientX < left || clientX > right || clientY < top || clientY > bottom) {
    return true;
  }
  return false;
};
