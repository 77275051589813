import type { FC, HTMLAttributes } from 'react';
import { memo } from 'react';
import { twMerge } from 'tailwind-merge';

import type { CommonDensityMenuProps } from '../DensityMenu';
import type { DensityType } from './DensityItem';
import { DensityItem, densityMessages } from './DensityItem';

type DensityMenuDesktopProps = HTMLAttributes<HTMLDivElement> & CommonDensityMenuProps;

const DensityMenuDesktop: FC<DensityMenuDesktopProps> = memo(function DensityMenuDesktop(props) {
  const {
    options,
    currentDensity,
    isCurrentDensity,
    onDensityChange,
    itemsClass,
    className,
    ...otherProps
  } = props;

  return (
    <div className={twMerge('DensityMenuDesktop', className)} {...otherProps}>
      {Object.keys(densityMessages)
        .filter(type => type !== 'card') // Remove card density for now (12/2023)
        .map(type => (
          <DensityItem
            key={type}
            densityType={type as DensityType}
            isActive={isCurrentDensity(type as DensityType)}
            className={itemsClass}
            onClick={() => onDensityChange(type as DensityType)}
          />
        ))}
    </div>
  );
});

export default DensityMenuDesktop;
