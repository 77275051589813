import { useQuery } from '@apollo/client';
import Image from 'next/image';
import { useRouter } from 'next/router';
import type { FC, ReactNode } from 'react';
import React, { memo, useCallback, useMemo } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { ErrorBlock } from '../../../../front/src/components/ErrorBlock';
import { IconClose } from '../../../../front/src/components/icons/Icons';
import { Link } from '../../../../front/src/components/Link';
import { Loader } from '../../../../front/src/components/Loader';
import { PopupMenu } from '../../../../front/src/components/PopupMenu/PopupMenu';
import { PopupMenuElement } from '../../../../front/src/components/PopupMenu/PopupMenuElement';
import { apiUrl, imageUrl } from '../../../../front/src/config';
import { useAuthProfile, usePermissions } from '../../../../front/src/hooks/usePermissions';
import { useWebsite } from '../../../../front/src/hooks/useWebsite';
import { ReactComponent as IconLogout } from '../../../../front/src/icons/icon-deconnexion.svg';
import { ReactComponent as IconFriends } from '../../../../front/src/icons/icon-friends.svg';
import { ReactComponent as IconHandleMySite } from '../../../../front/src/icons/icon-gerer-mon-site.svg';
import { ReactComponent as IconComments } from '../../../../front/src/icons/icon-mes-comm.svg';
import { ReactComponent as IconPreferences } from '../../../../front/src/icons/icon-mes-prefs.svg';
import { ReactComponent as IconModeratePage } from '../../../../front/src/icons/icon-modere-cette-page.svg';
import { ReactComponent as IconMyAccount } from '../../../../front/src/icons/icon-mon-compte.svg';
import { ReactComponent as IconMyProfile } from '../../../../front/src/icons/icon-mon-profile.svg';
import { useAppSelector } from '../../../../front/src/redux/hooks';
import { selectThreadId } from '../../../../front/src/thread/ThreadMessage/topic-slice';
import type { Group, QueryType } from '../../../../front/src/types/api';
import { useAreGroupsEnabled } from '../../utils/common';
import { listGroupsQuery } from '../groups/groupsManager/searchGroupsQuery';
import { useAllowAdminOfGroups } from '../groups/groupUtils';

const messages = defineMessages({
  yourSite: { defaultMessage: 'Votre site', id: 'vvUEfg' },
  groups: { defaultMessage: 'Cercles', id: '8euUn9' },
  groupAlphaCircle: { defaultMessage: 'AlphaCircle', id: 'QU8cyb' },
  groupNew: { defaultMessage: 'Gérer les cercles', id: 'nDpNrJ' },
  yourAccount: { defaultMessage: 'Votre compte', id: 'C52AKH' },
  guestMode: { defaultMessage: 'Vous êtes en mode <b>invité</b>', id: 'GgxeJp' },
  handleMySite: { defaultMessage: 'Gérer mon site', id: 'ScHy8a' },
  moderatePage: { defaultMessage: 'Modérer cette page', id: 'YvNn2a' },
  quitInvited: { defaultMessage: 'Quitter le mode invité', id: 'f+vdFJ' },
  profile: { defaultMessage: 'Mon profil', id: 'jdNpit' },
  comments: { defaultMessage: 'Mes commentaires', id: 'kraGzu' },
  myAccount: { defaultMessage: 'Mon compte', id: 'Zm1Gfe' },
  settings: { defaultMessage: 'Mes préférences', id: 'igFqms' },
  logout: { defaultMessage: 'Déconnexion', id: 'FEM+27' },
  noGroup: { defaultMessage: 'Pas de cercle disponible', id: 'J1K3T9' },
});

export type HeaderUserMenuSection = {
  title: JSX.Element;
  elements: JSX.Element[];
};
const isHeaderMenuSection = (section?: HeaderUserMenuSection): section is HeaderUserMenuSection =>
  Boolean(section);

/**
 *
 * @param showAccount
 * @param closeMenu
 * @constructor
 */
export const HeaderUserMenu: React.FC<{ showAccount: () => void; closeMenu: () => void }> = ({
  showAccount,
  closeMenu,
}) => {
  const { formatMessage } = useIntl();
  const { reload } = useRouter();
  const { isModerator, logout, isAuthDone } = usePermissions();

  const areGroupsEnabled = useAreGroupsEnabled();
  const showGroupManager = useAllowAdminOfGroups();

  const { profile, isGuest } = useAuthProfile();
  const { pseudo } = profile;
  const { id: websiteId, subscribeByTokenVip } = useWebsite();
  const threadId = useAppSelector(selectThreadId);

  const linkToBO = useCallback(
    (path = '') => `${apiUrl}/admin/#/website/${websiteId}/graphdebate/${path}`,
    [websiteId],
  );
  const handleLogout = useCallback(async () => {
    if (!logout) return;

    if (subscribeByTokenVip) {
      await logout();
      reload();
    } else {
      logout();
    }
  }, [logout, reload, subscribeByTokenVip]);

  const sections = useMemo<HeaderUserMenuSection[]>(() => {
    return (
      [
        areGroupsEnabled
          ? {
              title: <FormattedMessage {...messages.groups} />,
              elements: [
                <MenuCirclesElement key="link-groups" closeMenu={closeMenu} />,
                ...(showGroupManager
                  ? [
                      <Link key="link-new-group" to="/new-group">
                        <a>
                          <PopupMenuElement
                            icon={<IconFriends className="w-[18px] h-[18px]" />}
                            title={formatMessage(messages.groupNew)}
                            onClick={closeMenu}
                          />
                        </a>
                      </Link>,
                    ]
                  : []),
              ],
            }
          : undefined,
        isModerator
          ? {
              title: <FormattedMessage {...messages.yourSite} />,
              elements: [
                <a key="manage" href={linkToBO()} target="_blank" rel="noopener noreferrer">
                  <PopupMenuElement
                    icon={<IconHandleMySite />}
                    title={formatMessage(messages.handleMySite)}
                    onClick={closeMenu}
                  />
                </a>,
                ...(threadId
                  ? [
                      <a
                        key="moderate"
                        href={linkToBO(`?tags=thread_id:${threadId}&tab=all`)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <PopupMenuElement
                          icon={<IconModeratePage />}
                          title={formatMessage(messages.moderatePage)}
                          onClick={closeMenu}
                        />
                      </a>,
                    ]
                  : []),
                // <button type="button">
                //   <i className="icon-newtab" /> Mode éditeur
                // </button>
              ],
            }
          : undefined,
        isAuthDone && isGuest
          ? {
              title: (
                <FormattedMessage
                  {...messages.guestMode}
                  values={{ b: (chunk: ReactNode) => <b>{chunk}</b> }}
                />
              ),
              elements: [
                <PopupMenuElement
                  key="exit"
                  icon={<IconClose />}
                  title={formatMessage(messages.quitInvited)}
                  onClick={handleLogout}
                  role="button"
                />,
              ],
            }
          : undefined,
        pseudo
          ? {
              title: <FormattedMessage {...messages.yourAccount} />,
              elements: [
                <Link key="link-user" to="profile" params={{ pseudo }}>
                  <a>
                    <PopupMenuElement
                      icon={<IconMyProfile />}
                      title={formatMessage(messages.profile)}
                      onClick={closeMenu}
                    />
                  </a>
                </Link>,
                <Link key="user-comments" to="profile" params={{ pseudo, tab: 'comments' }}>
                  <a>
                    <PopupMenuElement
                      icon={<IconComments />}
                      title={formatMessage(messages.comments)}
                      onClick={closeMenu}
                    />
                  </a>
                </Link>,
                <Link key="user-preferences" to="profile" params={{ pseudo, tab: 'preferences' }}>
                  <a>
                    <PopupMenuElement
                      icon={<IconPreferences />}
                      title={formatMessage(messages.settings)}
                      onClick={closeMenu}
                    />
                  </a>
                </Link>,
                <PopupMenuElement
                  key="user-account"
                  icon={<IconMyAccount />}
                  title={formatMessage(messages.myAccount)}
                  role="button"
                  onClick={showAccount}
                />,
                <PopupMenuElement
                  key="logout"
                  icon={<IconLogout />}
                  title={formatMessage(messages.logout)}
                  onClick={handleLogout}
                  role="button"
                />,
              ],
            }
          : undefined,
      ] as HeaderUserMenuSection[]
    ).filter<HeaderUserMenuSection>(isHeaderMenuSection);
  }, [
    areGroupsEnabled,
    closeMenu,
    formatMessage,
    handleLogout,
    isAuthDone,
    isGuest,
    isModerator,
    linkToBO,
    pseudo,
    showAccount,
    showGroupManager,
    threadId,
  ]);

  return <PopupMenu sections={sections} profile={profile} closeMenu={closeMenu} />;
};

interface MenuCirclesElementProps {
  closeMenu: VoidFunction;
}

export const MenuCirclesElement: FC<MenuCirclesElementProps> = memo(function MenuCirclesElement(
  props,
) {
  const { closeMenu } = props;

  const { loading, error, data } = useQuery<QueryType>(listGroupsQuery, {
    fetchPolicy: 'cache-first',
  });

  if (loading) {
    return (
      <div className="flex justify-center my-2">
        <Loader black />
      </div>
    );
  }
  if (error || !data) return <ErrorBlock error={error} />;

  const groups = data.website?.groupSearch?.groups;

  if (!groups?.length) {
    return (
      <div className="my-2 italic">
        <FormattedMessage {...messages.noGroup} />
      </div>
    );
  }

  return (
    <>
      {(groups as Group[]).map(({ slug, image, name }) => (
        <Link key={slug} to={`/group/${slug}`}>
          <a>
            <PopupMenuElement
              icon={
                image ? (
                  <Image src={`${imageUrl}${image}`} alt={name || ''} width={18} height={18} />
                ) : null
              }
              title={name}
              onClick={closeMenu}
            />
          </a>
        </Link>
      ))}
    </>
  );
});
