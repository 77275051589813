import { twMerge } from 'tailwind-merge';

import type { LayoutBlock } from '../../../../front/src/types/layout';

//TODO: [29/03/24] isBlockToHideOnMobile and isBlockToHideOnDesktop functions might not be usefull anymore since api fills device array with ['mobile', 'desktop] by default.
//TODO: remove it if not needed soon
export const isBlockToHideOnMobile = (block: LayoutBlock) =>
  block?.device?.length === 1 && block?.device[0] === 'desktop';

export const isBlockToHideOnDesktop = (block: LayoutBlock) =>
  block?.device?.length === 1 && block?.device[0] === 'mobile';

export const isBlockToDisplayOnMobile = (block: LayoutBlock) =>
  block.device.some(device => device === 'mobile');

export const isBlockToDisplayOnDesktop = (block: LayoutBlock) =>
  block.device.some(device => device === 'desktop');

export const handleLayoutBlockDisplayClass = (block: LayoutBlock) =>
  twMerge(
    !isBlockToDisplayOnMobile(block) && '_md:hidden',
    !isBlockToDisplayOnDesktop(block) && 'md:hidden',
  );
