import { type PayloadAction } from '@reduxjs/toolkit';

import { createAppSlice } from '../../redux/hooks';
import type { RootState } from '../../redux/store';
import type { Category, Maybe } from '../../types/api';

// If a state should not be reset on logout, check /front/src/redux/store.ts

export type Tags = Maybe<Category>[];

export interface TagsState {
  tags?: Tags;
}

const initialState: TagsState = {};

// packages/front/src/redux/store.ts
export const tagsSlice = createAppSlice({
  name: 'tags',
  initialState,
  reducers: {
    /** Called when the initial list of tags is loaded in the tags page. */
    setTags(state, { payload }: PayloadAction<Tags | undefined>) {
      state.tags = payload;
    },
  },
});

export const { setTags } = tagsSlice.actions;

export const selectTags = (state: RootState) => state.tags.tags;
