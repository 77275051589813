import type { Binding } from 'classnames/bind';
import type { AnchorHTMLAttributes, ButtonHTMLAttributes, MutableRefObject } from 'react';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import type { ThemeColors } from '../../types/theme';

const sizes = {
  sm: 'text-sm',
  base: 'text-base', // default
  base2: 'text-base2',
  md: 'text-md',
  md2: 'text-md2',
  lg: 'text-lg',
  xl: 'text-xl',
};

type BaseProps = {
  color?: ThemeColors;
  variant?: 'contained' | 'outlined' | 'text';
  radius?: 'rounded-full' | 'rounded-md' | 'rounded' | 'rounded-sm' | false;
  isIcon?: boolean;
  noPadding?: boolean;
  size?: keyof typeof sizes;
};

export type ButtonV3Props = BaseProps &
  AnchorHTMLAttributes<HTMLAnchorElement> &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    as?: 'button' | 'link';
    // button props
    submit?: boolean;

    // link props
    linkDisableIndex?: boolean;
  };

interface BtnColorVariants extends Binding {
  base: string;
  outlined: string;
  contained: string;
  text: string;
}

const colorClasses: Record<ThemeColors, BtnColorVariants> = {
  gray: {
    base: 'text-gray-medium bg-transparent hover:text-gray',
    outlined:
      'border border-solid border-gray-semiLight text-gray-medium bg-transparent ' +
      'hover:border-gray-medium hover:text-gray',
    contained:
      'text-white bg-red-350 border-red-350 ' +
      'hover:text-red-350 hover:border-red-350 hover:bg-white',
    text: 'text-gray-medium bg-transparent hover:border-gray-medium hover:text-gray hover:bg-gray-lighter',
  },
  primary: {
    base: 'text-primary',
    outlined:
      'border border-solid border-primary text-primary bg-transparent ' +
      'hover:border-primary hover:text-white hover:bg-primary',
    contained:
      'text-white border-solid border border-primary bg-primary border-primary ' +
      'hover:text-primary hover:border-primary hover:bg-white',
    text: 'text-primary bg-transparent hover:text-white hover:bg-primary',
  },
  secondary: {
    base: 'text-secondary',
    outlined:
      'border border-solid border-secondary text-secondary bg-transparent ' +
      'hover:border-secondary hover:text-white hover:bg-secondary',
    contained:
      'text-white border-solid border border-secondary bg-secondary border-secondary ' +
      'hover:text-secondary hover:border-red-350 hover:bg-secondary',
    text: 'text-secondary bg-transparent hover:text-white hover:bg-secondary',
  },
};

/**
 * TODO: Temp create V3 button in aim not to break all existing buttons
 * Will be merged with old button component in next commits
 */
export const ButtonV3 = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonV3Props>(
  (props, ref) => {
    const {
      as = 'button',
      className,
      children,
      color = 'gray',
      variant = 'base',
      radius = 'rounded-md',
      submit,
      linkDisableIndex,
      href,
      isIcon,
      noPadding,
      size = 'base',
      ...others
    } = props;

    const styles = colorClasses[color];
    const variantClasses = styles[variant];
    const isLink = as === 'link' && !!href;

    const sizeClass = sizes[size];

    let baseClassesLayout = `group-button flex items-center justify-center disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-400`;
    if (!className?.includes('absolute')) baseClassesLayout += ' relative';
    const baseClassesStyle = twMerge(
      'min-h-touch min-w-touch',
      !noPadding && (isIcon ? 'p-3' : 'h-10 py-1 px-2'),
    );

    const computedClasses = twMerge(
      baseClassesLayout,
      baseClassesStyle,
      sizeClass,
      variantClasses,
      props.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
      radius,
      className,
    );

    if (isLink) {
      return (
        <a
          ref={ref as MutableRefObject<HTMLAnchorElement>}
          href={href}
          rel={linkDisableIndex ? 'nofollow' : undefined}
          className={computedClasses}
          tabIndex={props.disabled ? -1 : 0}
          {...(others as AnchorHTMLAttributes<HTMLAnchorElement>)}
        >
          {children}
        </a>
      );
    }

    // eslint-disable-next-line global-require
    return (
      <button
        ref={ref as MutableRefObject<HTMLButtonElement>}
        className={computedClasses}
        type={submit ? 'submit' : 'button'}
        tabIndex={props.disabled ? -1 : 0}
        {...others}
      >
        {children}
      </button>
    );
  },
);
