import { useRouter as useNavigation } from 'next/navigation';
import type { ReactNode } from 'react';
import { defineMessages } from 'react-intl';

import type { GraphModalInstance } from '../../../components/GraphModal/GraphModal';
import { GraphModal } from '../../../components/GraphModal/GraphModal';
import { useConnectedRouter } from '../../../hooks/useConnectedRouter';
import { deleteTopic } from '../../api/topic';
import { RemoveContent } from '../../Remove/RemoveContent';
import { useRemoveTopicWrapper } from '../../thread-utils';

const messages = defineMessages({
  title: { defaultMessage: "Suppression d'un sujet", id: 'qEOgMA' },
  guide: { defaultMessage: 'Etes-vous sûr(e) de vouloir supprimer votre sujet?', id: 'fFZEqm' },
  cancel: { defaultMessage: 'garder le sujet', id: 'UxFHu0' },
  remove: { defaultMessage: 'supprimer', id: 'y7ovCd' },
  confirmationLabel: { defaultMessage: 'le sujet a été supprimé', id: '7k1ks8' },
  failureLabel: { defaultMessage: 'impossible de supprimer le sujet', id: '8k0LuM' },
});

export type TopicRemoverProps = {
  topicId: string;
  content: string;
  children?: ReactNode;
};

/**
 *
 * @param topicId
 * @param content
 * @param children
 * @constructor
 */
export const TopicRemover = ({ topicId, content, children }: TopicRemoverProps) => {
  const remove = useRemoveTopicWrapper(deleteTopic, topicId);
  const { replace } = useConnectedRouter();
  const { refresh } = useNavigation();

  return (
    <GraphModal
      maxWidth={400}
      lazyContent={(tip: GraphModalInstance) => (
        <RemoveContent
          messages={messages}
          cancel={() => tip?.hide()}
          remove={async () => {
            await remove();
            tip?.hide();
            await replace('/');
            refresh();
          }}
          content={content}
        />
      )}
      showPageOverlay
      forceModal
    >
      {children}
    </GraphModal>
  );
};
