import type { DOMAttributes, HTMLAttributes } from 'react';

import { IconCloseCircle } from './Icons';

const sizes = {
  s: 'w-[12px] h-[12px]',
  m: 'w-[14px] h-[14px]',
};

interface Props {
  onClick?: DOMAttributes<HTMLSpanElement>['onClick'];
  title?: HTMLAttributes<HTMLSpanElement>['title'];
  size?: keyof typeof sizes;
}

export function IconRemove({ onClick, title, size = 's' }: Props) {
  return onClick || title ? (
    <span
      role="button"
      aria-label={title}
      tabIndex={-1}
      onClick={onClick}
      title={title}
      className="p-2 -mt-1 -mx-2 -mb-2 group"
    >
      <IconCloseCircle
        className={`${sizes[size]} text-white rounded-full bg-gray-550 group-hover:bg-primary-base p-[2px]`}
      />
    </span>
  ) : (
    <span className="rounded-full bg-current">
      <IconCloseCircle className={`${sizes[size]} text-white rounded-full p-[2px]`} />
    </span>
  );
}
