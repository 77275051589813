import type { LinkProps as NextLinkProps } from 'next/link';
import NextLink from 'next/link';
import type { FC, ReactNode } from 'react';
import React, { Children, cloneElement, memo } from 'react';

import { useCleanRoutes } from '../hooks/useCleanRoutes';
import type { QueryParamsMapType } from '../utils/routing';

export type LinkProps = Omit<NextLinkProps, 'href'> & {
  to: string;
  params?: QueryParamsMapType;
  disabled?: boolean;
  children?: ReactNode;
};

export const Link: FC<LinkProps> = memo(function Link(props: LinkProps) {
  const { children, disabled = false, to, params = {}, passHref = true, ...others } = props;
  const { cleanRoute } = useCleanRoutes();
  const child = Children.only(children) as React.ReactElement;
  if (disabled) return child;

  const { href, as } = cleanRoute({
    pathname: to,
    query: params as Record<string, string>,
  });

  return (
    <NextLink
      href={href}
      as={props.as ?? as ?? undefined}
      passHref={passHref}
      {...others}
      legacyBehavior
    >
      {cloneElement(child, { href: undefined })}
    </NextLink>
  );
});
