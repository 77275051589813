import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { stringToArrayElement } from '../../../graphdebate/src/utils/stringToColor';
import { lightenHexColor } from '../utils/colors';
import type { AvatarPropsType } from './Avatar';
import { sizesToPixels } from './Avatar-const';

const defaultBackgroundColors = [
  '#cc4b2f',
  '#cc7132',
  '#cd9835',
  '#cebf39',
  '#b5cd3a',
  '#8fcc38',
  '#6dcc37',
  '#4acc37',
  '#34cc3e',
  '#34cc5e',
  '#34cd82',
  '#35cda7',
  '#35cecd',
  '#31aacc',
  '#2e84cc',
  '#2b5fcb',
  '#2a3ccb',
  '#4131cb',
  '#6632cb',
  '#8a32cb',
  '#b033cb',
  '#cc32c1',
  '#cc2f9b',
  '#cc2d75',
  '#cc2b50',
  '#cc2a2e',
];

type DefaultLetterAvatarProps = Pick<
  AvatarPropsType,
  'pseudo' | 'profile' | 'size' | 'className' | 'style'
>;

export const DefaultLetterAvatar = forwardRef<HTMLImageElement, DefaultLetterAvatarProps>(
  (props, ref) => {
    const { pseudo: propsPseudo, profile, className, size = 'medium', style } = props;
    const usedPseudo = propsPseudo || profile?.pseudo;

    const lettersToDisplay = usedPseudo?.slice(0, 2);
    const backgroundColor = stringToArrayElement(usedPseudo || '', defaultBackgroundColors);
    const textColor = lightenHexColor(backgroundColor, 90);
    const { h: divHeight } = sizesToPixels[size] || sizesToPixels['medium'];

    return (
      <div
        ref={ref}
        className={twMerge(
          'DefaultLetterAvatar flex justify-center items-center bold uppercase',
          className,
          size === 'full' && 'w-full h-full',
        )}
        style={{ backgroundColor, color: textColor, fontSize: divHeight / 2, ...style }}
      >
        {lettersToDisplay}
      </div>
    );
  },
);
