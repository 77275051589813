import type { FC, SetStateAction } from 'react';
import { memo, useCallback } from 'react';

import { EmojisPopover } from '../../thread/Content/MessageContent/formatters/EmojisPopover';
import { IconEmoji } from '../icons/Icons';
import { ChatIconButton } from './ChatIconButton';

const noop = () => true;

interface Props {
  // setValue is not a good strategy because not all use cases are able to propose such a setter. It works if using useState, but not with most more complex structures.
  setValue: (value: SetStateAction<string>) => void;
}

export const EmojiSelector: FC<Props> = memo(function EmojiSelector(props) {
  const { setValue } = props;
  const addEmoji = useCallback(
    (emoji: string) => setValue(currentValue => currentValue + emoji),
    [setValue],
  );

  return (
    <EmojisPopover onAdd={addEmoji}>
      <ChatIconButton icon={<IconEmoji className="w-7 h-7 sm:w-5 sm:h-5" />} onClick={noop} />
    </EmojisPopover>
  );
});
