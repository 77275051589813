import dynamic from 'next/dynamic';

import { debateConfig } from '../../../graphdebate/config';

export function ensureLogRocketIsInitialized() {
  (async function () {
    if (debateConfig.enableLogRocket) {
      (await import('./logrocket-dynamic')).ensureLogRocketIsInitializedDynamic();
    }
  })();
}

export const IdentifyUsersForLogRocket = debateConfig.enableLogRocket
  ? dynamic(() => import('./logrocket-dynamic'), {
      ssr: false,
    })
  : () => null;
