import type { ChildMessages } from '../../../types/api';
import { uniqBy } from '../../../utils/common-utils';

export const mergeChronological = (
  existing: ChildMessages,
  incoming: ChildMessages,
  {
    args: { offset: argsOffset, adding, sort = '' },
  }: {
    args: { adding?: boolean; sort?: string; offset?: number };
  },
) => {
  // @TODO find a better implementation
  const messages = incoming?.messages ?? [];
  const offset = (argsOffset || incoming?.meta?.offset) as number;
  const prevMessages = existing?.messages ?? [];
  const prevOffset = existing?.meta?.offset as number;
  const prevCount = existing?.meta?.count as number;

  if (sort.startsWith('newest') && adding) {
    return {
      ...existing,
      meta: {
        ...existing?.meta,
        count: (existing?.meta?.count ?? 0) + messages.length,
      },
      messages: prevOffset === 0 ? [...messages, ...prevMessages] : prevMessages,
    };
  }

  if (sort.startsWith('oldest') && adding) {
    return {
      ...existing,
      meta: {
        ...existing?.meta,
        count: (existing?.meta?.count ?? 0) + (messages.length ?? 0),
      },
      messages:
        prevOffset + prevMessages.length >= prevCount
          ? [...prevMessages, ...messages]
          : prevMessages,
    };
  }

  const diff = offset - prevOffset;
  const extra1 = diff >= 0 ? prevMessages.length - diff : false;
  const extra2 = diff <= 0 ? messages.length - diff : false;
  const extra = [extra2, extra1].filter(elt => elt !== false)[0] as number;
  const canMerge = extra > -1;

  if (!canMerge) return incoming;

  return {
    ...existing,
    ...incoming,
    meta: {
      ...existing?.meta,
      ...incoming?.meta,
      offset: Math.min(offset, prevOffset),
      count: incoming?.meta?.count || (existing?.meta?.count || 0) + (extra as number),
    },
    messages: uniqBy(
      prevOffset < offset ? [...prevMessages, ...messages] : [...messages, ...prevMessages],
      '__ref',
    ),
  };
};
