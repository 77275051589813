import type { FC } from 'react';
import { memo } from 'react';

import { IGNORE_PROP } from '../formatters/common';

export const SimpleUrl: FC<{ url: string }> = memo(({ url }) => {
  return (
    <div className="my-3 flex">
      <a
        href={url}
        target="_blank"
        rel="noreferrer noopener"
        className="text-blue-250 hover:text-secondary-base"
        {...{ [IGNORE_PROP]: true }}
      >
        {url}
      </a>
    </div>
  );
});
