import { IS_DEV } from './optimization';

/**
 * Parse string safely: if string is not a valid JSON, returns `null` instead of crashing.
 * You can also privide type expected for JSON output. Otherwise, it will be `any` by default (or `null` if string is not a valid JSON).
 *
 * @param stringToCheck string to parse: will return null if no value
 * @param onParseError (optional) callback to handle error skipped. (Mainly to log it)
 *
 * @example
 * const stringTest1 = '{"id": 123, "name": "unicorn"}'
 * console.log(parseJSONString(stringTest1))
 * // { id: 123, name: "unicorn" }
 *
 * const stringTest2 = '<div id='123'>Rainbow</div>'
 * console.log(parseJSONString(stringTest2))
 * // null
 *
 * console.log(parseJSONString(stringTest2, console.warn))
 * // SyntaxError: Unexpected token '<', "<div id="123"... is not valid JSON
 * //   at JSON.parse (<anonymous>)
 * //   at [...]
 * //
 * // null
 *
 * //Example providing type expected
 * type Unicorn = {id: number; name: string; age: number}
 * const jsonFromString = parseJSONString<Unicorn>(whatever)
 * // pour TypeScript, jsonFromString sera de type Unicorn | null
 */
export const parseJSONString = <T = any>(
  stringToCheck?: string,
  onParseError?: (error?: unknown) => void,
) => {
  if (!stringToCheck && IS_DEV) {
    console.log('[parseJSONString] stringToCheck is falsy');
    return null;
  }

  try {
    return JSON.parse(stringToCheck as string) as T;
  } catch (e) {
    onParseError?.(e);
    return null;
  }
};
