import type { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';

import type { AppStore } from './store';

interface Props extends PropsWithChildren<{}> {
  store: AppStore;
}

export function ReduxProvider(props: Props) {
  const { children, store } = props;
  return <Provider store={store}>{children}</Provider>;
}
