import type { FC, HTMLAttributes } from 'react';
import { memo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import DensityIcon from '../../icons/DensityIcon';

export type DensityType = 'compact' | 'classic' | 'card';

// type DensityMenuProps = HTMLAttributes<HTMLDivElement> & {
//   itemsClass?: string;
// };

type TopicDensityItemProps = HTMLAttributes<HTMLDivElement> & {
  densityType: DensityType;
  isActive?: boolean;
  showLabel?: boolean;
};

export const densityMessages = defineMessages({
  compact: { defaultMessage: 'compact', id: 'sob+Lf' },
  classic: { defaultMessage: 'classic', id: 'oHD41H' },
  card: { defaultMessage: 'card', id: 'mpJrBL' },
});

export const DensityItem: FC<TopicDensityItemProps> = memo(function TopicDensityItem(props) {
  const { densityType, isActive, showLabel, className, ...otherProps } = props;

  return (
    <div
      className={twMerge(
        `density-${densityType} flex items-center gap-x-4`,
        props.onClick && ' cursor-pointer',
        className,
      )}
      {...otherProps}
    >
      <div className={twMerge('density-icon', isActive ? 'text-primary' : 'text-gray-soft')}>
        <DensityIcon densityType={densityType} />
      </div>
      {showLabel && (
        <div className="density-label first-letter:uppercase">
          <FormattedMessage {...densityMessages[densityType]} />
        </div>
      )}
    </div>
  );
});
