import React, { useEffect, useRef, useState } from 'react';

export const IfInViewPort: React.FC<
  {
    className?: string;
    content?: () => JSX.Element;
    whenVisible?: (entry: IntersectionObserverEntry) => void;
    whenInvisible?: (entry: IntersectionObserverEntry) => void;
    triggerOnce?: boolean;
  } & IntersectionObserverInit
> = ({
  className,
  content,
  whenVisible,
  triggerOnce,
  whenInvisible,
  root = null,
  rootMargin = '0px',
  threshold = 1,
}) => {
  const [visible, setVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          if (!visible) {
            setVisible(true);
            if (typeof whenVisible === 'function') whenVisible(entry);
          }
        } else if (!triggerOnce) {
          if (visible) {
            if (typeof whenInvisible === 'function') whenInvisible(entry);
            setVisible(false);
          }
        }
      },
      {
        root,
        rootMargin,
        threshold,
      },
    );

    const elt = ref.current;
    if (elt) observer.observe(elt);

    return () => {
      if (elt) observer.unobserve(elt);
    };
  }, [root, rootMargin, threshold, triggerOnce, visible, whenInvisible, whenVisible]);

  return (
    <div ref={ref} className={className}>
      {visible && typeof content === 'function' && content()}
    </div>
  );
};
