import type { FC, PropsWithChildren } from 'react';
import { memo, useCallback, useEffect, useRef } from 'react';

import { usePermissions } from '../../hooks/usePermissions';
import { useRefLatest } from '../../hooks/useRefLatest';
import { AuthPromiseContext } from './AuthWithPromise-context';

const rejectReasonCancel = 'canceled';

interface PromiseObj {
  promise: Promise<void>;
  resolve: VoidFunction;
  reject: (reason: any) => void;
}

interface AuthWithPromiseProviderProps {}

export const AuthWithPromiseProvider: FC<PropsWithChildren<AuthWithPromiseProviderProps>> = memo(
  function AuthWithPromiseProvider(props) {
    const { children } = props;
    const { authUserId, login, onModalOpenChange, subscribeToToken } = usePermissions();

    const promiseRef = useRef<PromiseObj | null>(null);
    const authUserIdRef = useRefLatest(authUserId);
    const loginRef = useRefLatest(login);

    useEffect(() => {
      return onModalOpenChange?.((open, withToken) => {
        if (!open && promiseRef.current) {
          if (withToken) {
            promiseRef.current.resolve();
            promiseRef.current = null;
          } else if (withToken === false) {
            promiseRef.current.reject(rejectReasonCancel);
            promiseRef.current = null;
          }
        }
      });
    }, [authUserIdRef, onModalOpenChange]);

    useEffect(() => {
      return subscribeToToken?.(token => {
        if (promiseRef.current) {
          if (token) {
            promiseRef.current.resolve();
            promiseRef.current = null;
          } else {
            console.log(
              'subscribeToToken returned a falsy token. Should we reject the loginWithPromise() promise?',
              token,
            );
          }
        }
      });
    }, [authUserIdRef, onModalOpenChange, subscribeToToken]);

    //     useEffect(() => {
    //       if (authUserId && promiseRef.current) {
    //         // If the user is already authenticated, resolve the promise immediately.
    //         promiseRef.current.resolve();
    //         promiseRef.current = null;
    //       }
    //     }, [authUserId]);

    const loginWithPromise = useCallback(async () => {
      if (promiseRef.current?.promise) {
        // If a promise already exists, reject it and start a new login workflow.
        promiseRef.current.reject(rejectReasonCancel);
        promiseRef.current = null;
      }
      // Create a new promise.
      promiseRef.current = {} as PromiseObj;
      promiseRef.current.promise = new Promise<void>((resolve, reject) => {
        // Attach the resolve function to the promise object.
        promiseRef.current!.resolve = resolve;
        promiseRef.current!.reject = reject;
        if (!authUserIdRef.current) {
          // If the user is not authenticated, initiate the login process.
          loginRef.current?.();
        } else {
          // If the user is already authenticated, resolve immediately.
          resolve();
          promiseRef.current = null;
        }
      });
      return promiseRef.current.promise;
    }, [authUserIdRef, loginRef]);

    // TOOD also reject if the login modal is closed

    return (
      <AuthPromiseContext.Provider value={loginWithPromise}>{children}</AuthPromiseContext.Provider>
    );
  },
);
