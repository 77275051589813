import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import type { SimplifiedUrlObject, SimplifiedUrlObject2 } from '../utils/clean-routes-utils';
import { useCleanRoutes } from './useCleanRoutes';
import { useWebsite } from './useWebsite';

export type RouteConfig = string | SimplifiedUrlObject;
export type RouteConfig2 = string | SimplifiedUrlObject2;
type TransitionOptions = Parameters<NextRouter['push']>[2];
interface ConnectedRouter extends NextRouter {
  push: (route: RouteConfig, as?: string, options?: TransitionOptions) => Promise<boolean>;
  replace: (route: RouteConfig, as?: string, options?: TransitionOptions) => Promise<boolean>;
}

export const useConnectedRouter = (): ConnectedRouter => {
  const { cleanRoute } = useCleanRoutes();
  const routerOriginal = useRouter();
  const { publicKey: key } = useWebsite();

  // TODO update the push() below to prefix with [website] (param in routes.ts).
  // It should take care of passing the "as" path + passing the right queries for "url" and "as".
  // makeCleanRoute se charge déjà de construire le "as". Il suffirait de préfixer avec /[website] avant de le faire travailler. SAUF qu'il ne doit pas y avoir le website dans l'URL "as". Je veux juste filtrer les queries. À repenser.

  // Memo to ensure the reference of the router doesn't change as long as the dependencies don't change.
  // It is important because the router will be used a lot as a dependency of hooks like useEffect, useCallback...
  return useMemo(() => {
    const { push: routerPush, replace: routerReplace, ...router } = routerOriginal;

    const push: ConnectedRouter['push'] = (route, asPath, options) => {
      // The second parameter, asPath, is useless and could be omitted (unless we want to preserve the exact same API as the nextjs native router). Except one usage that is to clarify and test before changing it, in:
      // packages/front/src/hooks/useRouterPageParam.ts

      if (route && typeof route !== 'string' && !route.pathname) {
        route.pathname = routerOriginal.pathname;
      }

      const { href, as: cleanAsPath } = cleanRoute(route as RouteConfig2);

      return routerPush(
        {
          pathname: href.pathname,
          query:
            typeof href.query === 'object' ? { ...href.query, key } : href.query + `&key=${key}`,
        },
        asPath ?? cleanAsPath ?? undefined,
        options,
      );
    };

    const replace: ConnectedRouter['replace'] = (url, as, options) => {
      // The second parameter, asPath, is useless and could be omitted (unless we want to preserve the exact same API as the nextjs native router). Except one usage that is to clarify and test before changing it, in:
      // packages/front/src/hooks/useRouterPageParam.ts

      if (url && typeof url !== 'string' && !url.pathname) {
        url.pathname = routerOriginal.pathname;
      }

      const { href, as: cleanAsPath } = cleanRoute(url as RouteConfig2);

      return routerReplace(
        {
          pathname: href.pathname,
          query:
            typeof href.query === 'object' ? { ...href.query, key } : href.query + `&key=${key}`,
        },
        as ?? cleanAsPath ?? undefined,
        options,
      );
    };

    return {
      ...router,
      replace,
      push,
    };
  }, [cleanRoute, key, routerOriginal]);
};
