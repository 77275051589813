import type { MutableRefObject } from 'react';
import { useCallback, useMemo, useRef } from 'react';

import type { EditorJSSerialized } from '../../editor/editorjs/editorjs-utils';
import { isBrowser } from '../../utils/web';

export type SerializedValue = string | EditorJSSerialized | null | undefined;
export type SerializedValueDefined = string | EditorJSSerialized;
export type InitialValueRef = MutableRefObject<string | EditorJSSerialized | undefined>;
export type SerializeValue = (value: SerializedValue) => void;
export type ClearValue = () => void;

/**
 * This hook should make it easier to serialize what's being typed in the text editor, and restore it if refreshing the text editor.
 * @param serializeId A unique identifier to serialize the value. It should uniquely identify the text editor instance among other instances. If 2 instances share the same ID, the serialized values will collide.
 * @returns Tuple (array) with 3 values:
 * - initialValueRef: a reference (from useRef) to read the initial value. Read the value with initialValueRef.current. Changes in this value don't trigger a re-render. It is intended for the initial value (first render of the text editor) only.
 * - serializeValue: a function to call each time the text editor value changes, to serialize it.
 * - clearValue: shortcut for serializeValue(null). Clears the serialized value. Call it when the user submits or explicitly closes the editor.
 */
export function useSerializeValue(serializeId: string) {
  const key = `useSerializeValue_${serializeId}`;

  const initialValueRef: InitialValueRef = useRefFn(() => readLocalStorage(key));

  const serializeValue: SerializeValue = useCallback(
    (value: string | EditorJSSerialized | null | undefined) => {
      if (!isBrowser) return;
      if (value == null) {
        // Clear the persisted value
        initialValueRef.current = undefined;
        localStorage.removeItem(key);
      } else {
        initialValueRef.current = value;
        localStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));
      }
    },
    [initialValueRef, key],
  );

  const clearValue: ClearValue = useCallback(() => {
    if (!isBrowser) return;
    initialValueRef.current = undefined;
    localStorage.removeItem(key);
  }, [initialValueRef, key]);

  return [initialValueRef, serializeValue, clearValue] as const;
}

export function serializedToExportedString(value: SerializedValue) {
  return typeof value === 'string' ? value : value?.html;
}

/**
 * Like useRef, but with the initial value calculated by the callback function.
 * Useful for expensive computation you don't want to repeat at each render.
 */
function useRefFn<T>(callback: () => T) {
  // We could use useState(callback)[0] instead (need to skip the .current).
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useRef<T>(useMemo(callback, []));
}

function readLocalStorage(key: string) {
  if (!isBrowser) return undefined;
  const val = localStorage.getItem(key);
  if (val == null) return undefined;
  try {
    return JSON.parse(val);
  } catch (error) {
    return val;
  }
}
