import { useWebsite } from '../../../front/src/hooks/useWebsite';
import type { Website } from '../../../front/src/types/api';

function isAlphaCircle(key: string) {
  return key === 'AlphaCircle';
}

// function useIsAlphaCircle() {
//   const { key } = useWebsite();
//   return isAlphaCircle(key);
// }

function isAlphaCircleOrTestDebate(key: string) {
  return isAlphaCircle(key) || key === 'MyApp';
}

export function useIsAlphaCircleOrTestDebate() {
  const { key } = useWebsite();
  return isAlphaCircleOrTestDebate(key);
}

export function useHasAds() {
  // We temporarily disable ads to boost SEO
  return /* !useIsAlphaCircle() */ false;
}

export function useAreGroupsEnabled() {
  return checkGroupsEnabled(useWebsite());
}

export function checkGroupsEnabled(website: Website) {
  return website.graphdebate?.group || false;
}

export function deepCopy<T>(obj: T): T {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (obj instanceof Date) {
    return new Date(obj) as T;
  }

  if (Array.isArray(obj)) {
    const arrCopy: any[] = [];
    for (const item of obj) {
      arrCopy.push(deepCopy(item));
    }
    return arrCopy as T;
  }

  if (obj instanceof Map) {
    const mapCopy = new Map();
    for (const [key, value] of obj) {
      mapCopy.set(key, deepCopy(value));
    }
    return mapCopy as T;
  }

  if (obj instanceof Set) {
    const setCopy = new Set();
    for (const item of obj) {
      setCopy.add(deepCopy(item));
    }
    return setCopy as T;
  }

  const objCopy = {} as Partial<T>;
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      objCopy[key] = deepCopy(obj[key]);
    }
  }
  return objCopy as T;
}
