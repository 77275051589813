import classnames from 'classnames';
import { useCallback, useMemo, useRef, useState } from 'react';

import { useClickOutside } from '../../hooks/useClickOutside';
import { useQuickLinks } from '../../hooks/useQuickLinks';
import type { AutosuggestElement } from './QuickSearchWithSuggestion';
import { QuickSearchWithSuggestion } from './QuickSearchWithSuggestion';

export const QuickSearch = () => {
  const ref = useRef<AutosuggestElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [isVisible, setIsVisible] = useState(false);

  const showQuickSearch = useCallback(() => setIsVisible(true), []);
  const hideQuickSearch = useCallback(() => setIsVisible(false), []);

  const focusInput = useCallback(() => {
    ref.current?.input?.focus();
  }, []);

  const { displayedFilters } = useQuickLinks();
  const suggestionsPlaceholder = useMemo(
    () => [
      {
        title: null,
        results: displayedFilters.map(filter => {
          return {
            pathname: 'topics',
            query: {
              filter: filter.filterKey,
            },
            asPath: '/topics',
            element: (
              <div className="flex items-center gap-2 p-1">
                {filter?.icon}
                <span className="text-[15px]">{filter.title}</span>
              </div>
            ),
          };
        }),
      },
    ],
    [displayedFilters],
  );

  // Listen to clicks outside the QuickSearch input and suggestions. Close it if clicked outside.
  useClickOutside(wrapperRef, hideQuickSearch);

  return (
    <div
      onClick={focusInput}
      className={classnames(
        'QuickSearchSuggestionWrapper h-full w-full cursor-text flex items-center group',
        isVisible && '_md:absolute _md:top-0 _md:left-0 _md:right-0 _md:bottom-0 _md:z-10',
      )}
      ref={wrapperRef}
    >
      <QuickSearchWithSuggestion
        ref={ref}
        suggestionsPlaceholder={suggestionsPlaceholder}
        show={isVisible}
        onShow={showQuickSearch}
        // escape, back, navigate
        onHide={hideQuickSearch}
      />
    </div>
  );
};
