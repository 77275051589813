import type { IncomingMessage } from 'http';
import dynamic from 'next/dynamic';
import type { FC, PropsWithChildren } from 'react';

import { frontConfig } from '../config';

export const computeLocaleFromIncomingMessage = (req?: IncomingMessage) => {
  if (!req) return frontConfig.defaultLocale;

  const locale = req.headers['accept-language']?.split(',')?.[0] || 'en-US';
  const localeFromReq = locale.split('-')[0];

  // Uncomment to restore the locale from the cookies.
  // Useless (even harmful) as long as there is no language switching feature in the front.
  return /* nextCookies({ req }).NEXT_LOCALE || */ localeFromReq || frontConfig.defaultLocale;
};

export const NoSSR: FC<PropsWithChildren<{}>> = dynamic(
  () =>
    Promise.resolve(function NoSSRInner(props: PropsWithChildren<{}>) {
      return <>{props.children}</>;
    }),
  { ssr: false },
) as any;

NoSSR.displayName = 'NoSSR';
