import type { ReactElement, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import type { AvatarPropsType } from '../../../../front/src/components/Avatar';
import { Avatar } from '../../../../front/src/components/Avatar';
import type { User } from '../../../../front/src/types/api';
import styles from './ChatAvatars.module.scss';

const classes = {
  border: 'absolute w-full h-full rounded-full border-white border',
  shadow: `absolute w-full h-full rounded-full border-white ${styles.shadow}`,
  DEFAULT: 'absolute w-full h-full rounded-full border-white',
};

/**
 *
 * @param users
 * @param variant
 * @param mapChildren
 * @constructor
 */
export const ChatAvatars = ({
  users,
  variant = 'border',
  size = 'medium1',
  mapChildren,
  className,
}: {
  users: User[];
  variant?: 'border' | 'shadow';
  size?: AvatarPropsType['size'];
  mapChildren?: (elt: ReactElement, key: string) => ReactNode;
  className?: string;
}) => {
  const count = users.length;

  if (count < 1) return <Avatar picture={null} className={classes[variant]} legacyImage />;

  let avatarsMap: ReactElement[] = users.map((user, index) => {
    const decay = (index + 0.5 - count / 2) / count;
    return (
      <Avatar
        key={user.id}
        profile={user.profile}
        picture={user.profile?.picture}
        size={size}
        className={twMerge(classes[variant], className)}
        style={{
          transform: `translate3d(${decay * 50}%, ${decay * 50}%, 0) scale(${
            0.5 + 1 / (count + 1)
          })`,
        }}
      />
    );
  });

  return (
    <>
      {mapChildren
        ? avatarsMap.map((avatar, index) => mapChildren(avatar, users[index].id!))
        : avatarsMap}
    </>
  );
};
