import { imageUrl } from '../config';

export type CamelToSnakeCase<S extends string> = S;
// export type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
//   ? `${T extends Capitalize<T> ? '_' : ''}${Lowercase<T>}${CamelToSnakeCase<U>}`
//   : S;

/**
 * Truncate a string
 */
export const truncate = (str: string | undefined, len: number, dontBreakWords = false) => {
  if (!str) return '';
  if (typeof len !== 'number' || str.length < len) return str;
  len = len - 3; // to cover the "..."

  let result = str.slice(0, len);

  if (dontBreakWords) {
    // re-trim if we are in the middle of a word
    result = result.substr(0, Math.min(result.length, result.lastIndexOf(' ')));
    return `${result}...`;
  }

  return `${result}...`;
};

/**
 * Truncate a string next to a match
 */
export const truncateAround = (str: string, match: string, len: number) => {
  if (!match) return str;
  const index = str.toLowerCase().indexOf(match.toLowerCase());
  const start = Math.max(0, index - len / 2);
  const end = Math.min(str.length, Math.max(index + len / 2, len));
  return (start === 0 ? '' : '...') + str.slice(start, end) + (end === str.length ? '' : '...');
};

export const computeImageSource = (rawSrc: unknown): string => {
  if (!rawSrc || typeof rawSrc !== 'string') return '';

  if (rawSrc.startsWith('/')) return new URL(rawSrc.replace(/(\/{2,})/g, '/'), imageUrl).toString();

  return rawSrc;
};
