import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { frontConfig } from '../../../front/src/config';

// If the navigation is not shallow, it resets the scroll to top.
// Because we have changed the scroll element from HTML (default) to #__next (to solve other issues like iOS scroll in installed PWA), nextjs does NOT automatically reset the scroll to top when changing of page, as it does with the default scroll element (HTML). So, as a workaround, we do it ourself.
// Caveat: it ignores the "scroll={false}" parameter on <Link> and push()
// https://github.com/vercel/next.js/discussions/45715
export function useScrollTopOnRouteChange() {
  const { events } = useRouter();
  useEffect(() => {
    const handleRouteChange = (_url: string, { shallow }: { shallow: boolean }) => {
      if (!shallow) {
        const scrollElement = document.querySelector<HTMLElement>(frontConfig.rootElementSelector);
        scrollElement?.scrollTo({ top: 0 });
      }
    };

    events.on('routeChangeComplete', handleRouteChange);

    // Unsubscribe from the event when the component unmounts
    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
  }, [events]);
}
