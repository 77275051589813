import type { NextPageContext } from 'next';
import { useRouter } from 'next/router';

export function getPageFromQuery(
  query: Record<string, string | string[]> | NextPageContext['query'],
) {
  const rawPage = query?.['page'] ?? '1';
  return parseInt(rawPage as string, 10);
}

export function useQueryPage() {
  const { query } = useRouter();

  return getPageFromQuery(query);
}
