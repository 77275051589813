import React from 'react';
// import { CSSTransition as BrowserCSSTransition } from 'react-transition-group';
import type { TransitionProps } from 'react-transition-group/Transition';

export const CSSTransition: React.FC<TransitionProps> = ({ children }) => {
  return <>{children}</>;
  // return isBrowser ? (
  //   // @TODO make it work on first load with SSR
  //   // <BrowserCSSTransition {...props}>
  //   <main className="fade">{children}</main>
  // ) : (
  //   // </BrowserCSSTransition>
  //   <main className="fade">{children}</main>
  // );
};
