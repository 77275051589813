import { gql } from '@apollo/client';

export const GroupMemberFragment = gql`
  fragment GroupMemberFragment on GroupMember {
    id
    grade {
      id
      role
      label
    }
    status
    member {
      id
      role
      email
      profile {
        id
        pseudo
        email
        picture
        bio
        proStatus
        user {
          id
        }
        myRights {
          isMe
        }
        relation {
          id
          status
          message
          guest {
            id
          }
          member {
            profile {
              # Used to check that a member's relation, is with the current user. E.g. for an invitation.
              id
            }
          }
        }
      }
    }
  }
`;

export const getGroupQuery = gql`
  # , $offsetAdmin: Int = 0
  query GetGroupQuery($groupId: IDOrString!) {
    me {
      id
      role
      profile {
        # getGroups(status: ["pending", "approved"]) {
        #   meta {
        #     count
        #   }
        #   groups {
        #     slug
        #   }
        # }
        groupMember(groupId: $groupId) {
          grade {
            role
          }
          status
          member {
            id
          }
        }
        groupIsMember(groupId: $groupId)
        groupIsAdmin(groupId: $groupId)
        groupIsOwner(groupId: $groupId)
      }
    }
    website {
      findGroup(groupId: $groupId) {
        id
        name
        description
        slug
        createdAt
        # owners {
        #   id
        #   username
        #   # email
        #   profile {
        #     id
        #     pseudo
        #     picture
        #     user {
        #       id
        #     }
        #     relation {
        #       id
        #       status
        #       message
        #       guest {
        #         id
        #       }
        #       member {
        #         profile {
        #           # Used to check that a member's relation, is with the current user. E.g. for an invitation.
        #           id
        #         }
        #       }
        #     }
        #   }
        # }
        image
        status
        countTopics
        lastActivity
        countMembersAdmin
        countMembersMember
        countMembersValid
        countMembersPending
        gradeList {
          grades {
            id
            role
            label
          }
        }
      }
    }
  }
`;
