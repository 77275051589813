import { parseToRgb } from 'polished';

/**
 *
 * @param color
 */
const hexToRgbInline = (color: string) => {
  const { red, blue, green } = parseToRgb(color);
  return [red, green, blue].join(', ');
};

/**
 *
 * @param variableName
 * @param color
 */
const buildColorVarStrings = (variableName: string, color: string) => {
  return {
    [`--${variableName}`]: color,
    [`--rgb-${variableName}`]: hexToRgbInline(color),
  };
};

type Palette = Record<string, string /* | Record<string, string> */>;

export const buildRGBColorsVars = <T extends Palette = Palette>(palette: T, overrideVars: T) => {
  const rows = {
    ...Object.entries(palette).reduce((colors, [colorName, color]) => {
      if (typeof color === 'string') {
        return {
          ...colors,
          ...buildColorVarStrings(`color-${colorName}`, color),
        };
      }

      return {
        ...colors,
        ...Object.entries(color as T).reduce((variants, [variantKey, variantColor]) => {
          const colorVariantVar =
            variantKey === 'DEFAULT' || variantKey === 'base'
              ? `color-${colorName}`
              : `color-${colorName}-${variantKey}`;

          return {
            ...variants,
            ...buildColorVarStrings(colorVariantVar, variantColor),
          };
        }, {}),
      };
    }, {}),

    ...Object.entries(overrideVars).reduce((variants, [colorVar, color]) => {
      return {
        ...variants,
        ...buildColorVarStrings(colorVar, color),
      };
    }, {}),
  };

  return Object.entries(rows)
    .map(([k, v]) => `${k}:${v};`)
    .join('\n');
};
