import type { INPMetricWithAttribution } from 'web-vitals';

import { IS_DEV } from '../../../../front/src/utils/optimization';
import { getOriginalFunctionInfosFromSourceMap } from '../sourceMap';

export const getRateIcon = (rate: INPMetricWithAttribution['rating']) => {
  switch (rate) {
    case 'poor':
      return '🔴';
    case 'needs-improvement':
      return '🟡';
    default:
      return '🟢';
  }
};

type PerformanceScriptsEntry = PerformanceLongAnimationFrameTiming['scripts'];

const handleScriptFunctionInfos = async (script: NonNullable<PerformanceScriptsEntry>[number]) => {
  let scriptInfos = {
    name: script.sourceFunctionName,
    source: script.sourceURL,
  };

  if (!IS_DEV) {
    const mapInfos = await getOriginalFunctionInfosFromSourceMap(script.sourceURL, {
      column: script.sourceCharPosition,
    });

    if (mapInfos) {
      scriptInfos = { name: mapInfos.mapName, source: mapInfos.mapSource };
    }
  }
  return scriptInfos;
};

const fillScriptsSources = async (scripts: PerformanceScriptsEntry) => {
  return scripts
    ? await Promise.all(
        scripts.map(async script => {
          const { name, source } = await handleScriptFunctionInfos(script);

          return {
            invoker: script.invoker,
            sourceFunctionName: name,
            sourceURL: source,
          };
        }),
      )
    : [];
};

export const splitINPVitalsMetrics = async (inpMetric: INPMetricWithAttribution) => {
  const { rating, value, id, navigationType, delta, attribution } = inpMetric;

  const commonEventMetrics = {
    /**
     * Use `delta` so the value can be summed.
     * See https://github.com/GoogleChrome/web-vitals
     */
    value: delta,
    /**
     * The rating as to whether the metric value is within the "good",
     * "needs improvement", or "poor" thresholds of the metric.
     */
    metric_rate: rating,

    /**
     * The current value of the metric.
     */
    metric_value: value,

    /**
     * The delta between the current value and the last-reported value.
     * On the first report, `delta` and `value` will always be the same.
     */
    metric_delta: delta,

    /**
     * A unique ID representing this particular metric instance. This ID can
     * be used by an analytics tool to dedupe multiple values sent for the same
     * metric instance, or to group multiple deltas together and calculate a
     * total. It can also be used to differentiate multiple different metric
     * instances sent from the same page, which can happen if the page is
     * restored from the back/forward cache (in that case new metrics object
     * get created).
     */
    metric_id: id,

    /**
     * The type of navigation.
     *
     * This will be the value returned by the Navigation Timing API (or
     * `undefined` if the browser doesn't support that API), with the following
     * exceptions:
     * - 'back-forward-cache': for pages that are restored from the bfcache.
     * - 'back_forward' is renamed to 'back-forward' for consistency.
     * - 'prerender': for pages that were prerendered.
     * - 'restore': for pages that were discarded by the browser and then
     * restored by the user.
     */
    metric_navigationType: navigationType,

    // Document base URL
    metric_uri: window.location.href,

    // The event target (a CSS selector string pointing
    // to the element responsible for the interaction):
    metric_target: attribution.interactionTarget,

    // The type of event that triggered the interaction:
    metric_eventType: attribution.interactionType,

    // Whether the page was loaded when the interaction
    // took place. Useful for identifying startup versus
    // post-load interactions:
    metric_loadState: attribution.loadState,
  };

  const interactionSubpartsMetrics = {
    /**
     * The time from when the user interacted with the page until when the
     * browser was first able to start processing event listeners for that
     * interaction. This time captures the delay before event processing can
     * begin due to the main thread being busy with other work.
     */
    inputDelay: Math.round(attribution.inputDelay),

    /**
     * The time from when the first event listener started running in response to
     * the user interaction until when all event listener processing has finished.
     */
    processingDuration: Math.round(attribution.processingDuration),

    /**
     * The time from when the browser finished processing all event listeners for
     * the user interaction until the next frame is presented on the screen and
     * visible to the user. This time includes work on the main thread (such as
     * `requestAnimationFrame()` callbacks, `ResizeObserver` and
     * `IntersectionObserver` callbacks, and style/layout calculation) as well
     * as off-main-thread work (such as compositor, GPU, and raster work).
     */
    presentationDelay: Math.round(attribution.presentationDelay),
  };

  /**
   * If the browser supports the Long Animation Frame API, this array will
   * include any `long-animation-frame` entries that intersect with the INP
   * candidate interaction's `startTime` and the `processingEnd` time of the
   * last event processed within that animation frame. If the browser does not
   * support the Long Animation Frame API or no `long-animation-frame` entries
   * are detect, this array will be empty.
   */
  const customLongAnimationFrameEntriesMetrics = await Promise.all(
    attribution.longAnimationFrameEntries.map(async entry => ({
      duration: entry.duration,
      blockingDuration: entry.blockingDuration,
      startTime: entry.startTime,
      scripts: await fillScriptsSources(entry.scripts),
    })),
  );

  return {
    commonEventMetrics,
    interactionSubpartsMetrics,
    customLongAnimationFrameEntriesMetrics,
  };
};

export const getSortedINPVitals = async (inpMetric: INPMetricWithAttribution, isB = false) => {
  const { commonEventMetrics, interactionSubpartsMetrics, customLongAnimationFrameEntriesMetrics } =
    await splitINPVitalsMetrics(inpMetric);

  const apiMetrics = {
    ...commonEventMetrics,
    metric_interactionSubparts: interactionSubpartsMetrics,
    metric_longAnimationFrameEntries: customLongAnimationFrameEntriesMetrics,
    isB,
  };

  return { gtagMetrics: commonEventMetrics, apiMetrics };
};

export type ApiInpMetrics = Awaited<ReturnType<typeof getSortedINPVitals>>['apiMetrics'] & {
  _id: string;
  website: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};
