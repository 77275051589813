import React from 'react';
import type { MessageDescriptor } from 'react-intl';
import { defineMessages, FormattedMessage } from 'react-intl';

import { handleError } from '../../utils/common-utils';

const errorMessages = defineMessages({
  serverError: { defaultMessage: `Erreur {errorCode}`, id: '6mKOKl' },
  clientError: { defaultMessage: `Une erreur est survenu sur votre appareil`, id: '7j9QI+' },
  genericError: {
    defaultMessage: 'Une erreur est survenue lors du chargement de GraphComment',
    id: 'W77IPQ',
  },
  retry: { defaultMessage: 'Rééssayer', id: 'VagFvW' },
});

export const ErrorLayout: React.FC<{ error: any }> = ({ error }) => {
  const errorCode: string | undefined = error?.statusCode?.toString();
  const errorMsg: MessageDescriptor | string = error?.message;
  handleError(error);
  return (
    <>
      <div className="show-more" data-ignore-caching>
        <FormattedMessage
          {...(errorCode ? errorMessages.serverError : errorMessages.clientError)}
          values={{ errorCode }}
        />
      </div>
      <div className="oops" data-ignore-caching>
        <i className="icon-bug" />
        <p>
          <b>
            {typeof errorMsg === 'string' ? (
              errorMsg
            ) : (
              <FormattedMessage {...(errorMsg || errorMessages.genericError)} />
            )}
          </b>
        </p>
        <button type="button" onClick={() => window.location.reload()}>
          <i className="icon-retry" />
          <FormattedMessage {...errorMessages.retry} />
        </button>
      </div>
    </>
  );
};
