import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { localStorage } from '../../services/storage/localStorage';
import type { ChildMessages, Message, Thread, User } from '../../types/api';
import type { RootState } from '../store';

// If a state should not be reset on logout, check /front/src/redux/store.ts

type ThreadId = string;

export type ChatWindowDescriptor = {
  status: 'opened' | 'reduced' | 'closed';
  isAlreadyView: boolean;
  threadId: ThreadId;
};

type ReducedChatWindowPayload = Pick<ChatWindowDescriptor, 'threadId'> &
  Partial<Pick<ChatWindowDescriptor, 'isAlreadyView'>>;

interface ChatWindow {
  loading: boolean;
  thread?: Thread;
}

export interface ChatReducerState {
  isCreating: boolean;
  windows: ChatWindowDescriptor[];
  chats: {
    [key: string]: ChatWindow;
  };
  chatCreationThreadId?: string;
  chatCreationUsers?: User[];
}

const initialState: ChatReducerState = {
  isCreating: false,
  windows: [],
  chats: {},
};

const setThread = (state: ChatReducerState, threadId: ThreadId, thread: Thread) => {
  if (!state.chats[threadId]) {
    state.chats[threadId] = { loading: false };
  }
  state.chats[threadId].thread = thread;
};

const updateStorageChatWindows = (windows: ChatWindowDescriptor[]) => {
  localStorage?.setItem('chat_windows', JSON.stringify(windows));
};

// TODO: Prepared work to handle sockets notifs on reduced windows. Will need to keep connection alive on this status (not just 'open)
// const notifyReducedChatWindows = (state, threadId: ThreadId) => {
//   const widowToNotify = state.windows.find(window => window.threadId === threadId);

//   if (widowToNotify && widowToNotify.status === 'reduced') {
//     const updatedWindows = state.windows.map(window =>
//       window.threadId === threadId ? { ...window, isAlreadyView: false } : window,
//     );
//     state.windows = updatedWindows;
//     updateStorageChatWindows(updatedWindows);
//   }
// };

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    initChatWindows(state, { payload }: PayloadAction<ChatWindowDescriptor[]>) {
      state.windows = payload;
    },
    initStorageWindows(state) {
      const storageWindows = JSON.parse(localStorage?.getItem('chat_windows') || '[]');
      if (storageWindows !== state.windows) state.windows = storageWindows;
    },
    startCreatingChat(state, { payload }: PayloadAction<User[] | undefined>) {
      state.isCreating = true;
      state.chatCreationUsers = payload;
    },
    stopCreatingChat(state) {
      state.isCreating = false;
      state.chatCreationUsers = undefined;
    },
    openChatWindowAction(
      state,
      { payload }: PayloadAction<{ threadId: ThreadId; replaceCreation?: boolean }>,
    ) {
      const { threadId, replaceCreation } = payload;
      state.windows = [
        { threadId, status: 'opened', isAlreadyView: true },
        ...state.windows.filter(elt => threadId !== elt.threadId),
      ];
      updateStorageChatWindows(state.windows);

      if (replaceCreation) {
        state.isCreating = false;
      }
    },
    reduceChatWindow(state, { payload }: PayloadAction<ReducedChatWindowPayload>) {
      const { threadId, isAlreadyView = true } = payload;
      state.windows = [
        { threadId, status: 'reduced', isAlreadyView },
        ...state.windows.filter(elt => threadId !== elt.threadId),
      ];
      updateStorageChatWindows(state.windows);
    },
    closeChatWindow(state, { payload }: PayloadAction<ThreadId>) {
      state.windows = state.windows.filter(elt => payload !== elt.threadId);
      updateStorageChatWindows(state.windows);
    },
    cleanChatWindows(state) {
      Object.assign(state, initialState);
    },
    updateThread(
      state,
      {
        payload: { threadId, thread },
      }: PayloadAction<{
        threadId: ThreadId;
        thread: Thread;
      }>,
    ) {
      setThread(state, threadId, thread);
      // notifyReducedChatWindows(state, threadId);
    },
    addMorePreviousMessages(
      state,
      {
        payload: { threadId, messages },
      }: PayloadAction<{
        threadId: ThreadId;
        messages: ChildMessages['messages'];
      }>,
    ) {
      const currentMessages = state.chats[threadId].thread?.childMessages?.messages;

      if (currentMessages && messages) {
        currentMessages.push(...messages);
      }
    },
    setChatCreationThread(
      state,
      {
        payload: { threadId, thread },
      }: PayloadAction<{
        threadId: ThreadId;
        thread: Thread;
      }>,
    ) {
      setThread(state, threadId, thread);
      state.chatCreationThreadId = threadId;
    },
    addNewMessage(
      state,
      {
        payload: { threadId, newMessage },
      }: PayloadAction<{ threadId: ThreadId; newMessage: Message }>,
    ) {
      if (!state.chats[threadId]) {
        state.chats[threadId] = { loading: false };
      }

      // TODO Test to handle chat notifications thru websocket
      // const targetWindow = state.windows.find(window => window.threadId === threadId);
      // if (targetWindow && targetWindow?.status !== 'opened') {
      //   targetWindow.isAlreadyView = false;
      // }

      const thread = state.chats[threadId].thread;
      const childMessages = thread?.childMessages;
      if (!childMessages) {
        throw new Error(
          `[addNewMessage] no thread?.childMessages in cache for threadId ${threadId} - thread: ${JSON.stringify(
            thread,
          )}`,
        );
      }
      childMessages.messages = [newMessage, ...(childMessages.messages || [])];
    },
  },
});

export const {
  initChatWindows,
  initStorageWindows,
  startCreatingChat,
  stopCreatingChat,
  openChatWindowAction,
  reduceChatWindow,
  closeChatWindow,
  cleanChatWindows,
  updateThread,
  addMorePreviousMessages,
  setChatCreationThread,
  addNewMessage,
} = chatSlice.actions;

export type FriendElementType = {
  value: string;
  label: User;
};

export const selectChatWindows = (state: RootState) => state.chat.windows ?? [];

export const selectChatIsCreating = (state: RootState) => state.chat.isCreating;
export const selectChatCreationUsers = (state: RootState) =>
  (state.chat.chatCreationUsers || []).map(
    user =>
      ({
        value: user?.id,
        label: user,
      } as FriendElementType),
  );
export const selectUnreadChatWindowsCount = (state: RootState) =>
  state.chat.windows.filter(chatWindow => !chatWindow.isAlreadyView).length;
