import type { MessageDescriptor } from 'react-intl';
import { FormattedMessage } from 'react-intl';

import { objectKeyRemove } from '../../../../graphdebate/src/utils/objects';
import type { BanDateRange, BanModeKey, BanOptionKey, BanTypeKey } from '../../i18n/ban';
import type { Author, Ban, User } from '../../types/api';

export interface BanFormValues {
  option?: BanOptionKey;
  duration?: BanDateRange;
  reason?: { id: string; description?: string };
  type?: BanTypeKey[];

  mode: BanModeKey;
  isCustom?: boolean;
}

export function formattedOrStr(label: string | MessageDescriptor | undefined) {
  return !label || typeof label === 'string' ? label : <FormattedMessage {...label} />;
}

export function getPseudo(user: Author | undefined) {
  return (user as User)?.profile?.pseudo || user?.username;
}

export function getEmail(user: User | undefined) {
  return user?.profile?.email || user?.email;
}

/**
 * Hack to handle legacy api 'username' ban type and process it as new GD 'pseudo' type
 * @param banType
 * @returns
 */
export function getGdBanType(banType: BanTypeKey) {
  if (banType === 'username') return 'pseudo';
  return banType;
}

/**
 * As we the most recent, we remove the type and __typename from returned object
 */
function getMostRecentBan(banArray: Ban[]) {
  const mostRecentBan = banArray.reduce((latest, ban) => {
    return new Date(ban.createdAt) > new Date(latest.createdAt) ? ban : latest;
  }, banArray[0]);
  return objectKeyRemove(mostRecentBan, ['__typename', 'type']);
}

/**
 * Check if the user is banned and return the most recent ban object
 * @param banArray
 * @returns
 */
export function getHandledBan(banArray?: Ban[]) {
  if (!banArray?.length) return null;
  const mostRecentBan = getMostRecentBan(banArray);
  return mostRecentBan;
}
