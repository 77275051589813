import type { FC, ReactNode } from 'react';
import { createContext, memo, useContext, useEffect, useRef } from 'react';

import type { ExposedData } from '../services/communication/dataExposer';
import { DataExposer } from '../services/communication/dataExposer';
import { isBrowser } from '../utils/web';

export const dataExposedContext = createContext<DataExposer>(new DataExposer(null));

const { Provider } = dataExposedContext;

interface Props {
  dataExposer?: DataExposer;
  initialExposedData?: ExposedData | null;
  children: ReactNode;
}

export const ExposedDataProvider: FC<Props> = memo(function ExposedDataProvider(props) {
  const { dataExposer, initialExposedData = null, children } = props;
  const dataRef = useRef(dataExposer || new DataExposer(initialExposedData));

  useEffect(() => {
    if (!isBrowser) return;

    dataRef.current.expose();
  }, [dataRef]);

  return <Provider value={dataRef.current}>{children}</Provider>;
});

export const useDataExposer = () => useContext(dataExposedContext);
