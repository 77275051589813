import { defineMessages, useIntl } from 'react-intl';

import type { InputFieldPropTypes } from '../../../components/FinalForm/InputField';
import { InputField } from '../../../components/FinalForm/InputField';
import { TextAreaField } from '../../../components/FinalForm/TextAreaField';
import { IconTwitterBird } from '../../../components/icons/Icons';
import { useWebsite } from '../../../hooks/useWebsite';
import { composeValidations, maxLength, minLength } from '../../../utils/validation';

const messages = defineMessages({
  pseudoPlaceholders: { defaultMessage: 'Votre pseudo*', id: '/D+9d8' },
  proStatusPlaceholders: { defaultMessage: 'Votre statut', id: 'VO3edn' },
  twitterUsernamePlaceholders: { defaultMessage: 'Votre Twitter', id: 'u1iBQv' },
  bioPlaceholders: { defaultMessage: 'A propos de vous', id: 'PbX9Bo' },
});

const twitterFieldProps: Partial<InputFieldPropTypes> = {
  config: {
    format: (elt: string) => (elt || '@').slice(1),
    parse: (elt: string) => `@${elt}`,
  },
  beforeElement: (
    <span className="text-secondary-base flex items-center">
      <IconTwitterBird className="mr-1" />@
    </span>
  ),
};

const pseudoValidation = composeValidations([minLength(4), maxLength(30)]);
const statusValidation = maxLength(80);

export const ProfileFormFields = () => {
  const { formatMessage } = useIntl();

  const { graphdebate } = useWebsite();
  const ssoOptions = graphdebate?.sso;
  const isPseudoHandledBySso = ssoOptions?.active && ssoOptions?.managePseudo;

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <InputField
          placeholder={formatMessage(messages.pseudoPlaceholders)}
          name="pseudo"
          className="prose-major text-gray-dark"
          required
          validate={pseudoValidation}
          showValidateMessage
          containerClassName="h-input"
          disabled={isPseudoHandledBySso}
        />
        <InputField
          placeholder={formatMessage(messages.twitterUsernamePlaceholders)}
          {...twitterFieldProps}
          name="twitterUsername"
          className="text-secondary-base prose-sub"
        />
      </div>
      <div className="grid grid-cols-1">
        <InputField
          placeholder={formatMessage(messages.proStatusPlaceholders)}
          name="proStatus"
          className="prose-sub text-gray-dark"
          validate={statusValidation}
          showValidateMessage
          containerClassName="h-input"
        />
      </div>
      <div className="grid grid-cols-1">
        <TextAreaField
          placeholder={formatMessage(messages.bioPlaceholders)}
          name="bio"
          className="w-full italic prose-base resize-none appearance-none outline-none"
        />
      </div>
    </div>
  );
};
