import type { AxiosError } from 'axios';
import classNames from 'classnames';
import truncate from 'lodash/truncate';
import React, { useCallback } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { IconRemove } from '../../../../../front/src/components/icons/IconRemove';
import { LimitedRelativeTime } from '../../../../../front/src/components/LimitedRelativeTime';
import { failureToast } from '../../../../../front/src/components/Toast';
import { useAppApolloClient } from '../../../../../front/src/hooks/useAppApolloClient';
import { usePermissions } from '../../../../../front/src/hooks/usePermissions';
import { useProfile } from '../../../../../front/src/hooks/useProfile';
import { useAppDispatch } from '../../../../../front/src/redux/hooks';
import { openChatWindow } from '../../../../../front/src/redux/slices/chat-service';
import { closeChatWindow } from '../../../../../front/src/redux/slices/chat-slice';
import { markInboxAsRead, removeInbox } from '../../../../../front/src/thread/api/inbox';
import type { Message, User } from '../../../../../front/src/types/api';
import { ChatAvatars } from '../../Chat/ChatAvatars';

const intl = defineMessages({
  noUser: { defaultMessage: "Cet utilisateur n'existe plus", id: 'FUM7UA' },
  leaveConversation: { defaultMessage: 'Quitter cette conversation', id: '3aA10Q' },
});

const getInboxUsers = ({ users, filteredId }: { users: User[]; filteredId?: string }) =>
  filteredId ? (users || []).filter(u => u && u.id !== filteredId) : users;

type InboxItemProps = { inbox: Message; tip?: { hide: () => void } };

/**
 *
 * @param inbox
 * @param tip
 * @constructor
 */
export const InboxItem = ({ inbox, tip }: InboxItemProps) => {
  const { formatMessage } = useIntl();
  const client = useAppApolloClient();
  const dispatch = useAppDispatch();
  const { authUserId = '' } = usePermissions();
  const { profile } = useProfile({ userId: authUserId });
  const { id: authProfileId = '' } = profile || {};

  const displayRecipients = useCallback(
    (users: User[]) => {
      const recipients = (users || []).filter(u => u && u.id !== authUserId);

      return recipients ? (
        recipients.map(u => u?.profile?.pseudo || u?.username).join(', ')
      ) : (
        <small className="italic">
          --
          <FormattedMessage {...intl.noUser} />
          --
        </small>
      );
    },
    [authUserId],
  );

  const openThreadFromMessage = useCallback(
    async (threadId: string) => {
      try {
        if (!inbox.isAlreadyView) await markInboxAsRead(inbox.id as string, client);
        if (!inbox.userVisibility?.length) return;
        await openChatWindow(client, { threadId, authUserId })(dispatch);
        if (tip) tip.hide();
      } catch (e) {
        const err = e as AxiosError<any>;
        const msg = err.response?.data?.msg || err.message;
        failureToast(typeof msg === 'string' ? msg : JSON.stringify(msg));
      }
    },
    [dispatch, authUserId, tip, inbox, client],
  );

  const onDeleteInbox = useCallback(
    (threadId: string) => (event: React.SyntheticEvent<HTMLSpanElement>) => {
      event.stopPropagation();
      removeInbox({ threadId, userId: authUserId }, { authProfileId, client })
        .then(() => dispatch(closeChatWindow(threadId)))
        .catch(err => failureToast(err.response?.data?.msg || err.message));
    },
    [client, dispatch, authProfileId, authUserId],
  );

  return (
    <button
      key={inbox.id}
      className={classNames(
        'flex items-center justify-start space-x-3 text-left border-b border-gray-200 last:border-none bg-white hover:bg-gray-200 p-3',
        !inbox.isAlreadyView && 'font-semibold',
      )}
      type="button"
      onClick={() => openThreadFromMessage(inbox.thread?.id as string)}
    >
      <div className="relative w-9 h-9">
        <ChatAvatars
          users={getInboxUsers({
            users: (inbox.userVisibility || []) as User[],
            filteredId: authUserId,
          })}
        />
      </div>
      <div className="flex flex-1 flex-col overflow-hidden">
        <div className="text-base">{displayRecipients(inbox.userVisibility as User[])}</div>
        <div className="text-gray-400 prose-sub tracking-wide truncate">
          {truncate(inbox.content as string, { length: 38 })}
        </div>
      </div>
      <div className="flex flex-col items-end justify-around text-xs">
        <span>
          <LimitedRelativeTime date={inbox.createdAt} />
        </span>
        <IconRemove
          onClick={onDeleteInbox(inbox.thread?.id as string)}
          title={formatMessage(intl.leaveConversation)}
        />
      </div>
    </button>
  );
};
