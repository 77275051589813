import { useEffect, useRef } from 'react';

export function useChanged(...objects: any[]): boolean {
  const prevObjects = useRef<any[]>([]);

  useEffect(() => {
    prevObjects.current = [...objects];
  }, [objects]);

  const hasChanged = objects.some((obj, index) => obj !== prevObjects.current[index]);

  // Check if it's the initial render and return true
  if (prevObjects.current.length === 0) {
    return true;
  }

  return hasChanged;
}
