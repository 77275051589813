export const wsLogger = {
  log: (message: string, data?: any) => {
    return data
      ? console.log(`🔌 [WebSocket] ${message}`, data)
      : console.log(`🔌 [WebSocket] ${message}`);
  },
  deprecated: (message: string, data: any) => {
    console.log(
      `🔌 [WebSocket] %cDeprecated:%c ${message}`,
      'color: orange;',
      'color: inherit;',
      data,
    );
  },
  unhandled: (message: string, data: any) => {
    console.log(
      `🔌 [WebSocket] %cUnhandled%c ${message}`,
      'color: orange;',
      'color: inherit;',
      data,
    );
  },
  unknown: (message: string, data: any) => {
    console.log(`🔌 [WebSocket] %cUnknown%c ${message}`, 'color: red;', 'color: inherit;', data);
  },
  warn: (message: string, data?: any) => {
    console.warn(`🔌 [WebSocket] %cWARN%c ${message}`, 'color: orange;', 'color: inherit;', data);
  },
  error: (message: string, data?: any) => {
    console.error(`🔌 [WebSocket] %cERROR%c ${message}`, 'color: red;', 'color: inherit;', data);
  },
};
