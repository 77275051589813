import classNames from 'classnames';
import type { ChangeEvent, MouseEvent } from 'react';
import React, { memo, useCallback, useRef, useState } from 'react';

import { ChatIconButton } from '../../../../front/src/components/chat/ChatIconButton';
import { ChatInput } from '../../../../front/src/components/chat/ChatInput';
import { EmojiSelector } from '../../../../front/src/components/chat/EmojiSelector';
import { IconImage } from '../../../../front/src/components/icons/Icons';
import { ReactComponent as SendIcon } from '../../../../front/src/icons/icon-send.inline.svg';
import { handleError } from '../../../../front/src/utils/common-utils';
import type { ChatMessageContentArgsType } from './createOrUpdateChatMessage';

interface Props {
  sendMessage: (elt: ChatMessageContentArgsType) => Promise<void>;
  disabled?: boolean;
  autoFocus?: boolean;
  restricted?: boolean;
}

export const ChatField = memo(({ sendMessage, disabled, autoFocus, restricted }: Props) => {
  const inputRef = useRef<HTMLInputElement>();
  const chatInputRef = useRef<HTMLTextAreaElement>(null);
  const clickInput = useCallback(() => {
    const input = inputRef?.current;
    if (!input) return;
    input.click();
  }, [inputRef]);
  const [value, setValue] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const isDisabled = loading || disabled || restricted;

  const sendMessageWithLoading = useCallback(
    (arg: ChatMessageContentArgsType) => {
      (async function () {
        if (isDisabled) {
          return;
        }
        setLoading(true);
        try {
          await sendMessage(arg);
          if (!arg.file) {
            setValue('');
          }
        } catch (error) {
          console.error('Err while sending message:');
          handleError(error);
          // eslint-disable-next-line no-console
          console.warn('could not send message');
        } finally {
          setLoading(false);
        }
      })();
    },
    [isDisabled, sendMessage],
  );

  const sendText = useCallback(
    (e?: MouseEvent | string) => {
      (e as MouseEvent)?.preventDefault?.();
      (e as MouseEvent)?.stopPropagation?.();
      sendMessageWithLoading({ message: value });
      // Setting the focus again should not be required with the `onPointerDown` event set in ChatIconButton, but we leave it, just in case a device doesn't work with it.
      chatInputRef.current?.focus();
      return false;
    },
    [sendMessageWithLoading, value],
  );

  const sendImage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      sendMessageWithLoading({ file: event.target.files?.[0] as File }),
    [sendMessageWithLoading],
  );

  const setMsgChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (!isDisabled) {
        setValue(e.target.value);
      }
    },
    [isDisabled],
  );

  return (
    <div
      className={classNames(
        `flex transition-opacity py-2 px-1 _sm:pb-8 _sm:px-3 items-end shadow`,
        isDisabled && 'opacity-50 pointer-events-none',
      )}
    >
      <ChatIconButton icon={<IconImage className="w-7 h-7 sm:w-5 sm:h-5" />} onClick={clickInput} />
      <input
        type="file"
        className="invisible"
        ref={inputRef as React.MutableRefObject<HTMLInputElement>}
        accept="image/*"
        onChange={sendImage}
      />
      <EmojiSelector setValue={setValue} />
      <ChatInput
        ref={chatInputRef}
        className="appearance-none resize-none py-[10px] px-4 outline-none bg-gray-lighter flex-1 sm:text-base rounded-3xl sm:h-9"
        autoFocus={autoFocus}
        restricted={restricted}
        value={value}
        onChange={setMsgChange}
        enter={sendText}
      />
      <div className="w-11 h-11 flex items-center justify-center">
        {loading ? (
          <div className="loader" />
        ) : (
          <ChatIconButton
            className="sm:hover:bg-white"
            icon={<SendIcon className="h-5 w-5 text-chat-background" />}
            onClick={sendText}
          />
        )}
      </div>
    </div>
  );
});
