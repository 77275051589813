import Head from 'next/head';
import React from 'react';

import { useWebsite } from '../../../../front/src/hooks/useWebsite';
import { html } from '../../../../front/src/utils/dom';
import { truncate } from '../../../../front/src/utils/string';

export type MetaPagePropTypes = {
  title?: string;
  description?: string;
  data?: Record<string, string | number>;
  index: boolean;
  follow: boolean;
  canonicalPath: string | undefined;
};

const completeTemplate = ({
  template,
  data = {},
}: {
  template?: string;
  data?: Record<string, string | undefined>;
}) => {
  if (!template) return null;
  const completed = template;
  return completed.replace(/{([^{]*)}/g, match => data[match.slice(1, -1)] || '');
};

export const MetaPage: React.FC<MetaPagePropTypes> = ({
  title: titleTemplate,
  description: descriptionTemplate,
  data = {},
  index,
  follow,
  canonicalPath,
}) => {
  const { url, name } = useWebsite();

  const canonicalUrl = url && canonicalPath ? url + canonicalPath : undefined;
  const allData = {
    websiteName: name,
    ...data,
  };

  const title = completeTemplate({
    template: titleTemplate,
    data: allData,
  });

  let description = completeTemplate({
    template: descriptionTemplate,
    data: allData,
  });
  description = description ? truncate(description, 160, true) : description;

  const htmlTitle = html(title ?? '');

  return (
    <Head>
      {title ? (
        <>
          <title key="title">{title}</title>
          <meta
            key="og:title"
            property="og:title"
            content={typeof htmlTitle === 'string' ? htmlTitle : title}
          />
          <meta
            key="twitter:title"
            name="twitter:title"
            content={typeof htmlTitle === 'string' ? htmlTitle : title}
          />
        </>
      ) : null}
      {description ? (
        <>
          <meta key="description" name="description" content={description} />
          <meta key="og:description" property="og:description" content={description} />
        </>
      ) : null}
      <meta
        key="robots"
        name="robots"
        content={[index ? 'index' : 'noindex', follow ? 'follow' : 'nofollow'].join(', ')}
      />

      {canonicalUrl ? (
        <>
          <link key="canonical" rel="canonical" href={canonicalUrl} />
          <meta key="og:url" property="og:url" content={canonicalUrl} />
        </>
      ) : null}
    </Head>
  );
};
