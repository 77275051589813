import { useEffect } from 'react';

import { useAppApolloClient } from '../../../../front/src/hooks/useAppApolloClient';
import { useConnectedRouter } from '../../../../front/src/hooks/useConnectedRouter';
import { usePermissions } from '../../../../front/src/hooks/usePermissions';
import { useAppDispatch } from '../../../../front/src/redux/hooks';
import { openChatWindow } from '../../../../front/src/redux/slices/chat-service';

let urlWasRead = false;

export function useOpenChatFromUrl() {
  const client = useAppApolloClient();
  const dispatch = useAppDispatch();
  const { query } = useConnectedRouter();
  const { isAuthDone } = usePermissions();
  useEffect(() => {
    // Opening the chat from the URL param should happen only once, regardless of the navigation, components (un)mounting... that happen while using the app.
    // Use case: opening the app from a web push notification related to a chat event, which URL brings the related chat thread ID we should open when opening that URL.
    if (!urlWasRead && isAuthDone) {
      let threadId = query.ct;
      if (threadId) {
        if (Array.isArray(threadId)) threadId = threadId[0];
        threadId;
        openChatWindow(client, { threadId })(dispatch);
      }
      urlWasRead = true;
    }
  }, [client, dispatch, isAuthDone, query.ct]);
}
