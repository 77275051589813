import { useEffect } from 'react';

import { useAppSelector } from '../redux/hooks';
import { selectIsBot } from '../redux/slices/utils-slice';
import { isBrowser } from '../utils/fragments/isBrowser';
import { usePermissions } from './usePermissions';

export function useWaitUserLoading() {
  const isBot = useAppSelector(selectIsBot);
  const { isAuthDone } = usePermissions();

  useEffect(() => {
    if (isBrowser && !isBot) {
      if (!isAuthDone) {
        // The global spinner is already shown, initially.
      } else {
        window.hideGlobalSpinner();
      }
    }
  }, [isAuthDone, isBot]);
}
