import type { MutableRefObject } from 'react';
import { useEffect, useState } from 'react';

import { debounce } from '../utils/common-utils';
import { useRefLatest } from './useRefLatest';

type ElementSize = {
  width: number;
  height: number;
};

type TriggerOption = 'width' | 'height';

/**
 * Observe height and width changes of the element and return new values
 */
export const useElementSize = (elementId: string) => {
  const [size, setSize] = useState<ElementSize>({ width: 0, height: 0 });

  useEffect(() => {
    const element = document.getElementById(elementId);
    return watchElement(element, setSize);
  }, [elementId]);

  return size;
};

export default useElementSize;

type ResizeCallback = (size: { width: number; height: number }) => any; /* void */

/**
 * Calls the callback when the element width or height changes.
 */
export function useElementSizeCallback(
  elementRef: MutableRefObject<HTMLElement | null>,
  callback: ResizeCallback,
  options?: { triggerOn: TriggerOption },
) {
  const callbackRef = useRefLatest(callback);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;
    return watchElement(element, callbackRef.current, options);
  }, [callbackRef, elementRef, options]);
}

function watchElement(
  element: HTMLElement | null,
  callback: ResizeCallback,
  options?: { triggerOn: TriggerOption },
) {
  if (!element) return;

  let isFirstTrigger = true;

  let lastWidth = element.offsetWidth;
  let lastHeight = element.offsetHeight;

  const observer = () => {
    const newWidth = element.offsetWidth;
    const newHeight = element.offsetHeight;

    // Check if we should trigger the callback based on the trigger option
    const shouldRefreshValues =
      (!options?.triggerOn && (newWidth !== lastWidth || newHeight !== lastHeight)) ||
      (options?.triggerOn === 'width' && newWidth !== lastWidth) ||
      (options?.triggerOn === 'height' && newHeight !== lastHeight);

    if (isFirstTrigger || shouldRefreshValues) {
      callback({ width: newWidth, height: newHeight });
      lastWidth = newWidth;
      lastHeight = newHeight;
    }

    if (isFirstTrigger) isFirstTrigger = false;
  };

  const debouncedUpdateSize = debounce(observer, 150);

  const resizeObserver = new ResizeObserver(debouncedUpdateSize);
  resizeObserver.observe(element);

  observer();

  // Clean on component unmount
  return () => resizeObserver.disconnect();
}
