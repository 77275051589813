import { ToastContainer } from '../../../front/src/components/Toast';
import { ProfileCreation } from '../../../front/src/contributor/Profile/ProfileCreation';
import { NoSSR } from '../../../front/src/utils/ssr';
import { ChatWindows } from './Chat';

const footerElement = (
  <NoSSR>
    <ProfileCreation />
    <ChatWindows />
    <ToastContainer limit={2} />
  </NoSSR>
);
export const Footer = () => footerElement;
