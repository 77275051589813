import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import type { PropsWithChildren } from 'react';

import type { TBodyModifier } from '../hooks/useBodyModifier';
import { BodyModifierProvider } from '../hooks/useBodyModifier';
import { omitSingle } from '../utils/common-utils';

export const BodyModifier: React.FC<PropsWithChildren<{}>> = memo(function BodyModifier({
  children,
}) {
  const [modifiers, setModifiers] = useState<Record<string, Partial<TBodyModifier>>>({});

  const setBodyModifier = useCallback(
    ({ id, ...values }: TBodyModifier) => {
      setModifiers(oldModifiers => ({
        ...oldModifiers,
        [id]: values,
      }));
    },
    [setModifiers],
  );

  const removeBodyModifier = useCallback(
    ({ id }: any) => {
      setModifiers(oldModifiers => omitSingle(id, oldModifiers));
    },
    [setModifiers],
  );

  const value = useMemo(
    () => ({
      setBodyModifier,
      removeBodyModifier,
    }),
    [setBodyModifier, removeBodyModifier],
  );

  const canScroll = useMemo(
    () => Object.values(modifiers).every(modifier => !modifier?.preventScroll),
    [modifiers],
  );

  const canScrollMobile = useMemo(
    () => Object.values(modifiers).every(modifier => !modifier?.preventScrollMobile),
    [modifiers],
  );

  useEffect(() => {
    if (canScrollMobile) {
      document.body.classList.remove('noscroll-mobile');
      document.documentElement.classList.remove('noscroll-mobile');
    } else {
      document.body.classList.add('noscroll-mobile');
      document.documentElement.classList.add('noscroll-mobile');
    }
  }, [canScrollMobile]);

  useEffect(() => {
    if (canScroll) {
      document.body.classList.remove('noscroll');
      document.documentElement.classList.remove('noscroll');
    } else {
      document.body.classList.add('noscroll');
      document.documentElement.classList.add('noscroll');
    }
  }, [canScroll]);

  return <BodyModifierProvider value={value}>{children}</BodyModifierProvider>;
});
