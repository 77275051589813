import { setContext } from '@apollo/client/link/context';

import type { GraphLoginAsync } from '../../../../../graphdebate/src/components/auth/LoadGraphLogin';
import type { AccessToken } from '../../../types/auth';

export type GetPublicKey = () => string;

export type GenerateAuthLinkArgs = {
  getToken?: GraphLoginAsync['getToken'];
  getPublicKey?: GetPublicKey;
  triggerRefreshToken?: () => Promise<void>;
};

// const expiredJwt =
//   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzhjOWY4YjZlMTA2ZjRhN2NkZTAzZGMiLCJ1c2VybmFtZSI6ImFudG9pbmVvbCIsImVtYWlsIjoiYW50b2luZS5vcnlsYW1iYWxsZUBnbWFpbC5jb20iLCJmYWNlYm9va19pZCI6IjEwMTU5MzA5ODIwNTgwMjMzIiwiZ29vZ2xlX2lkIjoiMTA1NTczMjMyNzk0MzE3NDg2OTY1Iiwicm9sZSI6InVzZXIiLCJmcm9tX3dlYnNpdGUiOiI1ZDg4ZGEzZDNmNWY2MzJlMjI5N2Y0ZGMiLCJ2YWxpZGF0aW9uIjp7fSwibGFuZ3VhZ2UiOiJmciIsImlhdCI6MTcwMjU0OTcyOSwiZXhwIjoxNzA1MTQxNzI5fQ.-TIS6CxZei8wJVoR4bB-sn4U7XOdtmEokEu3rB4tFfo';

let prevToken: AccessToken | undefined = undefined;
let alreadyFailed = false;

export const generateAuthLink = ({ getToken, getPublicKey }: GenerateAuthLinkArgs) =>
  setContext(async (_op, { headers }) => {
    let token = getToken ? await getToken() : null;
    const publicKey = getPublicKey ? getPublicKey() : null;

    // Test code to simulate a 401 on a specific gql query.
    if (_op.operationName === 'HomeTopicsQuery') {
      let tokenChangedFromSecondReq = !!prevToken && token !== prevToken;
      prevToken = token;
      if (!tokenChangedFromSecondReq && !alreadyFailed /* Math.random() < 0.3 */) {
        // token = expiredJwt;
        alreadyFailed = true;
      }
    }

    return {
      headers: {
        ...headers,
        ...(publicKey ? { website: publicKey } : null),
        ...(token ? { Authorization: `JWT ${token}` } : null),
      },
    };
  });
