import { gql } from '@apollo/client';

import { BasicProfileFragment } from '../../services/graphql-queries';
import type { GraphApiMutationsCreateOrEditProfileArgs } from '../../types/api';

export const BasicUserFragment = gql`
  fragment BasicUserFragment on User {
    id
    email
    username
    language
    role
    delete
    validationEmailNeed
    profile {
      id
      status
      pseudo
      isVip
    }
  }
`;

export const UserAccountMutation = gql`
  mutation UserAccountMutation(
    $username: String!
    $email: String!
    $language: String!
    $password: String!
  ) {
    editUser(username: $username, email: $email, language: $language, currentPassword: $password) {
      id
      ...BasicUserFragment
    }
  }
  ${BasicUserFragment}
`;

export type CreateOrEditProfileMutationVariables = GraphApiMutationsCreateOrEditProfileArgs;

export const UserProfileSettingsMutation = gql`
  mutation UserProfileSettingsMutation($loadingDepth: Int, $language: String, $sort: String) {
    createOrEditProfile(loadingDepth: $loadingDepth, language: $language, sort: $sort) {
      ...BasicProfileFragment
      disableMailNotif
    }
  }
  ${BasicProfileFragment}
`;

export const UserProfileMutation = gql`
  mutation UserProfileMutation(
    $id: String
    $pseudo: String
    $bio: String
    $twitterUsername: String
    $proStatus: String
    $newPicture: Upload
    $language: String
  ) {
    createOrEditProfile(
      id: $id
      pseudo: $pseudo
      bio: $bio
      twitterUsername: $twitterUsername
      proStatus: $proStatus
      picture: $newPicture
      language: $language
    ) {
      ...BasicProfileFragment
    }
  }
  ${BasicProfileFragment}
`;
