export const stringToColor = (
  stringToConvert: string,
  opacity: number = 1,
  format: 'hex' | 'rgba' = 'hex',
): string => {
  // hach calculate
  let hash = 0;
  for (let i = 0; i < stringToConvert.length; i++) {
    hash = stringToConvert.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Extract RGB components
  const red = (hash >> 16) & 0xff;
  const green = (hash >> 8) & 0xff;
  const blue = hash & 0xff;

  // Fpormat color
  if (format === 'hex') {
    // Convert opacity to hex (0-255)
    const alpha = Math.round(opacity * 255)
      .toString(16)
      .padStart(2, '0');
    return `#${((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1)}${alpha}`;
  } else {
    return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
  }
};

/**
 * Converts string to unique hsl color
 */
export const stringToHslColor = (stringToConvert: string, opacity: number = 1): string => {
  // hach calculate
  let hash = 0;
  for (let i = 0; i < stringToConvert.length; i++) {
    hash = stringToConvert.charCodeAt(i) + ((hash << 5) - hash);
  }

  const hue = hash % 360;
  const backgroundColor = `hsl(${hue}, 68%, 58%, ${opacity})`;

  return backgroundColor;
};

/**
 * Pick a specific element in array, based on the hash of the string provided
 */
export const stringToArrayElement = (stringToConvert: string, arrayToPickFrom: string[]) => {
  let hash = 0;
  for (let i = 0; i < stringToConvert.length; i++) {
    hash = stringToConvert.charCodeAt(i) + ((hash << 5) - hash);
  }
  const index = Math.abs(hash) % arrayToPickFrom.length;
  return arrayToPickFrom[index];
};
