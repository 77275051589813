type Func<T> = (...args: any[]) => T;
type ResolverFn = (...args: any[]) => string;

export function memoize2<T, ThisType>(func: Func<T>, resolver?: ResolverFn): Func<T> {
  const cache: Map<string, T> = new Map();

  return function (this: ThisType, ...args: any[]): T {
    const key = resolver ? resolver.apply(this, args) : args.join('-');
    if (!cache.has(key)) {
      const result: T = func.apply(this, args);
      cache.set(key, result);
    }
    return cache.get(key)!;
  };
}
