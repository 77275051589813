import { deepCopy } from './common';

const hexColors: PickableColors = {
  red: ['#971048', '#D0483F', '#F35B5A'],
  orange: ['#FF7900', '#FBA026'],
  green: ['#40A85F', '#61BD6D', '#18BC9D'],
  blue: ['#2969B0', '#2B81C8', '#55ABD2'],
  purple: ['#543982', '#9365B8'],
  bronze: ['#A46C38', '#A38E82'],
  dark: ['#28324E', '#75706C'],
};

type HexColor = `#${string}`;
type TwBgColor = `bg-${string}`;
type TwTxtColor = `text-${string}`;
export type PickableColors = { [key: string]: (HexColor | TwBgColor | TwTxtColor)[] };

type ColorCategory = {
  category: string;
  colors: string[];
};
/**
 * Class to randomly Pick a color from Website colors.
 * You can change colors to randomly pick by providing an object of colors on initialization.
 *
 * Each time you use pickRandomColor method, it will pick a color on a different category until
 * no color is left in any category, then restart from the begining.
 *
 * @param {PickableColors}
 * @example
 * // By default, will return hex color
 * const WebsiteColors = new WebsiteColorsPicker()
 * const randomWebsiteColor = WebsiteColors.pickRandomColor()
 * // '#2B81C8
 *
 * @example
 * // OR with your own colors
 * const MyOwnTwBgColors = {
 *  red: ['bg-strongScarlet', 'bg-valencia', 'bg-graphComment'],
 *  green: ['bg-chateauGreen', 'bg-fern', 'bg-mountainMeadown'],
 *  bronze: ['bg-cognac', 'bg-almondFrost'],
 * };
 * const WebsiteColors = new WebsiteColorsPicker(MyOwnTwBgColors)
 * const randomTwBgColor = WebsiteColors.pickRandomColor()
 * // 'bg-fern'
 */
export class WebsiteColorsPicker {
  private originalCategories: ColorCategory[];
  private remainingCategories: ColorCategory[];
  private categoriesToPickFrom: string[];

  constructor(providedColors: PickableColors = hexColors) {
    this.originalCategories = Object.entries(providedColors).map(([category, colors]) => ({
      category,
      colors,
    }));
    this.remainingCategories = deepCopy(this.originalCategories);
    this.categoriesToPickFrom = this.remainingCategories.map(category => category.category);
  }

  private resetRemainingCategories() {
    this.remainingCategories = deepCopy(this.originalCategories);
  }

  private resetCategoriesToPickFrom() {
    this.categoriesToPickFrom = this.remainingCategories.map(category => category.category);
  }

  pickRandomTwColor(): string {
    if (this.remainingCategories.length === 0) {
      this.resetRemainingCategories();
    }

    if (this.categoriesToPickFrom.length === 0) {
      this.resetCategoriesToPickFrom();
    }

    // Randomly choose a category
    const randomCategoryIndex = Math.floor(Math.random() * this.categoriesToPickFrom.length);
    const categoryKey = this.categoriesToPickFrom[randomCategoryIndex];
    const category = this.remainingCategories.find(c => c.category === categoryKey)!;

    // Randomly choose a color inside the category and remove it from the array
    const randomColorIndex = Math.floor(Math.random() * category.colors.length);
    const color = category.colors[randomColorIndex];
    category.colors.splice(randomColorIndex, 1);

    // Remove category if colors array is now empty
    if (category.colors.length === 0) {
      this.remainingCategories = this.remainingCategories.filter(c => c.category !== categoryKey);
    }

    // Update categories which can be picked on this loop
    this.categoriesToPickFrom.splice(randomCategoryIndex, 1);

    return color;
  }
}

export class CircularPicker {
  private items: string[];
  private currentIndex: number;

  constructor(items: string[]) {
    this.items = items;
    this.currentIndex = 0;
  }

  private getActualIndex(providedIndex: number): number {
    return providedIndex % this.items.length;
  }

  public pick(): string {
    const item = this.items[this.currentIndex];
    this.currentIndex = this.getActualIndex(this.currentIndex + 1);
    return item;
  }

  public pickIndex(index: number): string {
    const actualIndex = this.getActualIndex(index);
    return this.items[actualIndex];
  }
}
