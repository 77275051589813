import { defineMessages } from 'react-intl';

export const editorMessages = defineMessages({
  title: { defaultMessage: 'Titre', id: 'qfv+qu' },
  placeholder: { defaultMessage: 'Entrez un titre pour le sujet', id: 'GOfDPq' },
  bodyTitle: { defaultMessage: 'Contenu', id: 'b/Deno' },
  subjectRequired: { defaultMessage: 'Le sujet de la page est requis', id: 'FTO7+Q' },
  minContent: { defaultMessage: 'Le contenu de la page est trop court', id: 'HckdSc' },
  maxContent: { defaultMessage: 'Le contenu de la page est trop long', id: '9tJ7Jo' },
  minSubject: { defaultMessage: 'Le sujet de la page est trop court', id: 'jRDRvJ' },
  maxSubject: { defaultMessage: 'Le sujet de la page est trop long', id: 'podBF1' },
  contentRequired: { defaultMessage: 'Vous devez saisir un contenu', id: '69z2qs' },
  genericError: {
    defaultMessage:
      "Nous n'avons pas pu prendre en compte vos modifications pour les raisons suivantes :\n\n {error}",
    id: 'Ga5wSZ',
  },
  // The key is also in a response from the API. Don't change it.
  'maximum file length exceeded': {
    defaultMessage: "L'upload est réservé aux images inférieures à 2 Mo",
    id: '7FadpG',
  },
  optInNotif: {
    defaultMessage:
      "Recevoir une notification chaque fois que quelqu'un poste un message sur ce sujet",
    id: 'CxQktQ',
  },
  imagesOnly: { defaultMessage: "L'upload est réservé aux images seulement", id: '9eX/pe' },
  cancel: { defaultMessage: 'Annuler et retourner sur la page', id: 'r5z5BD' },
  send: { defaultMessage: 'Envoyer', id: 'EWLVLG' },
  submitConfirmation: {
    defaultMessage: 'Votre Sujet a bien été envoyé, il est en cours de modération.',
    id: 'BYj6wI',
  },
});
