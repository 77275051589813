import type { ApolloLink, HttpOptions } from '@apollo/client';
import { createUploadLink as httpLink } from 'apollo-upload-client';

import { graphUrl } from '../../../config';

// @TODO: evaluate if this is required
declare module 'apollo-upload-client' {
  function createUploadLink(cfg: HttpOptions): ApolloLink;
}

export const generateHttpLink = (url: string = graphUrl): ApolloLink => {
  return httpLink({
    uri: `${url}`,
    credentials: 'include',
  });
};
