import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { DensityType } from '../../components/density/children/DensityItem';
import type { AuthPermissions } from '../../hooks/usePermissions';
import { localStorage } from '../../services/storage/localStorage';
import type { Website } from '../../types/api';
import type { PageConfig } from '../../types/debate-types';
import type { LayoutBlock } from '../../types/layout';
import { isMobile } from '../../utils/web';
import type { RootState } from '../store';

// If a state should not be reset on logout, check /front/src/redux/store.ts

export interface UtilsState {
  isBot: boolean;
  website?: Website;
  me?: AuthPermissions;
  pageConfig?: PageConfig;
  previousPageParamsOverridden?: PageConfig['previousPageParams'];
  globalLoading?: boolean;
  customLayout?: LayoutBlock[];
  customDensity?: DensityType;
}

const initialState: UtilsState = {
  isBot: false,
  customDensity:
    (localStorage?.getItem('listDensity') as DensityType) || (isMobile() ? 'compact' : 'classic'),
};

export const utilsSlice = createSlice({
  name: 'utils',
  initialState,
  reducers: {
    setIsBot(state, { payload }: PayloadAction<boolean | undefined>) {
      state.isBot = !!payload;
    },
    setWebsite(state, { payload }: PayloadAction<Website>) {
      state.website = payload;
    },
    setMe(state, { payload }: PayloadAction<AuthPermissions>) {
      state.me = payload;
    },
    setPageConfig(state, { payload }: PayloadAction<PageConfig>) {
      state.pageConfig = payload;
    },
    startGlobalLoading(state) {
      state.globalLoading = true;
    },
    stopGlobalLoading(state) {
      state.globalLoading = false;
    },
    overridePreviousPageParams(
      state,
      { payload }: PayloadAction<PageConfig['previousPageParams']>,
    ) {
      state.previousPageParamsOverridden = payload;
    },
    setCustomLayout(state, { payload }: PayloadAction<UtilsState['customLayout']>) {
      state.customLayout = payload;
    },
    setCustomDensity(state, { payload }: PayloadAction<DensityType>) {
      localStorage?.setItem('listDensity', payload);
      state.customDensity = payload;
    },
  },
});

export const {
  setIsBot,
  setWebsite,
  setMe,
  setPageConfig,
  startGlobalLoading,
  stopGlobalLoading,
  overridePreviousPageParams,
  setCustomLayout,
  setCustomDensity,
} = utilsSlice.actions;

export const selectWebsite = (state: RootState) => state.utils.website;
export const selectIsBot = (state: RootState) => state.utils.isBot;
export const selectIsSignedIn = (state: RootState) => !!state.utils.me?.authUserId;
export const selectWithSubHeader = (state: RootState) => !!state.utils.pageConfig?.withSubHeader;
export const selectPageConfig = (state: RootState) => state.utils.pageConfig;
export const selectGlobalLoading = (state: RootState) => state.utils.globalLoading;
export const selectPreviousPageParams = (state: RootState) =>
  state.utils.previousPageParamsOverridden || state.utils.pageConfig?.previousPageParams;
export const selectCustomLayout = (state: RootState) => state.utils.customLayout;
export const selectCustomDensity = (state: RootState) => state.utils.customDensity;
