import type { FieldPolicy } from '@apollo/client';

import type { ChildMessages } from '../../../types/api';
import { mergeChronological } from './mergeChronological';
import { mergeRelevance } from './mergeRelevance';

export const childMessagesPolicy: FieldPolicy<ChildMessages> = {
  // keyArgs: ['sort'],
  merge: (existing, incoming, { args, readField }) => {
    if (!existing) return incoming;

    return args?.sort === 'relevance'
      ? mergeRelevance(existing, incoming, { args: { ...args }, readField })
      : mergeChronological(existing, incoming, {
          args: { ...args },
        });
  },
};
