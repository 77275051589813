import type { TippyProps } from '@tippyjs/react';
import classNames from 'classnames';
import { useCallback, useMemo, useReducer, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { Avatar } from '../../../components/Avatar';
import { Button } from '../../../components/Button/Button';
import type { TippyInstance } from '../../../components/GraphModal/Popover';
import { Popover } from '../../../components/GraphModal/Popover';
import { useProfile } from '../../../hooks/useProfile';
import { ReactComponent as IconAddFriend } from '../../../icons/icon-add-friend-inline.svg';
import { handleError } from '../../../utils/common-utils';
import { isBrowser } from '../../../utils/web';
import type { RequestFriendCallback, RequestFriendResponse } from '../../api/relation';

const intl = defineMessages({
  // \u00a0 = non-breaking space. Otherwise, we can have a single "?" on a separate line.
  invite: { defaultMessage: 'Inviter <user></user> à rejoindre vos amis\u00a0?', id: 'l3r9er' },
  cancel: { defaultMessage: 'Annuler', id: '8Kvk8a' },
  sendInvite: { defaultMessage: "Envoyer l'invitation", id: 'Kj5AZc' },
  placeholder: {
    defaultMessage: 'Vous pouvez joindre un message à votre demande... (Facultatif)',
    id: 'zhPP3O',
  },
});

type AddFriendProps = {
  userId: string;
  onAddFriend: (message: string) => Promise<RequestFriendResponse>;
  appendToBody?: boolean;
  placement?: string;
  inviteCallback?: RequestFriendCallback;
};

/**
 *
 * @param userId
 * @param onAddFriend
 * @param appendToBody
 * @param placement
 * @constructor
 */
export const AddFriend = ({
  userId,
  onAddFriend,
  appendToBody,
  placement = 'top',
  inviteCallback,
}: AddFriendProps) => {
  const { formatMessage } = useIntl();
  const [{ count, message }, setMessage] = useReducer(
    (_: unknown, msg: string) => ({
      message: msg,
      count: 300 - Number(msg?.length || 0),
    }),
    { count: 300, message: '' },
  );
  const [tip, setTip] = useState<TippyInstance>();
  const { profile } = useProfile({ userId });
  const { id: profileId, picture, pseudo } = profile || {};
  const handleSubmit = useCallback(async () => {
    try {
      if (!profileId) {
        console.warn('profileId is missing, cannot invite. Is the user authenticated?');
        return;
      }
      if (typeof tip?.hide === 'function') tip.hide();
      const response = await onAddFriend(message);
      inviteCallback?.(response);
    } catch (error) {
      handleError(error);
    }
  }, [profileId, tip, onAddFriend, message, inviteCallback]);

  const content = useMemo(
    () => (
      <div className="text-black space-y-4">
        <div className="mb-3 inline items-baseline text-base font-semibold">
          <FormattedMessage
            {...intl.invite}
            values={{
              user: function user() {
                return (
                  <span className="inline-flex items-baseline space-x-1">
                    <Avatar
                      profile={profile}
                      picture={picture}
                      size="small2"
                      className="mx-[2px]"
                      legacyImage
                    />{' '}
                    <b className="text-primary-base">{pseudo}</b>
                  </span>
                );
              },
            }}
          />
        </div>

        <div className="relative">
          <textarea
            className={`w-full text-base leading-snug text-gray-dark resize-none outline-none p-4 border rounded ${
              count < 0 ? 'border-primary-base' : 'border-gray-light'
            }`}
            style={{ minHeight: 100 }}
            placeholder={formatMessage(intl.placeholder)}
            onChange={e => setMessage(e.target.value)}
            value={message}
          />
          <span
            className={`text-xs absolute ${count >= 0 ? 'text-gray-medium' : 'text-primary-base'}`}
            style={{ bottom: 12, right: 8 }}
          >
            {Math.max(count, 0)}/300
          </span>
        </div>
        <div className="flex items-center justify-end">
          <Button variant="link" onClick={() => tip?.hide()}>
            <FormattedMessage {...intl.cancel} />
          </Button>
          <span className="circle">●</span>
          <Button
            type="submit"
            variant="link"
            className="bold"
            disabled={count < 0}
            onClick={handleSubmit}
          >
            <FormattedMessage {...intl.sendInvite} />
          </Button>
        </div>
      </div>
    ),
    [count, formatMessage, message, handleSubmit, profile, picture, pseudo, tip],
  );

  return (
    <Popover
      content={content}
      className={classNames('w-100 tippy-add-friend-override')}
      onCreate={setTip}
      appendTo={appendToBody && isBrowser ? document.body : 'parent'}
      placement={placement as TippyProps['placement']}
    >
      <Button
        className="text-primary inline-flex select-none no-underline hover:text-primary-variant h-8 w-8 items-center justify-center"
        variant="link"
        noPadding
      >
        <IconAddFriend className="h-5 w-5" />
      </Button>
    </Popover>
  );
};
