import type { StorageType } from './liveStorage';
import { generateLiveStorage } from './liveStorage';

export const generateWebsiteStorageKey = (key: string) => `__semio__token__${key}__`;
export const generateWebsiteStorageRefreshKey = (key: string) => `__semio__refresh_token__${key}__`;
export const generateSharedStorageKey = (key: string) => `__semio__shared__${key}__`;

export const localStorage: StorageType | Storage = (() => {
  if (typeof window !== 'object') return generateLiveStorage();

  try {
    window.localStorage.getItem('anything');
    return window.localStorage;
  } catch (e) {
    try {
      window.sessionStorage.getItem('anything');
      return window.sessionStorage;
    } catch (subError) {
      return generateLiveStorage();
    }
  }
})();

export default localStorage;
