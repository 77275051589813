import type { FC, HTMLAttributes, ReactNode } from 'react';
import { memo, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';

import { NewHeadingsMenu } from '../../../../graphdebate/src/components/Home/NewHeadingsMenu';
import { useAppSelector } from '../../redux/hooks';
import { selectHeadings } from '../../thread/ThreadMessage/topics-slice';
import { GraphModal } from '../GraphModal/GraphModal';

type CategoriesMenuModaleProps = HTMLAttributes<HTMLDivElement> & {
  poper: ReactNode;
};

const CategoriesMenuModale: FC<CategoriesMenuModaleProps> = memo(function CategoriesMenuModale({
  poper,
  className,
  ...otherProps
}) {
  const headings = useAppSelector(selectHeadings);

  const lazyRender = useCallback(
    (tip: any) => (
      <NewHeadingsMenu
        className="max-h-full"
        headings={headings}
        isModalDisplay
        onTagClick={tip.hide}
      />
    ),
    [headings],
  );

  const shouldDisplayBurger = headings?.length;

  return shouldDisplayBurger ? (
    <div className={twMerge('CategoriesMenuModale', className)} {...otherProps}>
      <GraphModal lazyContent={lazyRender} showPageOverlay forceModal widthXL>
        {poper}
      </GraphModal>
    </div>
  ) : null;
});

export default CategoriesMenuModale;
