import Head from 'next/head';
import type { FC } from 'react';
import { memo } from 'react';
import type { DiscussionForumPosting, Person, QAPage, Question, WithContext } from 'schema-dts';

import { useWebsite, useWebsiteMeta } from '../../hooks/useWebsite';
import type { Category, Guest, Topic, User, Vote } from '../../types/api';
import { sliceUnicodeAware } from '../../utils/common-utils';
import { stripHtml } from '../../utils/dom';
import { getTopicLastModifiedStr } from '../thread-utils';

const completeTemplate = ({ template, data }: { template: string; data: any }) => {
  if (!template) return '';
  return template.replace(/{([^{]*)}/g, match => data[match.slice(1, -1)] || '');
};

interface Props {
  topic: Topic;
}

export const TopicLd: FC<Props> = memo(function TopicLd(props) {
  // if (isBrowser && !frontConfig.enableSEOLdInFront) {
  //   return null;
  // }
  return <TopicLdInner {...props} />;
});

export const TopicLdInner: FC<Props> = memo(function TopicLdInner(props) {
  const { topic } = props;
  const { name: websiteName = '', url: websiteUrl = '', custom = {} } = useWebsite();
  const { topic: topicMeta = {} } = useWebsiteMeta('topic');
  const { subject: rawSubject, content: rawContent, votes: rawVotes = [] } = topic;
  const votes = rawVotes as Vote[];
  const { image } = custom;
  // Once I've found a category which is "null". Maybe an old, removed category?
  const categories = (topic?.categories?.filter(c => c) || []) as Category[];

  const subject = stripHtml(rawSubject as string) || '';
  const content = stripHtml(rawContent as string) || '';
  const topicExtractedData = {
    pageSubject: subject,
    pageContent: content,
    pageDate: topic?.createdAt,
    firstTagTitle: categories[0]?.title ?? '',
    websiteName,
    websiteUrl,
  };
  const completedTitle = completeTemplate({
    template: topicMeta?.title ?? (subject || ''),
    data: topicExtractedData,
  });
  const completedDescription = completeTemplate({
    template: topicMeta?.description ?? (content || ''),
    data: topicExtractedData,
  });
  const url = `${websiteUrl}/t/${topic.slug}`.replace(/[^:]\/\//, '/');
  const logoUrl = `${websiteUrl}/${image ?? 'images/apple-touch-icon.png'}`.replace(
    /[^:]\/\//,
    '/',
  );

  const authorPicture =
    (topic?.author as User)?.profile?.picture ?? (topic?.author as Guest)?.picture;
  const pseudo = (topic?.author as User)?.profile?.pseudo;
  const authorName = pseudo ?? topic?.author?.username ?? `Anonymous#${topic.id}`;
  const author: Person = {
    '@type': 'Person',
    name: authorName,
    image: authorPicture,
    ...(!!pseudo && { url: `${websiteUrl}/p/${pseudo}` }),
  };

  const isQuestion = false && /[?]/.test(subject);

  const lastModified = getTopicLastModifiedStr(topic);
  const dateData = {
    dateCreated: topic?.createdAt,
    datePublished: topic?.createdAt,
    dateModified: lastModified,
  };

  const answerCount = topic?.findOrCreateThread?.countMessages || topic.messagesCount;

  const questionObject: WithContext<QAPage> = {
    '@context': 'https://schema.org',
    '@type': 'QAPage',
    url,
    mainEntity: {
      '@type': 'Question',
      name: completedTitle,
      text: completedDescription,
      answerCount,
      upVoteCount: Math.max(
        votes.filter(elt => Number(elt.score ?? 0) > 0).length -
          votes.filter(elt => Number(elt.score ?? 0) < 0).length,
        0,
      ),
      author,
      ...dateData,
    } as Question,
  };

  const discussionObject: WithContext<DiscussionForumPosting> = {
    '@context': 'https://schema.org',
    '@type': 'DiscussionForumPosting',
    image: logoUrl,
    url,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url,
    },
    headline: sliceUnicodeAware(completedTitle, 0, 80),
    text: completedDescription,
    description: completedDescription,
    keywords: categories.map(tag => tag.title || tag.slug).join(','),
    ...dateData,
    publisher: {
      '@type': 'Organization',
      legalName: websiteName ?? '',
      name: websiteName ?? '',
      logo: {
        '@type': 'ImageObject',
        contentUrl: logoUrl,
        url: logoUrl,
      },
    },
    author,
    interactionStatistic: {
      '@type': 'InteractionCounter',
      interactionType: { '@type': 'CommentAction' },
      userInteractionCount: answerCount,
    },
    about: categories.map(({ title, slug }) => ({ '@type': 'DefinedTerm', name: title || slug })),
  };

  const jsonld = isQuestion ? questionObject : discussionObject;

  return (
    <Head>
      <script
        key={`json-lds-topic-${topic?.id}`}
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonld) }}
      />
    </Head>
  );
});
