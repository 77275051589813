import { ButtonV3Icon } from '../../../../components/Button/ButtonV3Icon';
import { IconClose } from '../../../../components/icons/Icons';

export type EmbedImageProps = {
  fileId?: string;
  url: string;
  removeEmbed?: () => void;
  provider_url?: string;
  author_url?: string;
  author_name?: string;
};

export type EmbedImageProps2 = { type: 'image' } & EmbedImageProps;

export const EmbedImage = (props: EmbedImageProps) => {
  const { fileId, url, removeEmbed } = props;

  return (
    <div className="embedImage flex justify-center mx-auto w-fit">
      <div className="relative group">
        <div className="embedImageInner p-3">
          <a
            href={fileId === 'preview' ? undefined : url?.trim()}
            target="_blank"
            rel="noopener noreferrer"
            className="overflow-hidden"
          >
            <img src={url?.trim()} alt="" />
            <div className="absolute inset-0 opacity-0 group-hover:opacity-50 duration-300 w-full h-full" />
          </a>
          {removeEmbed && (
            <div className="absolute -top-1 -right-1 opacity-0 group-hover:opacity-100 group-hover:bg-gray-light duration-300 rounded-full">
              <ButtonV3Icon
                className="ml-auto no-underline p-2"
                onClick={e => {
                  e.stopPropagation();
                  removeEmbed();
                }}
                icon={<IconClose className="w-3 h-3" fill="text-gray-600" />}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
