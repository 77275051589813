import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import type { PropsWithChildren } from 'react';
import React, { useContext, useEffect, useMemo, useRef } from 'react';

import { QUERY_PARAMS } from '../constants/graphlogin';
import { useAppDispatch } from '../redux/hooks';
import { setWebsite } from '../redux/slices/utils-slice';
import { websiteQuery } from '../services/graphql-queries';
import type { QueryType, Website, WebsiteLogin } from '../types/api';
import { isNamedDomain } from '../utils/web';

export interface WebsiteContextType extends Website {
  loading: boolean;
  key: string;
  originUrl: string;
}

export const EMPTY_WEBSITE: WebsiteContextType = {
  graphdebate: {
    meta: {},
  },
  graphcomment: {},
  custom: {},
  cgu: {},
  domainsWhiteList: [],
  loading: true,
  key: '',
  id: '',
  subscribeByTokenVip: false,
  name: '',
  originUrl: '',
  login: { order: [] },
};

export const websiteContext = React.createContext<WebsiteContextType>(EMPTY_WEBSITE);

interface WebsiteProviderProps {
  publicKey: string;
  website: Website | undefined;
  loading?: boolean;
}

export const WebsiteProvider: React.FC<PropsWithChildren<WebsiteProviderProps>> =
  function WebsiteProvider({ children, publicKey: key, website, loading }) {
    const { query, isReady } = useRouter();
    const originUrl = decodeURIComponent((query?.[QUERY_PARAMS.originUrl] as string) || '');

    const originUrlRef = useRef(originUrl);
    originUrlRef.current = originUrl || originUrlRef.current;
    // Not equivalent to useRefLatest, I don't know why. With useRefLatest, originUrl is an empty string in the signup form packages/graphlogin/pages/[key]/signup/index.tsx

    const dispatch = useAppDispatch();
    const value = useMemo(() => {
      const websiteMemoized = {
        ...(website ?? null),
        loading: loading || !isReady,
        key,
        id: website?.id ?? '',
        name: website?.name ?? '',
        domainsWhiteList: ((website?.domainsWhiteList ?? []) as string[]).filter(isNamedDomain),
        originUrl: originUrlRef.current,
        graphdebate: website?.graphdebate ?? {},
        login: website?.login as WebsiteLogin,
      };
      return websiteMemoized;
    }, [website, loading, isReady, key]);

    useEffect(() => {
      dispatch(setWebsite(value));
    }, [dispatch, value]);

    return (
      <websiteContext.Provider value={value || EMPTY_WEBSITE}>{children}</websiteContext.Provider>
    );
  };

export const useWebsite = () => {
  return useContext(websiteContext);
};

export function useWebsiteMeta(_pageName?: string) {
  const { graphdebate } = useWebsite();
  if (!graphdebate) throw new Error('Missing graphdebate in ThreadInteractiveMessage render');
  return graphdebate.meta || {};
}

interface WebsiteProviderGLProps {
  publicKey: string;
}

export function WebsiteProviderGL(props: PropsWithChildren<WebsiteProviderGLProps>) {
  const { publicKey } = props;
  const { data, loading } = useQuery<QueryType>(websiteQuery, {
    fetchPolicy: 'cache-first',
    skip: !publicKey,
  });
  // useCheckSSR(loading);
  const website = data?.website;

  return <WebsiteProvider {...props} website={website} loading={loading} />;
}
