import type { FC } from 'react';
import { memo } from 'react';

import type { Profile } from '../../types/api';
import { Avatar } from '../Avatar';
import { Link } from '../Link';
import type { MenuSectionProps } from './PopupMenuSection';
import { PopupMenuSection } from './PopupMenuSection';

interface Props {
  sections: MenuSectionProps[];
  profile: Profile;
  closeMenu: VoidFunction;
}

export const PopupMenu: FC<Props> = memo(({ sections, profile, closeMenu }) => {
  // We used to hide the section title if there was only one section. It is disabled for now. I find better to keep if now we show the picture and pseudo on the top.
  // const hideTitle =
  //   sections.map(section => section?.elements?.length > 0 && section.title)?.length < 2;

  return (
    <div className="p-2 bg-white rounded-lg">
      <div className="divide-y">
        <div className="font-bold pt-1 pb-3">
          <Link key="link-user" to="profile" params={{ pseudo: profile.pseudo }}>
            <a className="flex space-x-2 items-center" onClick={closeMenu}>
              {profile.picture && (
                <Avatar profile={profile} picture={profile.picture} size="small2" />
              )}
              <span className="truncate">{profile.pseudo}</span>
            </a>
          </Link>
        </div>
        {sections.map((props, index) => (
          <PopupMenuSection
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            {...props}
            // hideTitle={hideTitle}
          />
        ))}
      </div>
    </div>
  );
});
