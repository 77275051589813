import type { LayoutBlock } from '../../../front/src/types/layout';

/**
 * Takes customLayout as entry and returns an object where each key is a location in the template
 * with its filterd blocks
 * @param array CustomLayout
 *
 * @example
 * const customLayout = [
 *  { id: 1, locate: 'left', ...LayoutBlock },
 *  { id: 2, locate: 'right', ...LayoutBlock },
 *  { id: 3, locate: 'head', ...LayoutBlock },
 *  { id: 4, locate: 'left', ...LayoutBlock },
 *  { id: 5, locate: 'right', ...LayoutBlock }
 * ];
 *
 * const groupedLayout = groupByBlockLocation(customLayout);
 * // {
 * //   left: [
 * //       { id: 1, locate: 'left', ...LayoutBlock },
 * //       { id: 4, locate: 'left', ...LayoutBlock }
 * //   ],
 * //   right: [
 * //       { id: 2, locate: 'right', ...LayoutBlock },
 * //       { id: 5, locate: 'right', ...LayoutBlock }
 * //   ],
 * //   head: [{ id: 3, locate: 'head', ...LayoutBlock }],
 * // }
 *
 */
export function groupByBlockLocation(array: LayoutBlock[]) {
  return array.reduce((acc, item) => {
    const currentGroup = item.locate;

    if (!acc[currentGroup]) acc[currentGroup] = [];

    acc[currentGroup]?.push(item);

    return acc;
  }, {} as Partial<Record<LayoutBlock['locate'], LayoutBlock[]>>);
}
