import { gql, useQuery } from '@apollo/client';
import type { PropsWithChildren } from 'react';
import React, { useContext, useEffect, useMemo } from 'react';

import type { GraphLoginAsync } from '../../../graphdebate/src/components/auth/LoadGraphLogin';
import type { AuthBan } from '../contributor/Ban/ban-models';
import { getHandledBan } from '../contributor/Ban/ban-utils';
import { useAppDispatch } from '../redux/hooks';
import { logoutActionType } from '../redux/redux.utils';
import { setMe } from '../redux/slices/utils-slice';
import { userQuery } from '../services/graphql-queries';
import type { Ban, Profile, QueryType, User } from '../types/api';
import { useConnectedRouter } from './useConnectedRouter';
import { readIsGuest, readStatus } from './usePermissions-utils';
import { useWebsite } from './useWebsite';

export const CheckVIPQuery = gql`
  query CheckVIPQuery {
    me {
      id
      profile {
        id
        status
        isVip
      }
    }
    website {
      id
      subscribeByTokenVip
    }
  }
`;

interface AuthMethods {
  graphLogin: GraphLoginAsync;
}
export interface AuthPermissions extends GraphLoginAsync {
  isSuperAdmin: boolean;
  isOwner: boolean;
  isAdmin: boolean;
  isModerator: boolean;
  isVip: boolean;
  authUserId: string;
  currentBan?: AuthBan;
  isAuthDone: boolean;
  isDeleted: boolean;
  email?: string;
  username?: string;
  language: string;
  profile: Profile | undefined;
}
export const authContext = React.createContext<AuthPermissions>(null as any);

// For the error reporting only. Don't use in the app because it is unsafe.
export let _authUserId: string | undefined = undefined;

export const AuthProvider: React.FC<PropsWithChildren<AuthMethods>> = ({
  children,
  graphLogin,
}) => {
  // Risk with the cache enabled: this auth query used to cause issues with auth status sometimes incorrect when refreshing the page. Seen on localhost, I don't know if we have the issue once deployed.
  const { data, called, loading } = useQuery<QueryType>(userQuery, {
    fetchPolicy: 'network-only',
    // fetchPolicy: 'cache-and-network',
    // fetchPolicy: 'cache-first',
  });
  const website = useWebsite();

  const me = data?.me ?? {};
  const { id: authUserId, delete: isDeleted, email, username, language, profile } = me;
  _authUserId = authUserId;
  const isVip = data?.me?.profile?.isVip;
  const currentBan = getHandledBan(profile?.isBan as Ban[]);
  const { isSuperAdmin, isOwner, isAdmin, isModerator } = readStatus(me, website);
  const isAuthDone = called && !loading;

  const dispatch = useAppDispatch();
  const me1 = useMemo(
    () =>
      ({
        isSuperAdmin,
        isOwner,
        isAdmin,
        isModerator,
        isVip: !!isVip,
        authUserId: authUserId!,
        isAuthDone,
        currentBan,
        email,
        username,
        isDeleted,
        language,
        profile,
      } as AuthPermissions),
    [
      authUserId,
      email,
      isAdmin,
      isAuthDone,
      currentBan,
      isDeleted,
      isModerator,
      isOwner,
      isSuperAdmin,
      isVip,
      language,
      profile,
      username,
    ],
  );
  const value = useMemo(() => {
    const { logout, ...rest } = graphLogin;
    const me2: AuthPermissions = {
      ...me1,
      logout: async () => {
        if (logout) {
          dispatch({ type: logoutActionType });
          await logout();
        }
      },
      ...rest,
    };
    return me2;
  }, [graphLogin, me1, dispatch]);

  useEffect(() => {
    dispatch(setMe(me1));
  }, [dispatch, me1]);

  return <authContext.Provider value={value}>{children}</authContext.Provider>;
};

// similar to "me" returned by graphql
export const usePermissions = (forceRedirect = false) => {
  const { push } = useConnectedRouter();
  const auth = useContext(authContext);

  if (auth == null) {
    throw new Error('usePermissions must be used within the AuthProvider.');
  }

  useEffect(() => {
    // In case user is deleted, go back home
    if (forceRedirect && auth?.isDeleted) push('/');
  }, [forceRedirect, auth?.isDeleted, push]);

  return auth;
};

export const useMe = () => {
  const { authUserId, isDeleted, ...others } = usePermissions();
  return useMemo(() => {
    const me: User = {
      id: authUserId,
      delete: isDeleted,
      ...others,
    };
    return me;
  }, [authUserId, isDeleted, others]);
};

// Always reloaded on the client, because it is highly dynamic (the user signs in and out).
export const useAuthProfile = () => {
  let { profile, authUserId, isAuthDone } = useContext(authContext);
  if (!profile) {
    profile = {};
  }

  const isGuest = useMemo(
    () => readIsGuest(authUserId, isAuthDone, profile?.id),
    [authUserId, isAuthDone, profile?.id],
  );

  const hasProfile = useMemo(
    () => isAuthDone && authUserId && !!profile?.pseudo && profile?.status !== 'deleted',
    [profile, isAuthDone, authUserId],
  );
  return { profile, isGuest, hasProfile, loading: !isAuthDone };
};

export function useIsMine(author: User | undefined) {
  const { authUserId } = usePermissions();
  const isMine = useMemo(() => !!authUserId && authUserId === author?.id, [authUserId, author?.id]);
  return isMine;
}
