import { useRef } from 'react';

/**
 * Use it to wrap your value in a ref object which reference never changes. It is useful to read the latest value of a given state from a callback, effect... which is not supposed to be re-created when the state changes, e.g. changing the state should not rerun the effect or render child components, but when the effect run, we are interested in reading the latest value of the state.
 * @param value The value to wrap in a fixed ref
 * @returns The ref. Access the value with ref.current (same as useRef()).
 */
export function useRefLatest<T>(value: T) {
  const ref = useRef(value);
  ref.current = value;
  return ref;
}
