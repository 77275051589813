import type { RefObject } from 'react';
import { forwardRef, useEffect } from 'react';

import { useRefIntercept } from '../../hooks/useRefIntercept';
import { isCtrlOrMetaPressed } from '../../utils/web';
import type { SearchInputFieldProps } from '../Form/SearchInput';
import { SearchInputField } from '../Form/SearchInput';
import styles from './quickSearch.module.scss';

export interface SearchInputRefProps extends SearchInputFieldProps {}

const excludedSelector = '.codex-editor';

export const SearchInput = forwardRef<HTMLInputElement, SearchInputRefProps>(
  ({ children, value, ...inputProps }, refFn) => {
    const { showBackButton, onClickBack } = inputProps;
    const [refToForward, ref] = useRefIntercept(refFn);

    // Catch Ctrl + K: focus the search input
    useEffect(() => {
      function focusInput(event: KeyboardEvent) {
        if (
          isCtrlOrMetaPressed(event) &&
          event.code === 'KeyK' &&
          !targetIsInSelector(event, excludedSelector)
        ) {
          event.preventDefault();
          const elt = (ref as RefObject<HTMLInputElement>).current;

          // Triggers the onFocus event, which is expected to show the search details.
          elt?.focus();
        }
      }
      document.addEventListener('keydown', focusInput);
      return () => {
        document.removeEventListener('keydown', focusInput);
      };
    }, [ref]);

    // Catch Escape: trigger the "back" handler to request hiding the search details.
    useEffect(() => {
      function blurInput(event: KeyboardEvent) {
        if (event.key === 'Escape') {
          const elt = (ref as RefObject<HTMLInputElement>).current;
          if (showBackButton) {
            elt?.blur();
            onClickBack?.();
          }
        }
      }
      document.addEventListener('keydown', blurInput);
      return () => {
        document.removeEventListener('keydown', blurInput);
      };
    }, [onClickBack, ref, showBackButton]);

    return (
      <SearchInputField
        ref={refToForward}
        className={styles.searchContainer}
        value={value as string}
        {...inputProps}
      />
    );
  },
);

function targetIsInSelector(event: KeyboardEvent, selector: string) {
  return !!(
    event.target &&
    Array.from(document.querySelectorAll<HTMLElement>(selector)).some(elt =>
      elt.contains(event.target as Node),
    )
  );
}
