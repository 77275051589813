import { adWrapperStyles, OptiDigitalDesktopAds, OptiDigitalMobileAds } from './OptiDigitalAds';

export const generateOptiAds = ({
  start,
  freq,
  isMobile,
  total,
}: {
  start: number;
  freq: number;
  isMobile: boolean;
  total: number;
}): Array<{ position: number; element: JSX.Element }> =>
  Array(Math.ceil((total - start) / freq))
    .fill(null)
    .map((_, index) => start + index * freq)
    .map((position, index) => ({
      position,
      element: isMobile ? (
        <OptiDigitalMobileAds index={index} key={`ads-slot-mobile-${position}`} />
      ) : (
        <OptiDigitalDesktopAds index={index} key={`ads-slot-desktop-${position}`} />
      ),
    }));

export const ultimediaElement = (
  <div key="ultimedia-ads-slot" className={adWrapperStyles}>
    <div id="ultimedia_wrapper" />
  </div>
);

// export const OPTI_ADS = [
//   ...generateOptiAds({ start: 2, freq: 5, isMobile: true, total: 50 }),
//   ...generateOptiAds({ start: 2, freq: 7, isMobile: false, total: 50 }),
// ];
//
// export const OPTI_ADS_1 = [
//   ...generateOptiAds({ start: 5, freq: 5, isMobile: true, total: 50 }),
//   ...generateOptiAds({ start: 5, freq: 7, isMobile: false, total: 50 }),
// ];
