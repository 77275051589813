import { useQuery } from '@apollo/client';
import { memo, useCallback, useEffect, useMemo } from 'react';

import { ChatIconButton } from '../../../../front/src/components/chat/ChatIconButton';
import { IconAddFriend } from '../../../../front/src/components/icons/Icons';
import { usePermissions } from '../../../../front/src/hooks/usePermissions';
import { useAppDispatch, useAppSelector } from '../../../../front/src/redux/hooks';
import {
  closeChatWindow,
  reduceChatWindow,
  startCreatingChat,
  updateThread,
} from '../../../../front/src/redux/slices/chat-slice';
import type { RootState } from '../../../../front/src/redux/store';
import { WebsocketService } from '../../../../front/src/services/websocket';
import { ChatQuery } from '../../../../front/src/thread/api/inbox';
import type { QueryType, User } from '../../../../front/src/types/api';
import { isMobile, isPWA } from '../../../../front/src/utils/web';
import { ChatActionsHeader } from './ChatActionsHeader';
import { ChatAvatars } from './ChatAvatars';
import { ChatField } from './ChatField';
import { ChatMessageList } from './ChatMessageList';
import styles from './ChatWindow.module.scss';
import type { ChatMessageContentArgsType } from './createOrUpdateChatMessage';
import { createOrUpdateChatMessage } from './createOrUpdateChatMessage';
import { useChatUsers } from './useChatUsers';

export const ChatWindow = memo(({ threadId }: { threadId: string }) => {
  const { authUserId } = usePermissions();
  const dispatch = useAppDispatch();

  const handleChatData = useCallback(
    data => {
      const thread = data.website?.findOrCreateInbox;
      if (thread) {
        dispatch(updateThread({ threadId, thread }));
      }
    },
    [dispatch, threadId],
  );

  const { fetchMore, refetch } = useQuery<QueryType>(ChatQuery, {
    fetchPolicy: 'network-only',
    variables: {
      id: threadId,
      offset: 0,
    },
    onCompleted: handleChatData,
  });

  const thread = useAppSelector((state: RootState) => state.chat.chats[threadId]?.thread);

  const loading = useAppSelector(
    (state: RootState) =>
      state.chat.chats[threadId]?.loading ||
      !state.chat.chats[threadId]?.thread?.childMessages?.messages,
  );

  const users = useChatUsers({
    users: thread?.userVisibility ?? [],
  });

  const close = useCallback(() => {
    dispatch(closeChatWindow(threadId));
  }, [dispatch, threadId]);

  const reduce = useCallback(() => {
    dispatch(reduceChatWindow({ threadId }));
  }, [dispatch, threadId]);

  const sendMessage = useCallback(
    async (value: ChatMessageContentArgsType) => {
      if (!value.message && !value.file) {
        return;
      }
      await createOrUpdateChatMessage(dispatch, authUserId, {
        ...value,
        threadId: thread?.id as string,
      });
    },
    [dispatch, authUserId, thread?.id],
  );

  const createChat = useCallback(() => dispatch(startCreatingChat(users)), [dispatch, users]);

  useEffect(() => {
    if (loading || thread) return;

    close();
  }, [loading, thread, close]);

  useEffect(() => {
    WebsocketService.joinThread(threadId);
    return () => {
      WebsocketService.leaveThread(threadId);
    };
  }, [threadId]);

  useEffect(() => {
    const handlePwaRefetchOnWsClosed = () => {
      if (isPWA() || isMobile()) {
        console.log('refetch on ws close');
        refetch()
          .then(handleChatData)
          .catch(() => console.error('Error wile refetching chat data after ws closed'));
      }
    };

    WebsocketService.addCustomEventListener('close', handlePwaRefetchOnWsClosed);

    return () => {
      WebsocketService.removeCustomEventListener('close', handlePwaRefetchOnWsClosed);
    };
  }, [handleChatData, refetch]);

  const userListUI = useMemo(
    () => users.map((user: User) => user?.profile?.pseudo).join(', '),
    [users],
  );

  return (
    <div className={styles.container}>
      {thread ? (
        <>
          <ChatActionsHeader close={close} reduce={reduce}>
            <div className="flex items-center">
              <div className="relative h-6 w-6 mr-2">
                <ChatAvatars users={users} size="small2" />
              </div>
              <div className="truncate flex-1 text-lg sm:text-base font-bold mr-4 leading-5">
                {userListUI}
              </div>
              <ChatIconButton
                icon={<IconAddFriend className="w-[20px] h-[20px]" />}
                onClick={createChat}
                arial="add-friend"
              />
            </div>
          </ChatActionsHeader>
          <div className="flex-1 flex flex-col overflow-hidden">
            <ChatMessageList thread={thread} fetchMore={fetchMore} />
          </div>
          <ChatField sendMessage={sendMessage} restricted={false} autoFocus />
        </>
      ) : null}
    </div>
  );
});
