import type { FC } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import type { Maybe, Relation } from '../../../types/api';
import type { RequestFriendCallback } from '../../api/relation';
import { useOnUpdateFriendStatus } from '../../api/relation';

const messages = defineMessages({
  denyFriend: { defaultMessage: 'Refuser', id: 'zIfkSW' },
  acceptFriend: { defaultMessage: 'Accepter', id: '56kk/w' },
});

interface Props {
  isPending: boolean;
  relation: Maybe<Relation>;
  authUserId: string;
  callback?: RequestFriendCallback;
}

export const AcceptRefuseInvitation: FC<Props> = props => {
  const { isPending, relation, authUserId, callback } = props;
  const onAccept = useOnUpdateFriendStatus(relation, 'approved', callback);
  const onRemove = useOnUpdateFriendStatus(relation, 'refused', callback);
  if (!isPending || relation?.guest?.id !== authUserId) return null;

  return (
    <div className="space-x-2 text-xs">
      <button
        type="button"
        className="text-primary-base no-underline hover:underline"
        onClick={onRemove}
      >
        <FormattedMessage {...messages.denyFriend} />
      </button>
      <button
        type="button"
        className="text-gray-dark no-underline hover:text-primary-base hover:underline"
        onClick={onAccept}
      >
        <FormattedMessage {...messages.acceptFriend} />
      </button>
    </div>
  );
};
