import type { ContentBlock, ContentState, DraftDecorator, SelectionState } from 'draft-js';
import type { FC } from 'react';
import { memo, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { LoaderBlock } from '../../../../components/LoaderBlock';
import { useWebsite } from '../../../../hooks/useWebsite';
import { handleError } from '../../../../utils/common-utils';
import { fetchEmbed, getEmbedsEnabled } from '../../../../utils/embed';
import { EmbedError } from './EmbedError';
import type { EmbedImageProps2 } from './EmbedImage';
import { EmbedImage } from './EmbedImage';
import type { EmbedOGProps2 } from './EmbedOpenGraph';
import { EmbedOpenGraph } from './EmbedOpenGraph';
import type { EmbedVideoProps2 } from './EmbedVideo';
import { EmbedVideo } from './EmbedVideo';
import type { PreviewRegistrationRef } from './usePreviewRegistration';

export const typeToEmbedCmp = {
  image: EmbedImage,
  opengraph: EmbedOpenGraph,
  video: EmbedVideo,
  error: EmbedError,
};
export type AllowedEmbedType = keyof typeof typeToEmbedCmp;

export type EmbedAllType = AllowedEmbedType | 'embed' | 'newImage' | 'oldImage';

export type EmbedEntry =
  | EmbedImageProps2
  | EmbedVideoProps2
  | EmbedOGProps2
  | { type: EmbedAllType };

interface EmbedStandardProps {
  block: ContentBlock;
  blockStyleFn: Function;
  contentState: ContentState;
  customStyleFn: Function;
  customStyleMap: Record<
    'BOLD' | 'CODE' | 'ITALIC' | 'STRIKETHROUGH' | 'UNDERLINE',
    CSSStyleDeclaration
  >;
  decorator: DraftDecorator;
  direction: string; // e.g. LTR
  forceSelection: boolean;
  offsetKey: string;
  // preventScroll // unknown type
  selection: SelectionState;
  // tree: List // List from which library?
}

export type EmbedProps<P> = {
  blockProps: P;
} & Partial<EmbedStandardProps>;

function useEmbedAllowed(registeredEmbeds: PreviewRegistrationRef[]) {
  const website = useWebsite();

  // This component is represented by this reference in the registrations above.
  const thisCompRef = useRef(null);

  // When destroying the component, unregister it from the list of previews.
  useEffect(
    () => () => {
      const i = registeredEmbeds.indexOf(thisCompRef);
      if (i !== -1) {
        registeredEmbeds.splice(i, 1);
      }
    },
    [registeredEmbeds],
  );

  // If the component is already referenced, stop here and return.
  if (registeredEmbeds.includes(thisCompRef)) {
    return true;
  }
  const isEmbedEnabled = getEmbedsEnabled(website);
  if (isEmbedEnabled) {
    registeredEmbeds.push(thisCompRef);
  }
  return isEmbedEnabled;
}

export interface EmbedBlockProps {
  url: string;
  registeredEmbeds: PreviewRegistrationRef[];
}

export const EmbedBlock: FC<EmbedProps<EmbedBlockProps>> = memo(props => {
  const {
    blockProps: { url, registeredEmbeds },
  } = props;

  const [embed, setEmbed] = useState<EmbedEntry | null | undefined>(undefined);

  const isEmbedAllowed = useEmbedAllowed(registeredEmbeds);

  const fetchAndSetEmbedDebounced = useDebouncedCallback(async (_url: string) => {
    try {
      const _embed = await fetchEmbed(_url);
      setEmbed(_embed || null);
    } catch (error) {
      handleError(error);
    }
  }, 300);

  useEffect(() => {
    (async () => {
      if (!url || !isEmbedAllowed) return;
      fetchAndSetEmbedDebounced(url);
    })();
  }, [fetchAndSetEmbedDebounced, isEmbedAllowed, url]);

  const isLoading = url && embed === undefined;
  const noEmbedFound = !isLoading && !embed;

  if (!isEmbedAllowed || noEmbedFound) return null;

  return isLoading ? <LoaderBlock /> : <EmbedCmp embed={embed!} />;
});

const EmbedCmp: FC<{ embed: EmbedEntry }> = memo(({ embed }) => {
  const Cmp = typeToEmbedCmp[embed?.type as keyof typeof typeToEmbedCmp] || EmbedError;
  return <Cmp {...(embed as any)} />;
});
