import type { MessageDescriptor } from 'react-intl';
import { defineMessages } from 'react-intl';

import {
  serializedToExportedString,
  type SerializedValue,
} from '../thread/ThreadMessage/useSerializeValue';
import type { Category } from '../types/api';

export type ValidationReturnType = MessageDescriptor | string | null;
export type ValidationFnType<T extends SerializedValue | unknown[] | unknown = unknown> = (
  elt: T,
) => ValidationReturnType | Promise<ValidationReturnType>;

export const validationMessages = defineMessages({
  email: {
    defaultMessage: 'email invalide',
    id: '8YjJDO',
  },
  minLength: {
    defaultMessage: 'trop court',
    id: 'CvR6gP',
  },
  maxLength: {
    defaultMessage: 'trop long',
    id: '4v/NZq',
  },
  identifier: {
    defaultMessage: 'Le mot de passe doit comporter entre 4 et 30 caractères',
    id: 'porsdz',
  },
  password: {
    defaultMessage: 'Le mot de passe doit comporter au moins 8 caractères',
    id: 'lr8CJ5',
  },
  domain: {
    defaultMessage: 'the provided domain is not valid',
    id: '8s1EI8',
  },
  required: {
    defaultMessage: 'this field is required',
    id: 't0xnkW',
  },
  minValidTag: {
    defaultMessage: 'Veuillez choisir au moins 1 étiquette valide',
    id: 'iAQv+k',
  },
  maxValidTag: {
    defaultMessage: 'Veuillez choisir au maximum {maxValidTag} étiquettes',
    id: 'vAfRJc',
  },
});

export const validateRequired: ValidationFnType = value =>
  value !== undefined ? null : validationMessages.required;

export const minLength: (min: number) => ValidationFnType<string | unknown[]> =
  (min: number) => value =>
    (value?.length ?? 0) >= min ? null : validationMessages.minLength;

export const visibleEditorValue = (value: SerializedValue | undefined) =>
  serializedToExportedString(value)?.replace(/(<p>)|(&nbsp;)|(<\/p>)|(\n)/gi, '');

export const requiredEditor = (value: string | undefined) => {
  const l = visibleEditorValue(value)?.length;
  return l != null && l > 0 ? null : validationMessages.required;
};
export const minEditorLength =
  (min = 1, errorMessage?: MessageDescriptor): ValidationFnType<string | undefined> =>
  value =>
    (visibleEditorValue(value)?.length ?? 0) >= min
      ? null
      : errorMessage || validationMessages.minLength;

export const maxLength: (max: number) => ValidationFnType<string | undefined | unknown[]> =
  (max: number) => value =>
    value == null || value.length <= max ? null : validationMessages.maxLength;

export const composeValidations =
  <T>(validations: ValidationFnType<T>[]): ValidationFnType<T> =>
  async (value: T) => {
    if (validations.length === 0) return null;

    const result = await validations[0](value);

    return result || composeValidations(validations.slice(1))(value);
  };

export const isEmail = (value: string) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)
    ? validationMessages.email
    : null;

export const isValidCategory = (value: Category[], minTag = 1, maxTag = Infinity) => {
  if (value.length > maxTag) return validationMessages.maxValidTag;

  const isOnlyProposals = value.every(cat => cat.id?.startsWith('proposal'));
  if (value.length < minTag || isOnlyProposals) return validationMessages.minValidTag; // todo possibly add plural
  return null;
};

export const localhostRegex = /^(http:\/\/)?localhost:\d{4}$/;
export const domainRegex = /^(https?:\/\/)?([A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9]\.)+[A-Za-z]{2,}$/;

export const isDomain = (domain: string) =>
  domainRegex.test(domain) ? null : validationMessages.domain;
