import classnames from 'classnames';
import { forwardRef } from 'react';

import type { AvatarPropsType } from '../../components/Avatar';
import { Avatar } from '../../components/Avatar';
import { isMobile } from '../../utils/web';
import type { RequestFriendCallback } from '../api/relation';
import { ProfilePopover } from './ProfilePopover';

export type ProfileAvatarPropTypes = {
  userId: string | undefined;
  containerClassName?: string;
  inviteCallback?: RequestFriendCallback;
} & AvatarPropsType;

const isMobileDisplay = isMobile();

export const ProfileAvatarPopover = forwardRef<HTMLImageElement, ProfileAvatarPropTypes>(
  ({ userId, className, containerClassName, inviteCallback, ...avatarProps }, ref) => {
    return (
      <ProfilePopover
        userId={userId}
        trigger={isMobileDisplay ? 'click' : 'mouseenter'}
        appendToBody
        containerClassName={containerClassName}
        className="author-popover-root"
        inviteCallback={inviteCallback}
      >
        <Avatar ref={ref} className={classnames(className, 'cursor-pointer')} {...avatarProps} />
      </ProfilePopover>
    );
  },
);
