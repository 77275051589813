import type { MessageDescriptor } from 'react-intl';
import { defineMessages, FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as IconFilter } from '../icons/icon-action-filter-inline.svg';
import { IconArrowDown } from './icons/Icons';
import { Menu } from './Menu';
import CustomRadioButton from './Radio/CustomRadioButton';

const optionsMessage = defineMessages({
  noPeriod: { defaultMessage: 'Tout', id: 'rSuDeV' },
  lastDay: { defaultMessage: 'Les dernières 24h', id: 'FzaV9P' },
  lastWeek: { defaultMessage: 'La semaine dernière', id: '3Z7a/U' },
  lastMonth: { defaultMessage: 'Le mois dernier', id: 'E4VCjo' },
  lastYear: { defaultMessage: 'Les 12 derniers mois', id: 'FZ5Yt+' },
});

// const messages = defineMessages({
//   customPeriod: { defaultMessage: 'date précise', id: '1eIje2' },
//   dateInterval: { defaultMessage: '{from, date, short} à {to, date, short}', id: 'fwnFFp' },
// });

// const { customPeriod } = messages;

const getRetroPeriod = ({ year = 0, month = 0, day = 0 }) => {
  const now = new Date();
  return [
    new Date(
      now.getFullYear() - year,
      now.getMonth() - month,
      now.getDate() - day,
      now.getHours(),
      now.getMinutes() + 1,
      0,
    ),
    new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      now.getMinutes() + 1,
      0,
    ),
  ];
};

export const keyPeriodsGetter = {
  lastDay: () => getRetroPeriod({ day: 1 }),
  lastWeek: () => getRetroPeriod({ day: 7 }),
  lastMonth: () => getRetroPeriod({ month: 1 }),
  lastYear: () => getRetroPeriod({ year: 1 }),
  custom: (str = '') => {
    try {
      return str.split('_').map(elt => new Date(elt));
    } catch (_) {
      return [null, null];
    }
  },
};

export type PeriodIndex = keyof typeof keyPeriodsGetter;

export type PeriodMenuPropTypes = {
  options?: [string, MessageDescriptor][];
  period: PeriodIndex | undefined;
  changePeriod: (elt: string) => void;
  customizable?: boolean;
};

/**
 *
 * @param options
 * @param period
 * @param changePeriod
 * @param customizable
 * @constructor
 */
export const PeriodMenu = ({
  options = Object.entries(optionsMessage),
  period,
  changePeriod,
}: // customizable,
PeriodMenuPropTypes) => {
  // const selectRef = useRef<HTMLDivElement>();

  const DEFAULT_PERIOD = 'noPeriod';
  const currentPeriod = period || DEFAULT_PERIOD;

  return (
    <>
      <Menu
        content={[
          ...options.map(([key, message]) => (
            <button type="button" key={key} onClick={() => changePeriod(key)}>
              <div className="flex items-center gap-4">
                <CustomRadioButton isSelected={currentPeriod === key} />
                <span className={twMerge(currentPeriod === key && 'font-semibold')}>
                  <FormattedMessage {...message} />
                </span>
              </div>
            </button>
          )),
          // ...(customizable
          //   ? [
          //       <button
          //         key="__end_btn"
          //         type="button"
          //         onClick={() => selectRef.current && selectRef.current.click()}
          //       >
          //         <FormattedMessage {...customPeriod} />
          //       </button>,
          //     ]
          //   : []),
        ]}
      >
        <button type="button">
          <div className="h-10 rounded-md flex border border-gray-light items-center justify-center bg-white p-[0px_8px_0px_2px] md:p-[0px_12px_0px_6px]">
            <div className="flex gap-x-1 items-center">
              <IconFilter
                className={twMerge(currentPeriod !== DEFAULT_PERIOD && 'text-primary-dark')}
              />
              <IconArrowDown />
            </div>
          </div>
        </button>
      </Menu>

      {/* <GraphModal */}
      {/*   lazyContent={(controller: unknown) => ( */}
      {/*     <PeriodSelector changePeriod={changePeriod} controller={controller as TippyInstance} /> */}
      {/*   )} */}
      {/*   forceModal */}
      {/* > */}
      {/*   <div ref={selectRef as React.MutableRefObject<HTMLDivElement>} /> */}
      {/* </GraphModal> */}
    </>
  );
};
