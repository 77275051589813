import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { Link } from '../../../../front/src/components/Link';
import { ReactComponent as IconAllTagsSvg } from '../../../../front/src/icons/icon-tag-all.svg';
import { useAppSelector } from '../../../../front/src/redux/hooks';
import { selectHeadings } from '../../../../front/src/thread/ThreadMessage/topics-slice';
import type { SidebarFeaturedBlockData } from '../../../../front/src/types/layout';
import { isMobile } from '../../../../front/src/utils/web';
import { objectKeySwap } from '../../utils/objects';
import HeadingsMenuCategory from '../Home/children/HeadingsMenuCategory';
import { HeadingsMenuItem } from '../Home/children/HMChildren';
import { headingsMessages } from './BlockFeatured';

interface BlockSidebarFeaturedProps {
  className?: string;
  onTagClick?: () => void;
  isModalDisplay?: boolean;
  block: SidebarFeaturedBlockData;
}

export const fixedTwBgColors = [
  'bg-mariner',
  'bg-wisteria',
  'bg-mountainMeadown',
  'bg-fern',
  'bg-valencia',
  'bg-strongScarlet',
  'bg-neonCarrot',
  'bg-chateauGreen',
  'bg-pictonBlue',
  'bg-denim',
  'bg-blueGem',
  'bg-blueWhale',
  'bg-almondFrost',
  'bg-ironGray',
];

export const BlockSidebarFeatured: FC<BlockSidebarFeaturedProps> = memo(
  function BlockSidebarFeatured(props) {
    const { isModalDisplay = false, onTagClick, className, block } = props;
    const { data: blockData = [], options } = block;
    const customTitle = options?.customTitle;

    const defaultTagsLimit = options?.defaultTagsLimit || 3;
    const tagsLimitToHandle = +defaultTagsLimit;

    const reduxData = useAppSelector(selectHeadings);

    const headings = useMemo(() => reduxData || blockData, [blockData, reduxData]);

    const coloredHeadings = headings;

    return (
      <div
        className={twMerge(
          'BlockSidebarFeatured flex flex-col gap-y-2 md:gap-y-4',
          isModalDisplay && !isMobile() && 'md:gap-y-8',
          className,
        )}
      >
        {customTitle && <div className="text-md font-roboto font-semibold">{customTitle}</div>}

        {coloredHeadings?.map(heading => {
          const headingProps = objectKeySwap(heading, 'children', 'tags');

          return (
            <HeadingsMenuCategory
              key={headingProps.uid}
              {...headingProps}
              isModalDisplay={isModalDisplay}
              onTagClick={onTagClick}
              defaultTagsLimit={tagsLimitToHandle}
            />
          );
        })}
        <hr />
        <Link to="tags">
          <a>
            <HeadingsMenuItem
              className="all-tags-limit-handler cursor-pointer mt-2 rounded-lg px-2 -mx-2 hover:bg-gray-lighter"
              icon={<IconAllTagsSvg className="h-7 w-7 stroke-primary bg-white" />}
              content={
                <div className="bold">
                  <FormattedMessage {...headingsMessages.allCategories} />
                </div>
              }
            />
          </a>
        </Link>
      </div>
    );
  },
);
