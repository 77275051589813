import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { Avatar } from '../../../../../front/src/components/Avatar';
import type { GraphModalInstance } from '../../../../../front/src/components/GraphModal/GraphModal';
import { IconSendMessage } from '../../../../../front/src/components/icons/Icons';
import { Link } from '../../../../../front/src/components/Link';
import { AcceptRefuseInvitation } from '../../../../../front/src/contributor/Profile/ProfileRelations/AcceptRefuseInvitation';
import { useAppApolloClient } from '../../../../../front/src/hooks/useAppApolloClient';
import { usePermissions } from '../../../../../front/src/hooks/usePermissions';
import { useAppDispatch } from '../../../../../front/src/redux/hooks';
import { openChatWindow } from '../../../../../front/src/redux/slices/chat-service';
import type { Relation } from '../../../../../front/src/types/api';

const intl = defineMessages({
  addedYou: { defaultMessage: 'Vous a envoyé une invitation', id: 'TSJGif' },
  addFriend: { defaultMessage: 'Ajouter un ami', id: 'F3AkTA' },
  pendingInvitation: { defaultMessage: 'Invitation en attente', id: '5TXPTw' },
  sendMessage: { defaultMessage: 'Envoyer un message', id: 'xh0Ygd' },
  denyFriend: { defaultMessage: 'Refuser', id: 'zIfkSW' },
  acceptFriend: { defaultMessage: 'Accepter', id: '56kk/w' },
});

type FriendItemProps = {
  relation: Relation;
  tip?: GraphModalInstance;
};
export const FriendItem: React.FC<FriendItemProps> = ({ relation, tip }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const client = useAppApolloClient();

  const isPending = useMemo(() => relation.status === 'pending', [relation.status]);
  const { authUserId } = usePermissions();

  const startChatting = useCallback(
    event => {
      event.preventDefault();
      openChatWindow(client, { userId: relation.member?.id as string, threadId: '', authUserId })(
        dispatch,
      );
      tip?.hide();
    },
    [client, relation.member?.id, authUserId, dispatch, tip],
  );

  const closePopover = useCallback(() => {
    tip?.hide();
  }, [tip]);

  const iReceivedTheInvitation = isPending && relation.guest?.id === authUserId;
  const iSentTheInvitation = isPending && relation.author?.id === authUserId;

  return (
    <div
      key={relation.id}
      className="border-b border-gray-200 last:border-none bg-white hover:bg-gray-200 flex p-3 gap-x-6"
    >
      <Link to="profile" params={{ pseudo: relation.member?.profile?.pseudo }} passHref>
        <a onClick={closePopover} className="flex-1 flex gap-x-3 items-center overflow-hidden">
          <Avatar picture={relation.member?.profile?.picture} size="medium1" legacyImage />
          <div
            className={twMerge(
              'flex flex-1 overflow-hidden',
              relation.status === 'refused' && 'opacity-50',
            )}
          >
            <span
              className={classNames(
                'text-base truncate',
                iReceivedTheInvitation && 'font-semibold',
              )}
            >
              {relation.member?.profile?.pseudo}
            </span>
            {iReceivedTheInvitation || iSentTheInvitation ? (
              <>
                <div className="text-gray-400 text-sm tracking-wide">
                  {iReceivedTheInvitation && <FormattedMessage {...intl.addedYou} />}
                  {iSentTheInvitation && <FormattedMessage {...intl.pendingInvitation} />}
                </div>
                {iReceivedTheInvitation && (
                  <div className="text-xs text-gray-400 truncate">{relation?.message}</div>
                )}
              </>
            ) : null}
          </div>
          <AcceptRefuseInvitation
            isPending={isPending}
            relation={relation}
            authUserId={authUserId}
          />
        </a>
      </Link>
      {relation.status === 'approved' && (
        // @todo Move inside a Tooltip (bad positioning inside another Tippy) instead of using `title` attribute
        <button
          title={formatMessage(intl.sendMessage)}
          type="button"
          className="p-2 bg-white rounded-full border border-gray-200 group hover:bg-primary transition-all"
          onClick={startChatting}
        >
          <IconSendMessage className="h-5 w-5 group-hover:fill-white fill-gray-500" />
        </button>
      )}
    </div>
  );
};
