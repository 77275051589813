import type { FC } from 'react';
import { memo, useCallback, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { useAppSelector } from '../../../../front/src/redux/hooks';
import { selectHeadings } from '../../../../front/src/thread/ThreadMessage/topics-slice';
import type { Category } from '../../../../front/src/types/api';
import type { HeadingsBlockData } from '../../../../front/src/types/layout';
import { LinkToAll } from '../Home/children/LinkToAll';
import { SubjectBox } from '../Home/SubjectBox';

export const headingsMessages = defineMessages({
  headlines: { defaultMessage: `À la une`, id: 'wIZ792' },
  allCategories: { defaultMessage: 'Voir toutes les étiquettes', id: 'EqcRrt' },
});

const INITIAL_ROWS_LIMIT = 6;

interface Props {
  withLinkToAllCTA?: boolean;
  className?: string;
  block: HeadingsBlockData;
}

export const BlockFeatured: FC<Props> = memo(function BlockFeatured(props) {
  const { withLinkToAllCTA, className, block } = props;
  const { data: layoutData, options } = block;
  const customTitle = options?.customTitle;

  const displayedHeadings = useAppSelector(selectHeadings) || layoutData;

  const [isLimited, setLimited] = useState(false);
  const limitedHeadings = isLimited
    ? displayedHeadings.slice(0, INITIAL_ROWS_LIMIT)
    : displayedHeadings;

  const removeLimit = useCallback(() => setLimited(false), [setLimited]);

  if (limitedHeadings?.length < 1) return null;

  return (
    <section className={twMerge('BlockFeatured @container flex flex-col gap-y-5', className)}>
      <h2>{customTitle || <FormattedMessage {...headingsMessages.headlines} />}</h2>
      <ul className="flex flex-wrap gap-3">
        {limitedHeadings.map(heading => (
          <SubjectBox
            key={heading.uid}
            tags={heading.children as Category[]}
            title={heading.content as string}
            background={heading.background as string}
            color={heading.color}
          />
        ))}
      </ul>
      {isLimited && displayedHeadings.length > INITIAL_ROWS_LIMIT ? (
        <div className="flex justify-center items-center">
          <button type="button" onClick={removeLimit}>
            Voir toutes les rubriques
          </button>
        </div>
      ) : null}
      {withLinkToAllCTA && (
        <LinkToAll to="categories" intlMessage={headingsMessages.allCategories} />
      )}
    </section>
  );
});
