import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import type { FormRenderProps } from 'react-final-form';
import { defineMessages, FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';

import { ButtonV3 } from '../../components/Button/ButtonV3';
import { ButtonV3Icon } from '../../components/Button/ButtonV3Icon';
import { AvatarField } from '../../components/FinalForm/AvatarField';
import { SubmitButtonV3 } from '../../components/FinalForm/SubmitButtonV3';
import { IconClose } from '../../components/icons/Icons';
import { frontConfig } from '../../config';
import { useBodyModifier } from '../../hooks/useBodyModifier';
import { useAuthProfile, usePermissions } from '../../hooks/usePermissions';
import defaultAvatar from '../../images/gd-default-avatar.png';
import { isTouchableDevice } from '../../utils/web';
import { WebsiteCustomLogo } from '../Website/WebsiteLogo';
import { ProfileEditionForm } from './ProfileEdition/ProfileEditionForm';
import { ProfileFormFields } from './ProfileEdition/ProfileFormFields';

const messages = defineMessages({
  title: { defaultMessage: `Créez votre profil pour ce site`, id: '9G3pem' },
  validate: { defaultMessage: `Valider votre profil`, id: 'VCYDGc' },
  cancel: { defaultMessage: `Annuler et se déconnecter`, id: 'P1tTVK' },
});

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: '5vh max(2%, 10px)',
    // @TODO
    // remove once css cleaned
    zIndex: 800,
    alignItems: 'center',
  },
  content: {
    position: 'initial',
    margin: isTouchableDevice() ? '0 2% 0' : 'auto 2%',
    padding: '0',
    display: 'block',
    overflow: 'visible',
    flexShrink: 0,
    flexGrow: 0,
    border: 'none',
    maxWidth: 'min(600px, 100%)',
    backgroundColor: 'transparent',
  },
} as const;

interface Props {
  vipRequired?: boolean;
  onOpenCloseProfileModal?: (open: boolean) => void;
}

export const ProfileCreation: FC<Props> = props => {
  const { vipRequired, onOpenCloseProfileModal } = props;
  const { logout, authUserId, isAuthDone, isVip } = usePermissions();
  const { hasProfile, loading } = useAuthProfile();
  const [isOpen, setOpen] = useState(false);
  const setOpen2 = useCallback(
    (open: boolean) => {
      setOpen(open);
      onOpenCloseProfileModal?.(open);
    },
    [onOpenCloseProfileModal],
  );
  useBodyModifier({ preventScroll: isOpen });

  const closeModal = useCallback(() => {
    setOpen2(false);
  }, [setOpen2]);
  const cancelAndClose = useCallback(() => {
    closeModal();
    if (typeof logout === 'function') logout();
  }, [logout, closeModal]);

  const shouldShow =
    !!authUserId &&
    !hasProfile &&
    isAuthDone &&
    !loading &&
    (frontConfig.checkVIPAfterProfileCreation || !vipRequired || isVip);

  useEffect(() => {
    if (shouldShow) {
      setOpen2(true);
    }
    // Old code. Delete in a few weeks, if it is confirmed that this timeout is useless.
    // if (!shouldShow) return undefined;
    // const timeout = setTimeout(() => setOpen(true), 2000);
    // return () => clearTimeout(timeout);
  }, [shouldShow, setOpen, onOpenCloseProfileModal, setOpen2]);

  const profileCreationForm = useCallback(
    ({ handleSubmit }: FormRenderProps) => (
      <form onSubmit={handleSubmit} className="flex _sm:flex-col _sm:space-y-8 sm:space-x-8">
        <div className="relative w-24 h-24 _sm:self-center">
          <AvatarField name="newPicture" defaultPicture={defaultAvatar.src} />
        </div>
        <div className="flex flex-col items-stretch max-w-lg space-y-10">
          <ProfileFormFields />
          <div className="flex flex-col space-y-4">
            <SubmitButtonV3 variant="contained">
              <FormattedMessage {...messages.validate} />
            </SubmitButtonV3>
            <ButtonV3 variant="outlined" color="gray" onClick={cancelAndClose}>
              <FormattedMessage {...messages.cancel} />
            </ButtonV3>
          </div>
        </div>
      </form>
    ),
    [cancelAndClose],
  );

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={cancelAndClose}
      shouldCloseOnOverlayClick
      style={customStyles}
    >
      <div className="relative">
        <div className="rounded-lg bg-white overflow-hidden">
          <div className="p-10 py-5 flex flex-col items-center space-y-2">
            <p className="font-roboto text-md md:text-md2 font-semibold text-center">
              <FormattedMessage {...messages.title} />
            </p>
            <WebsiteCustomLogo className="block w-5/6 mx-auto" />
          </div>
          <div className="px-6 sm:px-8 pb-10 sm:pt-5">
            <ProfileEditionForm onEdited={closeModal} renderForm={profileCreationForm} />
          </div>
        </div>
        <ButtonV3Icon
          className="absolute top-0 right-0"
          onClick={cancelAndClose}
          icon={<IconClose />}
        />
      </div>
    </ReactModal>
  );
};
