import type { RefObject } from 'react';
import { useEffect } from 'react';

export function useClickOutside(domRef: RefObject<HTMLElement>, callback: VoidFunction) {
  useEffect(() => {
    function checkClickOutside(e: MouseEvent) {
      if (!domRef.current?.contains(e.target as Node)) {
        callback();
      }
    }
    window.addEventListener('click', checkClickOutside);
    return () => window.removeEventListener('click', checkClickOutside);
  }, [callback, domRef]);
}
