import classnamesBind from 'classnames/bind';
import type { FC, ReactNode } from 'react';
import { memo, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { Link } from '../../components/Link';
import type { ThemeColors } from '../../types/theme';

export type CategoryPropTypes = {
  slug?: string | null;
  name?: string | null;
  title?: string | null;
  className?: string;
  color?: ThemeColors;
  rootTags?: boolean;
  variant?: 'contained' | 'outlined';
  tooltip?: ReactNode;
  icon?: ReactNode;
  disableLink?: boolean;
  isGroup?: boolean;
};

interface IBtnClasses {
  base: string;
  contained: string;
}

const primaryClasses: IBtnClasses = {
  base: 'text-base text-red-350',
  contained: 'text-base text-red-350 bg-red-350 hover:text-white',
};

const grayClasses: IBtnClasses = {
  base: 'text-base text-gray',
  contained: 'text-base text-gray-medium bg-gray-medium hover:text-white',
};

export const CategoryBadge: FC<CategoryPropTypes> = memo(function CategoryBadge(props) {
  const { slug, name, title, className, color, tooltip, rootTags, variant, icon, disableLink } =
    props;
  const slugQuery = useMemo(() => ({ tag: slug }), [slug]);

  const styles = color === 'primary' ? { ...primaryClasses } : { ...grayClasses };

  const cx = classnamesBind.bind(styles);
  const contained = variant === 'contained';

  const classes = twMerge(
    'flex items-center justify-center py-1 px-3 break-words rounded-3xl cursor-pointer text-sm text-center font-normal tracking-4',
    variant === 'contained' && 'bg-opacity-10 hover:bg-opacity-100',
    cx({ base: !contained }, { contained }),
    className,
  );

  const content = (
    <>
      {icon && <span className="">{icon}</span>}
      <span>{title || name || slug}</span>
      {tooltip && tooltip}
    </>
  );

  if (disableLink) {
    return <div className={classes}>{content}</div>;
  }

  return (
    <Link to={rootTags ? '/tags' : 'category'} params={!rootTags ? slugQuery : undefined}>
      <a className={classes}>{content}</a>
    </Link>
  );
});
