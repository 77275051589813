import type { MutableRefObject } from 'react';
import { useEffect, useRef } from 'react';

import { useWebsite } from '../../../../hooks/useWebsite';
import { getNbImagesMax } from '../../../../utils/embed';

export type PreviewRegistrationRef = MutableRefObject<null>;

export function usePreviewRegistration(registeredPreviews: PreviewRegistrationRef[]) {
  const website = useWebsite();

  // This component is represented by this reference in the registrations above.
  const thisCompRef = useRef(null);

  // When destroying the component, unregister it from the list of previews.
  useEffect(
    () => () => {
      const i = registeredPreviews.indexOf(thisCompRef);
      if (i !== -1) {
        registeredPreviews.splice(i, 1);
      }
    },
    [registeredPreviews],
  );

  // If the component is already referenced, stop here and return.
  if (registeredPreviews.includes(thisCompRef)) {
    return true;
  }
  const nbImagesMax = getNbImagesMax(website);
  if (registeredPreviews.length < nbImagesMax) {
    registeredPreviews.push(thisCompRef);
    return true;
  }
  return false;
}
