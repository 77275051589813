function handlingUnreachableUrl(content: string) {
  const regexUnreachableUrl = /{unreachable_url}/g;
  // @TODO use intl trans
  const messageText = "Le lien ou l'objet attaché à ce message n'est plus accessible";
  const notificationMessageBox = `<span class='msg'>${messageText}</p>`;
  return content.replace(regexUnreachableUrl, notificationMessageBox);
}

const URL_REGEX =
  /^\s*(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))\s*$/gim;

export function rawHtmlFormatter(rawContent: string) {
  // Group replacements into a single operation to improve performance
  let content = rawContent
    .replace(/`{3}(<br \/>)?([\s\S]*?)`{3}(<br \/>)?/gm, '<pre>$2</pre>') // code blocks
    .replace(/`([\s\S]*?)`/gm, '<code>$1</code>')
    .replace(/&lt;br \/&gt;|&nbsp;/g, '<br />') // Replace HTML entities
    .replace(/&lt;([\s\S]*?)\/?&gt;/g, '<$1>') // Replace normal tags
    .replace(/<div>/g, '') // Replace <div> with nothing
    .replace(/<\/div>/g, '<br />') // Replace </div> with <br />
    .replace(/(\s*<br\s*\/>){3,}/g, '<br /><br />'); // Limit <br> to a maximum of two

  // Patch: wrap URLs alone on their row into a <p>
  content = content.replace(URL_REGEX, '<div class="paragraph">$1</div>');
  content = content.replace(/<p>(.*?)<\/p>/g, '<div class="paragraph">$1</div>');
  content = content.replace(/<li>([^<]+)<\/li>/g, '<li><div class="paragraph">$1</div></li>');

  content = handlingUnreachableUrl(content);

  return content;
}
