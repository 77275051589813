import axios from '../axios';

export const checkApiIsUp = async () => {
  try {
    const { data } = await axios.get<{ success?: boolean }>('/api/');
    return data?.success;
  } catch (e) {
    return false;
  }
};
