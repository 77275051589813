import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  filterMessages,
  topicsConnectedOnlyMap,
  topicsFilterOrder,
} from '../thread/Topic/Filter/constants';
import { filterMainIcons } from '../thread/Topic/Filter/iconElements';
import { useAuthProfile, usePermissions } from './usePermissions';

export const useQuickLinks = () => {
  const { authUserId, isAuthDone } = usePermissions();
  const { isGuest } = useAuthProfile();
  const notConnected = useMemo(
    () => isAuthDone && (!authUserId || isGuest),
    [authUserId, isGuest, isAuthDone],
  );

  const getMapValue = (map: Record<string, React.ReactNode>, key: keyof typeof map) =>
    key in map ? map[key] : null;

  const displayedFilters = useMemo(
    () =>
      (notConnected
        ? topicsFilterOrder.filter(elt => !(elt in topicsConnectedOnlyMap))
        : topicsFilterOrder
      ).map(key => ({
        filterKey: key,
        icon: getMapValue(filterMainIcons, key),
        title: <FormattedMessage {...filterMessages[key as keyof typeof filterMessages]} />,
      })),
    [notConnected],
  );

  return {
    displayedFilters,
  };
};
