import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

import { isBrowser } from '../utils/web';

export const useWindowSize = isBrowser
  ? () => {
      const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0,
      });

      useEffect(() => {
        function handleResize() {
          setWindowSize({
            width: window.innerWidth as number,
            height: window.innerHeight as number,
          });
        }

        const debouncedHandle = debounce(handleResize, 300, { maxWait: 1000, trailing: true });

        window.addEventListener('resize', debouncedHandle);

        handleResize();

        return () => window.removeEventListener('resize', debouncedHandle);
      }, []);

      return windowSize;
    }
  : () => ({ width: undefined, height: undefined });
