import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import type { InputPropTypes } from '../Form/Input';
import { Input } from '../Form/Input';
import type { UseGraphFieldArgument } from './useGraphField';
import { useGraphField } from './useGraphField';

export type InputFieldPropTypes = UseGraphFieldArgument<InputPropTypes['value']> &
  Omit<InputPropTypes, 'value'> & { showValidateMessage?: boolean };

/**
 *
 * @param config
 * @param validate
 * @param required
 * @param subscription
 * @param props
 * @constructor
 */
export const InputField = forwardRef<HTMLInputElement, InputFieldPropTypes>(
  (
    {
      config,
      validate,
      required,
      subscription,
      showValidateMessage,
      ...props
    }: InputFieldPropTypes,
    ref,
  ) => {
    const { computedError, input } = useGraphField<InputPropTypes['value'], HTMLElement>({
      name: props.name,
      config,
      validate,
      required,
      subscription,
    });

    const computedProps = {
      ...input,
      ...props,
      error: computedError,
      ...(showValidateMessage && {
        beforeElement: (
          <div className="absolute bottom-full text-primary text-[12px]">
            {validate && computedError && (
              <FormattedMessage {...(computedError as unknown as Record<string, string>)} />
            )}
          </div>
        ),
      }),
    };

    return <Input inputRef={ref} {...computedProps} />;
  },
);
