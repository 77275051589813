import { useState } from 'react';

let id = 0;

// Polyfill for useId from React 18
// But I can't import useId, I don't know why.
// doc: https://react.dev/reference/react/useId
export function useId() {
  // return useRef(Math.random().toString(36)).current;
  return useState(() => '' + id++)[0];
}
