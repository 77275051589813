import type { Reference } from '@apollo/client';
import { gql } from '@apollo/client';

import axios from '../../services/axios';
import type { QueryType } from '../../types/api';
import type { AppApolloClient } from '../../types/common-models';

const tmpInboxReadFragment = gql`
  fragment TmpInboxReadFragment on Message {
    id
    isAlreadyView
  }
`;

export const markInboxAsRead = async (id: string, client?: AppApolloClient) => {
  // It seems the server already knows if the inbox was read or not. We only need to update the local cache.
  // (But I don't know how the server knows.)
  if (client) {
    client.cache.writeFragment({
      id: `Message:${id}`,
      fragment: tmpInboxReadFragment,
      data: {
        id,
        isAlreadyView: true,
      },
    });
  }
};

export const removeInbox = async (
  { threadId, userId }: { threadId: string; userId: string },
  { client, authProfileId }: { client?: AppApolloClient; authProfileId: string },
) => {
  try {
    await axios.delete(`/api/inbox/${threadId}/user_visibility`, { data: { user: userId } });
  } finally {
    if (client && authProfileId) {
      client.cache.modify({
        id: `Profile:${authProfileId}`,
        fields: {
          getInboxList(search, { readField }) {
            const messages = (readField<Reference[]>('messages', search) ?? []).filter(ref => {
              const thread = readField('thread', ref as Reference);
              return threadId !== readField('id', thread as Reference);
            });

            return {
              ...search,
              messages,
            };
          },
        },
        broadcast: true,
      });
    }
  }
};

export const chatMessageFragment = gql`
  fragment chatMessageFragment on ChildMessages {
    meta {
      offset
      count
    }
    messages {
      id
      content
      createdAt
      contentEditedAt
      author {
        ... on User {
          id
          profile {
            id
            pseudo
            picture
            status
          }
        }
        ... on Guest {
          username
          picture
        }
      }
    }
  }
`;

export const chatFragment = gql`
  fragment chatFragment on Thread {
    id
    createdAt
    userVisibility {
      id
      profile {
        id
        pseudo
        picture
        status
        relation {
          id
          status
        }
      }
    }
  }
  ${chatMessageFragment}
`;

export const queryFindOrCreateInbox = gql`
  query QueryExistingThread($userVisibility: [ID], $offset: Int!) {
    website {
      id
      findOrCreateInbox(limit: 20, offset: $offset, userVisibility: $userVisibility) {
        ...chatFragment
        childMessages(offset: $offset, limit: 20, sort: "latest") {
          ...chatMessageFragment
        }
      }
    }
  }
  ${chatFragment}
`;

export const ChatQuery = gql`
  query ChatQuery($id: ID, $offset: Int!) {
    website {
      id
      findOrCreateInbox(inboxId: $id, offset: $offset, limit: 20) {
        ...chatFragment
        #note: sort = 'newest', force_sort = 'true' hardcoded in api
        childMessages(offset: $offset, limit: 20) {
          ...chatMessageFragment
        }
      }
    }
  }
  ${chatFragment}
`;

export const hasInboxQuery = async (
  client: AppApolloClient,
  recipientIds: string[],
  allowCache: boolean | undefined,
): Promise<string | null> => {
  try {
    const { data } = await client.query<QueryType>({
      query: queryFindOrCreateInbox,
      variables: {
        userVisibility: recipientIds,
        offset: 0,
      },
      fetchPolicy: allowCache ? undefined : 'network-only',
    });
    return data.website?.findOrCreateInbox?.id || null;
  } catch (err) {
    return null;
  }
};

export const createInboxThunk = async (recipientIds: string[]): Promise<{ _id: string }> => {
  const { data } = await axios.post(`/api/inbox`, {
    user_visibility: recipientIds,
  });
  return data.thread;
};
