import { isBrowser } from '../../utils/fragments/isBrowser';

export async function compressImage(file: File) {
  if (!isBrowser) {
    console.warn(
      'Trying to compress an image in a non-browser environment. The compression is skipped and the original image is returned as is.',
    );
    return file;
  }

  const { readAndCompressImage } = await import('browser-image-resizer');

  const blob = await readAndCompressImage(file);
  file = new File([blob], file.name);

  return file;
}
