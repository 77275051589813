let env: EnvFile;
try {
  // eslint-disable-next-line import/extensions
  env = require('./.env');
} catch {
  env = {
    enableCache: false,
  } as EnvFile;
}
const integrationUrl =
  (env.useLocalIntegration === true ? env.integrationUrlLocal : env.integrationUrl) ||
  env.integrationUrl ||
  'https://devintegration.graphcomment.com/graphlogin.js';

const graphloginUrl =
  (env.useLocalIntegration === true ? env.graphloginUrlLocal : env.graphloginUrl) ||
  env.graphloginUrl ||
  'https://devlogin.graphcomment.com';

const isDev = env.isDev === true || false;
const apiUrl = env.apiUrl || 'https://dev.graphcomment.com';

export const debateConfig = {
  ...env,
  isDev,
  publicKeyForDev: env.websiteKey || 'no-key3',
  language: 'fr',
  debug: process.env.DEBUG || env.debug || false,
  cacheEnabled: process.env.CACHE_ENABLED || env.cacheEnabled || false,
  cacheDebug: process.env.CACHE_DEBUG || env.cacheDebug || false,
  port: env.port || 3000,
  wsUrl: env.wsUrl || 'wss://dev.graphcomment.com/websocket',
  apiUrl,
  imageUrl: env.imageUrl || 'https://dev.graphcomment.com',
  yakliUrl: env.yakliUrl || 'https://dev.yak.li',
  mongoDsn: env.mongoDsn || 'mongodb://127.0.0.1:27017/graphcomment',
  imagePath: env.imagePath || '/live/images/',
  localiseApiKey: env.localiseApiKey || 'Gycafk7P-5i0hGB6fFpF8MNCywjMFWQ7',
  captchaApiKey: env.captchaApiKey || '6LeF2t8UAAAAAACFp7cA9RzBTP7b-QucxDNK2Bm0',
  translationsFile: '.translations.json',
  prettyUrls: true, // should always be enabled
  pyschoPath: env.pyschoPath || 'https://www.psychologies.com/psy_polish/look-a-like/', // should always be enabled
  graphloginUrl,
  integrationUrl,
  SPEED_UP_PARTIAL_SSR: env.SPEED_UP_PARTIAL_SSR || false,
  enablePWAInstallPrompt: false,
  enableAndroidPWAInstallPrompt: true,
  enableNotifInDev: true,
  enableLogRocket: false /* isDev || apiUrl.includes('dev.graphcomment.com') */,
} as const;

// export default debateConfig;

interface EnvFile {
  env?: string;
  isDev: boolean;
  noIndex?: boolean;
  enableCache: boolean;
  shouldPreventCustomHead: boolean;
  shouldPreventThirdParties: boolean;
  graphloginUrl: string;
  graphloginUrlLocal: string;
  integrationUrl: string;
  integrationUrlLocal: string;
  useLocalIntegration?: boolean;
  websiteKey?: string;
  publicKeyForDev?: string;
  SPEED_UP_PARTIAL_SSR: boolean;
  SHOW_BURGER_MENU?: boolean;
  HANDLE_DEBUG_STACK_LOGS?: boolean;
  ISR_REVALIDATE?: number;
  ISR_REVALIDATE_TOKEN?: string;
  BASE_PATH?: string;
  // Other keys used in the config, not defined yet in the env file:
  debug?: boolean;
  cacheEnabled?: boolean;
  cacheDebug?: boolean;
  port?: number;
  wsUrl?: string;
  apiUrl?: string;
  imageUrl?: string;
  yakliUrl?: string;
  mongoDsn?: string;
  imagePath?: string;
  localiseApiKey?: string;
  captchaApiKey?: string;
  pyschoPath?: string;
}
