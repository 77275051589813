import React from 'react';
import type { MessageDescriptor } from 'react-intl';
import { FormattedMessage } from 'react-intl';

import { ButtonV3 } from '../../components/Button/ButtonV3';
import { MessageContent } from '../Content/MessageContent/MessageContent';

export const RemoveContent: React.FC<{
  messages: Record<string, MessageDescriptor>;
  cancel: () => void;
  remove: () => void;
  content: string;
}> = ({ messages, cancel, remove, content }) => (
  <div className="flex flex-col px-2 py-3 sm:px-4 sm:py-7 max-w-full">
    <h2 className="text-center">
      <FormattedMessage {...messages.title} />
    </h2>
    <div className="my-5 text-center px-5">
      <FormattedMessage {...messages.guide} />
    </div>
    <blockquote className="mt-2 pl-2 border-l-4 border-solid border-gray-light text-gray-medium">
      <MessageContent content={content} />
    </blockquote>

    <div className="flex flex-wrap gap-2 my-4">
      <ButtonV3 onClick={cancel} as="link" color="primary">
        <FormattedMessage {...messages.cancel} />
      </ButtonV3>
      <ButtonV3 as="link" color="primary" variant="outlined" onClick={remove}>
        <FormattedMessage {...messages.remove} />
      </ButtonV3>
    </div>
  </div>
);
