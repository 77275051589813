import React, { Children, useCallback, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as IconArrowNext } from '../../../../front/src/icons/icon-arrow-next.svg';
import { ReactComponent as IconArrowPrev } from '../../../../front/src/icons/icon-arrow-previous.svg';
import styles from './Carousel.module.scss';

export const homeMessages = defineMessages({
  carouselAriaPrevious: { defaultMessage: 'étiquettes précédentes', id: 'AWAhR0' },
  carouselAriaNext: { defaultMessage: 'étiquettes suivantes', id: '4PNJsd' },
});

export type CarouselPropTypes = {
  children: React.ReactNode;
  contentRef: React.MutableRefObject<HTMLDivElement>;
  className?: string;
};

// Simple carousel to fit subject box need
export const Carousel = ({ children, contentRef, className }: CarouselPropTypes) => {
  const [selected, setSelected] = useState(0);
  const { formatMessage } = useIntl();

  const count = Children.count(children);

  useEffect(() => {
    if (selected < 0) setSelected(0);
    if (selected > count - 1) setSelected(count - 1);
  }, [selected, count]);

  const next = useCallback(() => {
    setSelected(val => val + 1);
  }, []);

  const previous = useCallback(() => {
    setSelected(val => val - 1);
  }, []);

  return (
    <>
      <div
        className={twMerge('carouselContainerInner flex grow flex-row items-stretch', className)}
        ref={contentRef}
      >
        <div
          className={
            count > 1 && selected > 0 ? styles.arrowContainer : styles.hiddenArrowContainer
          }
          onClick={previous}
          onKeyDown={previous}
          role="button"
          tabIndex={-1}
          aria-label={formatMessage(homeMessages.carouselAriaPrevious)}
        >
          <span className="rounded-lg rounded-l-none">
            <IconArrowPrev />
          </span>
        </div>
        <div className={twMerge('relative flex-1 overflow-hidden')}>
          {Children.map(children, (child, index) => (
            <div
              className="carouselSlide absolute w-full h-full overflow-hidden"
              style={{
                left: `${index * 100}%`,
                transform: `translate3d(${-selected * 100}%, 0, 0)`,
                transition: `transform .3s ease-in-out`,
              }}
            >
              {child}
            </div>
          ))}
        </div>
        <div
          className={
            count > 1 && selected < count - 1 ? styles.arrowContainer : styles.hiddenArrowContainer
          }
          onClick={next}
          onKeyDown={next}
          role="button"
          tabIndex={-1}
          aria-label={formatMessage(homeMessages.carouselAriaNext)}
        >
          <span className="rounded-lg rounded-r-none">
            <IconArrowNext />
          </span>
        </div>
      </div>

      <div
        className={
          count > 1
            ? 'flex shrink-0 items-center justify-center mt-3'
            : 'flex shrink-0 items-center justify-center mt-3 invisible'
        }
      >
        {Children.map(children, (_child, index) => (
          <div
            className={
              index === selected
                ? 'h-1 w-1 border rounded-full mx-1 bg-white'
                : 'h-1 w-1 border rounded-full mx-1'
            }
            // No need of id key
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          />
        ))}
      </div>
    </>
  );
};
