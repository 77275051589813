import type { FetchResult, Operation, ServerError, ServerParseError } from '@apollo/client';
import { Observable } from '@apollo/client';
import type { NetworkError } from '@apollo/client/errors';
import { onError } from '@apollo/client/link/error';
import Cookies from 'js-cookie';

import { TOKEN_NAME } from '../../../constants/graphlogin';
import { handleError, stringify } from '../../../utils/common-utils';
import { isApolloErrorAbort } from '../../../utils/graphql';
import { isBrowser } from '../../../utils/web';
import type { GenerateAuthLinkArgs } from './authLink';

export interface GenerateErrorLinkArgs extends GenerateAuthLinkArgs {
  logout: (() => Promise<void>) | undefined;
}

export function generateErrorLink({
  triggerRefreshToken,
  getPublicKey,
  logout,
}: GenerateErrorLinkArgs) {
  return onError(({ graphQLErrors, networkError, operation, forward }) => {
    // Special case: this is an authentication error, likely because the token is not valid anymore.
    // Example: the user was deleted from the back-office.
    // Problem: the API does not support it and returns an error, even for routes that are both public and authenticated.
    // To unblock the client, we remove the cookie and refresh, so that API requests are executed in public mode: no (invalid) token attached.
    // But it's a quick win. The ideal solution, TBD, would require more work.
    if (isAuthenticationError(networkError)) {
      if (isBrowser) {
        Cookies.remove(TOKEN_NAME);
        window.location.reload();
      } else {
        // SSR: shouldn't crash. The client will ensure the page is refreshed after deleting the credentials.
        // Return a success response, so that the page rendering does not block.
        // The server-side code is responsible for checking when data is undefined.
        const res: FetchResult = {
          data: {},
        };
        return Observable.of(res);
      }
    }

    if (
      ['invalid Token', 'Unauthorized'].includes(graphQLErrors?.[0].message || '') &&
      triggerRefreshToken
    ) {
      // Let's refresh token through async request
      return new Observable(observer => {
        (async function () {
          try {
            const key = getPublicKey?.();
            if (!key) {
              throw new Error(
                'Missing website key in Apollo generateErrorLink, cannot refresh the token.',
              );
            }
            await triggerRefreshToken();

            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };

            // Retry last failed request
            forward(operation).subscribe(subscriber);
          } catch (error) {
            logGqlApolloRequest(operation);
            // No refresh or client token available, we force user to login
            logout?.().catch(handleError);
            observer.error(error);
          }
        })();
      });
    }

    logGqlApolloRequest(operation);

    // General case: log and keep throwing an error for backward compatibility.
    if (graphQLErrors) {
      for (let { message, locations, path /* , extensions */ } of graphQLErrors) {
        handleError(
          `[-GraphQL error]: Message: ${message} - Location: ${JSON.stringify(
            locations,
          )} - Path: ${path}`,
        );
      }
    }

    if (networkError && !isApolloErrorAbort(networkError)) {
      console.error(`[-Network error]`);
      handleError(
        stringify(
          (networkError as ServerError)?.result ||
            (networkError as ServerParseError)?.bodyText ||
            (networkError as Error | ServerParseError)?.message ||
            networkError,
        ),
      );
    }

    return undefined;
  });
}

function isAuthenticationError(networkError?: NetworkError) {
  const authErrorObj = (networkError as any)?.result || {};
  return authErrorObj.gcCode === 410 && authErrorObj.msg === 'route need authentication';
}

function logGqlApolloRequest(operation: Operation) {
  const name = operation.operationName;
  const variables = operation.variables;
  if (name) {
    console.error(`[-GQL Apollo error] query name: ${operation.operationName}`);
  } else {
    const query = `${operation.operationName} {
  ${operation.query.loc?.source.body}
}`;
    console.error(`[-GQL Apollo error] query:`);
    console.error(query);
  }
  console.error(`[-GQL Apollo error] variables: ${stringify(variables)}`);
  console.error(`[-GQL Apollo error] Website:`, operation.getContext().headers?.website);
}
