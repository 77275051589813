import { gql } from '@apollo/client';

import axios from '../../services/axios';
import { FullProfileFragment } from '../../services/graphql-queries';
import { AuthorFragment } from '../../thread/api/thread';
import { ThreadMessageFragment } from '../../thread/ThreadMessages/query';
import type { AppApolloClient } from '../../types/common-models';

export const NotificationsSearchQuery = gql`
  query NotificationsSearchQuery($offset: Int!, $limit: Int!) {
    me {
      id
      profile {
        id
        status
        ...FullProfileFragment
        getNotifications(limit: $limit, offset: $offset) {
          meta {
            count
            offset
            limit
          }
          notifications {
            id
            object
            action
            type
            destination
            message {
              ...ThreadMessageFragment
              thread {
                id
                pageTitle
              }
              topic {
                id
                slug
              }
            }
            inbox
            userVisibility {
              ...AuthorFragment
            }
            user {
              ...AuthorFragment
            }
            fromUser {
              ...AuthorFragment
            }
            category {
              id
              slug
              title
            }
            view
            createdAt
            reward {
              id
              name
              type
            }
            groupMember {
              id
              group {
                id
                name
                slug
                image
              }
            }
          }
        }
      }
    }
    website {
      id
      name
    }
  }
  ${ThreadMessageFragment}
  ${AuthorFragment}
  ${FullProfileFragment}
`;

const tmpNotificationReadFragment = gql`
  fragment TmpNotificationReadFragment on Notification {
    id
    view
  }
`;

export const markNotifAsRead = async (
  notifIds: string[],
  websiteId: string,
  client?: AppApolloClient,
) => {
  const { data } = await axios.put(`/api/users/notifications/website/${websiteId}`, {
    ids: notifIds.join(','),
  });

  if (client) {
    notifIds.forEach(id =>
      client.writeFragment({
        id: `Notification:${id}`,
        fragment: tmpNotificationReadFragment,
        data: {
          id,
          view: true,
        },
      }),
    );
  }
  return data;
};
