import { memo } from 'react';

import { usePermissions } from '../../hooks/usePermissions';
import type { Topic } from '../../types/api';
import { isBrowser } from '../../utils/web';
import { BookmarkTopic } from './BookmarkTopic';
import styles from './TopicListItemActions.module.scss';
import { TopicPin } from './TopicPin';

export const TopicListItemActions = memo(
  ({ topic, className }: { topic: Topic; className: string }) => {
    const { isAdmin, isModerator, authUserId } = usePermissions();
    const isPinnable = isAdmin || isModerator;
    const { pinned, isFavorite } = topic;

    return (
      <div className={className}>
        {(pinned || isPinnable) && (
          <TopicPin
            className={
              (isPinnable && pinned && styles.squareAction) ||
              (isPinnable && styles.animatedAction) ||
              styles.square
            }
            id={topic.id as string}
            pinned={!!pinned}
          />
        )}

        {isBrowser && authUserId && (
          <BookmarkTopic
            className={isFavorite ? styles.squareAction : styles.animatedAction}
            id={topic.id as string}
            isFavorite={!!isFavorite}
          />
        )}
      </div>
    );
  },
);
