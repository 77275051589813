export interface StorageType {
  getItem: (key: string) => string | null;
  setItem: (key: string, value: string) => void;
  removeItem: (key: string) => boolean;
}

export const generateLiveStorage = (): StorageType => {
  const map = new Map();
  return {
    getItem: (key: string): string | null => map.get(key),
    setItem: (key: string, value: string): void => {
      map.set(key, value);
    },
    removeItem: (key: string): boolean => {
      if (!map.has(key)) return true;
      return map.delete(key);
    },
  };
};
