import { addNewMessage } from '../../../../front/src/redux/slices/chat-slice';
import type { AppDispatch } from '../../../../front/src/redux/store';
import { uploadFile } from '../../../../front/src/services/api/uploadFile';
import axios from '../../../../front/src/services/axios';
import type { Message } from '../../../../front/src/types/api';

// AOL: The implementation in this file, using redux as a local cache, is a hack because I didn't find how to update the Apollo cache + actually re-render the ChatWindow component with the new data (optimistic update). Weird behaviors (not to say bugs) prevent re-emiting when refetch is called or when the Apollo cache is updated. Sometimes, depending on the fetch policy.
// Some code is available below, using Apollo, in case someone with more knowledge in Apollo want to continue the investigation, fix it and remove the redux hack.

// const NewMessageFragment = gql`
//   fragment NewMessageFragment on Message {
//     __typename
//     id
//     author {
//       ...UserFragment
//     }
//     content
//     createdAt
//   }
//   fragment UserFragment on User {
//     id
//   }
// `;
//
// const UserFragment = gql`
//   fragment UserFragment on User {
//     id
//   }
// `;

type TargetArgsType =
  | { recipientIds: string[]; threadId?: null }
  | { threadId: string; recipientIds?: unknown };

export type ChatMessageContentArgsType =
  | { message?: null; file: File }
  | { message: string; file?: null };

export const createOrUpdateChatMessage = async (
  // client: AppApolloClient,
  dispatch: AppDispatch,
  authUserId: string,
  { threadId, message, file }: TargetArgsType & ChatMessageContentArgsType,
): Promise<{
  id: string;
}> => {
  if (!threadId) throw new Error('[createOrUpdateChatMessage] threadId is falsy.');

  let content = message;

  if (!content && !file) {
    throw new Error('No message to send and no file. Nothing to send in the thread.');
  }

  if (!content) {
    content = await uploadFile(file!);
  }

  // Update the redux cache
  const currentTimestamp = Date.now();
  const createdAt = new Date(currentTimestamp).toISOString();
  const newMessageId = `newMessage-${currentTimestamp}`;
  const newMessage: Message = {
    __typename: 'Message',
    id: newMessageId,
    author: { id: authUserId },
    content: content,
    createdAt,
  };
  dispatch(addNewMessage({ threadId, newMessage }));

  // // Attempt to update the Apollo cache - KO
  // try {
  //   client.cache.modify({
  //     id: `Thread:${threadId}`,
  //     fields: {
  //       childMessages(existing: ChildMessages = {}) {
  //         try {
  //           const newMessageId = 'newMessage';
  //           const user = client.cache.readFragment<User>({
  //             fragment: UserFragment,
  //             id: `User:${authUserId}`,
  //           });
  //           if (!user) {
  //             throw new Error(
  //               'Current user not found, cannot create a message fragment in the cache.',
  //             );
  //           }
  //           const newMessageRef = client.cache.writeFragment<Message>({
  //             fragment: NewMessageFragment,
  //             fragmentName: 'NewMessageFragment',
  //             id: `Message:${newMessageId}`,
  //             data: {
  //               __typename: 'Message',
  //               id: newMessageId,
  //               author: user,
  //               content: content!, // guaranteed to be defined with the above checks
  //               createdAt,
  //             },
  //           });
  //
  //           const { messages = [] } = existing;
  //           return {
  //             ...existing,
  //             messages: [...messages, newMessageRef],
  //           } as ChildMessages;
  //         } catch (error) {
  //           console.error(error);
  //           return existing;
  //         }
  //       },
  //     },
  //     // broadcast: true,
  //     // optimistic: true,
  //     /* broadcast: false // Include this to prevent automatic query refresh */
  //   });
  // } catch (error) {
  //   console.error(error);
  // }

  // client.cache.updateQuery(
  //   {
  //     query: ChatQuery /* queryFindOrCreateInbox */,
  //     variables: {
  //       userVisibility: recipientIds,
  //       offset: 0,
  //     },
  //   },
  //   (prevData: QueryType | null) => {
  //     if (!prevData) return prevData;
  //     const website = prevData?.website;
  //     const findOrCreateInbox = website?.findOrCreateInbox;
  //     const childMessages = findOrCreateInbox?.childMessages;
  //     const messages = childMessages?.messages || [];
  //     return {
  //       ...prevData,
  //       website: {
  //         ...website,
  //         findOrCreateInbox: {
  //           ...findOrCreateInbox,
  //           childMessages: {
  //             ...childMessages,
  //             messages: [...messages, newMessage],
  //           },
  //         },
  //       },
  //     };
  //   },
  // );
  // }

  const id = threadId;

  await axios.post(`/api/inbox/${id}`, {
    content,
  });

  return { id };
};
