import type { INPMetricWithAttribution } from 'web-vitals/attribution';
import { onINP } from 'web-vitals/attribution';

import axios from '../../../../front/src/services/axios';
import { IS_DEV } from '../../../../front/src/utils/optimization';
import { debateConfig } from '../../../config';
import { getRateIcon, getSortedINPVitals } from './gaUtils';

async function handleInpMetrics(
  inpMetric: INPMetricWithAttribution,
  websiteId?: string,
  isB = false,
) {
  const { rating, value, name } = inpMetric;

  const { gtagMetrics, apiMetrics } = await getSortedINPVitals(inpMetric, isB);

  if (IS_DEV) {
    const messageToLog: any[] = [`${getRateIcon(rating)} ${name} ${rating} (${value})`];
    if (rating !== 'good') {
      messageToLog.push({ metrics: apiMetrics });
    }
    console.log(...messageToLog);
  }

  try {
    if (debateConfig.env !== 'local') {
      if (name === 'INP') {
        // Send to Google Analytics
        gtag('event', name, gtagMetrics);

        // Send to API
        axios
          .post(`/api/pub/inp/${websiteId}`, apiMetrics)
          .then(res => console.log(`👌🏼 ~ Event ${name} successfully sent to API`, res));
      }
    }
  } catch (error) {
    throw error;
  }
}

// Pass the reporting function to the web-vitals INP reporter:
export const initGa4 = (websiteId?: string, isB = false) => {
  try {
    onINP(metric => handleInpMetrics(metric, websiteId, isB), {
      reportAllChanges: true,
    });
  } catch (error) {
    console.warn('initVitalsToAnalytics ERROR\n', error);
  }
};
