import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import { EmbedImage } from './EmbedImage';
import type { EmbedProps } from './EmbedLink';
import { SimpleUrl } from './SimpleUrl';
import { type PreviewRegistrationRef, usePreviewRegistration } from './usePreviewRegistration';

export interface Props {
  node: ReactNode;
  removeEmbed?: () => void;
  registeredPreviews: PreviewRegistrationRef[];
}

export const EmbedImagePreviewOrLink: FC<EmbedProps<Props>> = memo(props => {
  const {
    blockProps: { node, removeEmbed, registeredPreviews },
  } = props;

  const isPreviewable = usePreviewRegistration(registeredPreviews);

  if (typeof node !== 'string') {
    return <>{node}</>;
  }

  return isPreviewable ? (
    <EmbedImage url={node} removeEmbed={removeEmbed} />
  ) : (
    <SimpleUrl url={node} />
  );
});
