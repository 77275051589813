import classNames from 'classnames';
import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Avatar } from '../../../../../front/src/components/Avatar';
import { LimitedRelativeTime } from '../../../../../front/src/components/LimitedRelativeTime';
import { Link } from '../../../../../front/src/components/Link';
import { ReactComponent as IconCommentBlue } from '../../../../../front/src/icons/icon-comment-blue.svg';
import { ReactComponent as IconFriendBlue } from '../../../../../front/src/icons/icon-friend-blue.svg';
import { ReactComponent as IconGroupRed } from '../../../../../front/src/icons/icon-friends-red.svg';
import { ReactComponent as IconPinFull } from '../../../../../front/src/icons/icon-pin-fill.svg';
import { ReactComponent as IconEditSubject } from '../../../../../front/src/icons/icon-subject-created.svg';
import { ReactComponent as IconTag } from '../../../../../front/src/icons/icon-tag.inline.svg';
import { getVoteIcon } from '../../../../../front/src/thread/Vote/icons';
import type { Notification } from '../../../../../front/src/types/api';
import { truncate } from '../../../../../front/src/utils/string';
import { buildApiFileName } from '../../../../../front/src/utils/web';

const intl = defineMessages({
  comment_replied: {
    defaultMessage:
      '<b>{occurs, plural, one {{username}} other {{occurs} utilisateurs}}</b> {occurs, plural, one {a} other {ont}} répondu à votre commentaire sur la page <b>{thread}</b>.',
    id: 'cDdXV8',
  },
  comment_pinned: {
    defaultMessage:
      'Votre commentaire sur la page <b>{thread}</b> vient d’être épinglé par la rédaction.',
    id: 'kk1uT1',
  },
  comment_voted: {
    defaultMessage:
      'Votre commentaire sur la page <b>{thread}</b> a reçu {occurs, plural, one {un vote} other {{occurs} votes}}.',
    id: 'TYJS4Z',
  },
  comment_ecd: {
    defaultMessage: 'Votre commentaire sur la page <b>{thread}</b> a été éditorialisé.',
    id: 'U5PWXb',
  },
  comment_page: {
    defaultMessage:
      '{occurs, plural, one {Nouveau commentaire par <b>{username}</b>} other {{occurs} nouveaux commentaires}} sur la page <b>{thread}</b>.',
    id: 'FkVA9D',
  },
  reward_reward: {
    defaultMessage: 'Félicitations! Vous venez de recevoir un nouveau badge.',
    id: '617fCS',
  },
  user_complete: {
    defaultMessage:
      'La communauté du site {page} aimerait en savoir plus sur vous ! Ajoutez votre avatar, un statut pro et des compétences, une biographie. <b>Compléter votre profil.</b>',
    id: 'wcUgGl',
  },
  user_reco: {
    defaultMessage:
      '<b>{fromUsername}</b> vous invite à remplir votre profil, il aimerait en savoir plus sur vous !',
    id: 'WesUX/',
  },
  group_member_accepted: {
    defaultMessage: "Votre demande pour rejoindre le cercle <b>{group}</b> vient d'être acceptée.",
    id: 'b8P4jY',
  },
  group_member_pending: {
    defaultMessage:
      '<b>{username}</b> vous a envoyé une demande pour rejoindre votre cercle <b>{group}</b>.',
    id: 'gT6n6O',
  },
  tag_newtag: {
    defaultMessage: 'Le tag {tag} que vous avez proposé a été approuvé.',
    id: 'qqeLcB',
  },
  relation_demand: {
    defaultMessage: '<b>{username}</b> vous a envoyé une demande pour être ami.',
    id: 'rZz6yR',
  },
  relation_approved: {
    defaultMessage: "<b>{username}</b> a accepté votre demande d'ami.",
    id: 'zoePs8',
  },
});
const NOTIFICATION_ICONS = {
  'comment:pinned': <IconPinFull className="w-3 h-3" />,
  'comment:replied': <IconCommentBlue className="w-3 h-3" />,
  'comment:ecd': <IconEditSubject className="w-3 h-3" />,
  'comment:page': <IconCommentBlue className="w-3 h-3" />,
  'relation:demand': <IconFriendBlue className="w-3 h-3" />,
  'relation:approved': <IconFriendBlue className="w-3 h-3" />,
  'user:complete': <IconFriendBlue className="w-3 h-3" />,
  'user:reco': <IconFriendBlue className="w-3 h-3" />,
  'tag:newtag': <IconTag className="w-3 h-3" />,
  'group_member:accepted': <IconGroupRed className="w-3 h-3" />,
  'group_member:pending': <IconGroupRed className="w-3 h-3" />,
};

export const AVAILABLE_NOTIFICATIONS = Object.keys(NOTIFICATION_ICONS).concat([
  'comment:voted',
  'reward:reward',
]);

type NotificationItemProps = {
  notification: { occurrences: number } & Notification;
  onClick: <N extends Notification>(notification: N) => () => Promise<void> | void;
  data: { pseudo: string; picture: string; page: string };
};
export const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  onClick,
  data: { pseudo, picture, page },
}) => {
  const linkProps = useMemo(() => {
    const { action, object, user, message } = notification;
    const name = `${object}:${action}`;

    if (!pseudo) {
      console.warn(`[NotificationItemProps] Missing pseudo for notif`);
    }

    switch (name) {
      case 'group_member:pending':
        return {
          pathname: `/group/[slug]`,
          query: { slug: notification.groupMember?.group?.slug },
        };
      case 'group_member:accepted':
        return {
          pathname: `/group/[slug]`,
          query: { slug: notification.groupMember?.group?.slug },
        };
      case 'reward:reward':
        return {
          pathname: `/p/[pseudo]`,
          query: {
            pseudo,
            tab: 'rewards',
            action: `rewards-${notification.reward?.type || 'silver'}`,
          },
        };
      case 'tag:newtag':
      case 'tag:newTag':
        return {
          pathname: `/[tag]`,
          query: { tag: notification.category?.slug },
        };
      case 'relation:demand':
        if (!user?.profile?.pseudo) {
          console.warn(`[NotificationItemProps] Missing pseudo for notif user?.profile?.pseudo`);
        }
        return {
          pathname: `/p/[pseudo]`,
          query: { pseudo: user?.profile?.pseudo },
        };
      case 'relation:approved':
        if (!user?.profile?.pseudo) {
          console.warn(`[NotificationItemProps] Missing pseudo for notif user?.profile?.pseudo`);
        }
        return {
          pathname: `/p/[pseudo]`,
          query: { pseudo: user?.profile?.pseudo },
        };
      default:
        switch (object) {
          case 'user':
            if (!user?.profile?.pseudo) {
              console.warn(
                `[NotificationItemProps] Missing pseudo for notif user?.profile?.pseudo`,
              );
            }
            return {
              pathname: `/p/[pseudo]`,
              query: { pseudo: user?.profile?.pseudo, action: 'edit' },
            };
          case 'comment':
            return message?.id
              ? {
                  pathname: `/t/[slug]/d/[messageId]`,
                  query: { slug: message?.topic?.slug, messageId: message?.id },
                }
              : {
                  pathname: `/t/[slug]`,
                  query: { slug: message?.topic?.slug },
                };
          default:
            return {
              pathname: message?.id ? '/t/[slug]/d/[messageId]' : '/t/[slug]',
              query: message?.id
                ? {
                    slug: message?.topic?.slug,
                    messageId: message?.id,
                  }
                : {
                    slug: message?.topic?.slug,
                  },
            };
        }
    }
  }, [notification, pseudo]);

  const avatar = useMemo(() => {
    switch (notification.action) {
      case 'voted':
      case 'reward':
        return picture;
      case 'reco':
        return notification.fromUser?.profile?.picture;
      default:
        return notification.object === 'group_member' && notification.groupMember?.group?.image
          ? buildApiFileName(notification.groupMember?.group?.image as string)
          : notification.user?.profile?.picture;
    }
  }, [notification, picture]);

  const content = useMemo(
    () => (
      <FormattedMessage
        {...intl[`${notification.object}_${notification.action}` as keyof typeof intl]}
        values={{
          thread:
            notification.message?.thread &&
            truncate(notification.message.thread.pageTitle as string, 40),
          username: notification.user?.profile?.pseudo,
          fromUsername: notification.fromUser?.profile?.pseudo,
          page,
          group: notification.groupMember?.group?.name,
          tag: notification.category?.title,
          br: <br />,
          b: (chunk: ReactNode) => <b>{chunk}</b>,
          occurs: notification.occurrences,
        }}
      />
    ),
    [page, notification],
  );

  const icon = useMemo(() => {
    const { object, action, vote, reward } = notification;
    const name = `${object}:${action}`;
    switch (name) {
      case 'comment:voted':
        return getVoteIcon(vote?.score as number);
      case 'reward:reward':
        return (
          <span className={`reward possess possess-small capitalize is-${reward?.type}`}>
            <i className="icon-medal" />
            {reward?.name}
          </span>
        );
      default:
        return name in NOTIFICATION_ICONS
          ? NOTIFICATION_ICONS[name as keyof typeof NOTIFICATION_ICONS]
          : null;
    }
  }, [notification]);

  // const { action, object } = notification;
  // const name = `${object}:${action}`;

  // if (name === 'user:complete') {
  //   console.log(linkProps);
  // }

  return (
    <div
      className={classNames(
        'border-b last:rounded-b-md cursor-pointer hover:bg-gray-200 p-3',
        !notification.view ? 'bg-white' : 'bg-gray-lightest',
      )}
    >
      <Link to={linkProps.pathname} params={linkProps.query}>
        <a className="flex items-center space-x-3" onClick={onClick(notification)} href="">
          <Avatar picture={avatar} size="medium1" legacyImage />
          <div className="text-left flex-1 text-gray-dark">
            {/* <div>{name}</div> */}
            <div className="text-sm">{content}</div>
            <div className="text-xs mt-2 flex items-center text-gray-400 font-bold">
              <span className="align-baseline mr-1 inline-block">{icon}</span>
              <LimitedRelativeTime date={notification.createdAt} />
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
};
