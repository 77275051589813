import React from 'react';

import type { TextAreaPropTypes } from '../Form/TextArea';
import { TextArea } from '../Form/TextArea';
import type { UseGraphFieldArgument } from './useGraphField';
import { useGraphField } from './useGraphField';

export type TextAreaFieldPropTypes = UseGraphFieldArgument<string> &
  Omit<TextAreaPropTypes, 'value'>;

export const TextAreaField: React.FC<TextAreaFieldPropTypes> = ({
  config,
  validate,
  required,
  subscription,
  ...props
}) => {
  const { computedError, input } = useGraphField<string, HTMLTextAreaElement>({
    name: props.name,
    config,
    validate,
    required,
    subscription,
  });
  return <TextArea {...props} {...input} error={computedError} />;
};
