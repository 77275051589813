import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import { useAppDispatch } from '../redux/hooks';
import { stopGlobalLoading } from '../redux/slices/utils-slice';
import { checkApiIsUp } from '../services/api/up';
import { handleError } from '../utils/common-utils';
import { hideSpinner, showSpinner } from '../utils/spinner';
import { isBrowser } from '../utils/web';

// I don't know why I can't import it from node_modules/next/dist/shared/lib/router/router.d.ts
// interface RouteProperties {
//   shallow: boolean;
// }

type RouterError = Error & {
  code?: any;
  cancelled?: boolean;
};

export class StatusError extends Error {
  statusCode?: number;

  constructor(message: string, statusCode?: number) {
    super(message);
    this.statusCode = statusCode;
  }
}

export const useRouteTransitions = (initialError: StatusError | null = null) => {
  const router = useRouter();
  const [error, setError] = useState<StatusError | null>(initialError);
  const dispatch = useAppDispatch();

  const routeChangeStart = useCallback(() => {
    setError(null);
    showSpinner();
  }, []);

  const endLoading = useCallback(() => {
    dispatch(stopGlobalLoading());
    hideSpinner();
  }, [dispatch]);

  const routeChangeComplete = useCallback(
    (/* route: string, options: RouteProperties */) => {
      endLoading();
      setError(null);
    },
    [endLoading],
  );
  const routeChangeError = useCallback(
    (err: RouterError, route: string /* , options: RouteProperties */) => {
      endLoading();
      if (err.cancelled) {
        console.warn('Route navigation was cancelled:', route);
      } else {
        console.error(
          '[-Route error]',
          { err, route },
          err,
          err?.message,
          err?.cause,
          err?.code,
          err?.name,
        );
        handleError(err);
        // Let's hide the error for now, and see if it works around the error flash we consistently get when navigating on a tab opened several hours earlier.
        // https://trello.com/c/T4HbH9se/844-je-vois-syst%C3%A9matiquement-affich%C3%A9-erreur-400x-quand-je-laisse-un-onglet-ouvert-longtemps-je-reviens-je-clic-cela-saffiche-un-cour
        // Scenario that may reproduce it:
        // - Open a page in a browser
        // - Re-deploy the app
        // - Hover over links: the preload fails
        // - Click the link and browser "back": a 400 error may be shown during a short while when clicking "back" (then it correctly auto-shows the page, the 400 just appears in a flash)
        // - Click again another link and "back": the 400 should show again.
        // - If clicking the same link a second time, the error may be gone.
        // - If refreshing the original page, the error may be gone.
        // It is likely because the nextjs router nav preload has expired links to preload the page props from the current page links.
        // setError(new StatusError(route, 400));
      }
    },
    [endLoading],
  );

  useEffect(() => {
    if (!isBrowser) return undefined;
    router.events.on('routeChangeStart', routeChangeStart);
    router.events.on('routeChangeComplete', routeChangeComplete);
    router.events.on('routeChangeError', routeChangeError);
    return () => {
      router.events.off('routeChangeStart', routeChangeStart);
      router.events.off('routeChangeComplete', routeChangeComplete);
      router.events.off('routeChangeError', routeChangeError);
    };
  }, [router, routeChangeStart, routeChangeComplete, routeChangeError]);

  useEffect(() => {
    checkApiIsUp()
      .then(result => !result && setError(new StatusError('API Down', 504)))
      .catch(setError);
  }, []);

  return {
    error,
  };
};
