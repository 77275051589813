import { wsUrl } from '../../config';
import type { ExtractPayload, WsData, WsSendMessage, WsTypedData } from '../../types/websocket';
import { parseJSONString } from '../../utils/json';
import { wsLogger } from './wsLogger';

type WsPayload = object | string;
type WsMethodsCb = (payload?: WsPayload | any) => void;
type WsSubscription = { threads: string[] };

/**
 * This class is a wrapper around the WebSocket API.
 * It is used to send and receive messages to the server.
 */
class WebSocketService {
  private socket: WebSocket | null = null;
  private clientWebsiteId: string | null = null;
  private clientUserId: string | null = null;
  private subscriptions: WsSubscription = { threads: [] };
  private methods: { [key in WsTypedData['type']]?: WsMethodsCb } = {};
  private customEventListeners: { [event: string]: EventListenerOrEventListenerObject[] } = {};
  private untypedMessagesHandler: ((wsData: any) => void) | null = null;

  constructor() {}

  private connect() {
    this.socket = typeof window !== 'undefined' ? new WebSocket(wsUrl) : null;

    if (this.socket) {
      this.socket.onopen = this.onOpen.bind(this);
      this.socket.onmessage = this.onMessage.bind(this);
      this.socket.onerror = this.onError.bind(this);
      this.socket.onclose = this.onClose.bind(this);
    }
  }

  private onOpen() {
    wsLogger.log('Connection opened');
    this.setWebsiteId();
    if (this.clientUserId) this.setUserOnline();
    if (this.subscriptions.threads.length) this.resumeThreadsSubscriptions();
    if (Object.keys(this.customEventListeners).length) this.transferCustomEventListeners();
  }

  private onMessage(event: MessageEvent<string>) {
    const wsData = parseJSONString<WsData>(event.data) || event.data;

    if (typeof wsData === 'string') {
      return wsData === 'ping'
        ? this.socket?.send('pong')
        : wsLogger.unknown('Message received', { wsData });
    }

    if ('type' in wsData) {
      const { type, ...payload } = wsData;
      if (this.methods[type]) {
        this.methods[type]?.(payload);
      } else {
        wsLogger.unhandled('Type not handled yet. Stay tuned.', { type });
      }
    } else {
      this.untypedMessagesHandler?.(wsData);
    }
  }

  private onError(error: Event) {
    wsLogger.error(': ', error);
  }

  private onClose(event: CloseEvent) {
    wsLogger.log('Connection closed:', event.reason);
    this.reconnect();
  }

  private reconnect() {
    setTimeout(() => {
      wsLogger.log('Reconnecting...');
      this.connect();
    }, 5000);
  }

  private resumeThreadsSubscriptions() {
    this.send({ type: 'chat.join', joinThread: this.subscriptions.threads });
  }

  private resetUserSubscriptions() {
    const currentThreadsSubscriptions = this.subscriptions.threads;
    if (currentThreadsSubscriptions.length) this.leaveThread(currentThreadsSubscriptions);
    this.subscriptions = { threads: [] };
  }

  // CUSTOM EVENT LISTENERS HANDLERS
  private savePersistantEventListener(event: string, listener: EventListenerOrEventListenerObject) {
    if (!this.customEventListeners[event]) {
      this.customEventListeners[event] = [];
    }
    this.customEventListeners[event].push(listener);
  }

  private removePersistantEventListener(
    event: string,
    listener: EventListenerOrEventListenerObject,
  ) {
    if (this.customEventListeners[event]) {
      this.customEventListeners[event] = this.customEventListeners[event].filter(
        l => l !== listener,
      );
    }
  }

  private transferCustomEventListeners() {
    const newSocket = this.socket;
    const listeners = this.customEventListeners;

    if (newSocket) {
      for (const event in listeners) {
        if (listeners[event].length) {
          listeners[event].forEach(listener => newSocket.addEventListener(event, listener));
        }
      }
    }
  }

  public addCustomEventListener(event: string, listener: EventListenerOrEventListenerObject) {
    if (this.socket) {
      this.socket.addEventListener(event, listener);
      this.savePersistantEventListener(event, listener);
    }
  }

  public removeCustomEventListener(event: string, listener: EventListenerOrEventListenerObject) {
    if (this.socket) {
      this.socket.removeEventListener(event, listener);
      this.removePersistantEventListener(event, listener);
    }
  }

  public initWebsiteConnection(websiteId: string) {
    this.clientWebsiteId = websiteId;
    this.connect();
  }

  public send(message: WsSendMessage) {
    if (this.socket?.readyState === WebSocket.OPEN) {
      // wsLogger.log('Sending message:', message);
      this.socket.send(typeof message === 'string' ? message : JSON.stringify(message));
    }
  }

  public on<T extends WsTypedData['type']>(
    messageType: T,
    handler: (message: ExtractPayload<WsTypedData, T>) => void,
  ) {
    this.methods[messageType] = handler;
  }

  public setLegacyUntypedMessagesHandler(callback: (message: any) => void) {
    this.untypedMessagesHandler = callback;
  }

  public close(code?: number, reason?: string) {
    this.socket?.close(code, reason);
  }

  public setUserId(userId: string) {
    this.clientUserId = userId;
    this.setUserOnline();
  }

  public unsetUserId() {
    this.setUserOffline();
    this.clientUserId = null;
  }

  public getClientUserId() {
    return this.clientUserId;
  }

  public setWebsiteId() {
    this.send({
      type: 'init.website',
      websiteId: this.clientWebsiteId!,
    });
  }

  public setUserOnline() {
    this.send({
      type: 'user.online',
      websiteId: this.clientWebsiteId!,
      userId: this.clientUserId!,
    });
  }

  public setUserOffline() {
    this.send({
      type: 'user.offline',
      websiteId: this.clientWebsiteId!,
      userId: this.clientUserId!,
    });
    this.resetUserSubscriptions();
  }

  public joinThread(threadId: string | string[]) {
    const newThreads = Array.isArray(threadId)
      ? threadId.filter(id => !this.subscriptions.threads.includes(id))
      : this.subscriptions.threads.includes(threadId)
      ? []
      : [threadId];

    this.subscriptions.threads = [...this.subscriptions.threads, ...newThreads];

    newThreads.length &&
      this.send({
        type: 'chat.join',
        joinThread: newThreads,
      });
  }

  public leaveThread(threadId: string | string[]) {
    this.subscriptions.threads = Array.isArray(threadId)
      ? this.subscriptions.threads.filter(id => !threadId.includes(id))
      : this.subscriptions.threads.filter(id => id !== threadId);

    this.send({
      type: 'chat.leave',
      leaveThread: threadId,
    });
  }
}
/**
 * This is the main WebSocket service.
 */
export const WebsocketService = new WebSocketService();
