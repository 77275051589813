import React from 'react';

import type { User } from '../../../../front/src/types/api';
import { ChatAvatars } from './ChatAvatars';

export const ChatUsers: React.FC<{ users: User[] }> = ({ users }) => {
  return (
    <div className="text-lg sm:text-base font-bold text-center break p-5 text-black">
      <div className="relative h-25 w-25 mx-auto mb-5">
        <ChatAvatars users={users} size="vvbig" />
      </div>

      {users.map(user => user?.profile?.pseudo).join(', ')}
    </div>
  );
};
