import classNames from 'classnames';

import { frontConfig } from '../../config';
import { ReactComponent as IconVoteDiamond } from '../../icons/icon-vote-diamond.svg';
import { ReactComponent as IconVoteDislike } from '../../icons/icon-vote-dislike.svg';
import { ReactComponent as IconVoteLike } from '../../icons/icon-vote-like.svg';
import { ReactComponent as IconVoteTarget } from '../../icons/icon-vote-target.svg';
import { ReactComponent as IconVoteTroll } from '../../icons/icon-vote-troll.svg';
import { ReactComponent as IconVoteWarning } from '../../icons/icon-vote-warning.svg';
import { warnOrThrow } from '../../utils/common-utils2';

export const allVotes = [
  'diamond',
  'target',
  'like',
  'withoutopinion',
  'offtopic',
  'troll',
  'rulesviolation',
];

export const allVotesMap = {
  '3': 'diamond',
  '2': 'target',
  '1': 'like',
  '0': 'withoutopinion',
  '-1': 'offtopic',
  '-2': 'troll',
  '-3': 'rulesviolation',
} as const;

type _Keys = keyof typeof allVotesMap;
type VoteKey = (typeof allVotesMap)[_Keys]; // "diamond" | "target" | ...

// const icons: Record<VoteKey, JSX.Element> = {
//   rulesviolation: <IconVoteWarning className="w-[20px] h-[20px]" />,
//   troll: <IconVoteTroll className="w-[20px] h-[20px]" />,
//   offtopic: <IconVoteDislike className="w-[20px] h-[20px]" />,
//   like: <IconVoteLike className="w-[20px] h-[20px]" />,
//   target: <IconVoteTarget className="w-[20px] h-[20px]" />,
//   diamond: <IconVoteDiamond className="w-[20px] h-[20px]" />,
//   withoutopinion: <span className="icon-noopinion flex w-[20px] h-[20px]"></span>,
// };

const sizeClasses = {
  small: 'w-[14px] h-[14px]',
  large: 'w-[20px] h-[20px]',
};

type IconSize = keyof typeof sizeClasses;

function getIcons(vote: VoteKey | undefined, size: IconSize) {
  if (vote === 'rulesviolation') return <IconVoteWarning className={sizeClasses[size]} />;
  if (vote === 'troll') return <IconVoteTroll className={sizeClasses[size]} />;
  if (vote === 'offtopic') return <IconVoteDislike className={sizeClasses[size]} />;
  if (vote === 'like') return <IconVoteLike className={sizeClasses[size]} />;
  if (vote === 'target') return <IconVoteTarget className={sizeClasses[size]} />;
  if (vote === 'diamond') return <IconVoteDiamond className={sizeClasses[size]} />;
  if (vote === 'withoutopinion')
    return <span className={classNames('icon-noopinion flex', sizeClasses[size])}></span>;

  if (vote == null) return null;

  const errMsg = `Unknown vote type: ${vote}`;
  if (frontConfig.IS_DEV) {
    throw new Error(errMsg);
  } else {
    console.warn(errMsg);
  }
  return;
}

export const getVoteIcon = (
  score: number | VoteKey | undefined /* | string */,
  size: IconSize = 'small',
) => {
  if (typeof score === 'number' && (score > 3 || score < -3 || !Number.isInteger(score))) {
    warnOrThrow(`Invalid vote score, it expects an integer between -3 and 3: ${score}`);
    score = 0; // Fallback for prod
  }

  const vote =
    typeof score === 'number' ? allVotesMap[score.toString() as keyof typeof allVotesMap] : score;

  const icon = getIcons(vote, size);
  return icon ?? null;
};
