import type { Selector } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { type DependencyList, useMemo } from 'react';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import type { AppDispatch, RootState } from './store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// To combine with createSelector:
// useSelectorMemo(() => createSelector(selectA, selectB, (a, b) => a + b), []);
// It allows to use createSelector in a component and use dynamic fields.
export function useSelectorMemo<Result = unknown>(
  createSelectorCreator: () => Selector<RootState, Result>,
  dependencies: DependencyList,
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useAppSelector(useMemo(createSelectorCreator, dependencies));
}

// To ensure all redux direct usages are centralized in the `front` project. Otherwise, graphdebate and graphlogin can have different versions of redux installed, which causes bugs hard to debug.
export const createAppSelector = createSelector;
export const createAppSlice = createSlice;
