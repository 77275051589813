import classnames from 'classnames';
import Image from 'next/image';
import type { FC, ReactNode } from 'react';
import React, { useMemo } from 'react';

import { Loader } from '../../components/Loader';
import { apiUrl } from '../../config';
import { useWebsite } from '../../hooks/useWebsite';
import { ReactComponent as IconArrowPrev } from '../../icons/icon-arrow-previous.svg';
import { computeImageSource } from '../../utils/string';

export const WebsiteLogo: React.FC<{ className?: string }> = ({ className }) => {
  const { custom, loading } = useWebsite();
  const imageSrc = computeImageSource(custom?.image);

  return loading ? null : (
    <Image
      alt="logo-graphcomment.png"
      className={classnames('h-18 object-contain', className)}
      src={
        imageSrc && imageSrc.length > 0
          ? imageSrc
          : 'https://login.graphcomment.com/img/logo-graphcomment.png'
      }
      width={500}
      height={120}
    />
  );
};

interface Props {
  className?: string;
  withBackIcon?: boolean;
}

export const WebsiteCustomLogo: FC<Props> = props => {
  const { className, withBackIcon } = props;
  const { name, custom: customConfig, loading } = useWebsite();
  let { logo } = useMemo(() => customConfig ?? {}, [customConfig]);

  let src: string | undefined | null = undefined;
  if (logo?.image && logo?.imageActive) {
    src = `${apiUrl}/api/file/${logo.image.id}`;
  } else {
    const imageSrc = computeImageSource(customConfig?.image);
    src =
      imageSrc && imageSrc.length > 0
        ? imageSrc
        : loading
        ? null
        : 'https://login.graphcomment.com/img/logo-graphcomment.png';
  }

  // The width used to be: `w-[min(220px,100%)]`
  const widthClass = 'w-auto';
  const heightClass = logo?.textActive ? 'h-8' : 'h-10';

  let loaderElt: ReactNode | undefined = undefined;
  if (src === null && loading) {
    loaderElt = (
      <div className={`flex items-center justify-center ${widthClass} ${heightClass}`}>
        <Loader black />
      </div>
    );
  }

  const img = logo?.imageActive && (
    <div className="flex-1 relative flex items-center">
      {loaderElt || (
        <Image
          className={classnames('object-contain object-left', widthClass, heightClass, className)}
          src={src ?? ''}
          alt={name ?? ''}
          width={220}
          height={36}
          // priority
        />
      )}
    </div>
  );

  const txt = logo?.textActive && <span className="_sm:text-xs truncate">{logo.text}</span>;

  const imgTxtBlock = txt ? (
    <div className="flex-1 flex flex-col">
      {img}
      {txt}
    </div>
  ) : (
    img
  );

  return withBackIcon ? (
    <div className="flex-1 flex flex-row space-x-2">
      <IconArrowPrev className="w-2 h-3 self-center text-primary" />
      {imgTxtBlock}
    </div>
  ) : (
    imgTxtBlock || null
  );
};
