import type { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { NoSSR } from '../../../utils/ssr';
import DensityIcon from '../../icons/DensityIcon';
import { IconArrowDown } from '../../icons/Icons';
import { Menu } from '../../Menu';
import type { CommonDensityMenuProps } from '../DensityMenu';
import type { DensityType } from './DensityItem';
import { DensityItem } from './DensityItem';

export const DensityMenuMobile: FC<CommonDensityMenuProps> = function DensityMenuMobile({
  options,
  currentDensity,
  isCurrentDensity,
  onDensityChange,
  itemsClass,
  isResponsive,
}) {
  return (
    <NoSSR>
      <Menu
        content={options.map((optionKey, index) => (
          <button key={index} type="button">
            <DensityItem
              densityType={optionKey as DensityType}
              isActive={isCurrentDensity(optionKey as DensityType)}
              className={itemsClass}
              showLabel
              onClick={() => onDensityChange(optionKey as DensityType)}
            />
          </button>
        ))}
        contentClass={twMerge('DensityMenuMobile', isResponsive && 'md:hidden')}
      >
        <button type="button">
          <div
            className={twMerge(
              'h-10 rounded-md flex border border-gray-light items-center justify-center bg-white px-2',
              isResponsive && 'md:px-3',
            )}
          >
            <div className="flex gap-x-3 items-center">
              <DensityIcon densityType={currentDensity} />
              <IconArrowDown />
            </div>
          </div>
        </button>
      </Menu>
    </NoSSR>
  );
};
