import type { FC } from 'react';
import { memo } from 'react';
import type { MessageDescriptor } from 'react-intl';
import { FormattedMessage } from 'react-intl';

import { Link } from '../../../../../front/src/components/Link';
import type { gdRoutes } from '../../../../../front/src/utils/gdRoutes';

interface LinkToAllProps {
  to: keyof typeof gdRoutes;
  params?: Record<string, string>;
  intlMessage: MessageDescriptor;
}

export const LinkToAll: FC<LinkToAllProps> = memo(function LinkToAll(props) {
  const { to, params, intlMessage } = props;

  return (
    <Link to={to} params={params} passHref>
      <a className="block mx-auto text-center text-primary-base hover:text-primary-variant font-bold underline">
        <FormattedMessage {...intlMessage} />
      </a>
    </Link>
  );
});
