import type { ReactNode } from 'react';
import { memo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as IconCommentsOutlineGrey } from '../../../../../front/src/icons/icon-comments-outline-grey.svg';
import type { GraphdebateHeading } from '../../../../../front/src/types/api';
import { type HexColor, hexWithOpacity } from '../../../../../front/src/utils/colors';

type HeadingsMenuItemProps = {
  icon?: ReactNode;
  content: ReactNode;
  color?: string;
  suffix?: ReactNode;
  className?: string;
  onClick?: () => void;
};

type FakeIconType = Pick<HeadingsMenuItemProps, 'color'> & {
  category: GraphdebateHeading['content'];
};

const isColorATailwindBgClass = (color?: string) => color?.slice(0, 2) === 'bg';

export const MenuNbTopicsItems = memo(function MenuNbTopicsItems({ nbItems }: { nbItems: number }) {
  return (
    <div className="MenuNbTopicsItems flex gap-x-2 items-center text-[13px]">
      {nbItems}
      <IconCommentsOutlineGrey />
    </div>
  );
});

export const FakeIcon = ({ color, category }: FakeIconType) => {
  const isColorATailwindClass = isColorATailwindBgClass(color);
  const iconContent = category?.[0]?.toUpperCase();
  return (
    <div
      className={twMerge(
        'FakeIcon h-7 w-7 min-w-7 bg-gray-400 rounded-lg flex justify-center items-center text-white',
        isColorATailwindClass && color,
      )}
      {...(!isColorATailwindClass && { style: { backgroundColor: color } })}
    >
      {iconContent}
    </div>
  );
};

export const HeadingsMenuItem = memo(function HeadingsMenuItem({
  color,
  icon,
  content,
  suffix,
  className,
  ...otherProps
}: HeadingsMenuItemProps) {
  const [isHovered, setIsHovered] = useState(false);
  const isColorATailwindClass = isColorATailwindBgClass(color);

  const shouldDisplayHoverColorStyle = color && !isColorATailwindClass && isHovered;

  const hoverHexColor = color && hexWithOpacity(color as HexColor, 0.08);

  return (
    <div
      className={twMerge(
        'HeadingsMenuItem flex items-center gap-x-2 py-2',
        isColorATailwindClass && `${color} bg-opacity-0 hover:bg-opacity-[0.08]`,
        className,
      )}
      onMouseEnter={() => !isColorATailwindClass && setIsHovered(true)}
      onMouseLeave={() => !isColorATailwindClass && setIsHovered(false)}
      style={{ ...(shouldDisplayHoverColorStyle && { backgroundColor: hoverHexColor }) }}
      {...otherProps}
    >
      <div className="item-icon-container h-7 w-7">{icon}</div>
      <div className="item-content flex-1 overflow-hidden">{content}</div>
      {suffix}
    </div>
  );
});
