import type { FC } from 'react';
import { memo } from 'react';

import { handleError } from '../utils/common-utils';

interface Props {
  error: any;
}

export const ErrorBlock: FC<Props> = memo(function ErrorBlock(props) {
  const { error } = props;
  if (!error) return null;
  handleError(error);
  const msg = error.message || error.error.message || JSON.stringify(error);
  return (
    <div className="flex flex-col justify-center items-center my-6">
      <div className="space-y-4">
        <div>
          So bad! An error has occurred, which is preventing the application from working properly.
        </div>
        <div>
          <i>&ldquo;{msg}&rdquo;</i>
        </div>
      </div>
    </div>
  );
});
