import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo } from 'react';

import { debounce } from '../../utils/common-utils';
import { getContentOptiDigitalDesktop, getContentOptiDigitalMobile } from '../../utils/optiDigital';
import { isBrowser } from '../../utils/web';
import classes from './bg-ads.module.css';

const fakeDebounce = () => {};
fakeDebounce.cancel = () => null;
const loadAds = isBrowser
  ? debounce(pathname => {
      let suffixe = 'divers';
      switch (pathname) {
        case '/':
          suffixe = 'accueil';
          break;
        case '/[tag]':
          suffixe = 'hp-categories';
          break;
        case '/t/[slug]':
          suffixe = 'discussion';
          break;
        default:
          break;
      }

      try {
        // @ts-ignore
        optidigitalToolbox.infiniteScrollRefresh(`/63134227/forum.psychologies.com/${suffixe}`);
      } catch (e) {
        // nothing to do
      }
    }, 300)
  : fakeDebounce;

export const optiDigitalCancelInfiniteLoad = isBrowser
  ? () => {
      loadAds.cancel();
    }
  : () => null;

export const adWrapperStyles = classNames(
  'h-[280px] relative box-content overflow-hidden',
  classes.bgAds,
);

interface OptiDigitalProps {
  index: number;
  className?: string;
}

export const OptiDigitalDesktopAds: React.FC<OptiDigitalProps> = ({ index, className }) => {
  const { pathname } = useRouter();
  useEffect(() => {
    loadAds(pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const html = useMemo(
    () => ({
      __html: getContentOptiDigitalDesktop(index),
    }),
    [index],
  );

  // eslint-disable-next-line react/no-danger
  return (
    <div className={'py-5 _md:hidden'}>
      <div className={classNames(adWrapperStyles, className)} dangerouslySetInnerHTML={html} />
    </div>
  );
};

export const OptiDigitalMobileAds: React.FC<OptiDigitalProps> = ({ index, className }) => {
  const { pathname } = useRouter();

  useEffect(() => {
    loadAds(pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const html = useMemo(
    () => ({
      __html: getContentOptiDigitalMobile(index),
    }),
    [index],
  );

  // eslint-disable-next-line react/no-danger
  return (
    <div className={'py-5 md:hidden'}>
      <div className={classNames(adWrapperStyles, className)} dangerouslySetInnerHTML={html} />
    </div>
  );
};
