import type { TippyProps } from '@tippyjs/react';
import classnames from 'classnames';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';

import type { TippyInstance } from './GraphModal/Popover';
import { Popover } from './GraphModal/Popover';

export const Menu = forwardRef<
  unknown,
  {
    autoOpen?: boolean;
    content: React.ReactChild | React.ReactChild[];
    noHideDelay?: boolean;
    disabled?: boolean;
    noArrow?: boolean;
    contentClass?: string;
  } & TippyProps
>(function Menu(
  { autoOpen, content, contentClass, disabled, children, noArrow, noHideDelay = 200, ...props },
  ref,
) {
  const [index, setIndex] = useState(-1);
  const [tip, setTip] = useState<TippyInstance>();
  const menuRef = useRef<HTMLDivElement>(null);
  const contentLength = useMemo(() => (Array.isArray(content) ? content.length : 1), [content]);

  const manageTip = useCallback(() => autoOpen && tip && tip.show(), [autoOpen, tip]);
  const closeMenu = useCallback(
    () => setTimeout(() => tip?.hide(), noHideDelay ? 0 : 200),
    [noHideDelay, tip],
  );

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (!tip || !tip.state.isVisible) return;

      if (event.keyCode === 38) {
        // UP
        event.preventDefault();
        setIndex(value => Math.max(value - 1, -1));
      }

      if (event.keyCode === 40) {
        // DOWN
        event.preventDefault();
        setIndex(value => Math.min(value + 1, contentLength - 1));
      }

      if (event.keyCode === 13) {
        // ENTER
        if (index !== -1) {
          const $resultsList = tip.popper;
          const $results = $resultsList.querySelectorAll('.menu > li');
          const $result = $results[index];
          const $link = $result.querySelector<HTMLLIElement>('a, button');
          $link?.click();
          closeMenu();
        }
      }

      if (event.keyCode === 27) {
        // ESC
        closeMenu();
      }
    },
    [tip, contentLength, index, closeMenu],
  );

  const renderList = useCallback(
    () => (
      <ul className="menu" role="menu" tabIndex={-1}>
        {content &&
          (content as React.ReactChild[]).filter(Boolean).map((menuItem, i) => (
            <li
              // eslint-disable-next-line
              key={i}
              role="menuitem"
              aria-current={index === i}
              onClick={closeMenu}
              className={classnames({
                'menu-selected': index === i,
              })}
            >
              {menuItem}
            </li>
          ))}
      </ul>
    ),
    [closeMenu, content, index],
  );

  useImperativeHandle(
    ref,
    () => ({
      close: () => closeMenu(),
    }),
    [closeMenu],
  );

  useEffect(() => {
    if (tip) manageTip();
    const menuEl = menuRef.current;
    if (menuEl) menuEl.addEventListener('keypress', handleKeyPress);
    return () => menuEl?.removeEventListener('keypress', handleKeyPress);
  }, [tip, menuRef, handleKeyPress, manageTip]);

  return (
    <Popover
      // TODO fix typing
      ref={menuRef as any}
      className="menu-popover"
      theme="light-border"
      content={Array.isArray(content) ? renderList() : content || ''}
      placement={props.placement || 'bottom'}
      duration={[275, noHideDelay ? 0 : 250]}
      onCreate={setTip}
      maxWidth={props.maxWidth ?? 350}
      {...props}
      trigger="click"
    >
      <div className={twMerge('flex items-center', contentClass)}>{children}</div>
    </Popover>
  );
});
