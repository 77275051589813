import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { CategoryBadge } from '../../thread/Category/CategoryBadge';
import type { Topic } from '../../types/api';

const messages = defineMessages({
  publishedIn: { defaultMessage: 'publié dans', id: 'W5vNse' },
});

export const TopicSuggestion: React.FC<{ topic: Topic }> = ({ topic }) => {
  return (
    <div key={topic.id}>
      <div className="mb-2">{topic.subject}</div>
      <div className="flex flex-wrap items-center gap-2">
        <span className="text-gray-medium text-xs font-roboto">
          <FormattedMessage {...messages.publishedIn} />
        </span>
        {(topic?.categories ?? []).map(props => (
          <CategoryBadge key={props?.id} variant="contained" color="primary" {...props} />
        ))}
      </div>
    </div>
  );
};
