import classNames from 'classnames';
import type { FC } from 'react';
import { memo, useCallback } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { BulletSeparator } from '../../../../../front/src/components/BulletSeparator';
import type { RequestFriendCallback } from '../../../../../front/src/contributor/api/relation';
import { ProfileMember } from '../../../../../front/src/contributor/Profile/ProfileRelations/ProfileMember';
import { useAppDispatch } from '../../../../../front/src/redux/hooks';
import type { GroupMember, Maybe } from '../../../../../front/src/types/api';
import { updateMemberAfterInvite } from '../group-members-slice';

const messages = defineMessages({
  members: {
    defaultMessage: `{count, plural,
      =0 {Membre}
      =1 {Membre}
      other {Membres}
    }`,
    id: 'SvRMLg',
  },
  admins: {
    defaultMessage: `{count, plural,
      =0 {Admin}
      =1 {Admin}
      other {Admins}
    }`,
    id: 'OMzXMW',
  },
});

interface Props {
  groupMembers: Maybe<GroupMember>[] | undefined;
  count: number | undefined;
  isAdmin?: boolean;
}

export const GroupSummaryMembersCell: FC<Props> = memo(function GroupSummaryMembersCell(props) {
  const { count, groupMembers, isAdmin } = props;

  return (
    <div className="flex flex-col flex-1 space-y-3 md:px-4 _md:py-4">
      <h3 className="flex space-x-2 mt-10 mb-3">
        <div>
          <FormattedMessage
            {...(isAdmin ? messages.admins : messages.members)}
            values={{
              count: count,
            }}
          />
        </div>
        <BulletSeparator />
        <span>{count != null && count}</span>
      </h3>
      <div className={classNames('grid grid-cols-1 md:grid-cols-2 gap-x-2 md:gap-x-8')}>
        {groupMembers?.map(gMember => {
          return <ProfileMemberWrapper key={gMember?.member?.profile?.id} gMember={gMember} />;
        })}
      </div>
      {/* <AllMembersBtnModal slug={slug} isAdmin={isAdmin} /> */}
    </div>
  );
});

interface ProfileMemberWrapperProps {
  gMember: Maybe<GroupMember>;
}

const ProfileMemberWrapper: FC<ProfileMemberWrapperProps> = memo(function ProfileMemberWrapper(
  props,
) {
  const { gMember } = props;
  // const member = gMember?.member || {};
  const user = gMember?.member || {};
  const profile = user?.profile || {};
  const dispatch = useAppDispatch();

  const inviteCallback = useCallback<RequestFriendCallback>(
    response => {
      dispatch(updateMemberAfterInvite({ groupMember: gMember, response }));
    },
    [dispatch, gMember],
  );

  return (
    // <AuthorAvatar
    //   key={member.id}
    //   author={member}
    //   withProfilePopover
    //   size="medium1"
    //   // smallAvatar
    //   containerClassName="shrink-0"
    // />
    <ProfileMember
      relationId={profile.relation?.id as string}
      userId={profile.user?.id as string}
      user={user}
      status={profile?.relation?.status as string}
      relation={profile?.relation}
      inviteCallback={inviteCallback}
      isGroup
    />
  );
});
