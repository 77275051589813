import type { TippyProps } from '@tippyjs/react';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import type { FC, PropsWithChildren } from 'react';
import { memo } from 'react';

import { isBrowser } from '../../utils/web';

// Note: tippy.js and popper may be unmaintained. Suggestion if a replacement is required: https://floating-ui.com
export const Tooltip: FC<PropsWithChildren<Omit<TippyProps, 'children'>>> = memo(props => {
  const {
    children,
    trigger = 'mouseenter',
    theme = 'material',
    maxWidth = '100%',
    ...others
  } = props;
  if (!isBrowser /* || isTouchableDevice() */) {
    return <>{children}</>;
  }

  return (
    // preserve old implementation for continuous delivery please refactor asap
    <Tippy
      {...others}
      maxWidth={maxWidth}
      className={classNames('tippy-align-center', 'tippy-small', props.className)}
      trigger={trigger}
      delay={100}
      theme={theme}
      touch={false}
    >
      {children as any}
    </Tippy>
  );
});
