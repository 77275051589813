import type { TippyProps } from '@tippyjs/react';
import Tippy from '@tippyjs/react';
import React, { forwardRef, useCallback, useContext, useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import type { Instance } from 'tippy.js';

import { frontConfig } from '../../config';
import { useId } from '../../hooks/useId';
import { isBrowser } from '../../utils/web';
import { popoverContext } from './popoverContext';

// TODO fix typing
export type TippyInstance2 = /* Parameters<NonNullable<TippyProps['singleton']>>[0] */ any;
export type TippyInstance = Instance;

type TipShowHide = (tip: TippyInstance) => void;

export type PopoverPropTypes = Partial<Omit<TippyProps, 'onShow' | 'onHide' | 'ref'>> & {
  lazyContent?: (tip?: TippyInstance) => React.ReactNode;
  theme?: string;
  onShow?: TipShowHide;
  onHide?: TipShowHide;
  showPageOverlay?: boolean;
  preventPageScroll?: boolean;
  trigger?: 'click' | 'mouseenter' | 'manual';
  children: TippyProps['children'];
  containerClassName?: string;
};

const BrowserPopover = forwardRef<HTMLDivElement, PopoverPropTypes>(function BrowserPopover(
  {
    children,
    lazyContent,
    theme = 'material',
    onShow: propOnShow,
    onHide: propOnHide,
    showPageOverlay,
    preventPageScroll = false,
    containerClassName,
    ...tippyProps
  },
  ref,
) {
  const [tip, setTip] = useState<TippyInstance>();
  const { add, remove, nodeReference } = useContext(popoverContext);
  const id = useId();

  useEffect(() => {
    if (!showPageOverlay || !tip) return undefined;
    add(id);
    return () => remove(id);
  }, [add, id, remove, showPageOverlay, tip]);

  const handleShowPopover = useCallback<TipShowHide>(_tip => propOnShow?.(_tip), [propOnShow]);
  const handleHidePopover = useCallback<TipShowHide>(_tip => propOnHide?.(_tip), [propOnHide]);

  const onShowLazy = useCallback(
    (_tip: TippyInstance) => {
      setTip(_tip);
      handleShowPopover?.(_tip);
    },
    [handleShowPopover],
  );

  const computedProps: Partial<PopoverPropTypes> = {};

  if (lazyContent) {
    computedProps.content = (tip ? lazyContent(tip) : <div />) as React.ReactChild;
    computedProps.onShow = onShowLazy;
  } else {
    computedProps.onShow = handleShowPopover;
  }
  computedProps.onHide = handleHidePopover;

  if (showPageOverlay) {
    computedProps.appendTo = nodeReference?.current as Element;
  }

  return (
    <Tippy
      content={tippyProps?.content ?? []}
      {...tippyProps}
      {...computedProps}
      // @ts-ignore
      zIndex={frontConfig.zIndexHeaderPopover ?? 900}
      interactive
      trigger={tippyProps?.trigger || 'click'}
      theme={theme}
    >
      <div ref={ref} className={containerClassName} suppressHydrationWarning>
        {children}
      </div>
    </Tippy>
  );
});

const ServerPopover = function ServerPopover({ children }: { children?: ReactNode }) {
  return <div>{children}</div>;
};

export const Popover = (isBrowser ? BrowserPopover : ServerPopover) as typeof BrowserPopover;
