/**
 * Returns the same object swaping a specific key name to another to avoid collisions
 *
 * @param objectToHandle
 * @param keyNameToSwap
 * @param newKeyName
 *
 * @example
 * const myObject = { id: 12345, name: 'foo' };
 *
 * const newObject = objectKeySwap(myObject, 'name', 'label');
 * // { id: 12345, label: 'foo' }
 */
export const objectKeySwap = <
  T extends Record<string, any>,
  Knts extends keyof T,
  Nkn extends string,
>(
  objectToHandle: T,
  keyNameToSwap: Knts,
  newKeyName: Nkn,
): Omit<T, Knts> & Record<Nkn, T[Knts]> => {
  if (typeof objectToHandle !== 'object' || Array.isArray(objectToHandle)) {
    throw new Error('[objectKeySwap] This is not an object');
  }

  const result = {} as Omit<T, Knts> & Record<Nkn, T[Knts]>;
  (Object.keys(objectToHandle) as Array<keyof T>).forEach(key => {
    if (key === keyNameToSwap) {
      // Handle the swapped key
      (result as Record<Nkn, T[Knts]>)[newKeyName] = objectToHandle[keyNameToSwap];
    } else {
      // Handle the other keys
      (result as T)[key] = objectToHandle[key];
    }
  });

  return result;
};

/**
 * Returns the same object without specific key(s)
 *
 * @example
 * const originalObject = { a: 1, b: 2, c: 3 };
 * const modifiedObject = removeObjectKey(originalObject, 'b');
 * // { a: 1, c: 3 }
 *
 * const modifiedObject = removeObjectKey(originalObject, ['b', 'c']);
 * // { a: 1 }
 */
export const objectKeyRemove = <T extends Record<string, any>, Kntr extends keyof T>(
  objectToHandle: T,
  keyNameToRemove: Kntr | Kntr[],
): Omit<T, Kntr> => {
  if (Array.isArray(keyNameToRemove)) {
    return (Object.keys(objectToHandle) as Array<keyof T>).reduce((acc, key) => {
      if (!keyNameToRemove.includes(key as Kntr)) {
        acc[key as Exclude<keyof T, Kntr>] = objectToHandle[key as Exclude<keyof T, Kntr>]; // Conserver les clés non supprimées
      }
      return acc;
    }, {} as Omit<T, Kntr>);
  }

  const { [keyNameToRemove]: _, ...rest } = objectToHandle;
  return rest;
};
