import type { FC, ReactNode } from 'react';
import { isValidElement, memo, useContext } from 'react';

import { imageUrl } from '../../../../config';
import type { ImgOrStr } from '../../../../types/common-models';
import { EmbedImagePreviewOrLink } from '../Embed/EmbedImagePreviewOrLink';
import { SimpleUrl } from '../Embed/SimpleUrl';
import { usePreviewRegistration } from '../Embed/usePreviewRegistration';
import { isEditorjsContent } from '../message-utils';
import { LimiterContext } from './ElementLimiter';
import { stringReplacer } from './stringFormatter';

function replaceMongoImages(str: ImgOrStr) {
  // if it's a mongoid, eg: 5a328e37f2fc5923f6f33787.jpg
  return stringReplacer({
    str,
    regex:
      /(^|\s)(?!(https?:\/\/[\S]*))[0-9a-fA-F]{24}(?:\.(?:gif|jpg|jpeg|tiff|png|svg|webp))($|\s)/gim,
    factory: chunk => `${imageUrl}/api/file/${(chunk as string)?.trim()}`,
  });
}

function replaceImportImages(str: ReactNode) {
  // if it's a import image, eg: /images/website/5d88da3d3f5f632e2297f4dc/uploads/1591629582dfd3650ff0b0bc-830e-4fc5-a508-1bb8759e92d9.jpg
  return stringReplacer({
    str,
    regex: /(\/images\/website\/\w{24}\/uploads\/(?:.*?)\.(?:gif|jpg|jpeg|tiff|png|svg|webp))/gi,
    factory: chunk => `${imageUrl}${chunk}`,
  });
}

export const imagesFormatter = (node: ImgOrStr, version: string | null) => {
  if (typeof node !== 'string' || isEditorjsContent(version)) {
    return <LimitedImgPreviewer node={node} />;
  } else {
    const urlOrNode = replaceImportImages(replaceMongoImages(node));
    return <EmbedImagePreviewOrLinkWrapper node={urlOrNode} />;
  }
};

const EmbedImagePreviewOrLinkWrapper: FC<{ node: ReactNode }> = memo(({ node }) => {
  const { registeredPreviews } = useContext(LimiterContext);
  return (
    <EmbedImagePreviewOrLink
      blockProps={{
        node,
        registeredPreviews,
      }}
    />
  );
});

interface LimitedImgPreviewerProps {
  node: ReactNode;
}

const LimitedImgPreviewer: FC<LimitedImgPreviewerProps> = memo(function LimitedImgPreviewer(props) {
  const { node } = props;
  const { registeredPreviews } = useContext(LimiterContext);
  const isPreviewable = usePreviewRegistration(registeredPreviews);
  return isPreviewable || !isValidElement(node) || !node.props?.src ? (
    <>{node}</>
  ) : (
    <SimpleUrl url={node.props.src} />
  );
});
