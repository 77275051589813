import Image from 'next/image';
import type { CSSProperties, HTMLAttributes } from 'react';
import { forwardRef, Suspense } from 'react';
import { twMerge } from 'tailwind-merge';

import { useAvatarFallbackSrc } from '../hooks/useAvatarFallbackSrc';
import type { Author, Profile } from '../types/api';
import { sizesToClassName, sizesToPixels } from './Avatar-const';
import { DefaultLetterAvatar } from './DefaultLetterAvatar';

const defaultClassName = 'Avatar bg-white select-none object-cover';

export type AvatarSizes = keyof typeof sizesToClassName;

export type AvatarPropsType = {
  type?: Author['__typename'];
  pseudo?: string;
  profile?: Profile;
  picture: string | undefined | null;
  size?: AvatarSizes;
  className?: string;
  style?: CSSProperties;
  legacyImage?: boolean;
  noRound?: boolean;
  noCenter?: boolean;
};

export const Avatar = forwardRef<
  HTMLImageElement,
  AvatarPropsType & Omit<HTMLAttributes<HTMLImageElement>, 'placeholder'>
>((props, ref) => {
  const {
    type,
    pseudo,
    profile,
    picture,
    size = 'medium',
    className,
    style,
    legacyImage,
    noRound,
    noCenter,
    ...others
  } = props;

  const imgProps = useAvatarFallbackSrc(picture);

  const sizeClassName = sizesToClassName[size];
  const fullAvatarClassnames = twMerge(
    defaultClassName,
    !noCenter && 'self-center',
    !noRound && 'rounded-full',
    sizeClassName,
    className,
  );

  const commonAvatarProps = {
    ref,
    className: fullAvatarClassnames,
    style,
  };

  const commonImgAvatarProps = {
    ...commonAvatarProps,
    ...imgProps,
    ...others,
    alt: 'avatar',
  };

  const isUser = type === 'User' || profile;
  const hasCustomPicture = !imgProps.src.split('/').at(-1)?.includes('avatar');

  if (isUser && !hasCustomPicture) {
    return (
      <DefaultLetterAvatar
        pseudo={pseudo}
        profile={profile || { pseudo: 'UnknownUser' }}
        size={size}
        {...commonAvatarProps}
      />
    );
  }

  if (legacyImage) {
    return <LegacyImgAvatar {...commonImgAvatarProps} draggable={false} />;
  }

  if (size === 'full') {
    throw new Error(
      'Unsupported full size for now. It needs to be implemented with the right Image properties. Cf. Next.js doc.',
    );
  }

  const { w, h } = sizesToPixels[size];
  return (
    // This suspense syntax may only work with the Nextjs "app" router (using "pages" when it was coded).
    // I tried to add a skeleton while the image is loaded.
    <Suspense
      fallback={
        <div className={twMerge(sizeClassName, !noCenter && 'rounded-full', 'bg-gray-200')} />
      }
    >
      <Image {...commonImgAvatarProps} width={w} height={h} />
    </Suspense>
  );
});

Avatar.displayName = 'Avatar';

const LegacyImgAvatar = forwardRef<
  HTMLImageElement,
  Omit<AvatarPropsType, 'profile' | 'picture'> &
    Omit<HTMLAttributes<HTMLImageElement>, 'placeholder'>
>((props, ref) => {
  return <img ref={ref} {...props} />;
});
