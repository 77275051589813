import NProgress from 'nprogress';

import { dispatchOther } from '../redux/redux.utils';
import { startGlobalLoading, stopGlobalLoading } from '../redux/slices/utils-slice';
import { isBrowser } from './fragments/isBrowser';

declare global {
  interface Window {
    // Defined in packages/front/src/utils/spinner-initial.tsx which is imported in _document.tsx. Explanations are there about why we have a global spinner this way.
    // Since we don't reuse the initial spinner, the showSpinner and hideSpinner methods below don't use them.
    // But hideGlobalSpinner is called from useWaitUserLoading to hide the initial spinner when the user settings are available.
    showGlobalSpinner: VoidFunction;
    hideGlobalSpinner: VoidFunction;
  }
}

if (isBrowser) {
  NProgress.configure({ showSpinner: false });
}

let nbConcurrentLoaders = 0;

export function showSpinner() {
  if (!isBrowser) return;
  ++nbConcurrentLoaders;
  NProgress.start();
  dispatchOther(startGlobalLoading());
}

export function hideSpinner() {
  if (!isBrowser) return;
  if (nbConcurrentLoaders >= 1) {
    --nbConcurrentLoaders;
  }
  NProgress.done();
  dispatchOther(stopGlobalLoading());
}

// aliases - useful for global searches
export const startSpinner = showSpinner;
export const stopSpinner = hideSpinner;
