import type { FC } from 'react';
import type { MessageDescriptor } from 'react-intl';
import { FormattedMessage } from 'react-intl';

import { AutoHeight } from '../AutoHeight';

type SubmitError = MessageDescriptor | string | undefined | null;

interface Props {
  submitError: SubmitError;
}

export const FormError: FC<Props> = function FormError(props) {
  const { submitError: error } = props;
  if (!error) return null;

  let content: any = null;
  if (typeof error === 'string') {
    content = error;
  } else {
    content = <FormattedMessage {...error} />;
  }

  return (
    <AutoHeight>
      {content ? <div className="text-failure text-xs truncate pt-1">{content}</div> : null}
    </AutoHeight>
  );
};
