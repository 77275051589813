import { isBrowser } from './utils/fragments/isBrowser';

export interface EnvFrontConfig {
  apiUrl: string;
  wsUrl: string;
  imageUrl: string;
  graphUrl: string;
  yakliUrl?: string;
  WITH_TIMER: boolean;
  VERBOSE: boolean;
  IS_DEV?: boolean;
}

// eslint-disable-next-line import/extensions
const env: EnvFrontConfig = require('./.env');

export const { apiUrl, imageUrl, graphUrl, wsUrl, WITH_TIMER } = env;

// Defaults to true if undefined
export const VERBOSE = env.VERBOSE !== false;

export const frontConfig = {
  ...env,
  devTools: env.IS_DEV || (isBrowser && window.location.search.includes('dev')),
  defaultLocale: 'fr',
  disableCache: true,
  // Useful to debug the extra content for SEO.
  // Ideally, make it an environment variable to avoid polluting the commits.
  enableSEOLdInFront: false,
  rootElementSelector: '#__next',
  // rootElementSelector: 'html',
  // Here instead of directly in tailwind config so that we can refer to it in the code without importing the whole tailwind config, which refers to plugins and would make the client bundle bigger.
  zIndexHeaderPopover: 900,

  // Debug option
  showMessageId: false,
  checkVIPAfterProfileCreation: true,
  enableExperimentalEditorjs: true,
  // env.IS_DEV || (isBrowser && window.location.search.includes('witheditorjs')),
  /* && window.location.pathname.startsWith('/write') */ // New editor, instead of Draftjs
  bbfSelectionAlwaysOnLeft: false,

  // Seems to cause issues when loading the group page, loading all members of the page. Especially with HMR (hot reload in dev) and in Firefox.
  enableAbortQuery: false,

  // Push notification
  oneSignalSWScope: '/push/onesignal/',
  oneSignalAppIds: {
    // The various app IDs
    'localhost:3000': 'd113c273-1d23-4962-be5e-aa7e02391536',
    // 'app.nextcircle.com': '51b82382-920b-4ad0-905a-97778cae5381',
    // 'debate.osc-fr1.scalingo.io': 'b42f3cf7-a99b-46bd-93ca-ae8fffa8249b',
    'debate.graphcomment.com': 'XXXX',

    // All using the same app ID
    // 'localhost:3000': '51b82382-920b-4ad0-905a-97778cae5381',
    // 'app.nextcircle.com': '51b82382-920b-4ad0-905a-97778cae5381',
    // 'debate.osc-fr1.scalingo.io': '51b82382-920b-4ad0-905a-97778cae5381',
    // 'debate.graphcomment.com': '51b82382-920b-4ad0-905a-97778cae5381',
  },
  oneSignalVerbose: true,
  oneSignalOnLocalhost: false,
  logRocketId: env.IS_DEV ? '4zegaz/graphdebate-dev' : '4zegaz/graphdebate',
  // Can be switched to true to check if it has any impact on the Lighthouse/SEO score.
  fontOptionalInsteadOfSwap: false,
};

// export default frontConfig;
