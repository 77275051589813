import type { PayloadAction } from '@reduxjs/toolkit';

import type { RequestFriendResponse } from '../../../../front/src/contributor/api/relation';
import { createAppSelector, createAppSlice } from '../../../../front/src/redux/hooks';
import type { RootState } from '../../../../front/src/redux/store';
import type { Group, GroupMember, Maybe, QueryType, User } from '../../../../front/src/types/api';
import {
  checkCurrentUserBelongsToThisGroup,
  checkHasGroupAdminPermissions,
  checkHasGroupOwnerPermissions,
  checkJoinGroupIsPending,
  userCanEditMember,
} from './groupUtils';

// If a state should not be reset on logout, check /front/src/redux/store.ts

type GroupMemberId = string;

type GMemberStatus = 'approved' | 'pending' | 'deleted';

export interface GroupMembersState {
  groupIsMember?: boolean;
  groupIsAdmin?: boolean;
  groupIsOwner?: boolean;
  loadingGroup: boolean;
  errorGroup?: any;
  group?: Group;
  me?: User;
  currentGroupMember?: GroupMember;
  loadingMembers: boolean;
  errorMembers?: any;
  members: Record<GroupMemberId, GroupMember> | undefined;
  memberCount?: number;
  adminCount?: number;
  ownerCount?: number;
}

const initialState: GroupMembersState = {
  loadingGroup: false,
  loadingMembers: false,
  members: undefined,
};

function addMembersFn(
  state: GroupMembersState, // WritableDraft<GroupMembersState>
  { payload }: Pick<PayloadAction<Maybe<GroupMember>[] | undefined>, 'payload'>,
) {
  if (payload) {
    if (!state.members) state.members = {};
    for (const gMember of payload) {
      if (gMember?.id && !state.members?.[gMember.id]) {
        state.members[gMember.id] = gMember;
      }
    }
  }
  state.loadingMembers = false;
  state.errorMembers = undefined;
}

export const groupMembersSlice = createAppSlice({
  name: 'groupMembers',
  initialState,
  reducers: {
    setCurrentUserGroupRole(
      state,
      {
        payload: { groupIsMember, groupIsAdmin, groupIsOwner },
      }: PayloadAction<{
        groupIsMember: boolean | undefined;
        groupIsAdmin: boolean | undefined;
        groupIsOwner: boolean | undefined;
      }>,
    ) {
      state.groupIsMember = groupIsMember;
      state.groupIsAdmin = groupIsAdmin;
      state.groupIsOwner = groupIsOwner;
    },
    setCounts(
      state,
      {
        payload,
      }: PayloadAction<{
        memberCount: number | undefined;
        adminCount: number | undefined;
        ownerCount: number | undefined;
      }>,
    ) {
      const { memberCount, adminCount, ownerCount } = payload;
      state.memberCount = memberCount;
      state.adminCount = adminCount;
      state.ownerCount = ownerCount;
    },
    addMembers: addMembersFn,
    setNewMembers(state, { payload }: PayloadAction<Maybe<GroupMember>[] | undefined>) {
      if (payload) {
        state.members = {};
        for (const gMember of payload) {
          if (gMember?.id && !state.members[gMember.id]) {
            state.members[gMember.id] = gMember;
          }
        }
      }
      state.loadingMembers = false;
      state.errorMembers = undefined;
    },
    setLoadingMembers(state, { payload }: PayloadAction<boolean>) {
      state.loadingMembers = payload;
    },
    setErrorMembers(state, { payload }: PayloadAction<any>) {
      state.loadingMembers = false;
      state.errorMembers = payload;
    },
    setGroupAndCurrentMember(state, { payload }: PayloadAction<QueryType>) {
      state.group = payload?.website?.findGroup;
      const { groupMember, groupIsMember, groupIsAdmin, groupIsOwner } = payload?.me?.profile || {};
      state.currentGroupMember = groupMember;
      state.groupIsMember = groupIsMember;
      state.groupIsAdmin = groupIsAdmin;
      state.groupIsOwner = groupIsOwner;
      state.loadingGroup = false;
      state.errorGroup = undefined;
    },
    setLoadingGroup(state, { payload }: PayloadAction<boolean>) {
      state.loadingGroup = payload;
    },
    setErrorGroup(state, { payload }: PayloadAction<any>) {
      state.loadingGroup = false;
      state.errorGroup = payload;
    },
    updateCurrentGMStatus(state, { payload }: PayloadAction<string>) {
      if (!state.currentGroupMember) state.currentGroupMember = {};
      state.currentGroupMember.status = payload;
    },
    updateCurrentGMStatusGrade(
      state,
      {
        payload,
      }: PayloadAction<{
        status: GMemberStatus | undefined;
        gradeId: string | undefined;
        gMember?: GroupMember;
      }>,
    ) {
      const { status, gradeId, gMember } = payload;
      if (!gMember) {
        if (!state.currentGroupMember) state.currentGroupMember = {};
        if (status && status !== state.currentGroupMember.status) {
          state.currentGroupMember.status = status;
        }
        if (gradeId && gradeId !== state.currentGroupMember.grade?.id) {
          state.currentGroupMember.grade = (state.group?.gradeList?.grades || []).find(
            g => g?.id === gradeId,
          );
        }
      } else {
        const gmId = gMember.id!;
        if (!state.members) state.members = {};
        if (!state.members[gmId]) state.members[gmId] = {};
        if (status && status !== state.members[gmId].status) {
          state.members[gmId].status = status;
        }
        if (gradeId && gradeId !== state.members[gmId].grade?.id) {
          state.members[gmId].grade = (state.group?.gradeList?.grades || []).find(
            g => g?.id === gradeId,
          );
        }
      }
    },
    addOrUpdateMember(state, { payload }: PayloadAction<GroupMember | undefined>) {
      if (payload?.id) {
        const gmId = payload.id;
        if (!state.members) state.members = {};
        if (!state.members[gmId]) state.members[gmId] = {};
        Object.assign(state.members[gmId], payload);
      }
    },
    updateMemberAfterInvite(
      state,
      {
        payload,
      }: PayloadAction<{
        groupMember: Maybe<GroupMember>;
        response: RequestFriendResponse | undefined;
      }>,
    ) {
      const { groupMember, response } = payload;
      if (
        !groupMember?.id ||
        !state.members?.[groupMember.id]?.member?.profile?.relation ||
        !response
      ) {
        return;
      }
      state.members[groupMember.id].member!.profile!.relation!.status = response.status;
      if (!state.members[groupMember.id].member!.profile!.relation!.guest) {
        state.members[groupMember.id].member!.profile!.relation!.guest = {};
      }
      state.members[groupMember.id].member!.profile!.relation!.guest!.id = response.guest;
      state.members[groupMember.id].member!.profile!.relation!.message = response.message;
    },
    addOrUpdateGroup(state, { payload }: PayloadAction<Group | undefined>) {
      if (!state.group) state.group = {};
      Object.assign(state.group, payload);
    },
  },
});

export const {
  setCurrentUserGroupRole,
  setCounts,
  addMembers,
  setNewMembers,
  setLoadingMembers,
  setErrorMembers,
  setGroupAndCurrentMember,
  setLoadingGroup,
  setErrorGroup,
  updateCurrentGMStatus,
  updateCurrentGMStatusGrade,
  addOrUpdateMember,
  updateMemberAfterInvite,
  addOrUpdateGroup,
} = groupMembersSlice.actions;

// Does not cover other cases like website admin/owner.
// And there is currently a bug in the API: those fields are null. See selectHasGroupOwnerPermissions and selectHasGroupAdminPermissions.
// export const selectGroupIsMember = (state: RootState) => state.groupMembers.groupIsMember;
// export const selectGroupIsAdmin = (state: RootState) => state.groupMembers.groupIsAdmin;
// export const selectGroupIsOwner = (state: RootState) => state.groupMembers.groupIsOwner;

export const selectGroup = (state: RootState) => state.groupMembers.group;
export const selectHasGroup = (state: RootState) => !!state.groupMembers.group;
export const selectCurrentGroupMember = (state: RootState) => state.groupMembers.currentGroupMember;
export const selectCurrentGMStatus = (state: RootState) =>
  state.groupMembers.currentGroupMember?.status;
export const selectJoinGroupIsPending = (state: RootState) =>
  checkJoinGroupIsPending(state.groupMembers.currentGroupMember);
export const selectCurrentUserBelongsToThisGroup = (state: RootState) =>
  checkCurrentUserBelongsToThisGroup(state.groupMembers.currentGroupMember);
export const selectHasGroupOwnerPermissions = (state: RootState) =>
  state.groupMembers.groupIsOwner ||
  checkHasGroupOwnerPermissions(
    state.groupMembers.currentGroupMember,
    state.utils.me,
    state.groupMembers.group,
  );
export const selectHasGroupAdminPermissions = (state: RootState) =>
  state.groupMembers.groupIsAdmin ||
  checkHasGroupAdminPermissions(
    state.groupMembers.currentGroupMember,
    state.utils.me,
    state.groupMembers.group,
  );

export const selectGroupMembersLoading = (state: RootState) => state.groupMembers.loadingMembers;
export const selectGroupMembersError = (state: RootState) => state.groupMembers.errorMembers;
const selectMembersObj = (state: RootState) => state.groupMembers.members;
export const selectAllMembers = createAppSelector(selectMembersObj, m =>
  m ? Object.values(m).sort(sortByStatusAndRole) : undefined,
);
export const selectGroupMembersWithRole = createAppSelector(selectMembersObj, members =>
  !members
    ? undefined
    : Object.values(members)
        .filter(gm => getRole(gm) === 'member' && gm.status === 'approved')
        .sort(sortByStatusAndRole),
);
export const selectGroupNbAdminsOwners = (state: RootState) => {
  const na = state.groupMembers.adminCount,
    no = state.groupMembers.ownerCount;
  return na == null && no == null ? undefined : na == null ? no : no == null ? na : na + no;
};
// export const selectGroupNbOwners = (state: RootState) => state.groupMembers.ownerCount;
// export const selectGroupNbAdmins = (state: RootState) => state.groupMembers.adminCount;
export const selectGroupNbMembers = (state: RootState) => state.groupMembers.memberCount;
export const selectGroupHasMembers = createAppSelector(
  selectGroupNbAdminsOwners,
  selectGroupNbMembers,
  (c1, c2) => (c1 || 0) + (c2 || 0) > 0,
);

export const selectGroupAdminsOwners = createAppSelector(selectAllMembers, members =>
  members?.filter(gm => {
    const role = getRole(gm);
    return role && role !== 'member';
  }),
);

const memberToOrder = (gMember: GroupMember) => {
  let order = 0;
  const s = gMember.status as 'pending' | 'approved' | 'deleted';
  if (s === 'pending') {
    order += 100;
  } else if (s === 'approved') {
    order += 200;
  } else if (s === 'deleted') {
    order += 300;
  } else {
    order += 400;
  }
  const r = getRole(gMember);
  if (r === 'owner') {
    order += 1;
  } else if (r === 'admin') {
    order += 2;
  } else if (r === 'member') {
    order += 3;
  } else {
    order += 4;
  }
  return order;
};

function getRole(gMember: GroupMember) {
  return gMember.grade?.role as 'owner' | 'admin' | 'member' | undefined;
}

function sortByStatusAndRole(gMember1: GroupMember, gMember2: GroupMember) {
  return memberToOrder(gMember1) - memberToOrder(gMember2);
}

// For the case when an admin could promote other members to admin - currently not active
export const selectRoleDropdownOptions = createAppSelector(
  selectGroup,
  selectHasGroupOwnerPermissions,
  selectHasGroupAdminPermissions,
  (group, hasGroupOwnerPermissions, hasGroupAdminPermissions) =>
    (group?.gradeList?.grades || [])
      .filter(grade => userCanEditMember(hasGroupOwnerPermissions, hasGroupAdminPermissions, grade))
      .map(grade => ({
        value: grade?.id,
        label: grade?.label!,
      })),
);
