import { gql } from '@apollo/client';

import { AuthorFragment } from './thread';
import { TopicListItemFragment } from './topic';

export const quickSearchQuery = gql`
  query quickSearchQuery($search: String) {
    website {
      id
      name
      topicSearch(search: $search, page: 0, limit: 2) {
        topics {
          id
          subject
          slug
          categories {
            id
            slug
            title
          }
        }
      }
      messageSearch(search: $search, page: 1, limit: 2) {
        messages {
          id
          content
          topic {
            id
            subject
            slug
          }
          author {
            ...AuthorFragment
          }
        }
      }
      categorySearch(search: $search, page: 1, limit: 2) {
        categories {
          id
          title
          slug
        }
      }
    }
  }
  ${AuthorFragment}
`;

export const topicsSearchQuery = gql`
  query topicsSearchQuery(
    $search: String
    $page: Int
    $limit: Int
    $dateStart: GraphQLDateTime
    $dateEnd: GraphQLDateTime
    $skipContent: Boolean = false
  ) {
    website {
      id
      name
      topicSearch(
        search: $search
        page: $page
        limit: $limit
        dateStart: $dateStart
        dateEnd: $dateEnd
      ) {
        meta {
          count
          page
          limit
        }
        topics {
          id
          ...TopicListItemFragment
        }
      }
    }
  }
  ${TopicListItemFragment}
`;
