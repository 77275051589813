import type { MutableRefObject } from 'react';
import { useCallback, useEffect, useRef } from 'react';

// This hook is kind of a hack, because the Next.js native ways to scroll to a section when navigating don't seem to work well on this project. Don't hesitate to challenge if a refactoring is done.
export function useWaitForElement(elementId: string) {
  if (!elementId) {
    throw new Error(`Element ID required to wait for it. Received ${elementId}.`);
  }
  const animationFrameId = useRef<number>();
  const observer = useRef<IntersectionObserver>();

  useEffect(() => {
    // clean up the interval and observer when the component unmounts
    return () => stopWatching(animationFrameId, observer);
  }, []);

  return useCallback(() => _waitForElement(elementId, animationFrameId, observer), [elementId]);
}

// This function could be moved to a separate utility if we want to wait for an element to be available outside a React component.
// But the animationFrameId and observer are specific to a React component, so the logic will need to be refined a bit.
function _waitForElement(
  elementId: string,
  animationFrameId: MutableRefObject<number | undefined>,
  observer: MutableRefObject<IntersectionObserver | undefined>,
  timeout = 10000,
) {
  return new Promise<Element>((resolve, reject) => {
    const checkForElement = () => {
      const element = document.getElementById(elementId);
      if (element) {
        // element was found, stop watching and resolve the promise
        stopWatching(animationFrameId, observer);
        resolve(element);
      } else {
        animationFrameId.current = requestAnimationFrame(checkForElement);
      }
    };

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        // element became visible in the viewport, stop watching and resolve the promise
        stopWatching(animationFrameId, observer);
        resolve(entries[0].target);
      }
    });

    animationFrameId.current = requestAnimationFrame(checkForElement);

    // reject the promise after the timeout
    setTimeout(() => {
      stopWatching(animationFrameId, observer);
      reject(
        new Error(
          `Element with id "${elementId}" was not found within the timeout of ${timeout}ms`,
        ),
      );
    }, timeout);
  });
}

function stopWatching(
  animationFrameId: MutableRefObject<number | undefined>,
  observer: MutableRefObject<IntersectionObserver | undefined>,
) {
  if (animationFrameId.current != null) {
    cancelAnimationFrame(animationFrameId.current);
  }
  observer.current?.disconnect();
}
