import React, { createContext, memo, useCallback, useContext, useMemo } from 'react';
import type { PropsWithChildren } from 'react';

import type { CleanRoute, RoutesType } from '../utils/clean-routes-utils';
import { makeCleanRoute2 } from '../utils/clean-routes-utils';
import type { RouteConfig2 } from './useConnectedRouter';
import { useWebsite } from './useWebsite';

const cleanRoutesContext = createContext({
  cleanRoute: (route: RouteConfig2) => ({ href: route, as: route } as CleanRoute),
  getRoutes: () => ({
    home: '/',
  }),
});

export const CleanRoutesProvider: React.FC<PropsWithChildren<{ routes: RoutesType }>> = memo(
  function CleanRoutesProvider({ children, routes }) {
    const { key } = useWebsite();
    const cleanRoute = useCallback(
      (urlObject: RouteConfig2 /* SimplifiedUrlObject */) =>
        makeCleanRoute2(routes, urlObject, key),
      [key, routes],
    );
    const getRoutes = useCallback(() => routes, [routes]);

    // Memo to ensure the reference of the wrapper object doesn't change as long as the 2 methods don't change.
    const val = useMemo(() => ({ cleanRoute, getRoutes }), [cleanRoute, getRoutes]);

    return <cleanRoutesContext.Provider value={val}>{children}</cleanRoutesContext.Provider>;
  },
);

export const useCleanRoutes = () => useContext(cleanRoutesContext);
