import React, { useContext, useEffect } from 'react';

import { useId } from './useId';

export type TBodyModifier = {
  id: string;
  preventScroll?: boolean;
  preventScrollMobile?: boolean;
};

export const bodyContext = React.createContext<{
  setBodyModifier: (modifier: TBodyModifier) => void;
  removeBodyModifier: (modifier: { id: string }) => void;
}>({
  setBodyModifier: () => undefined,
  removeBodyModifier: () => undefined,
});

export const BodyModifierProvider = bodyContext.Provider;

export const useBodyModifier = (
  values: React.HTMLAttributes<HTMLBodyElement> & {
    preventScroll?: boolean | undefined;
    preventScrollMobile?: boolean | undefined;
  },
) => {
  const { setBodyModifier, removeBodyModifier } = useContext(bodyContext);
  const bodyModifier = useId();

  useEffect(() => {
    const id = bodyModifier;
    setBodyModifier({ id, ...values });
  }, [bodyModifier, setBodyModifier, values]);

  useEffect(() => {
    const id = bodyModifier;
    return () => removeBodyModifier({ id });
  }, [removeBodyModifier, bodyModifier]);
};
