import React from 'react';

export const removeLinkFormatter = (
  elt?: React.ExoticComponent | React.ReactElement | React.ReactNode,
) => {
  if (
    !(elt as React.ExoticComponent)?.$$typeof ||
    (elt as React.ReactElement)?.type !== 'a' ||
    !(elt as React.ReactElement)?.props?.children
  )
    return elt;

  return <>{(elt as React.ReactElement).props.children}</>;
};
