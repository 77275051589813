import type { ReactNode } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { Avatar } from '../../components/Avatar';
import { BulletSeparator } from '../../components/BulletSeparator';
import { Button } from '../../components/Button/Button';
import { IconClose, IconTwitterX } from '../../components/icons/Icons';
import { Link } from '../../components/Link';
import { useProfile } from '../../hooks/useProfile';
import { isMobile } from '../../utils/web';
import type { RequestFriendCallback } from '../api/relation';
import { profileInfosMessages } from './messages';
import { GradeBadge } from './ProfileData/GradeBadge';
import { ProfilePodiumRewards } from './ProfilePodiumRewards';
import { ProfileRelationActions } from './ProfileRelations/ProfileRelationActions';

const messages = defineMessages({
  goToProfilePage: { defaultMessage: 'Voir le profil complet', id: 'KtGw+K' },
});

const FooterItem = ({ children, className }: { children: ReactNode; className?: string }) => (
  <span className={twMerge('text-xs text-gray-semiDark2 font-source tracking-4', className)}>
    {children}
  </span>
);

/**
 *
 * @param userId
 * @param controller
 * @constructor
 */
export const ProfilePopoverContent = ({
  userId,
  controller,
  inviteCallback,
  noCloseButton,
}: {
  userId?: string;
  controller?: { hide: () => void };
  inviteCallback?: RequestFriendCallback;
  noCloseButton?: boolean;
}) => {
  const { profile } = useProfile({ userId });
  const currentUserId = profile?.user?.id as string;

  if (!profile) return null;

  const hasTwitterOrMedia = profile.twitterUsername;

  const isMob = isMobile();

  return (
    <div className="ProfilePopoverContent flex flex-col gap-y-4 relative rounded-lg md:min-w-[430px] lg:p-7">
      {!isMob && !noCloseButton && (
        <div className="absolute top-0 right-0">
          <Button variant="icon" className="no-underline" onClick={controller?.hide}>
            <IconClose />
          </Button>
        </div>
      )}

      <div className="flex justify-center gap-x-5">
        <div className="flex flex-col items-center gap-y-[10px]">
          <Avatar profile={profile} picture={profile.picture} size="big" legacyImage />
          <ProfilePodiumRewards profile={profile} />
        </div>

        <div className="flex flex-col flex-1  gap-y-[10px] min-w-0">
          <div className="profile_contactInfos">
            <GradeBadge profile={profile} className="self-start" />
            <div className={twMerge('flex items-center gap-x-2 h-8')}>
              <h2 className="text-[18px] leading-[18px] font-semibold capitalize text-black truncate mt-0.5">
                {profile?.pseudo}
              </h2>
              <ProfileRelationActions
                currentUserId={currentUserId}
                relationId={profile.relation?.id as string}
                status={profile.relation?.status as string}
                profile={profile}
                relation={profile?.relation}
                inviteCallback={inviteCallback}
                handleClose={controller?.hide}
                hideRemoveFriendButton
              />
            </div>

            {/* <StatusText profile={profile} className="pr-4" /> */}

            {/* The wireframes don't include the twitter username here. But let's keep the code in case we want to restore it. */}
            {/* {profile.twitterUsername && (
            <div className="flex items-center my-1 text-secondary-base prose-info">
              <TwitterBirdIcon />
              <span className="text-base ml-2">{profile.twitterUsername}</span>
            </div>
          )} */}

            <div className="text-[12px] text-gray-semiDark2">
              <FormattedMessage
                {...profileInfosMessages.inscriptionDate}
                values={{ createdAt: new Date(profile.createdAt ?? 0) }}
              />
            </div>
          </div>

          {hasTwitterOrMedia && (
            <div className="profile_medias">
              {profile.twitterUsername && (
                <div className="profile_twitter text-[#1DA1F2] flex items-center gap-x-2">
                  <IconTwitterX className="w-[18px] h-4" />
                  <div className="text-[14px] leading-[18px]">{profile.twitterUsername}</div>
                </div>
              )}
            </div>
          )}

          <div className="profile_bio">
            {profile?.bio ? (
              <div
                className="my-1 prose overflow-y-auto max-h-50 text-black text-[14px] pr-4 [word-break:break-word]"
                dangerouslySetInnerHTML={{ __html: profile?.bio }}
              />
            ) : (
              <div className="my-1 italic prose overflow-y-auto max-h-50 text-black text-[14px] pr-4">
                <FormattedMessage {...profileInfosMessages.missingBio} />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="profile_summary flex items-baseline justify-around gap-x-2 text-center">
        <Link to="profile" params={{ pseudo: profile.pseudo, tab: 'pages' }}>
          <a>
            <FooterItem className="hover:text-primary">
              <FormattedMessage
                {...profileInfosMessages.messagesCount}
                values={{ count: profile.meta?.countCommentAndMessage ?? 0 }}
              />
            </FooterItem>
          </a>
        </Link>
        <BulletSeparator />
        <FooterItem>
          <FormattedMessage
            {...profileInfosMessages.voteSendedCount}
            values={{ count: profile.meta?.voteSended ?? 0 }}
          />
        </FooterItem>
        <BulletSeparator />
        <FooterItem>
          <FormattedMessage
            {...profileInfosMessages.voteReveivedCount}
            values={{ count: profile.meta?.voteReceived ?? 0 }}
          />
        </FooterItem>
      </div>

      <div className="border-t border-gray-lighter flex justify-center">
        {profile.pseudo && (
          <Link to="profile" params={{ pseudo: profile.pseudo }}>
            <a className="flex-1 rounded-sm bg-gray-60 hover:bg-gray-200  px-3 py-2 outline-none focus:outline-none no-underline text-sm text-center cursor-pointer">
              <FormattedMessage {...messages.goToProfilePage} />
            </a>
          </Link>
        )}
      </div>
    </div>
  );
};
