import classNames from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { useWebsite } from '../../hooks/useWebsite';
import type { AvatarPropsType, AvatarSizes } from '../Avatar';
import { Avatar } from '../Avatar';
import { sizesToClassName } from '../Avatar-const';
import type { UseGraphFieldArgument } from './useGraphField';
import { useGraphField } from './useGraphField';

const messages = defineMessages({
  edit: {
    defaultMessage: 'Modifier mon avatar',
    id: 'ZOqx04',
  },
});

type AvatarFieldProps = {
  defaultPicture?: string;
  className?: string;
  size?: AvatarSizes;
} & Omit<AvatarPropsType, 'picture' | 'className'> &
  UseGraphFieldArgument<File>;

export const AvatarField = ({ name, defaultPicture, className, ...props }: AvatarFieldProps) => {
  const [picture, setPicture] = useState(defaultPicture);
  const { input } = useGraphField<File, HTMLInputElement>({ name });
  const pictureInputRef = useRef<HTMLInputElement>();
  const isPendingPictureUpload = picture?.startsWith('data:');

  const { graphdebate } = useWebsite();
  const ssoOptions = graphdebate?.sso;
  const isAvatarHandledBySso = ssoOptions?.active && ssoOptions?.manageAvatar;

  const onAvatarChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;

      if (!files || !files.length) return;

      input.onChange(files[0]);
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) setPicture(reader.result.toString());
      };
      reader.readAsDataURL(files[0]);
    },
    [input],
  );

  const clickInput = useCallback(
    () => pictureInputRef.current && pictureInputRef.current.click(),
    [pictureInputRef],
  );

  return (
    <div
      className={classNames(
        'avatarContainer relative mx-auto',
        props.size ? sizesToClassName[props.size] : 'w-24 h-24',
        className,
      )}
    >
      <Avatar {...props} picture={picture} className={classNames(!props.size && 'w-full h-full')} />
      {!isAvatarHandledBySso && (
        <>
          <input
            type="file"
            tabIndex={-1}
            ref={pictureInputRef as React.RefObject<HTMLInputElement>}
            onChange={onAvatarChange}
            className="w-0 h-0 opacity-0 pointer-events-none"
            accept=".png,.jpg,.jpeg,.gif"
          />
          <button type="button" className="group w-0 h-0" onClick={clickInput}>
            <div
              className={classNames(
                'transition-opacity absolute rounded-full top-0 left-0 flex items-center p-1 pt-2 text-white prose-caption font-bold justify-center drop-shadow-[0px_0px_1px_#000000] w-full h-full group-hover:bg-gray-900/20',
                !isPendingPictureUpload && 'bg-gray-900/10',
                isPendingPictureUpload && 'opacity-0 group-hover:opacity-100',
                props.size ? sizesToClassName[props.size] : 'w-full h-full',
              )}
              style={{ textShadow: !isPendingPictureUpload ? '0 0 15px black' : undefined }}
            >
              <FormattedMessage {...messages.edit} />
            </div>
          </button>
        </>
      )}
    </div>
  );
};
