import type { FC, PropsWithChildren } from 'react';
import { memo } from 'react';
import { twMerge } from 'tailwind-merge';

import { CSSTransition } from '../../components/Transition';
import type { StatusError } from '../../hooks/useRouteTransitions';
import type { LayoutBlock } from '../../types/layout';
import { ErrorLayout } from './ErrorLayout';

type CommonLayoutContainerProps = {
  area: LayoutBlock['locate'];
  className?: string;
  children?: React.ReactNode;
};

export type LayoutAsideContainerProps = CommonLayoutContainerProps & {
  isSlidableContainer?: boolean;
};

export type LayoutContainerProps = CommonLayoutContainerProps | LayoutAsideContainerProps;

interface LayoutMainContentProps {
  error: StatusError | null;
}

export const LayoutMainContent: FC<PropsWithChildren<LayoutMainContentProps>> = memo(
  function LayoutMainContent(props) {
    const { children: page, error } = props;

    return (
      <>
        {error && <ErrorLayout error={error} />}

        {!error && (
          <CSSTransition timeout={300}>
            {/* min-width: 0 is required with flex: 1 to ensure the div does not exceed the space available from the parent and its siblings (e.g. right panel). */}
            <div className="LayoutMainContent flex-1 min-w-0">{page}</div>
          </CSSTransition>
        )}
      </>
    );
  },
);

export const AsideContainer = memo(function AsideContainer(props: LayoutAsideContainerProps) {
  const { className, children, ...otherProps } = props;

  return (
    <aside
      className={twMerge('AsideContainer md:max-w-1/3 md:w-[300px] md:items-end pb-2', className)}
      {...otherProps}
    >
      {children}
    </aside>
  );
});

export const HeaderContainer = memo(function HeaderContainer(props: LayoutContainerProps) {
  const { className, children } = props;
  return (
    <header
      className={twMerge(
        'HeaderContainer dynamicAreaBlocks container flex flex-col h-[100px] mb-4',
        className,
      )}
    >
      {children}
    </header>
  );
});

export const FooterContainer = memo(function FooterContainer(props: LayoutContainerProps) {
  return <footer className="FooterContainer min-w-[4rem]" {...props} />;
});
