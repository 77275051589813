import { defineMessages } from 'react-intl';

export const chatMessages = defineMessages({
  // action to close inbox
  closeInbox: { defaultMessage: 'Fermer la discussion', id: 'DoKbIN' },
  reduceInbox: { defaultMessage: 'Réduire la discussion', id: 'ZgRxDy' },
  openInbox: { defaultMessage: 'Ouvrir la discussion', id: 'ZW815n' },
  createInbox: { defaultMessage: 'Créer la conversation', id: '5cn3wp' },
  addFriend: { defaultMessage: 'Ajouter des amis à la discussion', id: 'kfR458' },
  addOneFriendPlaceholder: { defaultMessage: 'Ajouter un ami', id: 'F3AkTA' },
  newInboxSubTitleFallback: { defaultMessage: 'nouvelle conversation', id: '9KP7F/' },
  noInboxYet: { defaultMessage: "vous n'avez pas encore échangé", id: 'aj4frn' },
  inboxTitle: { defaultMessage: 'Discussion', id: '20bImY' },
  newInboxTitle: { defaultMessage: 'Nouveau message', id: 'e1rfKR' },
  to: { defaultMessage: 'À :', id: '0KH7J2' },
  invalidChatInputPlacholder: { defaultMessage: 'Échange indisponible', id: 'Jrhr3+' },
  chatInputPlacholder: { defaultMessage: 'Écrivez un message ici', id: 'f6jCwC' },
  noFriends: { defaultMessage: 'Aucun ami correspondant', id: 'xW6Kvu' },
});
