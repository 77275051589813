import { cloneElement } from 'react';

import { emojiToImage } from '../../../../utils/emoji';
import { IGNORE_PROP } from './common';
import { stringReplacer } from './stringFormatter';

export function emojiFormatter(str: string) {
  return stringReplacer({
    str,
    regex: new RegExp(
      '([\ud83c-\ud83e][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u2639|\ue50a|\u27b0|\u2797|\u2796|\u2795|\u2755|\u2754|\u2753|\u274e|\u274c|\u2728|\u270b|\u270a|\u2705|\u26ce|\u23f3|\u23f0|\u23ec|\u23eb|\u23ea|\u23e9|\u27bf|\u00a9|\u00ae|(\u3299|\u303d|\u3030|\u2b55|\u2b50|\u2b1c|\u2b1b|\u2b07|\u2b06|\u2b05|\u2935|\u2934|\u27a1|\u2764|\u2757|\u2747|\u2744|\u2734|\u2733|\u2716|\u2714|\u2712|\u270f|\u270c|\u2709|\u2708|\u2702|\u26fd|\u26fa|\u26f5|\u26f3|\u26f2|\u26ea|\u26d4|\u26c5|\u26c4|\u26be|\u26bd|\u26ab|\u26aa|\u26a1|\u26a0|\u2693|\u267f|\u267b|\u3297|\u2666|\u2665|\u2663|\u2660|\u2653|\u2652|\u2651|\u2650|\u264f|\u264e|\u264d|\u264c|\u264b|\u264a|\u2649|\u2648|\u263a|\u261d|\u2615|\u2614|\u2611|\u260e|\u2601|\u2600|\u25fe|\u25fd|\u25fc|\u25fb|\u25c0|\u25b6|\u25ab|\u25aa|\u24c2|\u231b|\u231a|\u21aa|\u21a9|\u2199|\u2198|\u2197|\u2196|\u2195|\u2194|\u2139|\u2122|\u2049|\u203c|\u2668)([\uFE0E\uFE0F]))',
      'g',
    ),
    factory: chunk =>
      cloneElement(
        <span
          className="emoji"
          style={{ backgroundImage: `url(${emojiToImage((chunk ?? '').toString())})` }}
        >
          ${chunk}
        </span>,
        { [IGNORE_PROP]: true },
      ),
  });
}
