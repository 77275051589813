import { useWebsite } from '../../../../front/src/hooks/useWebsite';
import { GraphLoginConnectButton } from './login/GraphLoginConnectButton';
import { SsoConnectButton } from './login/SsoConnectButton';

const isNmSsoTest = false;

export const HeaderLogin = () => {
  const { graphdebate } = useWebsite();
  const isSsoActive = graphdebate?.sso?.active || isNmSsoTest;

  return isSsoActive ? <SsoConnectButton sso={graphdebate?.sso!} /> : <GraphLoginConnectButton />;
};
