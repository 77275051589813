import type { OperationVariables } from '@apollo/client/core/types';
import type { QueryOptions } from '@apollo/client/core/watchQueryOptions';
import { useApolloClient } from '@apollo/client/react/hooks/useApolloClient';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { frontConfig } from '../config';
import type { QueryType } from '../types/api';
import { isApolloErrorAbort } from '../utils/graphql';
import usePrevious from './usePrevious';
import { useRefLatest } from './useRefLatest';

// Idea of feature to implement: when the variables change, refetch. I think useQuery works this way. I would need to review all usages that use refetch, that potentially already implment this behavior.
// I could also add an option "refetchOnAuthChanges" that is combined with variable changes to know when to refetch.

interface UseQueryCallbackArgsObj
  extends Omit<QueryOptions<OperationVariables, QueryType>, 'fetchPolicy'> {
  onStartLoading?: VoidFunction;
  onResponse?: (data: QueryType) => void;
  onError?: (error: any) => void;
  /** The default behavior is to fetch once, at the first component rendering. If `skipInitialFetch` is true, this initial fetch is skipped, and nothing will be fetched until you call `refetch`. In any cases, you can `refetch` later. */
  skipInitialFetch?: boolean;
  /** A convenience to provide an initial value for this query that would be immediately dispatched through onResponse. Useful for SSR that may provide an initial value, and falling back to client fetching if not available. If initialData is provided, the initial fetch is always skipped and `skipInitialFetch` has no effect. */
  initialData?: QueryType | null;
  /** If you provide `initialData` but still want to initially fetch, set `forceInitialFetch` to true. Useful for SSR if the client query is different from the server query (token, variables...) */
  forceInitialFetch?: boolean;
  disableRefetchOnVarChange?: boolean;
  debugLabel?: string;
  debug?: boolean;
}

export type UseQueryCallbackArgs = UseQueryCallbackArgsObj | (() => UseQueryCallbackArgsObj);

export type UseQueryCallbackRefetch = (args2?: Partial<UseQueryCallbackArgsObj>) => Promise<void>;

/**
 * Home-made useQuery-like hook to fetch API data, exposing loading, error and refetch.
 * The major difference is that it does not return the data in the component, to avoid re-rendering the component each time the data changes. Instead, it exposes an onResponse callback you can use to dispatch to redux (or set the component state with useState, if you really want to).
 * @param args like useQuery() or client.query() args, with extra callbacks: onStartLoading, onError, onResponse, and extra options. Please refer to {@link UseQueryCallbackArgsObj} for more explanations on those properties.
 * @returns You typically want to use loading, error and refetch. initialData is for the case when data can exist in the Apollo cache (e.g. from getServerSideProps), and for SSR, you need to have all data displayed at the initial rendering. After the initial rendering, initialData will always be undefined (by design, but could change).
 * @see {@link UseQueryCallbackArgsObj}
 */
export function useQueryCallback(args: UseQueryCallbackArgs) {
  const argsObj = useMemo(() => (typeof args === 'function' ? args() : args), [args]);
  const argsRef = useRefLatest(argsObj);
  const shouldRefetchOnApolloReset = !argsObj.skipInitialFetch;

  const client = useApolloClient();

  const hasCachedResultRef = useRef<boolean | null>(null);

  // On the first render, initialData is returned (undefined on subsequent renders) and onResponse is called with these data.
  let initialData: QueryType | undefined | null = argsObj.initialData;
  if (initialData) {
    argsObj.skipInitialFetch = !argsObj.forceInitialFetch;
  } else if (!argsObj.skipInitialFetch && hasCachedResultRef.current == null) {
    const cachedData = client.readQuery(argsObj);

    hasCachedResultRef.current = !!cachedData;

    if (cachedData) {
      argsObj.skipInitialFetch = true;
      initialData = cachedData;
    }
  }
  const initialDataRef = useRefLatest(initialData);
  useEffect(() => {
    if (initialDataRef.current) {
      argsRef.current?.onResponse?.(initialDataRef.current);
    }
  }, [argsRef, initialDataRef]);

  const [loading, setLoading] = useState(
    !argsObj.skipInitialFetch &&
      (!hasCachedResultRef.current || !!argsObj.notifyOnNetworkStatusChange),
  );
  const [error, setError] = useState<any>(undefined);

  const aborterRef = useRef<AbortController | null>(null);

  const refetch = useCallback<UseQueryCallbackRefetch>(
    async function refetch(args2?: Partial<UseQueryCallbackArgsObj>) {
      // If calling refetch a 2nd time before the 1st query completes, the 1st query is cancelled.
      if (frontConfig.enableAbortQuery) {
        aborterRef.current?.abort();
        aborterRef.current = new AbortController();
      }

      // Merge (shallow) the initial arguments with the refetch arguments, with a special treatment for variables that are also merged (shallow).
      const _args2 = args2;
      const _args = argsRef.current;
      const { variables: prevVariables } = _args || {};
      const { variables: newVariables } = _args2 || {};
      const mergedArgs: UseQueryCallbackArgsObj & { fetchPolicy: QueryOptions['fetchPolicy'] } = {
        ..._args,
        ..._args2,
        variables: { ...prevVariables, ...newVariables },
        ...(!!aborterRef.current && {
          context: {
            fetchOptions: {
              signal: aborterRef.current.signal,
            },
          },
        }),
        fetchPolicy: 'no-cache',
      };
      try {
        setLoading(true);
        setError(undefined);
        mergedArgs?.onStartLoading?.();

        // Big hack: consume the gql API as we would with a traditional REST API. In this project,
        // useQuery, the Apollo cache, data merge and the refetch have a highly unpredictable behavior.
        // To have a working "fetch more on scroll" feature, we bypass all of that and add the fetched
        // members to a map on redux.
        // When writing this comment, the API also has a bug: each request returns members in a random order.
        // Using a map in redux allows to dedupe.
        const result = await client.query<QueryType>(mergedArgs);
        if (result.error) throw result.error;
        setLoading(false);
        mergedArgs?.onResponse?.(result.data);
        // Here, set the aborter to null? Or in finally{}?
      } catch (err) {
        if (isApolloErrorAbort(err)) return;
        setError(err);
        setLoading(false);
        mergedArgs?.onError?.(err);
      }
    },
    [argsRef, client],
  );

  // Initial fetch, when not disabled by the options.
  // It also refetches in case of route change (pathname, using asPath to check changes) or hot reload.
  const initiallyFetchedRef = useRef(false);
  const { asPath } = useRouter();
  const asPathPrev = usePrevious(asPath);
  useEffect(
    function uqcPathChangeEffect() {
      if (
        (asPath !== asPathPrev || !initiallyFetchedRef.current) &&
        !argsRef.current.skipInitialFetch
      ) {
        initiallyFetchedRef.current = true;

        refetch();
      }
    },
    [argsRef, asPath, asPathPrev, refetch],
  );

  // Watch for changes in the variables fields and trigger refetch
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (argsRef.current.disableRefetchOnVarChange) {
      return;
    }

    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, Object.values(argsObj.variables || []));

  // Special case: when the store is reset, we refetch.
  // Apollo refetches all queries (useQuery and watchQuery) on reset store. We do the same here.
  // But only for queries that immediately fetch with the hook. We assume the async ones that are triggered on demand should not be auto-refetched. If this behavior is not correct, we may introduce another option to alter this behavior.
  useEffect(
    function uqcApolloResetEffect() {
      if (shouldRefetchOnApolloReset) {
        return client.onResetStore(refetch);
      }
      return undefined;
    },
    [argsRef, client, refetch, shouldRefetchOnApolloReset],
  );

  // Clean-up refs for HMR. I find it weird, but on reload with HMR, it calls all useEffects (clean-up functions, then normal effect function, as if the component was re-mounted), but it does NOT reset the refs. If hasCachedResultRef is not reset, it causes a bug when changing some code in thread-slice.ts (outside React components) while on the yakli page. The issue: the initialData check at the beginning of the function is not re-run. It causes the redux state not to be re-filled.
  useEffect(
    () => () => {
      hasCachedResultRef.current = null;
      initiallyFetchedRef.current = false;
      aborterRef.current = null;
    },
    [],
  );

  return useMemo(
    () => ({ loading, error, refetch, initialData }),
    [error, initialData, loading, refetch],
  );
}
