import classNames from 'classnames';
import type { FC } from 'react';
import { memo } from 'react';

import type { AvatarSizes } from '../components/Avatar';
import type { RequestFriendCallback } from '../contributor/api/relation';
import { InviteMessage } from '../contributor/Profile/ProfileData/InviteMessage';
import type { Author, Profile } from '../types/api';
import { AuthorAvatar } from './AuthorAvatar';
import { AuthorPseudo } from './AuthorPseudo';

interface Props {
  author: Author | undefined;
  userId: string | undefined;
  profile: Profile | undefined;
  skipAvatar?: boolean;
  firstChild?: boolean;
  center?: boolean;
  className?: string;
  size?: AvatarSizes;
  withInvite?: boolean;
  inviteCallback?: RequestFriendCallback;
  isGroup?: boolean;
}

export const AuthorAvatarPseudo: FC<Props> = memo(function AuthorAvatarPseudo(props) {
  let {
    author,
    userId,
    profile,
    skipAvatar,
    // firstChild,
    center,
    className,
    size,
    withInvite,
    inviteCallback,
    isGroup,
  } = props;
  if (!profile && !author) return null;

  let pseudoElt = (
    <AuthorPseudo
      author={author}
      userId={userId}
      profile={profile}
      withProfilePopover
      containerClassName="inline truncate"
      withStatusBadge
      inviteCallback={inviteCallback}
      isGroup={isGroup}
    />
  );
  if (withInvite) {
    if (profile?.relation) {
      pseudoElt = (
        <div className="flex-1 truncate">
          {pseudoElt}
          <div className="prose-base truncate">
            <InviteMessage profile={profile} relation={profile.relation} />
          </div>
        </div>
      );
    }
  }
  return (
    <div
      className={classNames(
        'inline-flex gap-2',
        center ? 'items-center' : 'items-baseline',
        className,
        isGroup && 'flex flex-1 min-w-0',
      )}
    >
      {!skipAvatar && (
        <AuthorAvatar
          author={author}
          userId={userId}
          profile={profile}
          withProfilePopover
          smallAvatar
          containerClassName="self-center"
          size={size}
          inviteCallback={inviteCallback}
          isGroup={isGroup}
        />
      )}
      {isGroup ? (
        <div className="flex flex-col flex-1 min-w-0">
          {pseudoElt}
          <div className="truncate text-sm mb-2">{profile?.proStatus}</div>
          <div
            className="line-clamp-2 text-gray-500"
            dangerouslySetInnerHTML={{ __html: profile?.bio || '' }}
          ></div>
        </div>
      ) : (
        pseudoElt
      )}
    </div>
  );
});
