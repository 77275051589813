// Separate file to ensure Avatar.tsx only exports one thing, the component, so that hot reload works well.
export const sizesToClassName = {
  small: 'w-4 h-4',
  small2: 'w-6 h-6',
  medium: 'w-8 h-8',
  medium1: 'w-9 h-9',
  medium2: 'w-10 h-10',
  medium3: 'w-13 h-13',
  big: 'w-16 h-16',
  vbig: 'w-20 h-20',
  vvbig: 'w-24 h-24',
  group: 'w-[685px] h-[150px]',
  full: undefined,
};
export const sizesToPixels = {
  small: { w: 4 * 4, h: 4 * 4 },
  small2: { w: 6 * 4, h: 6 * 4 },
  medium: { w: 8 * 4, h: 8 * 4 },
  medium1: { w: 9 * 4, h: 9 * 4 },
  medium2: { w: 10 * 4, h: 10 * 4 },
  medium3: { w: 13 * 4, h: 13 * 4 },
  big: { w: 16 * 4, h: 16 * 4 },
  vbig: { w: 20 * 4, h: 20 * 4 },
  vvbig: { w: 24 * 4, h: 24 * 4 },
  group: { w: 685, h: 150 },
};
