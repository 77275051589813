import type { ReactNode } from 'react';
import React from 'react';

export const stringReplacer = ({
  str,
  regex,
  factory,
}: {
  str: ReactNode;
  regex: RegExp;
  factory: (str: string) => React.ReactNode;
}): React.ReactNode => {
  if (!str || typeof str !== 'string') return str ?? null;

  const execution = regex.exec(str);

  if (!execution) return str;

  const chunk = execution[0];

  return (
    <>
      {str.slice(0, execution.index) || null}
      {factory(chunk)}
      {str.slice(execution.index + chunk.length) || null}
    </>
  );
};
