import { memo } from 'react';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as IconCommentsBubbleBlue } from '../../../../icons/icon-comments-blue-double.svg';
import { ReactComponent as IconCommentsBubbleGrey } from '../../../../icons/icon-comments-outline-grey2.svg';
import type { Topic } from '../../../../types/api';
import { getVoteIcon } from '../../../Vote/icons';
import { TopicItemLastMessageBlock } from './TopicItemLastMessageBlock';

type TopicItemInteractionsType = {
  topic: Topic;
  className?: string;
  isResponsive?: boolean;
  isSidebar?: boolean;
};

const TopicItemMessages = memo(function TopicItemMessages({
  messagesCount,
  isResponsive = true,
  isSidebar = false,
}: Pick<Topic, 'messagesCount'> & { isResponsive?: boolean; isSidebar?: boolean }) {
  return messagesCount ? (
    <div
      className={twMerge(
        'topic-messages flex items-center text-gray-soft gap-x-1 text-[13px]',
        isResponsive && 'md:gap-x-2 md:text-[15px]',
        isSidebar && 'text-[13px] text-gray-400',
      )}
    >
      {!isSidebar ? (
        <IconCommentsBubbleGrey
          className={twMerge('h-3 w-3', isResponsive && 'md:h-[18px] md:w-[18px]')}
        />
      ) : (
        <IconCommentsBubbleBlue className="h-4 w-4" />
      )}
      {messagesCount}
    </div>
  ) : null;
});

const TopicItemVotes = memo(function TopicItemVotes({
  votes,
  isResponsive = true,
}: Pick<Topic, 'votes'> & { isResponsive?: boolean }) {
  if (votes?.length) {
    const mainScores = Object.entries(
      votes.reduce(
        (prev, vote) => ({
          ...prev,
          [`${vote?.score}`]: (prev[`${vote?.score}`] || 0) + 1,
        }),
        {} as Record<string, number>,
      ),
    )
      .map(([score]) => parseInt(score, 10))
      .slice(0, 3);

    return (
      <div className="topic-votes flex items-center gap-1 md:gap-2">
        <div className="flex [&>*+*]:ml-[-5px]">
          {mainScores.map((score, index) => (
            <div
              key={index}
              className={twMerge(
                'flex justify-center items-center bg-white p-[3px] rounded-full',
                isResponsive && '_md:h-[15px] _md:w-[15px]',
              )}
            >
              {getVoteIcon(score)}
            </div>
          ))}
        </div>
        <div className={twMerge('text-gray-soft text-[13px]', isResponsive && 'md:text-[15px]')}>
          {votes.length}
        </div>
      </div>
    );
  }
  return null;
});

export const TopicItemInteractions = memo(function TopicItemInteractions({
  topic,
  className,
  isResponsive = true,
  isSidebar = false,
}: TopicItemInteractionsType) {
  const { messagesCount, votes } = topic;
  const shouldDisplay = messagesCount || votes?.length;

  return shouldDisplay ? (
    <div
      className={twMerge(
        'TopicItemInteractions flex items-center gap-x-3 mt-[5px]',
        isResponsive && 'md:gap-x-5 md:mt[10px]',
        className,
      )}
    >
      <TopicItemMessages
        messagesCount={messagesCount}
        isResponsive={isResponsive}
        isSidebar={isSidebar}
      />
      <TopicItemVotes votes={votes} isResponsive={isResponsive} />
      <TopicItemLastMessageBlock
        topic={topic}
        className={isResponsive ? 'md:hidden flex items-center' : ''}
        isResponsive={isResponsive}
        isSidebar={isSidebar}
        renderInline
      />
    </div>
  ) : null;
});
