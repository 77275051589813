import type React from 'react';
import { Children, cloneElement } from 'react';

import { IGNORE_PROP } from './common';

export const recursiveFormatElement = ({
  element,
  format,
}: {
  format: (el: React.ReactNode) => React.ReactElement;
  element: React.ReactElement;
}): React.ReactNode => {
  if ((element?.props ?? {})[IGNORE_PROP] || element?.type === 'code') return element;

  const formattedElement = format(element);

  const children = formattedElement?.props?.children;

  if (!children) return formattedElement;

  return cloneElement(
    formattedElement,
    formattedElement.props,
    Children.map(children, child =>
      recursiveFormatElement({
        element: child,
        format,
      }),
    ),
  );
};
