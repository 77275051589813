import type { QueryHookOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { FullProfileFragment } from '../services/graphql-queries';
import type { Profile, QueryType } from '../types/api';

export const FullProfileQuery = gql`
  query FullProfileQuery($pseudo: String, $userId: ID) {
    profile(pseudo: $pseudo, userId: $userId) {
      id
      ...FullProfileFragment
    }
  }
  ${FullProfileFragment}
`;
export const useProfile = ({
  pseudo,
  userId,
}: {
  pseudo?: string;
  userId?: string;
} = {}) => {
  const options = useMemo<QueryHookOptions>(
    () => ({
      // @FIX use cache-first once the API is running OK
      // fetchPolicy: frontConfig.disableCache ? 'network-only' : 'cache-and-network',
      // If network, it fetches many times while scrolling in the comments of a topic.
      fetchPolicy: 'cache-first',
      variables: { pseudo, userId },
      skip: !pseudo && !userId,
    }),
    [pseudo, userId],
  );
  const { data, loading, error } = useQuery<QueryType>(FullProfileQuery, options);

  return { profile: data?.profile as Partial<Profile>, loading, error };
};
