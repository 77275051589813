import type { Reference } from '@apollo/client';
import { gql } from '@apollo/client';
import { useCallback } from 'react';

import { useAppApolloClient } from '../../hooks/useAppApolloClient';
import axios from '../../services/axios';
import { FullProfileFragment } from '../../services/graphql-queries';
import type { Maybe, Relation, RelationSearch } from '../../types/api';
import type { AppApolloClient } from '../../types/common-models';
import type { FriendActionType } from '../../types/friends';

export const tmpUpdateFriendRelationFragment = gql`
  fragment tmpUpdateFriendRelationFragment on Relation {
    id
    status
  }
`;
export const tmpUpdateProfileRelationFragment = gql`
  fragment tmpUpdateProfileRelationFragment on Profile {
    id
    relation {
      status
    }
  }
`;

const tmpCreateFriendFragment = gql`
  fragment tmpCreateFriendFragment on Relation {
    id
    status
    guest {
      id
    }
    member {
      id
    }
    author {
      id
    }
  }
`;

export interface RequestFriendResponse {
  author: string;
  created_at: string;
  delete: boolean;
  guest: string;
  message: string;
  relation: string[];
  status: string;
  updated_at: string;
  website: string;
  __v: number;
  _id: string;
}

export type RequestFriendCallback = (response: RequestFriendResponse) => void;

export const requestFriend = async (
  {
    userId,
    profileId,
    message,
    authUserId,
    authProfileId,
  }: {
    userId: string;
    profileId: string;
    message: string;
    authUserId: string;
    authProfileId: string;
  },
  apolloClient: AppApolloClient,
) => {
  const { data } = await axios.post<RequestFriendResponse>('/api/relation', {
    user_id: userId,
    message,
  });
  const { _id: newRelationId, guest: newFriendId } = data ?? {};
  const client = apolloClient as AppApolloClient;

  if (client && authUserId && authProfileId && newRelationId) {
    client.cache.modify({
      id: `Profile:${authProfileId}`,
      fields: {
        relationSearch(
          existing: RelationSearch = { meta: { count: 0 }, relations: [] },
          { readField },
        ) {
          const newRelationRef = client.cache.writeFragment<Relation>({
            id: `Relation:${newRelationId}`,
            data: {
              __typename: 'Relation',
              id: newRelationId,
              status: 'pending',
              author: { id: authUserId },
              ...(newFriendId
                ? {
                    guest: { id: newFriendId },
                    member: { id: newFriendId },
                  }
                : {}),
            },
            fragment: tmpCreateFriendFragment,
          });

          return (existing?.relations ?? []).some(
            ref =>
              readField('id', ref as Reference) === newRelationId &&
              readField('status', ref as Reference) === 'pending',
          )
            ? existing
            : {
                relations: [...(existing?.relations ?? []), newRelationRef],
                meta: { ...(existing?.meta ?? {}), count: (existing?.meta?.count ?? 0) + 1 },
              };
        },
      } as any,
    });

    client.writeFragment({
      id: `Profile:${profileId}`,
      fragment: tmpUpdateProfileRelationFragment,
      data: {
        id: profileId,
        status: 'pending',
        relation: {
          status: 'pending',
        },
      },
    });
  }

  return data;
};

export const userRelationsQuery = gql`
  query userRelationsQuery($search: String, $status: String, $page: Int, $limit: Int) {
    me {
      id
      profile {
        id
        status
        ...FullProfileFragment
        relationSearch(page: $page, limit: $limit, status: $status, search: $search) {
          meta {
            count
            page
            limit
          }
          relations {
            id
            author {
              id
              profile {
                id
                pseudo
              }
            }
            guest {
              id
              profile {
                id
                pseudo
              }
            }
            member {
              id
              profile {
                id
                status
                pseudo
                picture
              }
            }
            status
            message
          }
        }
      }
    }
  }
  ${FullProfileFragment}
`;

export async function updateFriendStatus(
  { relationId, profileId }: { relationId: string; profileId: string },
  client: AppApolloClient,
  status: 'approved' | 'refused',
) {
  const { data } = await axios.put<RequestFriendResponse>(`/api/relation/${relationId}`, {
    status: status,
  });

  updateRelationProfileCache(client, relationId, profileId, status);

  return data;
}

export function useOnUpdateFriendStatus(
  relation: Maybe<Relation>,
  status: 'approved' | 'refused',
  callback: RequestFriendCallback | undefined,
) {
  const client = useAppApolloClient();

  return useCallback<FriendActionType>(
    async event => {
      event.preventDefault();
      const resp = await updateFriendStatus(
        { relationId: relation?.id as string, profileId: relation?.member?.profile?.id as string },
        client,
        status,
      );
      callback?.(resp);
    },
    [callback, client, relation?.id, relation?.member?.profile?.id, status],
  );
}

function updateRelationProfileCache(
  client: AppApolloClient,
  relationId: string,
  profileId: string,
  status: 'approved' | 'refused',
) {
  if (client) {
    if (relationId) {
      client.writeFragment({
        id: `Relation:${relationId}`,
        fragment: tmpUpdateFriendRelationFragment,
        data: {
          id: relationId,
          status,
        },
      });
    }
    if (profileId) {
      client.writeFragment({
        id: `Profile:${profileId}`,
        fragment: tmpUpdateProfileRelationFragment,
        data: {
          id: profileId,
          relation: {
            id: relationId,
            status,
          },
        },
      });
    }
  }
}
