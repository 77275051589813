import type { Action, AnyAction, Selector } from '@reduxjs/toolkit';
import { useState } from 'react';

import type { AppDispatch, RootState } from './store';
import { getStore } from './storeIsolated';

export const logoutActionType = '__LOGOUT';

export function readSelectorOnce<T>(selector: Selector<RootState, T>): T {
  const state = getStore().getState();
  return selector(state);
}

export function useSelectorOnce<T>(selector: Selector<RootState, T>): T {
  const [value] = useState(() => readSelectorOnce(selector));
  return value;
}

/**
 * Dispatcher to use in a context that has no dedicated dispatcher, i.e. the store is unknown.
 * Example: use it in a utility function.
 * Don't use: in component or hook (use `useAppDispatch` instead) or in a thunk (the state should be accessible there)
 */
export const dispatchOther: AppDispatch = <A extends Action = AnyAction>(action: A) => {
  return getStore().dispatch(action);
};
