import { useMemo } from 'react';

import { getAuthStatus } from './useAuthStatus-utils';
import { useAuthProfile, usePermissions } from './usePermissions';

export const useAuthStatus = () => {
  const { isModerator, authUserId, isAuthDone, isAdmin, isOwner } = usePermissions();
  const { isGuest, loading } = useAuthProfile();

  return useMemo(
    () => getAuthStatus(isAuthDone, loading, authUserId, isGuest, isModerator, isAdmin, isOwner),
    [isAuthDone, loading, authUserId, isGuest, isModerator, isAdmin, isOwner],
  );
};
