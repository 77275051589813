import type { KeyboardEvent, RefObject } from 'react';
import React, { forwardRef, useCallback } from 'react';
import type { RenderInputComponentProps } from 'react-autosuggest';

import { useConnectedRouter } from '../../hooks/useConnectedRouter';
import { SearchInput } from './SearchInput';

export const QuickSearchInput = forwardRef<
  HTMLInputElement,
  RenderInputComponentProps & { onClickBack?: () => void }
>(({ value, onKeyDown, ...inputProps }, ref) => {
  const { push } = useConnectedRouter();
  const isActive = !!inputProps['aria-activedescendant'];
  const preventDefault = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => e.preventDefault(),
    [],
  );

  const onKeyDownPlus = useCallback<React.KeyboardEventHandler<HTMLInputElement>>(
    event => {
      if (typeof onKeyDown === 'function') onKeyDown(event);
      if (event.key !== 'Enter' || isActive) return;
      if (!(value?.length >= 2)) {
        event.preventDefault();
        const elt = (ref as RefObject<HTMLInputElement>).current;
        elt?.focus();

        return;
      }
      // If typing Enter, it goes to the search page for advanced results.
      push({ pathname: '/search/[term]', query: { term: value } });
      inputProps.onClickBack?.();
    },
    [onKeyDown, isActive, value, push, inputProps, ref],
  );

  return (
    <SearchInput
      ref={ref}
      {...inputProps}
      value={value}
      onKeyDown={onKeyDownPlus}
      onKeyUp={preventDefault}
    ></SearchInput>
  );
});
