import type { PropsWithChildren } from 'react';
import React, { useContext } from 'react';

import type { EmbedAllType } from '../Embed/EmbedLink';
import type { PreviewRegistrationRef } from '../Embed/usePreviewRegistration';

export type LimiterContextValue = {
  order: string[];
  typeElementMap: Record<string, EmbedAllType>;
  typeLimitMap: Record<EmbedAllType, number>;
  updateType: (value: { type: string; id: string }) => void;
  nbImagesMax: number;
  isEmbedEnabled: boolean;
  registeredEmbeds: PreviewRegistrationRef[];
  registeredPreviews: PreviewRegistrationRef[];
};

export const LimiterContext = React.createContext<LimiterContextValue>({
  order: [],
  typeElementMap: {},
  typeLimitMap: {} as Record<EmbedAllType, number>,
  updateType: () => null,
  isEmbedEnabled: true,
  nbImagesMax: 50,
  registeredEmbeds: [],
  registeredPreviews: [],
});

export const generateTreeElementOrder = () => {
  const order: string[] = [];

  return {
    addElement: () => {
      const id = Math.random().toString(36).slice(2);
      order.push(id);
      return id;
    },
    order,
  };
};

export const ElementLimiter: React.FC<PropsWithChildren<{ id: string }>> = ({ id, children }) => {
  const { order, typeElementMap, typeLimitMap } = useContext(LimiterContext);
  const currentType = typeElementMap[id];
  const currentLimit = typeLimitMap[currentType] as number;

  if (!currentLimit) return <>{children}</>;

  const typeOrder = order.filter(anId => typeElementMap[anId] === currentType);
  const currentIndex = typeOrder.findIndex(anId => anId === id);

  return <>{currentIndex < currentLimit ? children : null}</>;
};
