import { debateConfig } from '../../config';

export const ssrRevalidateHomeData = () => ssrRevalidateRouteData('/');

export const ssrRevalidateRouteData = async (routePath: string) => {
  try {
    // Verify that routePath parameter is a real path
    if (!routePath.startsWith('/')) throw new Error('Invalid route path: must start with /');
    if (routePath.includes(' ')) throw new Error('Invalid route path: must not contain any spaces');

    const revalidation = await fetch('/api/revalidate', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${debateConfig.ISR_REVALIDATE_TOKEN}`,
      },
    });

    if (revalidation.ok) console.log('💡 ~ revalidation:', await revalidation.json());
  } catch (error) {
    console.log('⚠️ ~ error:', error);
  }
};
