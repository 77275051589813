import { useRouter } from 'next/router';

import { useAppSelector } from '../redux/hooks';
import { selectPageConfig } from '../redux/slices/utils-slice';

export function useSkipLayout() {
  const { query } = useRouter();
  const pageConfig = useAppSelector(selectPageConfig);
  return !!query.skip_layout || pageConfig?.skipLayout;
}
