import classnames from 'classnames';
import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Tooltip } from '../../components/GraphModal/Tooltip';
import { ReactComponent as IconPinFull } from '../../icons/icon-pin-fill.svg';
import { ReactComponent as IconPinOutlined } from '../../icons/icon-pin-outlined.svg';
import { useAppDispatch } from '../../redux/hooks';
import type { Topic } from '../../types/api';
import { handleError } from '../../utils/common-utils';
import { NoSSR } from '../../utils/ssr';
import { pinTopic } from '../api/topic';
import { updateTopicInTagList } from '../ThreadMessage/topics-slice';

const messages = defineMessages({
  pin: { defaultMessage: 'Epingler le sujet', id: 'pG8uE5' },
  unPin: { defaultMessage: 'Ne plus épingler le sujet', id: 'icsk3n' },
});

/**
 *
 * @param pageId
 * @param pinned
 * @param props
 * @constructor
 */
export const TopicPin = ({
  id: pageId,
  pinned,
  ...props
}: {
  id: string;
  pinned: boolean;
} & React.HTMLAttributes<HTMLDivElement>) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const toggle = useCallback(async () => {
    try {
      const { pinned: newPinnedState } = await pinTopic({ pageId, unPin: pinned });
      const topicUpdate: Required<Pick<Topic, 'id' | 'pinned'>> = {
        id: pageId,
        pinned: newPinnedState,
      };
      dispatch(updateTopicInTagList(topicUpdate));
    } catch (error) {
      handleError(error);
    }
  }, [pageId, pinned, dispatch]);

  const message = pinned ? messages.unPin : messages.pin;

  return (
    <NoSSR>
      <div role="button" onClick={toggle} tabIndex={-1} {...props}>
        <Tooltip content={formatMessage(message)}>
          <div className={classnames('h-5 transition-colors')}>
            <span className="w-4 h-4">{pinned ? <IconPinFull /> : <IconPinOutlined />}</span>
          </div>
        </Tooltip>
      </div>
    </NoSSR>
  );
};
