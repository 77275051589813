import Script from 'next/script';

import type { StorageType } from '../../../../front/src/services/storage/liveStorage';
import type { ModalOpenSubscriber, ModalResizeSubscriber } from '../../../../front/src/types/auth';
import type {
  GetTokenAsync,
  GLOptionsType,
  GraphLogin,
  SubscribeToToken,
} from '../../../../front/src/types/graphlogin';
import { debateConfig } from '../../../config';

// The goal of this file is to provide a wrapper around window.__semio__graphlogin() methods that are defined in the integration script, debateConfig.integrationUrl (Script tag below). The script is loaded asynchronously, so the __semio__graphlogin field, on `window`, is available later (undefined before the script is loaded).
// getGraphLogin(), exported below, exports a similar interface to the original methods in __semio__graphlogin(), except that methods are async. Each method exposes a promise, with the exception of subscription methods that have exactly the same interface (but the subscriptions are deferred internally, until the target script is ready).
// Thanks to this wrapper, the integration script is loaded asynchronously, with the strategy "lazyOnload" (on CPU idle time), and the lighthouse score should be slightly improved when not authenticated (good for Google indexing).

declare global {
  interface Window {
    __semio__graphlogin: GLInitializer;
  }
}
type GLInitializer = (options: GLOptionsType) => GraphLogin;

let resolveGL: (value: GLInitializer | PromiseLike<GLInitializer>) => void | undefined;
let rejectGL: (reason?: any) => void | undefined;

const glPromise = new Promise<GLInitializer>((resolve, reject) => {
  resolveGL = resolve;
  rejectGL = reject;
});

const afterLoadGraphLogin = () => {
  resolveGL(window.__semio__graphlogin);
};
const handleErrorGraphLogin = (reason?: any) => {
  rejectGL(reason || new Error('Failed to load GraphLogin for an unknown reason'));
};

export function LoadGraphLogin() {
  return (
    <Script
      strategy="lazyOnload"
      src={debateConfig.integrationUrl}
      onLoad={afterLoadGraphLogin}
      onError={handleErrorGraphLogin}
    />
  );
}

type GLFunctions = Required<
  Pick<GraphLogin, 'subscribeToToken' | 'onModalOpenChange' | 'onModalResize'>
>;

function wrapPromisedMethod<T extends keyof GLFunctions>(obj: Promise<GraphLogin>, methodName: T) {
  const obj2 = obj as Promise<GLFunctions>;
  return (...args: Parameters<GLFunctions[T]>) => {
    let unsub: VoidFunction | undefined;
    let unsubscribed = false;

    (async function async() {
      const resolvedObj = await obj2;
      if (!unsubscribed) {
        unsub = (resolvedObj[methodName] as any)?.(...args);
      }
    })();

    return () => {
      unsubscribed = true;
      unsub?.();
    };
  };
}

export interface GraphLoginAsync {
  login: () => Promise<void>;
  signup: () => Promise<void>;
  logout: () => Promise<void>;
  isReady: Promise<boolean>;
  resetPassword: (email?: string) => Promise<void>;
  validateEmailUpdate: (email?: string) => Promise<void>;
  getToken: GetTokenAsync;
  subscribeToToken: SubscribeToToken;
  customStorage: Promise<Storage | StorageType>;
  TOKEN_NAME: Promise<string>;
  /** @returns a method that you can call to unsubscribe. Useful for useEffect. */
  onModalOpenChange: ModalOpenSubscriber;
  fetchGraphLogin: GraphLogin['fetchGraphLogin'];
  onModalResize: ModalResizeSubscriber;
}

export function getGraphLogin(websiteKey: string, locale: string): GraphLoginAsync {
  const auth = glPromise.then(graphlogin => {
    return graphlogin({
      getLocale: () => locale,
      key: websiteKey,
      storage: { type: 'cookie', secure: true },
    });
  });
  return {
    login: async () => (await auth).login(),
    signup: async () => (await auth).signup(),
    logout: async () => (await auth).logout(),
    isReady: auth.then(a => a.isReady),
    resetPassword: async (email?: string) => (await auth).resetPassword(email),
    validateEmailUpdate: async (email?: string) => (await auth).validateEmailUpdate(email),
    getToken: async () => (await auth).readToken?.() || '',
    subscribeToToken: wrapPromisedMethod(auth, 'subscribeToToken'),
    customStorage: auth.then(a => a.customStorage),
    TOKEN_NAME: auth.then(a => a.TOKEN_NAME),
    onModalOpenChange: wrapPromisedMethod(auth, 'onModalOpenChange'),
    fetchGraphLogin: async (...args) => (await auth).fetchGraphLogin(...args),
    onModalResize: wrapPromisedMethod(auth, 'onModalResize'),
  };
}
