import { useQuery } from '@apollo/client';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useEffect, useMemo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
  markNotifAsRead,
  NotificationsSearchQuery,
} from '../../../../../front/src/contributor/api/notification';
import { useAuthProfile } from '../../../../../front/src/hooks/usePermissions';
import type { Notification, QueryType } from '../../../../../front/src/types/api';
import { debounce, uniqBy } from '../../../../../front/src/utils/common-utils';
import { useRefetchOnOpenAndBg } from '../header-utils';
import type { HeaderMenuItemProps } from '../HeaderMenuItemWrapper';
import { HeaderMenuItemWrapper, SubHeaderMenuTitle } from '../HeaderMenuItemWrapper';
import { AVAILABLE_NOTIFICATIONS, NotificationItem } from './NotificationItem';

const intl = defineMessages({
  title: { defaultMessage: 'Notifications', id: 'NAidKb' },
  markAllAsRead: { defaultMessage: 'Tout marquer comme lu', id: 'XXNY47' },
  noNotification: { defaultMessage: 'Pas encore de notification', id: 'OmXcDr' },
});

export const Notifications = ({
  onCountChange = () => {},
  activeColor,
  limit = 20,
  tip,
}: HeaderMenuItemProps) => {
  const { data, loading, fetchMore, refetch, client } = useQuery<QueryType>(
    NotificationsSearchQuery,
    {
      variables: { offset: 0, limit },
    },
  );

  useRefetchOnOpenAndBg(tip, refetch);

  const { meta, notifications } = data?.me?.profile?.getNotifications || {};
  const { id: websiteId, name: websiteName } = data?.website || {};
  const {
    profile: { picture = '', pseudo = '' },
  } = useAuthProfile();

  const hasMore = !loading && (notifications?.length as number) < (meta?.count as number);

  const validNotifications = useMemo<Notification[]>(
    () =>
      ((notifications || []) as Notification[]).filter(({ action, object }) =>
        AVAILABLE_NOTIFICATIONS.includes(`${object}:${action}`),
      ),
    [notifications],
  );

  const visibleNotifications = useMemo<Array<Notification & { occurrences: number }>>(
    () =>
      // @ts-ignore
      validNotifications.reduce((acc, next) => {
        // If the notification is about a new tag that has been deleted from the BO, the category is not available anymore, and the notification should be hidden (or it will have a wrong link).
        if (next.object === 'tag' && next.action === 'newtag' && !next.category) return acc;

        if (!acc.length) return [{ ...next, occurrences: 1 }];
        const prev = acc.pop();
        if (
          prev &&
          prev.object === 'comment' &&
          prev.object === next.object &&
          prev.action !== 'ecd' &&
          prev.action === next.action
        ) {
          // Keep the previous one as it is the most recent
          return [...acc, { ...prev, occurrences: prev.occurrences + 1 }];
        }
        return [...acc, prev, { ...next, occurrences: 1 }];
      }, [] as Array<Notification & { occurrences: number }>),
    [validNotifications],
  );

  const unreadNotifications = useMemo<string[]>(
    () => (validNotifications || []).filter(notif => !notif.view).map(({ id }) => id as string),
    [validNotifications],
  );

  const onMarkAllReadClick = useCallback(async () => {
    if (!unreadNotifications || !unreadNotifications.length) return;
    await markNotifAsRead(unreadNotifications, websiteId as string, client);
  }, [unreadNotifications, websiteId, client]);

  const fetchMoreData = useCallback(async () => {
    const currentLength = notifications?.length ?? 0;
    if (loading || !hasMore) {
      return;
    }

    await fetchMore({
      variables: {
        offset: currentLength,
        limit: 10,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const result = cloneDeep(fetchMoreResult);
        if (!result?.me?.profile?.getNotifications) return previousResult;

        result.me.profile.getNotifications.notifications = uniqBy(
          [
            ...(previousResult?.me?.profile?.getNotifications?.notifications ?? []),
            ...(fetchMoreResult?.me?.profile?.getNotifications?.notifications ?? []),
          ],
          notification => notification?.id,
        );

        return result;
      },
    });
  }, [fetchMore, hasMore, loading, notifications?.length]);

  const debouncedFetch = debounce(fetchMoreData, 500);

  const handleScrollBottomReached = useCallback(() => {
    if (hasMore) {
      debouncedFetch();
    }
  }, [hasMore, debouncedFetch]);

  const onNotifClick = useCallback(
    (notif: Notification) => async () => {
      if (!notif.view) await markNotifAsRead([notif.id as string], websiteId as string, client);
      if (tip) tip?.hide();
    },
    [client, websiteId, tip],
  );

  useEffect(
    () => onCountChange(unreadNotifications?.length ?? 0),
    [unreadNotifications?.length, onCountChange],
  );

  return (
    <HeaderMenuItemWrapper
      onScrollBottomReached={handleScrollBottomReached}
      activeColor={activeColor}
      loading={loading}
      hasMore={hasMore}
      headerContent={
        <div className="w-full flex items-center justify-between">
          <SubHeaderMenuTitle>
            <FormattedMessage {...intl.title} />
          </SubHeaderMenuTitle>
          {visibleNotifications?.length > 0 ? (
            <button
              type="button"
              className="hover:underline tracking-4 text-sm"
              onClick={onMarkAllReadClick}
            >
              <FormattedMessage {...intl.markAllAsRead} />
            </button>
          ) : null}
        </div>
      }
      content={
        <>
          {visibleNotifications.map(notif => (
            <NotificationItem
              notification={notif}
              key={notif.id}
              onClick={onNotifClick}
              data={{ page: websiteName as string, pseudo, picture }}
            />
          ))}
          {loading ? <div className="p-2 loader loader-centered" /> : null}
          {!loading && !visibleNotifications?.length && (
            <div className="p-2 text-center text-gray-400 bg-white">
              <FormattedMessage {...intl.noNotification} />
            </div>
          )}
        </>
      }
    />
  );
};
