import React, { cloneElement } from 'react';

export type MenuSectionProps = {
  title: React.ReactNode;
  elements: React.ReactElement[];
  hideTitle?: boolean;
};

/**
 *
 * @param title
 * @param elements
 * @param hideTitle
 * @constructor
 */
export const PopupMenuSection = ({ title, elements, hideTitle }: MenuSectionProps) => (
  <div className="px-1 pt-2 pb-1">
    {!hideTitle && (
      <div className="py-2 font-bold uppercase text-xs text-gray-300 font-source tracking-widest">
        {title}
      </div>
    )}
    {Array.isArray(elements)
      ? elements.map((elt, index) => cloneElement(elt, { key: elt?.props?.keyAs ?? index }))
      : elements}
  </div>
);
