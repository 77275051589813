import type { User, Website } from '../types/api';

export function readStatus(member: User | undefined, website: Website | undefined) {
  // AOL: profile.myRights is what we should follow. When coding it, it has just been implemented in the API. I don't trust it yet 100%, so I also keep the old implementation as fallback.
  // We can clean it later (beware of the case when the authentication is not completed yet).
  const { id: authUserId, role, profile } = member ?? {};
  const r = profile?.myRights || {};

  const { owner, administrators, moderators } = website ?? {};
  const isSuperAdmin = r.isSuperAdmin != null ? r.isSuperAdmin : role === 'admin';
  const ownerId = owner?.id ?? 'ownerId';
  const isOwner =
    isSuperAdmin || (r.isWebsiteOwner != null ? r.isWebsiteOwner : ownerId === authUserId);
  const isAdmin =
    isOwner ||
    (r.isWebsiteAdministrator != null
      ? r.isWebsiteAdministrator
      : (administrators ?? []).some(elt => elt?.id === authUserId));
  const isModerator =
    isAdmin ||
    (r.isWebsiteModerator != null
      ? r.isWebsiteModerator
      : (moderators ?? []).some(elt => elt?.id === authUserId));
  return { isSuperAdmin, isOwner, isAdmin, isModerator };
}

export function readIsGuest(
  authUserId: string | undefined,
  isAuthDone: boolean,
  profileId: string | undefined,
) {
  return Boolean(authUserId && isAuthDone && !profileId);
}
