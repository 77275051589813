import { useCallback } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ButtonV3 } from '../../../../../front/src/components/Button/ButtonV3';
import { warnToast } from '../../../../../front/src/components/Toast';
import type { GraphdebateSso } from '../../../../../front/src/types/api';
import { openUrlInPopup } from '../../../../../front/src/utils/popup';

type SsoConnectButtonProps = {
  sso: GraphdebateSso;
};

const toastMessage = defineMessages({
  ssoEndpointError: { defaultMessage: "L'url du SSO n'est pas défini dans le BO", id: 'R5Zzqk' },
});

export const SsoConnectButton = ({ sso }: SsoConnectButtonProps) => {
  const ssoEndpoint = sso?.externalUrlLogin;
  const handleSsoPopup = useCallback(
    () =>
      ssoEndpoint
        ? openUrlInPopup(ssoEndpoint)
        : warnToast(<FormattedMessage {...toastMessage.ssoEndpointError} />),
    [ssoEndpoint],
  );

  return (
    <>
      <ButtonV3
        onClick={handleSsoPopup}
        variant="contained"
        className="h-10 px-2 md:px-3 bg-white shadow text-gray-darkv4 hover:text-white hover:bg-primary hover:font-semibold"
        radius="rounded-md"
      >
        <FormattedMessage id="EhUb+m" defaultMessage="Connexion" />
      </ButtonV3>
    </>
  );
};
