import { WITH_TIMER } from '../config';

export const IS_DEV = process.env.NODE_ENV === 'development';
export const SHOW_TIMER = IS_DEV && WITH_TIMER;
export const initTimer = SHOW_TIMER
  ? (name: string) => {
      const timer = `${name} ${Math.random().toString(36).slice(2)}`;
      // eslint-disable-next-line no-console
      console.time(timer);
      return timer;
    }
  : () => '';
// eslint-disable-next-line no-console
export const timeLog = SHOW_TIMER ? console.timeLog.bind(console) : () => null;

// eslint-disable-next-line no-console
export const timeEnd = SHOW_TIMER ? console.timeLog.bind(console) : () => null;

export type AsyncFn<T extends unknown[], U> = (...args: [...T, string | undefined]) => Promise<U>;

export const withTimer = SHOW_TIMER
  ? <T extends unknown[], U>(fn: AsyncFn<T, U>, name: string) =>
      async (...args: T) => {
        const timer = initTimer(name);
        const result = await fn(...args, timer);
        timeEnd(timer);
        return result;
      }
  : <T extends unknown[], U>(x: AsyncFn<T, U>) => x;
