import { gql } from '@apollo/client';

export const listGroupsQuery = gql`
  query ListGroupsQuery {
    website {
      groupSearch {
        meta {
          count
          offset
          page
          limit
        }
        groups {
          id
          name
          description
          slug
          # createdAt
          # owners {
          #   id
          #   username
          #   email
          # }
          image
          # status
          # countTopics
          # lastActivity
          # countMembersAdmin
          # countMembersMember
          # countMembersValid
          # countMembersPending
        }
      }
    }
  }
`;
