import classNames from 'classnames';
import type { ForwardedRef, PropsWithChildren, ReactNode } from 'react';
import React, { forwardRef, memo, useRef } from 'react';
import useMeasure from 'react-use/lib/useMeasure';
import { useDebounce } from 'use-debounce';

const reverseStyle = {
  transform: 'scaleY(-1)',
};

export const AutoHeight: React.FC<
  PropsWithChildren<{
    reverse?: boolean;
    waitForInitialHeight?: boolean;
    frameLimit?: number;
    className?: string;
  }>
> = memo(({ children, reverse, waitForInitialHeight = false, frameLimit = 3, className }) => {
  const didMountRef = useRef<boolean>(false);
  const diffCountRef = useRef<number>(0);

  const [ref, { height }] = useMeasure();
  const [debouncedHeight] = useDebounce(height, 16, { trailing: true });

  if (height === debouncedHeight) {
    diffCountRef.current = 0;
  } else {
    diffCountRef.current += 1;
  }

  const cssHeight = !didMountRef.current || diffCountRef.current > frameLimit ? 'auto' : height;

  didMountRef.current = didMountRef.current || !waitForInitialHeight || !!height;

  return (
    <AutoHeightInner cssHeight={cssHeight} reverse={reverse} className={className} ref={ref}>
      {children}
    </AutoHeightInner>
  );
});

type AutoHeightInnerProps = {
  cssHeight: number | 'auto';
  reverse: boolean | undefined;
  className: string | undefined;
  children: ReactNode;
};

const AutoHeightInner = memo(
  forwardRef((props: AutoHeightInnerProps, ref: ForwardedRef<Element>) => {
    const { cssHeight, reverse, className, children } = props;
    return (
      <div
        style={{
          height: cssHeight,
          ...(reverse ? reverseStyle : null),
        }}
        className={classNames('transition-all duration-150 overflow-hidden', className ?? '')}
      >
        <div ref={ref as React.Ref<HTMLDivElement>} style={reverse ? reverseStyle : undefined}>
          {children}
        </div>
      </div>
    );
  }),
);

export { useMeasure };
