import { useApolloClient } from '@apollo/client';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { generateOptiAds } from '../../../../front/src/components/Ads/PageListAds';
import { LoaderBlock } from '../../../../front/src/components/LoaderBlock';
import { useAppSelector } from '../../../../front/src/redux/hooks';
import { selectIsBot } from '../../../../front/src/redux/slices/utils-slice';
import { homeTopicsQuery } from '../../../../front/src/thread/api/topic';
import { type Topics } from '../../../../front/src/thread/ThreadMessage/topics-slice';
import ContentHeader from '../../../../front/src/thread/Topic/ContentHeader';
import { TopicList } from '../../../../front/src/thread/Topic/TopicList';
import type { Topic } from '../../../../front/src/types/api';
import type { LastTopicsBlockData, SortType } from '../../../../front/src/types/layout';
import { useHasAds } from '../../utils/common';
import { useOnCachedTokenReady } from '../auth/HasTokenProvider';
import { LinkToAll } from '../Home/children/LinkToAll';

const messages = defineMessages({
  recentTopics: { defaultMessage: `Les derniers sujets`, id: 'gw10ZN' },
  allTopics: { defaultMessage: 'Voir tous les sujets', id: 'B1V/39' },
});

interface Props {
  topics?: Topics;
  limit?: number;
  sort?: SortType;
  withLinkToAllCTA?: boolean;
  className?: string;
  block: LastTopicsBlockData;
  isResponsive?: boolean;
  isSidebar?: boolean;
  itemTitleClassName?: string;
}
const hasTopicsListBeenUpdated = (currentTopics: Topic[] = [], newTopics: Topic[]) => {
  const isSameListLength = currentTopics.length === newTopics.length;

  const isListModified = currentTopics.some(
    (topic, tIndex) =>
      topic.id !== newTopics[tIndex].id ||
      topic.lastMessage?.id !== newTopics[tIndex].lastMessage?.id,
  );

  return !isSameListLength || isListModified;
};

export const BlockLastTopics: FC<Props> = memo(function BlockLastTopics(props) {
  const {
    withLinkToAllCTA,
    className,
    block,
    isResponsive = true,
    isSidebar = false,
    itemTitleClassName = '',
  } = props;
  const { options, data: blockData } = block;
  const { sort: blockDataSort, limit: blockDataLimit = 20, customTitle } = options;
  const limit = +blockDataLimit; // parseInt to handle string number value from BO

  const [topics, setTopics] = useState<Topic[]>(blockData);
  const [isCheckingForToken, setIsCheckingForToken] = useState(true);

  const isBot = useAppSelector(selectIsBot);
  const client = useApolloClient();
  const { query } = useRouter();

  const querySort = query?.sort as SortType;

  const fetchClientTopicsAndUpdateState = useCallback(() => {
    return client
      .query({
        query: homeTopicsQuery,
        variables: { sort: querySort || blockDataSort, limit, skipContent: true },
      })
      .then(({ data }) => {
        const requestTopics = data.website.topicList.topics;

        const topicsHaveBeenUpdated = hasTopicsListBeenUpdated(topics, requestTopics);
        if (!topicsHaveBeenUpdated) return;

        console.log('ℹ️ Topics list refreshed');
        setTopics(requestTopics);
      });
  }, [blockDataSort, client, limit, querySort, topics]);

  useEffect(() => {
    if (isBot) {
      setIsCheckingForToken(false);
    }
  }, [isBot]);

  useOnCachedTokenReady(async hasToken => {
    if (!isBot && hasToken) await fetchClientTopicsAndUpdateState();
    setIsCheckingForToken(false);
  });

  useEffect(() => {
    fetchClientTopicsAndUpdateState();
  }, [fetchClientTopicsAndUpdateState, querySort]);

  if (isCheckingForToken) {
    return <LoaderBlock />;
  }

  return (
    <div className={twMerge('BlockLastTopics flex flex-col gap-y-4 md:gap-y-6', className)}>
      <ContentHeader
        withCategoriesMenu
        isResponsive={isResponsive}
        contentTitle={customTitle || <FormattedMessage {...messages.recentTopics} />}
        titleClassName={block.locate === 'right' ? 'text-md font-roboto font-semibold' : ''}
        listLength={!isCheckingForToken && topics?.length}
        topicListActions={{
          sortMenu: { sortType: querySort || blockDataSort },
          densityMenu: true,
          createTopicCTA: true,
        }}
      />

      {!!topics?.length ? (
        <BlockLastTopicsLoaded
          isResponsive={isResponsive}
          itemTitleClassName={itemTitleClassName}
          isSidebar={isSidebar}
          topics={topics}
          limit={limit}
        />
      ) : null}

      {withLinkToAllCTA && (
        <LinkToAll to="topics" params={{ filter: 'new' }} intlMessage={messages.allTopics} />
      )}
    </div>
  );
});

interface BlockLastTopicsLoadedProps {
  topics: Topic[];
  limit: number;
  isResponsive?: boolean;
  isSidebar?: boolean;
  itemTitleClassName?: string;
}

const BlockLastTopicsLoaded: FC<BlockLastTopicsLoadedProps> = memo(props => {
  const { topics, limit, isResponsive, itemTitleClassName, isSidebar } = props;

  const hasAds = useHasAds();

  const computedAds = useMemo(
    () =>
      !hasAds
        ? undefined
        : [
            ...generateOptiAds({
              start: 2,
              freq: 7,
              isMobile: false,
              total: limit,
            }),
            ...generateOptiAds({
              start: 2,
              freq: 5,
              isMobile: true,
              total: limit,
            }),
          ],
    [hasAds, limit],
  );

  return (
    <TopicList
      itemTitleClassName={itemTitleClassName}
      isResponsive={isResponsive}
      isSidebar={isSidebar}
      topics={topics}
      computedAds={computedAds}
    />
  );
});
