import { useQuery } from '@apollo/client';
import type { ReactNode } from 'react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { ChatIconButton } from '../../../../front/src/components/chat/ChatIconButton';
import { chatMessages } from '../../../../front/src/components/chat/chatMessages';
import { Tooltip } from '../../../../front/src/components/GraphModal/Tooltip';
import { IconCloseCircle } from '../../../../front/src/components/icons/Icons';
import { useAppApolloClient } from '../../../../front/src/hooks/useAppApolloClient';
import { usePermissions } from '../../../../front/src/hooks/usePermissions';
import { useAppDispatch } from '../../../../front/src/redux/hooks';
import { openChatWindow } from '../../../../front/src/redux/slices/chat-service';
import { closeChatWindow } from '../../../../front/src/redux/slices/chat-slice';
import { ChatQuery } from '../../../../front/src/thread/api/inbox';
import type { QueryType } from '../../../../front/src/types/api';
import { ChatAvatars } from './ChatAvatars';
import styles from './ReducedChatWindow.module.scss';
import { useChatUsers } from './useChatUsers';

const mapChildren = (child: ReactNode, key: string) => (
  <Tooltip content={<FormattedMessage {...chatMessages.openInbox} />} key={key}>
    {child}
  </Tooltip>
);

export const ReducedChatWindow: React.FC<{ threadId: string; isAlreadyView?: boolean }> = ({
  threadId,
  isAlreadyView = true,
}) => {
  const client = useAppApolloClient();
  const { data } = useQuery<QueryType>(ChatQuery, {
    variables: {
      id: threadId,
      offset: 0,
    },
    // fetchPolicy: frontConfig.disableCache ? 'network-only' : undefined,
    fetchPolicy: 'cache-first',
  });
  const thread = data?.website?.findOrCreateInbox;
  const users = useChatUsers({ users: thread?.userVisibility ?? [] });
  const { authUserId } = usePermissions();

  const dispatch = useAppDispatch();
  const open = useCallback(() => {
    openChatWindow(client, { threadId, authUserId, allowCache: true })(dispatch);
  }, [dispatch, client, threadId, authUserId]);
  const close = useCallback(
    event => {
      event.stopPropagation();
      event.preventDefault();
      dispatch(closeChatWindow(threadId));
    },
    [dispatch, threadId],
  );

  if (users.length < 1) return null;

  return (
    <div className="w-28 h-18 flex justify-center items-center group">
      <div className={styles.container} onClick={open} onKeyDown={open} role="button" tabIndex={-1}>
        <ChatAvatars
          users={users}
          variant="shadow"
          mapChildren={mapChildren}
          {...(!isAlreadyView && { className: 'border-4 border-secondary' })}
        />
        <ChatIconButton
          className="absolute top-0 right-0 bg-white -mr-2 -mt-2 hidden group-hover:flex transform scale-75"
          icon={<IconCloseCircle className="w-[14px] h-[14px]" />}
          onClick={close}
          arial={chatMessages.closeInbox}
        />
      </div>
    </div>
  );
};
