import { selectUnit } from '@formatjs/intl-utils';
import React, { useMemo } from 'react';
import { defineMessages, FormattedDate, FormattedMessage, FormattedRelativeTime } from 'react-intl';

const messages = defineMessages({
  limitedRelative: { defaultMessage: '{formattedDate}', id: 'OnAry9' },
});

const defaultRenderRelative = (formattedDate: string) => <>{formattedDate}</>;

const defaultRenderExact = (formattedDate: string) => (
  <FormattedMessage {...messages.limitedRelative} values={{ formattedDate }} />
);

export type TimeRenderFn = (arg: {
  formattedDate: string;
  relativeOrExact: 'relative' | 'exact';
}) => React.ReactElement;

type Props = {
  date: string | Date | number | undefined;
  render?: TimeRenderFn;
  renderRelative?: (elt: string) => React.ReactElement;
  renderExact?: (elt: string) => React.ReactElement;
} & Intl.DateTimeFormatOptions;

export const LimitedRelativeTime = ({
  date,
  render,
  year = 'numeric',
  month = 'numeric',
  day = 'numeric',
  ...dateTimeOptions
}: Props) => {
  const finalRenderRelative = useMemo(() => {
    return render
      ? (formattedDate: string) =>
          render({
            formattedDate,
            relativeOrExact: 'relative',
          })
      : defaultRenderRelative;
  }, [render]);

  const finalRenderExact = useMemo(() => {
    return render
      ? (formattedDate: string) =>
          render({
            formattedDate,
            relativeOrExact: 'exact',
          })
      : defaultRenderExact;
  }, [render]);

  const dateObj = typeof date === 'string' || typeof date === 'number' ? new Date(date) : date;
  const selectedUnit = dateObj == null ? dateObj : selectUnit(dateObj);

  let interval;
  switch (selectedUnit?.unit) {
    case 'hour':
      interval = 60;
      break;
    case 'minute':
      interval = 10;
      break;
    case 'second':
      interval = 1;
      break;
    default:
      break;
  }

  switch (selectedUnit?.unit) {
    case 'hour':
    case 'minute':
    case 'second':
      return (
        <FormattedRelativeTime {...selectedUnit} style="short" updateIntervalInSeconds={interval}>
          {finalRenderRelative}
        </FormattedRelativeTime>
      );
    default:
      return (
        <FormattedDate value={date} year={year} month={month} day={day} {...dateTimeOptions}>
          {finalRenderExact}
        </FormattedDate>
      );
  }
};
