import classNames from 'classnames';
import React, { forwardRef, useCallback, useRef, useState } from 'react';
import type { ReactNode } from 'react';
import type { FormRenderProps } from 'react-final-form';
import { defineMessages, useIntl } from 'react-intl';

import { Avatar } from '../../../../front/src/components/Avatar';
import type { GraphModalInstance } from '../../../../front/src/components/GraphModal/GraphModal';
import { GraphModal } from '../../../../front/src/components/GraphModal/GraphModal';
import type { TippyInstance } from '../../../../front/src/components/GraphModal/Popover';
import { Popover } from '../../../../front/src/components/GraphModal/Popover';
import { Menu } from '../../../../front/src/components/Menu';
import { frontConfig } from '../../../../front/src/config';
import { Account } from '../../../../front/src/contributor/Account';
import { useBodyModifier } from '../../../../front/src/hooks/useBodyModifier';
import { useAuthProfile, usePermissions } from '../../../../front/src/hooks/usePermissions';
import { ReactComponent as IconFriendsRed } from '../../../../front/src/icons/icon-friends-red.svg';
import { ReactComponent as IconFriends } from '../../../../front/src/icons/icon-friends.svg';
import { ReactComponent as IconMessagesBlue } from '../../../../front/src/icons/icon-messages-blue.svg';
import { ReactComponent as IconMessages } from '../../../../front/src/icons/icon-messages.svg';
import { ReactComponent as IconNotificationsOrange } from '../../../../front/src/icons/icon-notifications-orange.svg';
import { ReactComponent as IconNotifications } from '../../../../front/src/icons/icon-notifications.svg';
import type { ThemePaletteColors } from '../../../../front/src/types/theme';
import { isBrowser } from '../../../../front/src/utils/web';
import { Friends } from './friends/Friends';
import type { HeaderMenuItemProps } from './HeaderMenuItemWrapper';
import { HeaderUserMenu } from './HeaderUserMenu';
import { Inbox } from './inbox/Inbox';
import { Notifications } from './notifications/Notifications';

const badgePosition = { top: 4, right: 2 };

export const Badge: React.FC<{ count: number }> = ({ count }) =>
  count > 0 ? (
    <div
      className="absolute w-4 h-4 bg-secondary text-white font-bold flex 
      items-center justify-center rounded-full text-xs select-none"
      style={badgePosition}
    >
      {count}
    </div>
  ) : null;

type SubHeaderIconPropTypes = {
  isActive: boolean;
  label: ReactNode;
  icon: ReactNode;
  iconActive?: ReactNode;
  activeColor: ThemePaletteColors;
  count?: number;
};

export const SubHeaderIcon = forwardRef<HTMLDivElement, SubHeaderIconPropTypes>(
  ({ icon, iconActive, isActive, activeColor, count = 0, label }, ref) => (
    <div
      ref={ref}
      role="button"
      aria-label={typeof label === 'string' ? label : ''}
      className={classNames(
        'relative w-9 h-9 outline-none flex justify-center items-center',
        activeColor && `hover:text-${activeColor}`,
        activeColor,
      )}
    >
      <Badge count={count} />
      <div>{isActive ? iconActive : icon}</div>
    </div>
  ),
);

const messages = defineMessages({
  manageInvitations: { defaultMessage: "Gérer les invitations d'amis", id: 'pu+HWQ' },
  inbox: { defaultMessage: 'Messagerie', id: 'm9LJUb' },
  notifications: { defaultMessage: 'Notifications', id: 'NAidKb' },
});

export const SubHeaderMenu = ({
  cmp: Cmp,
  count,
  activeColor,
  ...props
}: Omit<SubHeaderIconPropTypes, 'isActive'> & {
  cmp: keyof JSX.IntrinsicElements | React.ComponentType<HeaderMenuItemProps>;
}) => {
  const [tip, setTip] = useState<GraphModalInstance>();
  const [counter, setCounter] = useState(count ?? 0);
  const [isOpen, setIsOpen] = useState(false);

  const setTipRef = useCallback((_tip: TippyInstance) => {
    setTip(_tip);
  }, []);

  const unsetTipRef = useCallback(() => {
    setTip(undefined);
  }, []);

  useBodyModifier({ preventScroll: !!tip });

  return (
    // Wrap in span to avoid a tippy warning
    <span>
      <Popover
        // preventPageScroll
        className={`tippy-${activeColor} tippy-header-customized`}
        placement="bottom-end"
        theme="header"
        maxWidth="none"
        content={
          <Cmp
            activeColor={activeColor}
            onCountChange={newCount => setCounter(newCount)}
            tip={tip}
          />
        }
        onShow={setTipRef}
        onHide={unsetTipRef}
        appendTo={isBrowser ? document.body : 'parent'}
      >
        <div
          onFocus={() => setIsOpen(true)}
          onMouseOver={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          <SubHeaderIcon count={counter} isActive={isOpen} activeColor={activeColor} {...props} />
        </div>
      </Popover>
    </span>
  );
};

export const HeaderMenu = () => {
  const { formatMessage } = useIntl();
  const { isAuthDone, authUserId } = usePermissions();
  const {
    profile: { picture },
  } = useAuthProfile();
  const accountRef = useRef<TippyInstance>();
  const accountFormRef = useRef<FormRenderProps['form']>();
  const menuRef = useRef<{ close: () => void }>();

  const showAccount = useCallback(() => {
    accountRef.current?.show();
    menuRef.current?.close();
  }, [accountRef, menuRef]);

  const closeMenu = useCallback(() => {
    menuRef.current?.close();
  }, [menuRef]);

  const onCreate = useCallback(
    account => {
      accountRef.current = account;
    },
    [accountRef],
  );

  if (!isAuthDone || !authUserId) return null;

  const handleResetAccountForm = () => {
    if (accountFormRef.current) {
      accountFormRef.current.reset();
    }
  };

  return (
    <div className="HeaderMenu flex gap-x-[20px] sm:gap-x-[25px]">
      <div className="flex items-center gap-x-[5px] sm:gap-x-[15px]">
        <SubHeaderMenu
          icon={<IconFriends className=" h-8 w-8 sm:h-9 sm:w-9" />}
          iconActive={<IconFriendsRed />}
          activeColor="red"
          label={formatMessage(messages.manageInvitations)}
          cmp={Friends}
        />

        <SubHeaderMenu
          icon={<IconMessages className=" h-8 w-8 sm:h-9 sm:w-9" />}
          iconActive={<IconMessagesBlue />}
          activeColor="blue"
          label={formatMessage(messages.inbox)}
          cmp={Inbox}
        />

        <SubHeaderMenu
          icon={<IconNotifications className=" h-8 w-8 sm:h-9 sm:w-9" />}
          iconActive={<IconNotificationsOrange />}
          activeColor="yellow"
          label={formatMessage(messages.notifications)}
          cmp={Notifications}
        />
      </div>
      <div className="flex items-center justify-center">
        <Menu
          ref={menuRef}
          placement="bottom-end"
          // buttonClassName="outline-none focus:outline-none"
          theme="light-border"
          noArrow
          noHideDelay
          content={<HeaderUserMenu showAccount={showAccount} closeMenu={closeMenu} />}
          appendTo={isBrowser ? document.body : 'parent'}
        >
          <div className="relative outline-none overflow-visible">
            <div role="button" className="flex items-center justify-center">
              <Avatar
                picture={picture}
                size="medium1"
                legacyImage
                className="h-[25px] w-[25px] sm:h-[36px] sm:w-[36px]"
              />
            </div>

            <GraphModal
              trigger="manual"
              lazyContent={tip => <Account tip={tip} accountFormRef={accountFormRef} />}
              onCreate={onCreate}
              onHide={handleResetAccountForm}
              placement="bottom-end"
              theme="light-border"
              // className="account-popover tippy-popover-panel"
              className="tippy-no-padding"
              appendTo={isBrowser ? document.body : 'parent'}
              // @ts-ignore
              zIndex={frontConfig.zIndexHeaderPopover ?? 900}
              forcePopover
            >
              <div className="absolute w-full h-full top-0 left-0 pointer-events-none outline-none" />
            </GraphModal>
          </div>
        </Menu>
      </div>
    </div>
  );
};
