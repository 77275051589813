import type { HTMLAttributes, ReactNode } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { LimitedRelativeTime } from '../../../../components/LimitedRelativeTime';
import { Link } from '../../../../components/Link';
import { ProfilePopover } from '../../../../contributor/Profile/ProfilePopover';
import { useWebsite } from '../../../../hooks/useWebsite';
import type { Topic, User } from '../../../../types/api';
import { isMobile } from '../../../../utils/web';
import { AuthorAvatar } from '../../../AuthorAvatar';

type TopicItemLastMessageBlockType = HTMLAttributes<HTMLDivElement> & {
  topic: Topic;
  renderInline?: boolean;
  isResponsive?: boolean;
  isSidebar?: boolean;
};

const messages = defineMessages({
  lastMessageDetails: {
    defaultMessage: `{relativeOrExact, select,
      relative {a répondu,<div>{formattedDate}</div>}
      other {a répondu,<div>le {formattedDate}</div>}
    }`,
    id: 'vkxIfF',
  },
});

type RenderMessageProps = {
  formattedDate: string;
  relativeOrExact: 'exact' | 'relative';
  renderInline?: boolean;
  isResponsive?: boolean;
  isSidebar?: boolean;
};

function renderLastMessageDetails(props: RenderMessageProps) {
  const {
    formattedDate,
    relativeOrExact,
    renderInline,
    isResponsive = true,
    isSidebar = false,
  } = props;

  return (
    <div
      className={twMerge(
        'text-gray-soft w-24 text-[13px] leading-[15px] group-hover:text-primary',
        renderInline && 'flex gap-x-1 w-auto',
        isResponsive && 'md:text-[13px]',
        isSidebar && 'md:text-[12px]',
      )}
    >
      <FormattedMessage
        {...messages.lastMessageDetails}
        values={{
          formattedDate,
          relativeOrExact,
          div: (content: ReactNode) => <div>{content}</div>,
        }}
      />
    </div>
  );
}

export const TopicItemLastMessageBlock = ({
  topic,
  renderInline,
  className,
  isResponsive = true,
  isSidebar = false,
  ...otherProps
}: TopicItemLastMessageBlockType) => {
  const { lastMessage, slug } = topic;
  const { graphdebate } = useWebsite();

  // @ts-ignore
  const shouldIndexDiscussion = graphdebate?.meta?.topic?.index ?? 0;

  const isMobileDevice = isMobile();
  return lastMessage ? (
    <div
      className={twMerge('TopicItemLastMessageBlock', isResponsive && 'md:mt-4', className)}
      {...otherProps}
    >
      <div className={twMerge('flex items-center gap-x-1', isResponsive && 'md:gap-x-3')}>
        <ProfilePopover
          userId={(lastMessage?.author as User).id}
          trigger={isMobileDevice ? 'click' : 'mouseenter'}
        >
          <AuthorAvatar
            size={isMobileDevice ? 'small' : 'small2'}
            author={lastMessage?.author}
            userId={(lastMessage?.author as User).id}
            profile={(lastMessage?.author as User).profile}
            className={isResponsive ? '_md:w-[15px] _md:h-[15px]' : ''}
          />
        </ProfilePopover>
        <Link
          to={lastMessage ? 'message' : 'topic'}
          params={{
            slug,
            ...(lastMessage ? { messageId: lastMessage.id } : null),
          }}
          scroll={true}
        >
          <a
            className="flex items-start group"
            rel={lastMessage && !shouldIndexDiscussion ? 'nofollow' : undefined}
          >
            <span className="flex flex-col group-hover:text-primary">
              <LimitedRelativeTime
                date={lastMessage?.createdAt}
                render={arg =>
                  renderLastMessageDetails({ ...arg, renderInline, isResponsive, isSidebar })
                }
              />
            </span>
          </a>
        </Link>
      </div>
    </div>
  ) : null;
};
