import type { ReactChild } from 'react';
import { forwardRef } from 'react';

import { ReactComponent as IconCloseCircle } from '../../icons/icon-x-circle.svg';
import { Tooltip } from '../GraphModal/Tooltip';
import { Button } from './Button';

export const RemoveButton = forwardRef<HTMLElement, { content: ReactChild }>(
  ({ content, ...props }, ref) => (
    <Button variant="link" className="no-underline" ref={ref} {...props}>
      <Tooltip trigger="mouseenter" content={content as ReactChild}>
        <div>
          <IconCloseCircle />
        </div>
      </Tooltip>
    </Button>
  ),
);
