import Head from 'next/head';
import { lighten } from 'polished';

import { useWebsite } from '../../hooks/useWebsite';
import { buildRGBColorsVars } from '../../utils/theme';

const colorsThemeConfig = require('../../../../front/src/theme.colors.v3');

const legacyColorsMap = {
  graphComment: '#f35b5b',
  'chateau-green': '#41a85f',
  denim: '#2969b0',
  'neon-carrot': '#fba026',
  wisteria: '#9365b8',
  'almond-frost': '#a38f84',
  cognac: '#a46b38',
  'mountain-meadow': '#1abc9c',
  fern: '#61bd6d',
  'picton-blue': '#54acd2',
  mariner: '#2c82c9',
  'blue-whale': '#28324e',
  'iron-gray': '#75706b',
  valencia: '#d14841',
  'blue-gem': '#553982',
  'strong-scarlet': '#971048',
  orange: '#FF7900',
} as const;

const isExistingColor = (color: string): color is keyof typeof legacyColorsMap =>
  color in legacyColorsMap;

const getLegacyColor = (color: string) => {
  if (isExistingColor(color)) {
    return legacyColorsMap[color];
  }

  return null;
};

/**
 *
 * @param primary
 */
const computePrimaryColor = (primary: string) => {
  return {
    primary,
    primaryLight: lighten(0.2)(primary),
    primaryVariant: lighten(0.1)(primary),
  };
};

/**
 *
 * @param secondary
 */
const computeSecondaryColor = (secondary: string) => {
  return {
    secondary,
    secondaryLight: lighten(0.2)(secondary),
    secondaryVariant: lighten(0.1)(secondary),
  };
};

const computeWebsiteCustomColors = ({
  primaryColor,
  secondaryColor,
  publicKey,
}: {
  primaryColor?: string;
  secondaryColor?: string;
  publicKey?: string;
}) => {
  let customWebsitePrimaryColor;
  let customWebsiteSecondaryColor;

  if (publicKey === 'MyApp' || publicKey === 'psychologies-com') {
    customWebsitePrimaryColor = colorsThemeConfig.primary.DEFAULT;
  } else if (primaryColor) {
    customWebsitePrimaryColor = getLegacyColor(primaryColor);
  }

  if (secondaryColor) {
    customWebsiteSecondaryColor = getLegacyColor(secondaryColor);
  }

  return {
    ...(customWebsitePrimaryColor
      ? {
          ...computePrimaryColor(customWebsitePrimaryColor),
        }
      : {}),

    ...(customWebsiteSecondaryColor
      ? {
          ...computeSecondaryColor(customWebsiteSecondaryColor),
        }
      : {}),
  };
};

export const WebsiteStyleTheme = () => {
  const website = useWebsite();
  const { primary, primaryLight, primaryVariant, secondary, secondaryLight, secondaryVariant } =
    computeWebsiteCustomColors({
      primaryColor: website?.color,
      secondaryColor: undefined, // TODO review?
      publicKey: website?.publicKey,
    });

  // 1/ Expose palette default colors in CSS vars
  // 2/ Override custom config colors keys/variants with loaded website config
  return (
    <Head>
      <style>{`
        :root {
             ${buildRGBColorsVars(colorsThemeConfig, {
               ...(primary && {
                 'color-typography-primary': primary,
                 'color-primary': primary,
                 'color-primary-text': primary,
               }),

               ...(primaryLight && {
                 'color-primary-light': primaryLight,
               }),

               ...(primaryVariant && {
                 'color-primary-variant': primaryVariant,
               }),

               ...(secondary && {
                 'color-typography-secondary': secondary,
                 'color-secondary': secondary,
                 'color-secondary-text': secondary,
               }),

               ...(secondaryLight && {
                 'color-secondary-light': secondaryLight,
               }),

               ...(secondaryVariant && {
                 'color-secondary-variant': secondaryVariant,
               }),
             })}
      `}</style>
    </Head>
  );
};
