import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useRef } from 'react';

import { useRefLatest } from '../../../../front/src/hooks/useRefLatest';
import type { GetTokenAsync } from '../../../../front/src/types/graphlogin';

const GetTokenContext = createContext<GetTokenAsync>(null as any);

/**
 * For special cases that need to behave differently when an access token is available, e.g. refetch data that could be different from the server data. Beware, it does NOT mean that the user is authenticated, only that a token is stored somewhere. This token could be outdated or invalidated.
 * To check for authentication, use usePermissions() instead.
 */
export function useOnCachedTokenReady(callback: (hasToken: boolean) => void) {
  const getTokenRef = useRefLatest(useContext(GetTokenContext));
  const callbackRef = useRefLatest(callback);
  const cachedTokenReadyRef = useRef(false);
  useEffect(() => {
    (async function () {
      try {
        const token = await getTokenRef.current();
        callbackRef.current(!!token);
      } catch (error) {
        callbackRef.current(false);
      } finally {
        cachedTokenReadyRef.current = true;
      }
    })();
  }, [callbackRef, getTokenRef]);
  return cachedTokenReadyRef.current;
}

interface Props {
  getToken: GetTokenAsync;
}

export const SubscribeToTokenProvider: FC<PropsWithChildren<Props>> = props => {
  const { children, getToken } = props;
  return <GetTokenContext.Provider value={getToken}>{children}</GetTokenContext.Provider>;
};
