import type { FC } from 'react';
import { memo } from 'react';

import { IconDensityCard, IconDensityClassic, IconDensityCompact } from './Icons';

type DensityIconProps = {
  densityType: string;
};

const DensityIcon: FC<DensityIconProps> = memo(function DensityIcon(props) {
  const { densityType } = props;

  switch (densityType) {
    case 'compact':
      return <IconDensityCompact />;
    case 'classic':
      return <IconDensityClassic />;
    case 'card':
      return <IconDensityCard />;
    default:
      console.error(`Density icon for ${densityType} doesn't exist.`);
      return <></>;
  }
});

export default DensityIcon;
