import type { QueryResult } from '@apollo/client/react/types/types';
import classNames from 'classnames';
import { memo, useCallback, useMemo, useRef } from 'react';
import { FormattedDate } from 'react-intl';
import { Virtuoso } from 'react-virtuoso';
import { twMerge } from 'tailwind-merge';

import { usePermissions } from '../../../../front/src/hooks/usePermissions';
import { useAppDispatch } from '../../../../front/src/redux/hooks';
import { addMorePreviousMessages } from '../../../../front/src/redux/slices/chat-slice';
import type { Author, Guest, Maybe, Message, Thread, User } from '../../../../front/src/types/api';
import { html } from '../../../../front/src/utils/dom';
import styles from './ChatMessageList.module.scss';
import { ChatUsers } from './ChatUsers';

const emojiRegex =
  /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}]/gu;

const checkForEmojis = (content: string) => {
  const emojis = content.match(emojiRegex) || [];
  // Remove all emojis from the content and check if there's any non-emoji text left
  const hasNonEmojiText = content.replace(emojiRegex, '').length > 0;

  return !emojis.length || hasNonEmojiText
    ? { hasJustEmojis: false, emojis }
    : { hasJustEmojis: true, emojis };
};

const BigEmojiItem = (props: { emoji: string }) => (
  <div className="h-12 w-12 leading-[3rem] relative">
    <div className="inline absolute top-[3px] text-[3rem]">{props.emoji}</div>
  </div>
);

const displayEmojisContent = (emojisToDisplay: string[]) => {
  return (
    <div className="flex">
      {emojisToDisplay.map(emoji => (
        <BigEmojiItem key={emoji} emoji={emoji} />
      ))}
    </div>
  );
};

const parseContent = (content: string) => {
  // eslint-disable-next-line no-useless-escape
  const regexUrl =
    /(https?:\/\/[^"<\s]+[^.<\s"()][-A-Za-z0-9+&@#/%=~_|])(?![^<>]*>|[^"]*?<\/a)(?=\s|$)/gi;
  const regexImg = /(?:http(?:s?):)(?:[/|.|\w|\s|-])*\.(?:jpg|gif|jpeg|tiff|png|svg|webp)/gi;
  return content
    .replace(regexImg, match => `<img class="max-w-full" src="${match}" style=""  alt=""/>`)
    .replace(
      regexUrl,
      match => `<a href="${match}" class="link" rel="nofollow" target="_blank">${match}</a>`,
    )
    .replace(
      emojiRegex,
      match => `<div class="inline-block mx-[0.1rem]" style="font-size: 1rem; vertical-align: -1px;">
      ${match}
    </div>`,
    );
};

const USERLESS_SPACER = <div className="h-2 shrink-0" />;
const MESSAGE_TIP = (
  <div
    className="absolute"
    style={{
      position: 'absolute',
      bottom: -10,
      left: 15,
      borderBottom: '3px solid rgb(226, 232, 240)',
      borderLeft: '3px solid rgb(226, 232, 240)',
      borderTop: '3px solid white',
      borderRight: '3px solid white',
    }}
  />
);

/**
 *
 * @param basic
 * @param author
 * @constructor
 */
export const MessageSpacer = ({ basic, author }: { basic?: boolean; author?: Author }) => {
  if (basic) {
    return USERLESS_SPACER;
  }

  if (!author) {
    return USERLESS_SPACER;
  }

  return (
    <div className="relative text-xs text-gray-semiDark flex shrink-0 pt-3 items-center overflow-hidden flex-shink-0">
      {/* <Avatar picture={(author as User)?.profile?.picture} className="w-8 h-8 mr-2" legacyImage /> */}
      <div className={classNames('pl-1 flex-1 overflow-hidden', styles.break)}>
        {(author as Guest)?.username || (author as User)?.profile?.pseudo}
      </div>
      {MESSAGE_TIP}
    </div>
  );
};

/**
 *
 * @param ts
 * @constructor
 */
export const TimestampDisplay = ({ ts }: { ts?: number }) => (
  <div className="text-center text-sm pt-5 pb-2 text-gray-semiDark flex-shink-0">
    <FormattedDate
      value={ts}
      year="numeric"
      month="long"
      day="numeric"
      hour="numeric"
      minute="numeric"
    />
  </div>
);

const messageClassName = 'max-w-[75%] text-base sm:text-sm rounded-3xl sm:rounded-2xl';

/**
 *
 * @param message
 * @param mine
 * @constructor
 */
export const MessageInbox = ({ message, mine }: { message?: Message; mine?: boolean }) => {
  const currentContent = message?.content!;
  const { hasJustEmojis, emojis } = checkForEmojis(currentContent);

  const shouldDisplayBigEmojis = hasJustEmojis && emojis.length <= 3;

  const messageClasses = twMerge(
    messageClassName,
    mine ? 'text-white self-end bg-chat-background' : 'bg-gray-lighter self-start',
    shouldDisplayBigEmojis && 'bg-transparent max-w-full',
  );
  const contentToDisplay = shouldDisplayBigEmojis
    ? displayEmojisContent(emojis)
    : html(parseContent(currentContent));

  return message ? (
    <div className="pt-1 flex flex-col">
      <div className={messageClasses}>
        <div
          className={twMerge(
            'px-3 py-1 gap-x-1 gap-y-2 [&>img:first-child]:pt-2 [&>img]:pb-2',
            shouldDisplayBigEmojis && 'p-0',
          )}
        >
          {contentToDisplay}
        </div>
      </div>
    </div>
  ) : null;
};

const TS_DIFF = 3600 * 1000;

interface Props {
  thread: Thread;
  fetchMore: QueryResult['fetchMore'];
}

type MessageListItem = {
  message?: Message | null;
  mine: boolean;
  authorId: Maybe<string>;
  ts: number;
};

export const ChatMessageList = memo(function ChatMessageList(props: Props) {
  const dispatch = useAppDispatch();
  const { thread, fetchMore } = props;
  const { authUserId } = usePermissions();
  const messages = useMemo(
    () => thread?.childMessages?.messages ?? [],
    [thread?.childMessages?.messages],
  );

  const allUsers = useMemo(
    () => (thread?.userVisibility?.filter(elt => elt) ?? []) as Author[],
    [thread],
  );

  const users = useMemo(
    () => allUsers.filter(elt => elt).filter(elt => (elt as User).id !== authUserId),
    [allUsers, authUserId],
  );

  const handleFetchMore = useCallback(
    (options: { variables: { offset: number } }) => {
      fetchMore(options).then(({ data }) => {
        const olderestMessages = data.website?.findOrCreateInbox?.childMessages?.messages;
        if (olderestMessages) {
          dispatch(addMorePreviousMessages({ threadId: thread.id!, messages: olderestMessages }));
        }
      });
    },
    [fetchMore, dispatch, thread.id],
  );
  // const author = useMemo(
  //   () => allUsers.find(user => (user as User).id === authUserId),
  //   [allUsers, authUserId],
  // );

  const count = users.length;
  const totalMessagesCount = thread?.childMessages?.meta?.count ?? 0;
  const visibleMessagesCount = messages.length;
  const createdAt = thread?.createdAt || (messages[0] || {}).createdAt || new Date();

  const loadedMessages: MessageListItem[] = useMemo(() => {
    return messages
      .map((message?: Message | null) => ({
        message,
        mine: (message?.author as User)?.id === authUserId,
        authorId: (message?.author as User)?.id,
        ts: new Date(message?.createdAt).getTime(),
      }))
      .reverse();
  }, [authUserId, messages]);

  const initialPosition = useRef(Math.max(loadedMessages.length - 1, 0)).current;

  const firstItemIndex = Math.max(0, totalMessagesCount - loadedMessages.length);

  const loadMoreMessages = useCallback(() => {
    if (visibleMessagesCount < totalMessagesCount) {
      handleFetchMore({ variables: { offset: visibleMessagesCount } });
    }
  }, [handleFetchMore, totalMessagesCount, visibleMessagesCount]);

  const renderItemContent = useCallback(
    (index: number, item: MessageListItem) => {
      const { message, authorId, mine, ts } = item;
      const messageElement = (
        <MessageInbox key={message?.id} message={message as Message} mine={mine} />
      );

      const indexInCurrentLoadedMessages = index - firstItemIndex;

      const previousMessage = loadedMessages[indexInCurrentLoadedMessages - 1];

      const previousAuthorId = previousMessage?.authorId;
      const diff = ts - previousMessage?.ts;
      const showDate = diff > TS_DIFF;
      const showSpacer = previousAuthorId !== authorId;

      return (
        <div key={message?.id} className={styles.msgItem}>
          {showDate ? <TimestampDisplay ts={ts} /> : null}
          {showSpacer ? <MessageSpacer basic={mine || count < 3} author={message?.author} /> : null}
          {messageElement}
        </div>
      );
    },
    [count, firstItemIndex, loadedMessages],
  );

  return (
    <Virtuoso
      firstItemIndex={firstItemIndex}
      initialTopMostItemIndex={initialPosition}
      data={loadedMessages}
      startReached={loadMoreMessages}
      itemContent={renderItemContent}
      followOutput={true}
      style={{ paddingBottom: 30 }}
      components={{
        Header: function Header() {
          return (
            <>
              <ChatUsers users={users as User[]} />
              <TimestampDisplay ts={createdAt} />
            </>
          );
        },
      }}
    />
  );
});
