import type { NextWebVitalsMetric } from 'next/app';

import { IS_DEV } from '../../../../front/src/utils/optimization';

type AnalyticsOptions = { withDevLogs?: boolean };
/**
 * Takes Next metric data and send it to Google Analytics in the proper format expected by their tracking API.
 *
 * Also, **in dev env only**, show a log of params sent.
 *
 */
export function sendToGoogleAnalytics(metric: NextWebVitalsMetric, options?: AnalyticsOptions) {
  const { id, name, value } = metric;
  const ShowLogs = options?.withDevLogs;

  const gtagEventParams = {
    event_category: 'web-vital',
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    event_label: id, // id unique to current page load
    non_interaction: true, // avoids affecting bounce rate.
  };

  ShowLogs &&
    IS_DEV &&
    console.log('🦄 ~ sendToGoogleAnalytics ~ gtagEvent:', { name, params: gtagEventParams });

  try {
    if (name !== 'INP' && !IS_DEV) {
      window.gtag('event', name, gtagEventParams);
      ShowLogs && IS_DEV && console.log(`👌🏼 ~ Event ${name} successfully sent`);
    }
  } catch (error) {
    console.warn(`Couldn't send ${name} event to GA:\n`, error);
  }
}
