import type { Category, Topic } from '../../types/api';
import { handleError } from '../../utils/common-utils';
import { onLoadPromise } from '../../utils/dom';
import { isEqual } from '../../utils/fragments/isEqual';
import { isBrowser } from '../../utils/web';

export interface ExposedData {
  pageName?: string;
  tag?: Category;
  topic?: Topic;
  tags?: Category[];
  currentUrl?: string;
  title?: string;
  pseudo?: string;
  tab?: string;
}
type DataSubscriberType = (data: ExposedData) => void;

export const INITIAL_SUB_NAME = `__gc__public__data__initial__sub__`;
export const INITIAL_DATA_SERVICE_NAME = `__gc__public__data__service__`;

declare global {
  interface Window {
    [INITIAL_SUB_NAME]?: DataSubscriberType[];
    [INITIAL_DATA_SERVICE_NAME]?: DataExposer;
  }
}

export class DataExposer {
  private data: ExposedData | null;

  private subscribers: Set<DataSubscriberType> = new Set();

  constructor(data: ExposedData | null = null) {
    this.data = data;
  }

  async expose() {
    if (!isBrowser) return;

    await onLoadPromise;

    const initialSubs = window?.[INITIAL_SUB_NAME] ?? [];
    window[INITIAL_DATA_SERVICE_NAME] = window?.[INITIAL_DATA_SERVICE_NAME] ?? this;

    initialSubs.forEach(this.subscribe.bind(this));
  }

  getDataExposed() {
    return this.data;
  }

  setDataExposed(rawValue: Partial<ExposedData> | null) {
    const value = {
      ...rawValue,
      currentUrl: isBrowser ? window?.location?.href : '',
    };

    if (isEqual(this.data, value)) return;

    this.data = value;
    this.subscribers.forEach(fn => {
      try {
        fn(value);
      } catch (error) {
        console.error('setDataExposed: error while calling a subscriber function.');
        handleError(error);
      }
    });
  }

  unsubscribe(sub: DataSubscriberType) {
    this.subscribers.delete(sub);
  }

  subscribe(sub: DataSubscriberType) {
    if (this.data) {
      sub(this.data);
    }

    this.subscribers.add(sub);

    return () => this.unsubscribe(sub);
  }
}

export const computeBase64FromPublicData = (values: ExposedData) => {
  if (!values) return null;

  const { pageName, tags, tag } = values;

  if (pageName !== 'thread' && pageName !== 'page' && pageName !== 'tag') return null;

  const firstTag = (tags || ([tag] as Category[]))
    .filter(Boolean)
    .find(({ status }) => status === 'approved');

  if (!firstTag) return null;

  const gcData = { tag: firstTag.slug };

  const gcStr = JSON.stringify(gcData);

  return isBrowser ? btoa(gcStr) : Buffer.alloc(gcStr.length, gcStr).toString('base64');
};
