import type { FC } from 'react';
import { memo } from 'react';

import { usePermissions } from '../../../hooks/usePermissions';
import type { Maybe, Profile, Relation } from '../../../types/api';

interface InviteMessageProps {
  profile: Profile;
  relation?: Maybe<Relation>;
  margin?: boolean;
}

export const InviteMessage: FC<InviteMessageProps> = memo(({ profile, relation, margin }) => {
  const { authUserId } = usePermissions();
  const rel = relation || profile?.relation;
  const guestId = rel?.guest?.id;
  const isAuthedUserRelationGuest = authUserId === guestId;
  const currentUserId = profile?.user?.id as string;
  const inviteMessage = rel?.message;
  const shouldShowInviteMessage = !!(
    inviteMessage &&
    authUserId &&
    authUserId !== currentUserId &&
    rel?.status === 'pending' &&
    isAuthedUserRelationGuest
  );

  return !shouldShowInviteMessage ? null : margin ? (
    <div className={'mt-1 mb-6'}>{inviteMessage}</div>
  ) : (
    <>{inviteMessage}</>
  );
});
