import type { FC } from 'react';
import { memo, useCallback, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { FloatingLoader } from '../../../../front/src/components/layout/FloatingLoader';

const messages = defineMessages({
  longLoading: {
    defaultMessage: 'La page prend plus de temps que prévu pour charger',
    id: 'qDF8TJ',
  },
  retry: { defaultMessage: 'réessayer', id: 'XTAHjV' },
});

interface FloatingLoaderProps {
  enableTooLongWarning?: boolean;
}

export const FloatingLoaderWithTimer: FC<FloatingLoaderProps> = memo(
  function FloatingLoaderWithTimer(props) {
    const { enableTooLongWarning } = props;

    const [showReload, setShowReload] = useState(false);

    const reload = useCallback(() => window.location.reload(), []);

    // If the loader is still visible after 5 seconds, show a message indicating it takes too long to load.
    useEffect(() => {
      let timeoutId: ReturnType<typeof setTimeout>;
      if (enableTooLongWarning) {
        timeoutId = setTimeout(() => setShowReload(true), 5000 /* * 1000 */);
      }
      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }, [enableTooLongWarning]);

    return (
      <FloatingLoader
        extraContent={
          showReload ? (
            <>
              <p className="mb-2">
                <b>
                  <FormattedMessage {...messages.longLoading} />
                </b>
              </p>
              <button type="button" className="underline" onClick={reload}>
                <i className="icon-retry" />
                <FormattedMessage {...messages.retry} />
              </button>
            </>
          ) : null
        }
      />
    );
  },
);
