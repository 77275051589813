import { type PayloadAction } from '@reduxjs/toolkit';

import { createAppSlice } from '../../redux/hooks';
import type { RootState } from '../../redux/store';
import type { Category, GraphdebateHeading, Topic, TopicSearch, Website } from '../../types/api';

// If a state should not be reset on logout, check /front/src/redux/store.ts

export type Topics = Topic[];
export type Headings = GraphdebateHeading[];

export interface TopicsState {
  // We may want to merge topics, tagTopicList and the topic in topic-slice.ts for components that update a topic that are reused for all those state updates. We could use a map as we do for the messages in a topic.
  topics?: Topics;
  headings?: Headings;
  tagInfo?: Category;
  tagTopicList?: TopicSearch;
}

const initialState: TopicsState = {};

// packages/front/src/redux/store.ts
export const topicsSlice = createAppSlice({
  name: 'topics',
  initialState,
  reducers: {
    /** Called when the initial list of messages is loaded in a topic page. Either from a paginated list (sort: oldest or newest) or infinite scroll (sort: relevance). The messages tree is stored in the redux state. addMessagesToTree is the recursive function that reads the tree and extracts all relevant information to store in the state. */
    setTopicsAndHeadings(state, { payload }: PayloadAction<Website | undefined>) {
      state.headings = websiteToHeadings(payload);
      state.topics = websiteToTopics(payload);
    },
    setTopics(state, { payload }: PayloadAction<Website | undefined>) {
      state.topics = websiteToTopics(payload);
    },
    /** Called when the initial list of has not been loaded yet to display categoriesMenu. Basically when the user didn't come from home page. */
    setHeadings(state, { payload }: PayloadAction<Website | undefined>) {
      state.headings = websiteToHeadings(payload);
    },
    setTagInfo(state, { payload }: PayloadAction<Website | undefined>) {
      state.tagInfo = payload?.categoryLoad;
      state.tagTopicList = payload?.topicList;
    },
    /** When a (partial) topic updates for any reason (edit, deletion, status update...), this method updates the message object in the store. Thanks to how redux works with selectors, the rest of the app should be updated. Except the code that has not been updated yet to use redux (old code still relying on component states or Apollo cache). */
    updateTopicInTagList(state, { payload }: PayloadAction<Partial<Topic> /* | undefined */>) {
      const topic = payload;
      if (!state.tagTopicList) state.tagTopicList = {};
      if (!state.tagTopicList.topics) state.tagTopicList.topics = [];
      let topicInList = state.tagTopicList.topics.find(t => t?.id === topic.id);
      if (!topicInList) {
        topicInList = topic;
        state.tagTopicList.topics.push(topicInList);
      }
      Object.assign(topicInList, topic);
    },
  },
});

export const { setTopicsAndHeadings, setTopics, setHeadings, setTagInfo, updateTopicInTagList } =
  topicsSlice.actions;

export const selectTopics = (state: RootState) => state.topics.topics;
export const selectHeadings = (state: RootState) => state.topics.headings;
export const selectTagInfo = (state: RootState) => state.topics.tagInfo;
export const selectTagTopicList = (state: RootState) => state.topics.tagTopicList;

export function websiteToTopics(website?: Website) {
  return (website?.topicList?.topics?.filter(t => !!t) as Topic[]) ?? [];
}

export function websiteToHeadings(website?: Website) {
  return (website?.graphdebate?.headings ?? []) as GraphdebateHeading[];
}
