import { createInboxThunk, hasInboxQuery } from '../../thread/api/inbox';
import type { AppApolloClient } from '../../types/common-models';
import type { AppDispatch } from '../store';
import { openChatWindowAction } from './chat-slice';

export interface OpenChatOptions {
  authUserId?: string;
  threadId?: string;
  userId?: string;
  replaceCreation?: boolean;
  allowCache?: boolean;
}

export const openChatWindow =
  (
    client: AppApolloClient,
    { threadId, userId, replaceCreation, authUserId, allowCache }: OpenChatOptions,
  ) =>
  async (dispatch: AppDispatch) => {
    if (!threadId && !authUserId)
      throw new Error('openChatWindow needs at least threadId or authUserId, but both are falsy.');
    return threadId
      ? dispatch(
          openChatWindowAction({
            threadId,
            replaceCreation,
          }),
        )
      : hasInboxQuery(client, [authUserId!, userId as string], allowCache)
          .then(
            newThreadId =>
              newThreadId ??
              createInboxThunk([authUserId!, userId as string]).then(({ _id }) => _id),
          )
          .then(
            newThreadId => newThreadId && dispatch(openChatWindowAction({ threadId: newThreadId })),
          );
  };
