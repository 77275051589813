import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectCustomDensity, setCustomDensity } from '../../redux/slices/utils-slice';
import { isMobile } from '../../utils/web';
import type { DensityType } from './children/DensityItem';
import { densityMessages } from './children/DensityItem';
import DensityMenuDesktop from './children/DensityMenuDesktop';
import { DensityMenuMobile } from './children/DensityMenuMobile';

export type CommonDensityMenuProps = {
  options: DensityType[];
  currentDensity: DensityType;
  isCurrentDensity: (density: DensityType) => boolean;
  onDensityChange: (density: DensityType) => void;
  itemsClass?: string;
  isResponsive?: boolean;
};

export const DensityMenu = function DensityMenu({ isResponsive = true }) {
  const dispatch = useAppDispatch();
  const options = Object.keys(densityMessages).filter(type => type !== 'card') as DensityType[]; // Remove 'card' density for now (12/2023);

  const defaultDensity = 'compact';
  const currentDensity = useAppSelector(selectCustomDensity) || defaultDensity;

  const isCurrentDensity = useCallback(
    (density: DensityType) => density === currentDensity,
    [currentDensity],
  );

  const onDensityChange = useCallback(
    (dType: DensityType) => dispatch(setCustomDensity(dType)),
    [dispatch],
  );

  const commonProps = {
    options,
    currentDensity,
    isCurrentDensity,
    onDensityChange,
  };

  return isMobile() || !isResponsive ? (
    <DensityMenuMobile {...commonProps} isResponsive={isResponsive} />
  ) : (
    <DensityMenuDesktop
      className="flex rounded-md h-10 overflow-hidden border border-gray-210 divide-x-[1px] divide-gray-210 self-start"
      itemsClass="flex justify-center w-10 h-10  hover:shadow-inner"
      {...commonProps}
    />
  );
};
