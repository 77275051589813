import type { FC } from 'react';
import { memo, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';

import { CreateTopicButton } from '../../components/Button/CreateTopicButton';
import { DensityMenu } from '../../components/density/DensityMenu';
import { PeriodMenu, type PeriodMenuPropTypes } from '../../components/PeriodMenu';
import type { SortMenuPropTypes } from '../../components/SortMenu';
import { SortMenu } from '../../components/SortMenu';
import { useConnectedRouter } from '../../hooks/useConnectedRouter';
import { getCleanPathname } from '../../utils/clean-routes-utils';

export type ListActionsProps = {
  listActionsClass?: string;
  sortMenu?: Partial<SortMenuPropTypes> & { sortType: string };
  periodMenu?: PeriodMenuPropTypes;
  densityMenu?: boolean;
  createTopicCTA?: boolean;
  isResponsive?: boolean;
};

const ListActions: FC<ListActionsProps> = memo(function ListActions(props) {
  const { sortMenu, periodMenu, densityMenu, createTopicCTA, listActionsClass, isResponsive } =
    props;

  const router = useConnectedRouter();
  const { pathname, query, push } = router;

  const changeSort = useCallback(
    newSort =>
      push(
        {
          pathname: getCleanPathname(pathname),
          query: { ...query, sort: newSort },
        },
        undefined,
        { shallow: true },
      ),
    [pathname, query, push],
  );

  return (
    <div
      className={twMerge(
        'ListActions flex gap-x-2',
        isResponsive && 'md:gap-x-4',
        listActionsClass,
      )}
    >
      <div className={twMerge('sort flex gap-x-2', isResponsive && 'md:gap-x-4')}>
        {sortMenu && (
          <SortMenu
            sortType={sortMenu.sortType}
            changeSort={changeSort}
            isResponsive={isResponsive}
          />
        )}
        {periodMenu && <PeriodMenu {...periodMenu} />}
      </div>
      <div
        className={twMerge(
          'other flex-1 flex gap-x-2 justify-between',
          isResponsive && 'md:gap-x-4 md:justify-end',
        )}
      >
        {/* TODO: Rethink DensityMenu to handle mobile and desktop inside */}
        {densityMenu && <DensityMenu isResponsive={isResponsive} />}
        {createTopicCTA && (
          <CreateTopicButton
            mainContainerClass="h-10"
            className={twMerge(
              'h-10 px-2 [&>div]:gap-x-3 [&>div>span]:px-0',
              isResponsive && 'md:px-3',
            )}
            buttonRadius="rounded-md"
            {...(router.query?.tag && { params: { tag: router.query.tag as string } })}
          />
        )}
      </div>
    </div>
  );
});

export default ListActions;
