import type { PropsWithChildren } from 'react';
import React, { memo, useContext, useMemo, useState } from 'react';

export const defaultHeaderHeight = 104;

export const headerContext = React.createContext<{
  setHeight: (height: number) => void;
  height: number;
}>({
  setHeight: () => null,
  height: defaultHeaderHeight,
});

const { Provider } = headerContext;

export const HeaderHeightProvider: React.FC<PropsWithChildren<{}>> = memo(
  function HeaderHeightProvider({ children }) {
    const [height, setHeight] = useState(defaultHeaderHeight);

    const value = useMemo(
      () => ({
        setHeight,
        height,
      }),
      [height, setHeight],
    );

    return <Provider value={value}>{children}</Provider>;
  },
);

export const useHeaderHeight = () => useContext(headerContext);
