import type { FC, HTMLAttributes } from 'react';
import { memo } from 'react';
import { twMerge } from 'tailwind-merge';

type CustomRadioButtonProps = HTMLAttributes<HTMLDivElement> & { isSelected?: boolean };

const CustomRadioButton: FC<CustomRadioButtonProps> = memo(function CustomRadioButton(props) {
  const { isSelected, className, ...otherProps } = props;

  return (
    <div
      className={twMerge(
        'CustomRadioButton h-4 w-4 flex items-center justify-center border border-gray-soft rounded-full',
        className,
      )}
      {...otherProps}
    >
      {isSelected && <div className="radio-selected h-2 w-2 bg-primary-dark rounded-full" />}
    </div>
  );
});

export default CustomRadioButton;
