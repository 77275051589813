import { useEffect } from 'react';

import { useConnectedRouter } from './useConnectedRouter';
import { usePermissions } from './usePermissions';

export const useUserLocale = () => {
  const router = useConnectedRouter();
  const { profile: userProfile } = usePermissions();

  useEffect(() => {
    if (userProfile && router.locale !== userProfile.language) {
      console.log(`😃 Switch to user language (${userProfile.language})`);

      router.replace({ pathname: router.pathname, query: router.query }, undefined, {
        locale: userProfile.language,
        scroll: false,
      });
    }
  }, [router, userProfile]);
};
