import { ApolloLink } from '@apollo/client';

const timeStartLink = new ApolloLink((operation, forward) => {
  operation.setContext({ start: Date.now() });
  return forward(operation);
});

const logTimeLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(data => {
    // data from a previous link
    const time = Date.now() - operation.getContext().start;
    // eslint-disable-next-line no-console
    console.log(`operation ${operation.operationName} took ${time} to complete ${Date.now()}`);
    return data;
  });
});

export const timeLink = ApolloLink.from([timeStartLink, logTimeLink]);
