import React, { useCallback, useState } from 'react';

import { html } from '../../../../utils/dom';
import { EmbedContent } from './EmbedContent';

export type EmbedVideoProps = {
  html: string;
  thumbnail_url: string;
  title: React.ReactNode;
  description: React.ReactNode;
  provider_url?: string;
  provider_name?: string;
  initialUrl: string;
  url: string;
};

export type EmbedVideoProps2 = { type: 'video' } & EmbedVideoProps;

export const EmbedVideo: React.FC<EmbedVideoProps> = ({
  html: content,
  thumbnail_url,
  title,
  description,
  provider_url,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const startPlaying = useCallback(() => setIsPlaying(true), [setIsPlaying]);

  return (
    <EmbedContent
      source={thumbnail_url}
      media={isPlaying ? <div>{html(content)}</div> : null}
      title={
        <div role="button" onClick={startPlaying} tabIndex={-1}>
          {title}
        </div>
      }
      description={description as string}
      provider={provider_url}
    />
  );
};
