import { defineMessages } from 'react-intl';

export const TOPIC_FILTER_FAVORITE = 'favorite';
export const TOPIC_FILTER_NEW = 'new';
export const TOPIC_FILTER_POPULAR = 'popular';
export const TOPIC_FILTER_PARTICIPATED = 'participated';
export const TOPIC_FILTER_READ = 'read';
export const TOPIC_FILTER_UNANSWERED = 'unanswered';
export const TOPIC_FILTER_CREATED = 'created';

export const filterMessages = defineMessages({
  [TOPIC_FILTER_FAVORITE]: { defaultMessage: 'Sujets favoris', id: 'jUFbg2' },
  [TOPIC_FILTER_NEW]: { defaultMessage: 'Nouveaux sujets', id: 'Bsf0e1' },
  [TOPIC_FILTER_POPULAR]: { defaultMessage: 'Sujets les plus discutés', id: 'oBlXcc' },
  [TOPIC_FILTER_PARTICIPATED]: { defaultMessage: 'Sujets commentés', id: 'Y2PfpB' },
  [TOPIC_FILTER_READ]: { defaultMessage: 'Sujets en cours de lecture', id: 'wLXRCE' },
  [TOPIC_FILTER_UNANSWERED]: { defaultMessage: 'Sujets sans réponse', id: 'Kp6aHS' },
  [TOPIC_FILTER_CREATED]: { defaultMessage: 'Sujets créés', id: 'g8fyTZ' },
});

export const topicsFilterOrder = Object.keys(filterMessages);

export const topicsConnectedOnlyMap = {
  [TOPIC_FILTER_FAVORITE]: true,
  [TOPIC_FILTER_PARTICIPATED]: true,
  [TOPIC_FILTER_READ]: true,
  [TOPIC_FILTER_CREATED]: true,
};
