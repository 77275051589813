import type { NormalizedCacheObject } from '@apollo/client';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import type { GetServerSidePropsResult, GetStaticPropsResult } from 'next';

import { isBrowser } from '../../utils/fragments/isBrowser';
import type { GenerateSemiologicLinkArgs } from './links/semiologicLink';
import { generateSemiologicLink } from './links/semiologicLink';
import { semiologicTypePolicies } from './typePolicies/semiologicTypePolicies';

export type ApolloClientArgs = GenerateSemiologicLinkArgs;

export const createApolloClient = ({
  graphUrl,
  getPublicKey,
  getToken,
  triggerRefreshToken,
  logout,
}: ApolloClientArgs) =>
  new ApolloClient({
    ssrMode: !isBrowser,
    link: generateSemiologicLink({
      getToken,
      getPublicKey,
      graphUrl,
      triggerRefreshToken,
      logout,
    }),
    cache: new InMemoryCache({
      possibleTypes: {
        Author: ['Guest', 'User'],
      },
      typePolicies: semiologicTypePolicies,
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  });

// Below utilities are for the recommended approach to use Apollo with Next.
// Inspired by the official example:
// https://github.com/vercel/next.js/tree/canary/examples/with-apollo
//
// What we should NOT do: https://www.apollographql.com/docs/react/performance/server-side-rendering/#executing-queries-with-getdatafromtree
// But it is already done in this project. Let's migrate progressively to the recommended usage. (It should increase perfs by avoiding useless renders and processings.)

const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

type ApolloStateSave = {
  [APOLLO_STATE_PROP_NAME]: NormalizedCacheObject;
};

type PageProps<P> = {
  props: P;
};
type ResWithProps<P> = {
  props: P & ApolloStateSave;
};

export function addApolloState<P>(
  client: ApolloClient<NormalizedCacheObject>,
  pagePropsRes: GetStaticPropsResult<P>,
) {
  if (isPropsResult(pagePropsRes)) {
    if (!pagePropsRes) {
      throw new Error(`addApolloState cannot add Apollo prop, pageProps is undefined.`);
    }
    if (!pagePropsRes.props) {
      pagePropsRes.props = {} as P;
    }
    (pagePropsRes as ResWithProps<P>).props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pagePropsRes;
}

function isPropsResult<P>(pageProps: GetServerSidePropsResult<P>): pageProps is PageProps<P> {
  return !(pageProps as any).redirect && !(pageProps as any).notFound;
}
