import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  error: { defaultMessage: "impossible d'afficher ce genre de média", id: 'Krsf0v' },
});

export const EmbedError: React.FC = () => {
  return (
    <div className="EmbedError flex justify-center">
      <FormattedMessage {...messages.error} />
    </div>
  );
};
