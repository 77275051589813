import classNames from 'classnames';
import type { FC } from 'react';
import { memo } from 'react';

import { Loader } from './Loader';

interface Props {
  white?: boolean;
  customHeightClass?: string;
  noMargin?: boolean;
}

export const LoaderBlock: FC<Props> = memo(function LoaderBlock(props) {
  const { white, customHeightClass, noMargin } = props;
  return (
    <div
      className={classNames(
        'flex justify-center items-center',
        customHeightClass || (!noMargin && 'my-6'),
      )}
    >
      <Loader black={!white} />
    </div>
  );
});
