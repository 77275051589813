import { memo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as IconCategoriesMenuLink } from '../../icons/icon-menu-categories.svg';
import type { LayoutAsideContainerProps } from './layoutContainers';

export const SlidableArea = memo(function SlidableArea(props: LayoutAsideContainerProps) {
  const { area, className, children, isSlidableContainer, ...otherProps } = props;
  const [showCont, setShowCont] = useState(false);

  return (
    <>
      <div
        className={twMerge(
          'absolute z-[2] p-2 top-2 rounded-r-md hover:shadow-lg cursor-pointer 3colsbreak:hidden',
          !showCont && 'shadow-md',
        )}
        onClick={() => setShowCont(!showCont)}
      >
        <IconCategoriesMenuLink />
      </div>
      <div
        className={twMerge(
          'SlidableArea max-w-[90%] md:max-w-1/3 md:w-[300px] md:items-end pb-2 pt-[65px]',
          `absolute z-[1] bg-white animatedSlideShow from-${area}-side shadow-md`,
          showCont && 'open',
          className,
        )}
        {...otherProps}
      >
        {children}
      </div>
    </>
  );
});
