export const STATUS_LOADING = '' as const;
export const STATUS_UNAUTH = 'unauth' as const;
export const STATUS_GUEST = 'guest' as const;
export const STATUS_USER = 'auth' as const;
export const STATUS_MOD_ADMIN = 'moderator' as const;

const allStatuses = [STATUS_LOADING, STATUS_UNAUTH, STATUS_GUEST, STATUS_USER, STATUS_MOD_ADMIN];

export type AuthStatus = (typeof allStatuses)[number];

export function getAuthStatus(
  isAuthDone: boolean,
  loading: boolean,
  authUserId: string | undefined,
  isGuest: boolean,
  isModerator: boolean,
  isAdmin: boolean,
  isOwner: boolean,
) {
  if (!isAuthDone || loading) return STATUS_LOADING;
  if (!authUserId) return STATUS_UNAUTH;
  if (isGuest) return STATUS_GUEST;
  if (isModerator || isAdmin || isOwner) return STATUS_MOD_ADMIN;

  return STATUS_USER;
}

export function visibleForEverybody(visibilities: AuthStatus[], status: AuthStatus) {
  return (
    !visibilities.length ||
    (!status &&
      // It's not really everybody, but as long as the user is not authenticated, we show the blocks that are visible for both unauthenticated and normal authenticated users. guests and admins may have a flash, which should not be critical. We favor what the googlebot may perceive for SEO score.
      visibilities.includes(STATUS_UNAUTH) &&
      // visibilities.includes(STATUS_GUEST) &&
      visibilities.includes(STATUS_USER)) /* &&
      visibilities.includes(STATUS_MOD_ADMIN)) */
  );
}
