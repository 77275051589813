import { type PayloadAction } from '@reduxjs/toolkit';

import { createAppSlice } from '../../redux/hooks';
import type { RootState } from '../../redux/store';
import type { Topic } from '../../types/api';

// If a state should not be reset on logout, check /front/src/redux/store.ts

export interface TopicState {
  topic?: Topic;
}

const initialState: TopicState = {};

// packages/front/src/redux/store.ts
export const topicSlice = createAppSlice({
  name: 'topic',
  initialState,
  reducers: {
    /** Called when the initial list of messages is loaded in a topic page. Either from a paginated list (sort: oldest or newest) or infinite scroll (sort: relevance). The messages tree is stored in the redux state. addMessagesToTree is the recursive function that reads the tree and extracts all relevant information to store in the state. */
    setTopic(state, { payload }: PayloadAction<Topic | undefined>) {
      state.topic = payload;
    },
    // Currently a shorthand for setTopic(undefined)
    clearTopic(state) {
      state.topic = undefined;
    },

    /** When a (partial) topic updates for any reason (edit, deletion, status update...), this method updates the message object in the store. Thanks to how redux works with selectors, the rest of the app should be updated. Except the code that has not been updated yet to use redux (old code still relying on component states or Apollo cache). */
    updateTopic(state, { payload }: PayloadAction<Partial<Topic> /* | undefined */>) {
      const topic = payload;
      if (!state.topic) state.topic = {};
      Object.assign(state.topic, topic);
    },
  },
});

export const { setTopic, clearTopic, updateTopic } = topicSlice.actions;

export const selectTopic = (state: RootState) => state.topic.topic;
export const selectThreadId = (state: RootState) => state.topic.topic?.findOrCreateThread?.id;
export const selectHasTopic = (state: RootState) => !!state.topic.topic;
