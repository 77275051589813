import { useQuery } from '@apollo/client';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback } from 'react';

import type { Profile, QueryType, Relation } from '../../../types/api';
import { uniqBy } from '../../../utils/common-utils';
import { userRelationsQuery } from '../../api/relation';
import { ProfileMember } from './ProfileMember';

export const useRelations = ({
  limit,
  status = '',
  search = '',
}: {
  limit: number;
  status?: string;
  search?: string;
}) => {
  const { data, fetchMore, refetch, loading } = useQuery<QueryType>(userRelationsQuery, {
    variables: { page: 1, limit, search, status },
    // fetchPolicy: frontConfig.disableCache ? 'network-only' : 'cache-first',
    fetchPolicy: 'cache-and-network',
    skip: !!search,
  });

  const collection = data?.me?.profile?.relationSearch;
  const relations = (collection?.relations ?? []) as Relation[];
  const count = collection?.meta?.count ?? 0;
  const currentPage = collection?.meta?.page ?? 1;
  const currentCount = relations.length;
  const cannotLoadMore = loading || currentCount === 0 || currentCount >= count;

  const loadMore = useCallback(() => {
    if (cannotLoadMore) return;

    fetchMore({
      variables: {
        page: currentPage + 1,
        limit,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const result = cloneDeep(fetchMoreResult);
        if (!result?.me?.profile?.relationSearch) return previousResult;

        result.me.profile.relationSearch.relations = uniqBy(
          [
            ...(previousResult?.me?.profile?.relationSearch?.relations ?? []),
            ...(fetchMoreResult?.me?.profile?.relationSearch?.relations ?? []),
          ],
          relation => relation?.id,
        );

        return result;
      },
    });
  }, [fetchMore, currentPage, limit, cannotLoadMore]);

  return {
    total: count,
    loadMore: cannotLoadMore ? undefined : loadMore,
    refetch,
    elements: relations
      .filter(relation => relation && relation.member?.id && relation.status !== 'refused')
      .map(relation => {
        return (
          <ProfileMember
            key={relation.member?.profile?.id}
            relationId={relation.id as string}
            userId={relation.member?.id as string}
            profile={relation.member?.profile as Profile}
            user={relation.member!}
            status={relation.status as string}
            relation={relation}
          />
        );
      })
      .filter(Boolean),
    relations,
    loading,
  };
};
