import classNames from 'classnames';
import React, { memo } from 'react';

import { AuthorAvatarPseudo } from '../../../thread/AuthorAvatarPseudo';
import type { Maybe, Profile, Relation, User } from '../../../types/api';
import type { RequestFriendCallback } from '../../api/relation';
import { ProfileRelationActions } from './ProfileRelationActions';

interface Props {
  profile?: Profile;
  user: User;
  relationId: string;
  userId: string;
  status: string;
  relation: Maybe<Relation>;
  inviteCallback?: RequestFriendCallback;
  isGroup?: boolean;
}

export const ProfileMember: React.FC<Props> = memo(props => {
  let { profile, user, relationId, userId, status, relation, inviteCallback, isGroup } = props;
  if (!user && !profile) {
    throw new Error('Either profile or user must be provided');
  }
  profile = (profile || user?.profile)!;
  return profile || user ? (
    <div
      className={classNames(
        'flex flex-1 overflow-hidden items-center justify-between py-2 md:py-3 space-x-2 border-b border-gray-medium2',
      )}
    >
      <AuthorAvatarPseudo
        author={user}
        userId={userId}
        profile={profile}
        size={isGroup ? 'vbig' : 'medium'}
        withInvite
        firstChild
        inviteCallback={inviteCallback}
        center
        isGroup={isGroup}
      />
      <ProfileRelationActions
        currentUserId={userId}
        relationId={relationId}
        status={status}
        profile={profile}
        relation={relation}
        inviteCallback={inviteCallback}
      />
    </div>
  ) : null;
});
