import { SourceMapConsumer } from 'source-map-js';

type SourcePosition = {
  line?: number;
  column?: number;
};

export const getOriginalFunctionInfosFromSourceMap = async (
  sourceURL: string,
  sourcePosition: SourcePosition,
) => {
  const { line = 1, column = 1 } = sourcePosition;

  if (!sourceURL.endsWith('.js')) return null;

  try {
    const response = await fetch(`${sourceURL}.map`);
    if (!response) throw new Error('Error while getting sources');

    const sourceMap = await response.json();

    const consumer = new SourceMapConsumer(sourceMap);
    if (!consumer) throw new Error('Error while generating sources consumer');

    const originalPosition = consumer.originalPositionFor({
      bias: SourceMapConsumer.GREATEST_LOWER_BOUND,
      line,
      column,
    });

    return {
      mapName: originalPosition?.name || 'Unknown function name',
      mapSource: originalPosition?.source || 'Unknown source URL',
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};
