import type { FC } from 'react';
import { memo } from 'react';

import { IconSortNewest, IconSortOldest, IconSortPopularity } from './Icons';

type SortIconProps = React.SVGAttributes<SVGElement> & {
  sortType: string;
};

const SortIcon: FC<SortIconProps> = memo(function SortIcon(props) {
  const { sortType, ...iconProps } = props;

  switch (sortType) {
    case 'relevance':
      return <IconSortPopularity {...iconProps} />;
    case 'newest':
      return <IconSortNewest {...iconProps} />;
    case 'oldest':
      return <IconSortOldest {...iconProps} />;
    default:
      console.error(`Sort icon for ${sortType} doesn't exist.`);
      return <></>;
  }
});

export default SortIcon;
