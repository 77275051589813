import type { FC, PropsWithChildren } from 'react';
import { memo } from 'react';
import { twMerge } from 'tailwind-merge';

type GradientBorderContainerProps = {
  from: `from-${string}`;
  via?: `via-${string}`;
  to: `to-${string}`;
  direction?: 'top' | 'right';
  className?: string;
  contentContainerClass?: string;
};

const twDirectionClass = {
  right: 'bg-gradient-to-r',
  top: 'bg-gradient-to-t',
};

/**
 * Takes 2 tailwind colors to generate the gradient border.
 *
 * Colors passed as parameters should follow tw gradient format (from-XXXX via-XXX to-XXXX).
 *
 * @param from - string
 * @param via - (optional) string
 * @param to - string
 * @param direction - (optional) 'top' | 'right'
 *
 * @example
 * // If we want a gradient from #8AB6E2 to fern (tw color in tw config)
 * <GradientBorderContainer from='from-[#8AB6E2]' to='to-fern' >
 *   [container content]
 * </GradientBorderContainer>
 *
 */
const GradientBorderContainer: FC<PropsWithChildren<GradientBorderContainerProps>> = memo(
  function GradientBorderContainer(props) {
    const { from, via, to, direction = 'top', className, contentContainerClass, children } = props;

    return (
      <div
        className={twMerge(
          'GradientBorderContainer h-10 rounded-md p-[1px]',
          twDirectionClass[direction],
          from,
          via,
          to,
          className,
        )}
      >
        <div
          className={twMerge(
            'h-full flex items-center justify-center bg-white rounded-[5px]',
            contentContainerClass,
          )}
        >
          {children}
        </div>
      </div>
    );
  },
);

export default GradientBorderContainer;
