import classnames from 'classnames';
import type { FC } from 'react';
import { memo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Tooltip } from '../../components/GraphModal/Tooltip';
import { ReactComponent as IconMedal } from '../../icons/icon-medal-inline.svg';

export const rewardTypeTextClassnames = {
  copper: { text: 'text-bronze-400', bg: 'bg-bronze-400' },
  silver: { text: 'text-gray-350', bg: 'bg-gray-350' },
  gold: { text: 'text-yellow-250', bg: 'bg-yellow-250' },
} as const;

export type RewardsKeys = keyof typeof rewardTypeTextClassnames;
export const rewardsType = Object.keys(rewardTypeTextClassnames) as Array<RewardsKeys>;

export const intlRewards = defineMessages({
  autobiographer: {
    defaultMessage: 'Compléter la section "Mon profil" du profil utilisateur',
    id: 'stB7jG',
  },
  selfier: { defaultMessage: "Change l'avatar par défaut", id: 'i4weyv' },
  commentator: { defaultMessage: 'Laissez 10 commentaires', id: '1jX+Gn' },
  optimistic: { defaultMessage: 'Laissez 10 commentaires qui ont 1 vote positif. ', id: 'WjGX1a' },
  enthusiast: {
    defaultMessage:
      'Visitez le site chaque jour pendant 30 jours consécutifs. (Les jours sont comptés en UTC.)',
    id: 'G7KZ+F',
  },
  fanatic: {
    defaultMessage:
      'Visitez le site chaque jour pendant 100 jours consécutifs. (Les jours sont comptés en UTC.)',
    id: 'sMLMQY',
  },
  spark: { defaultMessage: 'Obtenez un vote Brillant 1 fois sur vos commentaires', id: 'k9ElHV' },
  epic: { defaultMessage: 'Obtenez un vote Brillant 100 fois sur vos commentaires', id: 'i1YlzS' },
  legendary: {
    defaultMessage: 'Obtenez un vote Brillant 1000 fois sur vos commentaires',
    id: 'nrHKYg',
  },
  friendly: { defaultMessage: 'Partager 10 discussions sur les réseaux sociaux', id: 'fKq7/P' },
  people: { defaultMessage: 'Partager 150 discussions sur les réseaux sociaux', id: 'U4Tu1z' },
  'serial sharer': {
    defaultMessage: 'Partager 1000 discussions sur les réseaux sociaux',
    id: '5DNKeg',
  },
  hyperlinker: {
    defaultMessage: 'Intégrez 10 fois un contenu dans vos commentaires',
    id: 'RDpRfC',
  },
  journalist: {
    defaultMessage: 'Intégrez 150 fois un contenu dans vos commentaires',
    id: 'ogEraq',
  },
  broadcaster: {
    defaultMessage: 'Intégrez 1000 fois un contenu dans vos commentaires',
    id: 'aomo+v',
  },
  influencer: { defaultMessage: 'Être sélectionné 1 fois par la rédaction. ', id: '2CD0bO' },
  'guest star': { defaultMessage: 'Être sélectionné 25 fois par la rédaction. ', id: 'S6pZmP' },
  rasputin: { defaultMessage: 'Être sélectionné 100 fois par la rédaction. ', id: 'E6ooqu' },
  firefighter: { defaultMessage: 'Signaler une première infraction', id: 'k9o5jS' },
  citizen: { defaultMessage: 'Premier vote négatif (hors sujet ou pire)', id: '29gj3Y' },
  lobbyist: { defaultMessage: 'Atteindre 80 votes négatifs', id: '9hK7wx' },
  detractor: { defaultMessage: 'Atteindre 500 votes négatifs', id: 'e127KE' },
  supporter: { defaultMessage: "Premier vote (j'aime ou supérieur)", id: '+KJ070' },
  deputy: { defaultMessage: 'Atteindre 80 votes positifs', id: 'RXeAoh' },
  marshall: { defaultMessage: 'Atteindre 500 votes positifs', id: 'NDppWV' },
  'civic duty': { defaultMessage: 'Votez 1 000 fois ou plus', id: 'RDKg93' },
  senator: { defaultMessage: 'Votez 30 fois dans une journée', id: '+dEqgl' },
  sheriff: {
    defaultMessage: 'Agir en tant que modérateur désigné pendant au moins un an',
    id: 'HH6ET1',
  },
  author: { defaultMessage: 'Créer mon premier sujet', id: 'ZrDXXS' },
  bestseller: { defaultMessage: 'Créer 10 sujets qui ont 1 vote positif', id: 'naVH+h' },
  bright: { defaultMessage: 'Obtenez 10 votes "Brillant" sur un de vos sujets', id: 'aegAl5' },
  'team spirit': { defaultMessage: 'Rejoignez un groupe', id: 'VRPQOd' },
  'tribe chief': { defaultMessage: 'Créez votre propre groupe', id: 'e+9dfA' },
  leader: { defaultMessage: 'Obtenez plus de 50 membres à votre groupe', id: 'JplAv5' },
  networker: { defaultMessage: 'Invitez votre premier ami', id: 'Xqjd/R' },
  shiny: { defaultMessage: 'Obtenez un vote Brillant 100 fois sur tous vos sujets', id: 'L98897' },
  preacher: { defaultMessage: 'Obtenez plus de 250 membres à votre groupe', id: 'VLybZG' },
  'world star': { defaultMessage: 'Atteindre 500 votes positifs sur vos sujets', id: '9as28+' },
  celebrity: { defaultMessage: 'Obtenez plus de 1000 membres à votre groupe', id: 'aoU+hn' },
  conqueror: { defaultMessage: 'Obtenez un vote Brillant 1000 fois sur vos sujets', id: 'VTScsf' },
  jetsetter: { defaultMessage: 'Obtenez plus de 25 AMIS ', id: 'ZmdvFJ' },
});

export const rewardsDefinitions = [
  { name: 'commentator', type: 'copper' },
  { name: 'optimistic', type: 'copper' },
  { name: 'spark', type: 'copper' },
  { name: 'firefighter', type: 'copper' },
  { name: 'influencer', type: 'copper' },
  { name: 'citizen', type: 'copper' },
  { name: 'supporter', type: 'copper' },
  { name: 'selfier', type: 'copper' },
  { name: 'autobiographer', type: 'copper' },
  { name: 'author', type: 'copper' },
  { name: 'bright', type: 'copper' },
  { name: 'team spirit', type: 'copper' },
  { name: 'tribe chief', type: 'copper' },
  { name: 'leader', type: 'copper' },
  { name: 'networker', type: 'copper' },
  { name: 'friendly', type: 'copper' },
  { name: 'deputy', type: 'silver' },
  { name: 'civic duty', type: 'silver' },
  { name: 'guest star', type: 'silver' },
  { name: 'epic', type: 'silver' },
  { name: 'lobbyist', type: 'silver' },
  { name: 'bestseller', type: 'silver' },
  { name: 'shiny', type: 'silver' },
  { name: 'people', type: 'silver' },
  { name: 'preacher', type: 'silver' },
  { name: 'rasputin', type: 'gold' },
  { name: 'detractor', type: 'gold' },
  { name: 'marshall', type: 'gold' },
  { name: 'legendary', type: 'gold' },
  { name: 'world star', type: 'gold' },
  { name: 'celebrity', type: 'gold' },
  { name: 'conqueror', type: 'gold' },
  { name: 'jetsetter', type: 'gold' },
] as Array<{ name: keyof typeof intlRewards; type: RewardsKeys }>;

export type RewardProps = {
  type: keyof typeof rewardTypeTextClassnames;
  name: keyof typeof intlRewards;
};

export const RewardCard: FC<RewardProps> = memo(function RewardCard(props) {
  const { type, name } = props;
  return (
    <Tooltip content={<FormattedMessage {...intlRewards[name]} />}>
      <div
        className={classnames(
          'flex justify-center items-center px-3 py-1 truncate text-white rounded-full select-none',
          rewardTypeTextClassnames[type].bg,
        )}
      >
        <span className="opacity-75">
          <IconMedal />
        </span>
        <span className="mx-1">{name}</span>
      </div>
    </Tooltip>
  );
});
