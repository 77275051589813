import jsonp from 'fetch-jsonp';

import axios from '../services/axios';
import type { EmbedImageProps2 } from '../thread/Content/MessageContent/Embed/EmbedImage';
import type { EmbedEntry } from '../thread/Content/MessageContent/Embed/EmbedLink';
import type { EmbedOGProps2 } from '../thread/Content/MessageContent/Embed/EmbedOpenGraph';
import type { EmbedVideoProps2 } from '../thread/Content/MessageContent/Embed/EmbedVideo';
import type { Website } from '../types/api';
import { handleError } from './common-utils';

const embedCache = {} as Record<string, EmbedEntry | undefined>;

// https://stackoverflow.com/a/6041965/4053349
export const embedRegex =
  /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g;
// /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/g;

const IMAGE_REGEX = /\bhttps?:\/\/[^/\s]+\/\S+\.(?:gif|jpe?g|tiff|png|svg|webp)\b/gi;

const REGEX_YOUTUBE =
  /(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/ytscreeningroom\?v=|\/feeds\/api\/videos\/|\/user\S*[^\w\-\s]|\S*[^\w\-\s]))([\w-]{11})[?=&+%\w-]*/i;

const REGEX_DAILYMOTION = /dailymotion.com\/video\/([a-zA-Z0-9-_]+)/i;

const REGEX_TWITCH = /twitch.tv\/[a-zA_Z0-9_]+/i;

const REGEX_TWITTER = /twitter\.com?\/.+\/status\/.+/i;

const REGEX_VIMEO =
  /vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)*/i;

const REGEX_FLICKR = /flickr\.com\/photos\/.+/i;

/**
 * Use a free HTTPS image proxy if the image is not secured
 */
export function proxifyImage(url: string) {
  if (url && url.indexOf('http://') === 0) {
    return `https://images.weserv.nl/?url=${encodeURIComponent(url.substr(7))}`;
  }
  return url;
}

/**
 * Load a script from the url
 */
const injectedScripts: string[] = [];

export function loadScript(url: string, onloadCallback: () => void) {
  if (injectedScripts.indexOf(url) === -1) {
    const script = window.document.createElement('script');
    script.async = true;
    script.src = url;
    if (onloadCallback) {
      script.onload = onloadCallback;
    }
    window.document.body.appendChild(script);
  }
}

/**
 * Format a URL, usage:
 *
 * formatUrl('http://example.com', { foo: '1', bar: '2' })
 * => http://example.com?foo=1&bar=2
 */
export function formatUrl(url: string, params: Record<string, string>) {
  let result = url;
  Object.keys(params).forEach((param, i) => {
    const val = encodeURIComponent(params[param]);
    result += `${i === 0 ? '?' : '&'}${param}=${val}`;
  });
  return result;
}

async function scrap(url: string) {
  try {
    // Custom config: `noError` to avoid showing the error toast.
    const resp = await axios.post('/api/pub/scrap', { url }, { noError: true } as any);
    return resp?.data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(`Error fetching embed:\n\n${url}\n\n${err}`);
    handleError(err);
    return undefined;
  }
}

function fetchYoutube(videoId: string, initialUrl: string) {
  return async () => {
    const url = `https://www.youtube.com/watch?v=${videoId}`;
    const videoDetails = await scrap(url);
    if (!videoDetails) {
      return;
    }

    const embed: EmbedVideoProps2 = {
      type: 'video',
      provider_name: 'YouTube',
      provider_url: 'https://www.youtube.com/',
      title: videoDetails.title,
      description: (videoDetails.description || '').replace(/\n|&#10;/g, ' '),
      initialUrl,
      thumbnail_url: videoDetails.image,
      // thumbnail_height: videoDetails.snippet.thumbnails.medium.height,
      // thumbnail_width: videoDetails.snippet.thumbnails.medium.width,
      // view_count: videoDetails.statistics.viewCount,
      // like_count: videoDetails.statistics.likeCount,
      url: `https://www.youtube.com/watch?v=${videoId}`,
      html: `<iframe width=" 459" height="344" src="https://www.youtube.com/embed/${videoId}?feature=oembed&autoplay=1" frameborder="0" allowfullscreen="allowfullscreen"></iframe>`,
    };
    return embed;
  };
}

function fetchOembed(initialUrl: string, oembedUrl: string, jsonpCallbackParam?: string) {
  return async () => {
    // Spotify bug fix:
    // Spotify removes special characters from the callback function name.
    // angular.callbacks._1 becomes angularcallbacks1

    /*
                                        const jsonpCount = angular.callbacks.$$counter.toString(36);
                                        const jsonpCallbackAlt = 'angularcallbacks' + angular.callbacks.$$counter.toString(36);
                                        window[jsonpCallbackAlt] = function(json) {
                                          angular.callbacks['_' + jsonpCount](json);
                                        };
                                        */

    const data = await jsonp(oembedUrl, {
      jsonpCallback: jsonpCallbackParam || 'callback',
      timeout: 10 * 1000,
    });

    const resp = (await data.json()) as EmbedOGProps2;

    if (!resp || (resp as any).error) {
      throw new Error(`Error fetching embed: ${oembedUrl}`);
    }

    resp.initialUrl = initialUrl;

    return resp;
  };
}

// Try to read Open Graph meta tags with the help of YQL by Yahoo
function fetchOpenGraph(url: string) {
  return async () => {
    const meta = await scrap(url);

    // We can't display the embed if the title and the description are missing
    if (
      !meta ||
      (!meta['og:title'] && !meta.title && !meta['og:description'] && !meta.description)
    ) {
      return;
    }

    // Success! Let's extract all meta data
    const info = {
      type: 'opengraph',
      provider_url: meta['og:url'] ? meta['og:url'] : url,
      title: meta['og:title'] || meta.title,
      description: meta['og:description'] || meta.description,
      initialUrl: url,
    } as EmbedOGProps2;

    if (meta['og:site_name']) {
      info.provider_name = meta['og:site_name'];
    }

    if (meta['og:image:secure_url']) {
      meta['og:image'] = meta['og:image:secure_url'];
    }
    meta['og:image'] = proxifyImage(meta['og:image']);

    if (meta['og:image']) {
      info.image = meta['og:image'];
    } else if (meta.image) {
      info.image = meta.image;
    }

    // eslint-disable-next-line
    return info;

    // Failure
    // return $q.reject(response.status + ' ' + response.statusText);
  };
}

/**
 * Fetch embed (if any format matches),
 * and return a callback() to fetch informations
 */
export const fetchEmbed = async (url: string) => {
  const cacheKey = url as keyof typeof embedCache;
  if (embedCache[cacheKey]) return embedCache[cacheKey];

  let callback: (() => Promise<EmbedEntry | undefined>) | undefined = undefined;

  if (url.match(REGEX_YOUTUBE)) {
    callback = fetchYoutube(RegExp.$1, url);
  }

  if (url.match(REGEX_DAILYMOTION)) {
    callback = fetchOembed(
      url,
      `https://www.dailymotion.com/services/oembed?autoplay=true&url=http://www.dailymotion.com/video/${RegExp.$1}`,
    );
  }

  if (url.match(REGEX_TWITCH)) {
    callback = fetchOembed(
      url,
      `https://api.twitch.tv/v5/oembed?autoplay=true&url=${encodeURIComponent(url)}`,
    );
  }

  if (url.match(REGEX_VIMEO)) {
    callback = fetchOembed(
      url,
      `https://vimeo.com/api/oembed.json?autoplay=true&url=${encodeURIComponent(url)}`,
    );
  }

  if (url.match(REGEX_FLICKR)) {
    callback = fetchOembed(
      url,
      `https://www.flickr.com/services/oembed/?format=json&url=${encodeURIComponent(url)}`,
      'jsoncallback',
    );
  }

  // // TODO fix me
  // const REGEX_INSTAGRAM = /instagr\.?am(\.com)?\/.+/i;
  // if (url.match(REGEX_INSTAGRAM)) {
  //   // don't load the Instagram widget on small screens
  //   if (window.document.body.offsetWidth > 400) {
  //     callback = fetchOembed(
  //       url,
  //       `https://api.instagram.com/oembed/?maxwidth=320&lang=${
  //         locale().key
  //       }&omitscript=true&url=${encodeURIComponent(url)}`,
  //     );
  //     loadScript('https://platform.instagram.com/en_US/embeds.js', () => {
  //       setInterval(() => window.instgrm.Embeds.process(), 1500);
  //     });
  //   }
  // }
  //
  // // TODO fix me
  // const REGEX_SPOTIFY = /open\.spotify\.com\/(track|album|user|playlist)\/[a-zA-Z0-9-_]+/i;
  // if (url.match(REGEX_SPOTIFY)) {
  //   //callback = fetchOembed(url, `https://open.spotify.com/oembed?url=${encodeURIComponent(url)}`);
  //   callback = fetchOembed(url, `https://embed.spotify.com/oembed?url=${encodeURIComponent(url)}`);
  // }

  if (url.match(REGEX_TWITTER)) {
    // don't load the Twitter widget on small screens
    if (window.document.body.offsetWidth > 400) {
      callback = fetchOembed(
        url,
        formatUrl('https://publish.twitter.com/oembed', {
          url,
          // lang: locale(),
          omitscript: 'true',
          // theme: CommentService.website.theme || 'light',
        }),
      );
      loadScript('https://platform.twitter.com/widgets.js', () => {
        // @ts-ignore
        setInterval(() => window.twttr.widgets.load(), 1500);
      });
    }
  }

  if (isImageUrl(url)) {
    callback = async () => ({
      type: 'image',
      url,
      provider_url: url,
    });
  }

  // none of the above worked, let's try Open Graph
  if (!callback) {
    callback = fetchOpenGraph(url);
  }

  embedCache[cacheKey] = await callback();
  return embedCache[cacheKey];
};

export function isImageUrl(url: string) {
  return !!url.match(IMAGE_REGEX);
}

export function isEmbedOfTypeImage(embed: EmbedEntry | undefined): embed is EmbedImageProps2 {
  return embed?.type === 'image';
}

export function shouldRenderEmbed(
  isImg: boolean,
  url: string,
  website: Website | undefined,
  listOfLinkEmbeds: string[],
) {
  if (isImg) {
    return true;
    // if (!shouldRenderImagePreview(url, website, listOfImagesWithPreview)) {
    //   return false;
    // }
  } else {
    if (!shouldRenderLinkEmbed(url, website, listOfLinkEmbeds)) {
      return false;
    }
  }
  return true;
}

export function shouldRenderImagePreview(
  url: string,
  website: Website | undefined,
  listOfImagesWithPreview: string[],
) {
  // First, check if we reached the max number of allowed images rendered in the message.
  const nbImagesMax = getNbImagesMax(website);
  if (listOfImagesWithPreview.length >= nbImagesMax) {
    return false;
  }
  // Then, check if the image was already rendered somewhere else in the message.
  return !listOfImagesWithPreview.includes(url);
}

export function shouldRenderLinkEmbed(
  url: string,
  website: Website | undefined,
  listOfLinkEmbeds: string[],
) {
  // First, check if the link embed is enabled.
  const embedsEnabled = getEmbedsEnabled(website);
  if (!embedsEnabled) {
    return false;
  }
  // Then, check if the link embed was already rendered somewhere else in the message.
  return !listOfLinkEmbeds.includes(url);
}

export function getNbImagesMax(website: Website | undefined) {
  const maxEmbeddedImages = website?.graphdebate?.embededFiles;
  return maxEmbeddedImages === null ? 0 : maxEmbeddedImages ?? 50;
}

export function getEmbedsEnabled(website: Website | undefined): boolean {
  // defaults to true
  return !!website?.graphdebate?.embededExternalLink ?? true;
}
