import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';
import { Collapse } from 'react-collapse';
import type { FormRenderProps } from 'react-final-form';
import { Form } from 'react-final-form';
import { defineMessages, FormattedMessage } from 'react-intl';

import { confirmationToast, failureToast } from '../../../components/Toast';
import { useConnectedRouter } from '../../../hooks/useConnectedRouter';
import { useAuthProfile } from '../../../hooks/usePermissions';
import { BasicProfileFragment } from '../../../services/graphql-queries';
import type { GraphApiMutations, Profile } from '../../../types/api';
import { textToHtml } from '../../../utils/dom';
import { UserProfileMutation } from '../../api/user';

const messages = defineMessages({
  confirmNewData: { defaultMessage: 'Vos données on été enregistrées', id: '06CP0U' },
  failureNewData: { defaultMessage: "Impossible d'enregistrer vos données", id: 'FSWczr' },
});
const intlErrors = defineMessages({
  "the bio isn't good": {
    defaultMessage: 'La description de votre profil est invalide',
    id: 'rLTcIV',
  },
  'pseudo already used': {
    defaultMessage: "Le pseudonyme choisi n'est pas disponible",
    id: 'ZjZu3x',
  },
  'bio too long': {
    defaultMessage: 'La description de votre profil est trop longue.',
    id: 'czsuvm',
  },
});

const formSubscription = {};

interface Props {
  onEdited?: (elt: unknown) => void;
  renderForm: ({ handleSubmit }: FormRenderProps) => JSX.Element;
  profile?: Profile;
}

export const ProfileEditionForm: React.FC<Props> = ({ onEdited, renderForm, profile }) => {
  const { replace, push, query, locale: language } = useConnectedRouter();
  const [updateProfile, { client }] = useMutation<GraphApiMutations>(UserProfileMutation);

  const { profile: currentProfile } = useAuthProfile();
  // Once the API supports editing others' profile as admin, uncomment here and above.
  let { id: profileId, pseudo, proStatus, twitterUsername, bio, user } = profile || currentProfile;
  const { id } = user || {};

  // Convert back <br /> to \n for the form initial value. (see submit below for the other way around)
  bio = bio?.replaceAll('<br />', '\n');

  const onSubmit = useCallback(
    async (variables: Profile) => {
      try {
        const isNewPseudo = variables.pseudo !== pseudo;

        // Preserve line breaks: convert \n to <br /> in the bio
        variables.bio = textToHtml((variables.bio || '') as string);

        const { data, errors } = await updateProfile({
          variables,
          update: (proxy, result) =>
            // @FIX remove once dataloader can properly index a Profile from its pseudo OR userId
            proxy.writeFragment({
              id: `Profile:${result?.data?.createOrEditProfile?.id || profileId}`,
              fragment: BasicProfileFragment,
              data: result?.data?.createOrEditProfile,
            }),
        });
        if (errors?.length) throw new Error(errors[0].message);
        const newProfile = data?.createOrEditProfile;
        if (!newProfile) throw new Error('no updated profile');

        confirmationToast(<FormattedMessage {...messages.confirmNewData} />);
        await client.resetStore(); // Causes a small delay before the redirection, if the pseudo changes

        if (isNewPseudo || query.action) {
          let { action, ...query2 } = query;
          if (isNewPseudo) {
            query2 = { ...query2, pseudo: newProfile.pseudo };
          }
          if (isNewPseudo) {
            await replace({
              query: query2,
            });
          } else {
            await push({
              query: query2,
            });
          }
        }

        if (onEdited) onEdited(newProfile);
      } catch (e) {
        const err = e as Error;
        const errorMessage =
          intlErrors[err.message as keyof typeof intlErrors] ?? messages.failureNewData;
        failureToast(<FormattedMessage {...errorMessage} />);
      }
    },
    [pseudo, updateProfile, onEdited, client, query, profileId, replace, push],
  );

  return (
    <Collapse
      key="info"
      isOpened
      theme={{
        collapse: 'flex-1 transition-all duration-200',
      }}
    >
      <Form
        onSubmit={onSubmit}
        render={renderForm}
        subscription={formSubscription}
        initialValues={{ id, pseudo, proStatus, twitterUsername, bio, language }}
      />
    </Collapse>
  );
};
