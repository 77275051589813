import { useCallback, useEffect, useMemo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Link } from '../../../../../front/src/components/Link';
import { useRelations } from '../../../../../front/src/contributor/Profile/ProfileRelations/useRelations';
import { useAuthProfile, usePermissions } from '../../../../../front/src/hooks/usePermissions';
import { useWaitForElement } from '../../../../../front/src/hooks/useWaitForElement';
import type { Relation } from '../../../../../front/src/types/api';
import { debounce, handleError } from '../../../../../front/src/utils/common-utils';
import { useRefetchOnOpenAndBg } from '../header-utils';
import type { HeaderMenuItemProps } from '../HeaderMenuItemWrapper';
import { HeaderMenuItemWrapper, SubHeaderMenuTitle } from '../HeaderMenuItemWrapper';
import { FriendItem } from './FriendItem';

const intl = defineMessages({
  yourFriends: { defaultMessage: 'Vos amis', id: 'SBXByq' },
  addedYou: { defaultMessage: 'Vous a ajouté', id: '9zF2vI' },
  addFriend: { defaultMessage: 'Ajouter un ami', id: 'F3AkTA' },
  noFriends: { defaultMessage: 'Aucun ami', id: 'Co8gAE' },
});

const isPending = (r: Relation) => r.status === 'pending';
const canAccept = (r: Relation, id: string) => isPending(r) && r.author?.id !== id;
const sortRelationsPerStatusAndPseudo = (authUserId: string) => (a: Relation, b: Relation) => {
  // Show pending invitations that user needs to accept/deny first
  if (canAccept(b, authUserId)) return 1;
  if (canAccept(a, authUserId)) return -1;
  // Show accepted friends next
  if (!isPending(b)) return 1;
  if (!isPending(a)) return -1;
  // Show pending invitations sent by user, sorted by pseudo
  return String(a?.member?.profile?.pseudo) < String(b?.member?.profile?.pseudo) ? -1 : 1;
};

export const Friends = ({ tip, limit = 20, activeColor, onCountChange }: HeaderMenuItemProps) => {
  const {
    profile: { pseudo },
  } = useAuthProfile();
  const { authUserId } = usePermissions();

  // Load friends (first menu item)
  const { total, loadMore, refetch, relations, loading } = useRelations({
    limit,
    search: '',
  });

  useRefetchOnOpenAndBg(tip, refetch);

  const canLoadMore = !loading && relations.length < total;

  const pendingRequestsCount = useMemo(
    () =>
      (relations || []).filter(
        relation => relation.status === 'pending' && relation.author?.id !== authUserId,
      ).length,
    [relations, authUserId],
  );

  const fetchMoreData = useCallback(() => {
    if (loading || !canLoadMore || !loadMore) {
      return;
    }
    loadMore();
  }, [canLoadMore, loadMore, loading]);

  const debouncedFetch = debounce(fetchMoreData, 500);

  const handleScrollBottomReached = useCallback(() => {
    if (canLoadMore) {
      debouncedFetch();
    }
  }, [canLoadMore, debouncedFetch]);

  useEffect(
    () => (typeof onCountChange === 'function' ? onCountChange(pendingRequestsCount) : undefined),
    [onCountChange, pendingRequestsCount],
  );

  const waitForElement = useWaitForElement('search-friend');

  const hideTip = useCallback(() => tip?.hide(), [tip]);

  const handleOpenFriendsPage = useCallback(() => {
    // Extra work in addition to the navigation:
    // Hide the popover
    tip?.hide();
    // Wait for the friends section to be available, and scroll to it.
    waitForElement()
      .then(el => {
        // element was found, scroll to it
        el.scrollIntoView({ behavior: 'smooth' });
      })
      .catch(err => handleError(err));
  }, [tip, waitForElement]);

  return (
    <HeaderMenuItemWrapper
      onScrollBottomReached={handleScrollBottomReached}
      activeColor={activeColor}
      loading={loading}
      hasMore={canLoadMore}
      headerContent={
        <div className="w-full flex items-center justify-between">
          <SubHeaderMenuTitle>
            <Link
              to="profile"
              params={{
                pseudo,
                tab: 'friends',
              }}
              passHref
            >
              <a onClick={hideTip} className="hover:underline">
                <FormattedMessage {...intl.yourFriends} />
              </a>
            </Link>
          </SubHeaderMenuTitle>

          {pseudo && (
            <Link
              to="profile"
              params={{
                pseudo,
                tab: 'friends',
                action: 'searchUsers',
              }}
              passHref
            >
              <a onClick={handleOpenFriendsPage} className="hover:underline tracking-4 text-sm">
                <FormattedMessage {...intl.addFriend} />
              </a>
            </Link>
          )}
        </div>
      }
      content={
        <>
          {!loading && total === 0 && (
            <div className="p-2 text-center text-gray-400 bg-white">
              <FormattedMessage {...intl.noFriends} />
            </div>
          )}

          {relations
            // TODO AOL temporary addition to avoid logging errors in FriendItem.
            .filter(relation => !!relation.member?.profile)
            .slice()
            .sort(sortRelationsPerStatusAndPseudo(authUserId))
            .map(relation => (
              <FriendItem key={relation.id} relation={relation} tip={tip} />
            ))}
          {loading && <div className="loader loader-centered" />}
        </>
      }
    ></HeaderMenuItemWrapper>
  );
};
