import type { MessageId, ReduxMessage } from '../ThreadMessage/thread-slice';

export function getAncestorId(
  messageId: MessageId | undefined,
  messages: Record<string, ReduxMessage>,
): string | undefined {
  if (!messageId) return undefined;
  const msg = messages[messageId];
  if (!msg) throw new Error(`[getAncestorId2] msg not found for messageId ${messageId}`);
  if (!msg.parentMessageId) return messageId;
  return getAncestorId(msg.parentMessageId, messages);
}
