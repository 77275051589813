module.exports = {
    env: 'alphacircle',
    isDev: false,
    noIndex: false,
    enableCache: false,
    shouldPreventCustomHead: false,
    shouldPreventThirdParties: false,
    graphloginUrl: 'https://devlogin.graphcomment.com',
    integrationUrl: 'https://devintegration.graphcomment.com/graphlogin.js',
    websiteKey : 'AlphaCircle',
    SPEED_UP_PARTIAL_SSR: false,
    SHOW_BURGER_MENU: true,
    ISR_REVALIDATE_TOKEN: 'bca88b5b0f2ae59d15c62817509b556e363f64acb70ca50e83c7d5ed5844a596'
};
