import { useApolloClient } from '@apollo/client';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';
import { defineMessages } from 'react-intl';

import { failureToast } from '../../../../front/src/components/Toast';
import type { AuthPermissions } from '../../../../front/src/hooks/usePermissions';
import { usePermissions } from '../../../../front/src/hooks/usePermissions';
import { useQueryCallback } from '../../../../front/src/hooks/useQueryCallback';
import { useWebsite } from '../../../../front/src/hooks/useWebsite';
import { useAppDispatch } from '../../../../front/src/redux/hooks';
import { readSelectorOnce } from '../../../../front/src/redux/redux.utils';
import type { RootState } from '../../../../front/src/redux/store';
import type {
  Grade,
  Group,
  GroupMember,
  GroupMemberSearch,
  QueryType,
} from '../../../../front/src/types/api';
import { handleError } from '../../../../front/src/utils/common-utils';
import { getGroupMembersQuery } from './2-GroupMembers';
import {
  addMembers,
  selectAllMembers,
  selectHasGroupAdminPermissions,
  setCounts,
  setErrorGroup,
  setErrorMembers,
  setGroupAndCurrentMember,
  setLoadingMembers,
  setNewMembers,
} from './group-members-slice';
import { getGroupQuery } from './groupQuery';

export const groupMessages = defineMessages({
  members: {
    defaultMessage: `{count, plural,
      =0 {Membre}
      =1 {Membre}
      other {Membres}
    }`,
    id: 'SvRMLg',
  },
  admins: {
    defaultMessage: `{count, plural,
      =0 {Admin}
      =1 {Admin}
      other {Admins}
    }`,
    id: 'OMzXMW',
  },
});

export function checkJoinGroupIsPending(groupMember: GroupMember | undefined) {
  return groupMember?.status === 'pending';
}

export function checkCurrentUserBelongsToThisGroup(groupMember: GroupMember | undefined) {
  const userListedInGroup = !!groupMember;
  const joinGroupIsPending = checkJoinGroupIsPending(groupMember);

  return userListedInGroup && !joinGroupIsPending && groupMember?.status !== 'deleted';
}

// /!\ it does not include the me.profile.groupIsAdmin info
export function checkHasGroupAdminPermissions(
  groupMember: GroupMember | undefined,
  me: AuthPermissions | undefined,
  group: Group | undefined,
) {
  return (
    checkHasGroupOwnerPermissions(groupMember, me, group) || groupMember?.grade?.role === 'admin'
  );
}

// /!\ it does not include the me.profile.groupIsOwner info
export function checkHasGroupOwnerPermissions(
  groupMember: GroupMember | undefined,
  me: AuthPermissions | undefined,
  group: Group | undefined,
) {
  const { isSuperAdmin, isOwner, isAdmin } = me || {};

  const belongs = checkCurrentUserBelongsToThisGroup(groupMember);
  const groupOwners = getGroupOwners(group);
  return (
    isSuperAdmin ||
    isOwner ||
    isAdmin ||
    (belongs &&
      (groupMember?.grade?.role === 'owner' ||
        // Check if the member is owner of the group
        !!groupOwners?.some(
          owner =>
            owner?.id === groupMember?.id ||
            owner?.member?.id === (groupMember?.member?.id || me?.authUserId),
        )))
  );
}

export function getGroupOwners(group: Group | undefined) {
  if (!group) return undefined;
  const ownerSearch = ((group as any).ownerSearch as Group['memberSearch']) || {};
  let { group_members: group_owners = [] } = ownerSearch || {};
  return group_owners;
}

export function getGroupAdmins(group: Group | undefined) {
  const adminSearch = ((group as any).adminSearch as Group['memberSearch']) || {};
  let { group_members: group_admins = [] } = adminSearch || {};
  return group_admins;
}

export function useAllowAdminOfGroups() {
  const { key } = useWebsite();
  const { isOwner, isAdmin, username } = usePermissions();
  return isOwner || isAdmin || (key === 'MyApp' && username === 'graphcomment');
}

export function useFetchGroup(skipInitialFetch?: boolean) {
  const dispatch = useAppDispatch();
  const { query } = useRouter();
  const { slug: slugRaw } = query;
  if (!slugRaw) throw new Error('Missing URL slug, cannot render the group.');
  const slug = slugRaw as string;
  const res = useQueryCallback({
    query: getGroupQuery,
    variables: { groupId: slug },
    skipInitialFetch,
    onResponse(data) {
      dispatch(setGroupAndCurrentMember(data));
    },
    onError(err) {
      handleError(err);
      dispatch(setErrorGroup(err));
      failureToast(
        "Argh, we couldn't load the members! Please try again later and let us know if the issue persists.",
      );
    },
  });

  // Re-fetch the group data when the auth status changes (login/logout)
  const { authUserId } = usePermissions();
  const firstRef = useRef(true);
  const { refetch } = res;
  useEffect(() => {
    if (firstRef.current) {
      firstRef.current = false;
    } else {
      refetch();
    }
  }, [authUserId, refetch]);

  return res;
}

export function userCanEditMember(
  isGroupOwner: boolean,
  isGroupAdmin: boolean,
  grade: Grade | undefined,
) {
  return isGroupOwner || (isGroupAdmin && grade?.role === 'member');
}

export interface FetchMembersVariables {
  groupId: string;
  offset?: number;
  status?: string;
}

export function useFetchMembersWithOffset() {
  const client = useApolloClient();
  const dispatch = useAppDispatch();

  const fetchMembersWithOffset = useCallback(
    async (more?: boolean) => {
      const offset = more ? readSelectorOnce(selectAllMembers)?.length || 0 : 0;
      const reset = !offset;
      try {
        if (reset) {
          dispatch(setLoadingMembers(true));
        }
        // Big hack: consume the gql API as we would with a traditional REST API. In this project,
        // useQuery, the Apollo cache, data merge and the refetch have a highly unpredictable behavior.
        // To have a working "fetch more on scroll" feature, we bypass all of that and add the fetched
        // members to a map on redux.
        // When writing this comment, the API also has a bug: each request returns members in a random order.
        // Using a map in redux allows to dedupe.
        const groupId = readSelectorOnce((state: RootState) => state.groupMembers.group?.id);
        if (!groupId) throw new Error('Missing groupId (URL slug?), cannot update the group.');
        const variables: FetchMembersVariables = { groupId, offset };
        const isGroupAdmin = readSelectorOnce(selectHasGroupAdminPermissions);
        if (!isGroupAdmin) {
          variables.status = 'approved';
        }
        const { error, data } = await client.query<QueryType>({
          query: getGroupMembersQuery,
          variables,
        });
        if (error) throw error;
        const { memberCount, adminCount, ownerCount } = (data?.website?.findGroup || {}) as {
          memberCount: GroupMemberSearch;
          adminCount: GroupMemberSearch;
          ownerCount: GroupMemberSearch;
        };
        dispatch(
          setCounts({
            memberCount: memberCount.meta?.count,
            adminCount: adminCount.meta?.count,
            ownerCount: ownerCount.meta?.count,
          }),
        );
        dispatch(
          (reset ? setNewMembers : addMembers)(
            data?.website?.findGroup?.memberSearch?.group_members,
          ),
        );
      } catch (err) {
        handleError(err);
        dispatch(setErrorMembers(err));
        failureToast(
          "Argh, we couldn't load the members! Please try again later and let us know if the issue persists.",
        );
      }
    },
    [client, dispatch],
  );
  return fetchMembersWithOffset;
}

export function useHasGenericEditPermissions() {
  const { isAdmin, isOwner, isSuperAdmin } = usePermissions();
  const hasGenericEditPermissions = isAdmin || isOwner || isSuperAdmin;
  return hasGenericEditPermissions;
}
