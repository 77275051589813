import { gql } from '@apollo/client';

import { dispatchOther } from '../../redux/redux.utils';
import axios from '../../services/axios';
import { FullProfileFragment } from '../../services/graphql-queries';
import type { GraphApiMutations, Message, QueryType } from '../../types/api';
import type { AppApolloClient } from '../../types/common-models';
import { updateMessage } from '../ThreadMessage/thread-slice';
import { ThreadMessageFragment } from '../ThreadMessages/query';
import { AuthorFragment } from './thread';

export const MessageListItemFragment = gql`
  fragment MessageListItemFragment on Message {
    id
    content
    author {
      ...AuthorFragment
    }
    topic {
      id
      slug
      subject
    }
    createdAt
  }
  ${AuthorFragment}
`;

export const messagesSearchQuery = gql`
  query messagesSearchQuery(
    $search: String
    $page: Int
    $limit: Int
    $dateStart: GraphQLDateTime
    $dateEnd: GraphQLDateTime
  ) {
    website {
      id
      name
      messageSearch(
        search: $search
        page: $page
        limit: $limit
        dateStart: $dateStart
        dateEnd: $dateEnd
      ) {
        meta {
          count
          page
          limit
        }
        messages {
          ...MessageListItemFragment
        }
      }
    }
  }
  ${MessageListItemFragment}
`;

export const MessagesProfileQuery = gql`
  query messagesProfileQuery($page: Int, $limit: Int, $pseudo: String) {
    profile(pseudo: $pseudo) {
      id
      pseudo
      messageOrCommentList(page: $page, limit: $limit) {
        meta {
          count
          page
          limit
        }
        messages {
          id
          content
          author {
            ...AuthorFragment
          }
          parentMessage {
            id
            author {
              ...AuthorFragment
            }
          }
          thread {
            id
            pageTitle
            url
            topic {
              id
              slug
              subject
              status
            }
          }
          createdAt
          votes {
            score
          }
        }
      }
    }
  }
  ${AuthorFragment}
`;

export const ListMessagesInboxQuery = gql`
  query ListMessagesInboxQuery($offset: Int, $limit: Int) {
    me {
      id
      profile {
        id
        status
        ...FullProfileFragment
        getInboxList(offset: $offset, limit: $limit) {
          meta {
            count
            offset
            limit
          }
          messages {
            ...ThreadMessageFragment
            isAlreadyView
            userVisibility {
              id
              username
              profile {
                id
                pseudo
                picture
                status
              }
            }
            thread {
              id
            }
          }
        }
      }
    }
  }
  ${ThreadMessageFragment}
  ${FullProfileFragment}
`;

export const CreateOrUpdateMessageMutation = gql`
  mutation CreateOrUpdateMessageMutation(
    $adminBoost: Boolean
    $content: String!
    $files: [ID]
    $hiddenEmbeds: [String]
    $embeds: String
    $threadId: ID!
    $parentId: ID
    $messageId: ID
  ) {
    createOrEditMessage(
      messageId: $messageId
      parentId: $parentId
      threadId: $threadId
      content: $content
      hiddenEmbeds: $hiddenEmbeds
      embeds: $embeds
      adminBoost: $adminBoost
      fileIds: $files
      guestUserName: ""
      guestEmail: ""
    ) {
      id
      ...ThreadMessageFragment
    }
  }
  ${ThreadMessageFragment}
`;

const FindThreadFromMessageQuery = gql`
  query FindThreadFromMessageQuery($slug: String, $messageId: ID) {
    website {
      id
      findTopic(slug: $slug) {
        id
        findOrCreateThread(offset: 0, limit: 1, messageId: $messageId) {
          id
        }
      }
    }
  }
`;

export const findThreadIdFromMessageId = (
  variables: {
    slug: string;
    messageId: string;
  },
  client: AppApolloClient,
) =>
  client
    .query<QueryType>({
      query: FindThreadFromMessageQuery,
      variables,
    })
    .then(({ data }) => data?.website?.findTopic?.findOrCreateThread?.id);

// Related type: FormDataType
export interface CreateOrUpdateMessageVariablesSmall {
  adminBoost: boolean;
  content: string;
  hiddenEmbeds: string[] | undefined;
  embeds: string | undefined;
}

export interface CreateOrUpdateMessageVariables {
  adminBoost: boolean;
  content: string;
  threadId: string;
  files?: string[];
  hiddenEmbeds: string[] | undefined;
  isGuest?: boolean;
  pageId?: string;
  parentId?: string;
  messageId?: string;
  embeds: string | undefined;
}

export const createOrUpdateMessage = async (
  variables: CreateOrUpdateMessageVariables,
  client: AppApolloClient,
) => {
  const response = await client.mutate<GraphApiMutations>({
    mutation: CreateOrUpdateMessageMutation,
    variables,
  });

  return response?.data?.createOrEditMessage;
};

export interface DeleteResponseStatusFragment {
  status: string; // e.g. 'deleted'
}

export interface DeleteResponseData extends DeleteResponseStatusFragment {
  _id: string;
  website: string;
  content: string;
  parent_id: string;
  page: string;
  inbox: boolean;
  user_visibility: unknown[];
  hidden_embeds: unknown[];
  embeds: string;
  thread: string;
  author: {
    profiles: unknown[];
    _id: string;
  };
  files: unknown[];
  offenses: unknown[];
  spam: boolean;
  shadow: boolean;
  guest: boolean;
  created_at: string;
  updated_at: string;
  edited_at: string;
  content_edited_at: string;
  is_edited: boolean;
  status: string; // e.g. 'deleted'
  child_comments: unknown[];
  voters: unknown[];
  views: number;
  cached_author: {
    username: string;
    picture: string; // e.g. '/images/avatar_3.png';
    user_rank: number;
  };
  result: number;
  root_id: string;
  moderation: {
    result: {
      created_at: string; // e.g. '2023-08-04T07:51:34.139Z';
      provider: string;
      status: string; // e.g. 'OK';
      updated_at: string; // e.g. '2023-08-04T07:51:56.032Z';
    };
    sended: string; // e.g. 'off';
  };
  count_child: number;
}

export async function deleteMessage({ commentId }: { commentId: string | number }) {
  const { data } = await axios.delete<DeleteResponseData>(`/api/comment/${commentId}`);
  const { _id, status } = data;
  dispatchOther(updateMessage({ message: { id: _id, status }, noErrorIfMissing: true }));
  return data;
}

export const reportMessage = async (comment: Message, report: any, threadId = '') => {
  const { data } = await axios.post(
    // @TODO remove else case when everything is done via Apollo
    `/api/thread/${threadId || comment?.thread?.id || comment.thread}/comment/${
      comment.id || (comment as any)._id
    }/offense`,
    report,
  );
  return data;
};

export const markSpam = async ({
  websiteId,
  threadId,
  commentId,
}: {
  websiteId: string;
  threadId: string;
  commentId: string;
}) => {
  const { data } = await axios.post(
    `/api/website/${websiteId}/thread/${threadId}/comment/${commentId}/markspam`,
  );

  return data;
};
