import classnames from 'classnames';
import type { FC, ReactNode } from 'react';
import { useCallback, useRef } from 'react';

import type { GraphModalInstance } from '../../../../front/src/components/GraphModal/GraphModal';
import type { ThemePaletteColors } from '../../../../front/src/types/theme';
import { scrollBottomReached } from '../../../../front/src/utils/dom';

export interface HeaderMenuItemProps {
  activeColor: ThemePaletteColors;
  limit?: number;
  tip?: GraphModalInstance;
  onCountChange?: (read: number) => void;
}

export interface HeaderMenuItemWrapperProps {
  headerContent: ReactNode;
  content: ReactNode;
  activeColor: ThemePaletteColors;
  onScrollBottomReached: () => void;
  loading: boolean;
  hasMore: boolean;
}

export const HeaderMenuItemWrapper: FC<HeaderMenuItemWrapperProps> = ({
  headerContent,
  content,
  activeColor,
  onScrollBottomReached,
  loading,
  hasMore,
}) => {
  const listInnerRef = useRef<HTMLDivElement>(null);

  const colorClassesMap: Partial<Record<ThemePaletteColors, string>> = {
    blue: 'bg-blue border-blue',
    yellow: 'bg-yellow border-yellow',
    red: 'bg-red-100 border-red-100',
  };

  const onScroll = useCallback(() => {
    if (!listInnerRef.current || loading || !hasMore) return;

    if (scrollBottomReached(listInnerRef.current)) {
      onScrollBottomReached();
    }
  }, [hasMore, loading, onScrollBottomReached]);

  return (
    <div className="popoverContainer flex flex-col justify-between rounded-md border min-w-[300px] max-w-[500px] w-auto border-none sm:w-80 shadow-[0px_0px_15px_#0000001a]">
      <div
        className={classnames(
          'popoverHeader flex justify-between border rounded-t-md p-3 text-sm sm:text-base',
          colorClassesMap[activeColor],
        )}
      >
        {headerContent}
      </div>
      <div
        className="popoverContent max-h-[400px] overflow-y-auto rounded-b-md flex flex-col"
        ref={listInnerRef}
        onScroll={onScroll}
      >
        {content}
      </div>
    </div>
  );
};

export const SubHeaderMenuTitle = ({ children }: { children: ReactNode }) => {
  return (
    <span className="font-black text-gray uppercase text-xs font-source tracking-wide">
      {children}
    </span>
  );
};
