import { useEffect, useMemo } from 'react';

import { useAuthStatus } from '../../../../front/src/hooks/useAuthStatus';
import type { AuthStatus } from '../../../../front/src/hooks/useAuthStatus-utils';
import { visibleForEverybody } from '../../../../front/src/hooks/useAuthStatus-utils';
import { useAppDispatch } from '../../../../front/src/redux/hooks';
import { setCustomLayout } from '../../../../front/src/redux/slices/utils-slice';
import type { LayoutBlock } from '../../../../front/src/types/layout';

const filterLayoutByAuthStatus = (layout: LayoutBlock[], status: AuthStatus) => {
  // Initially, pageName came from _app.tsx:
  // const pageName = typeof Component.pageName === 'function' ? Component.pageName() : '';
  if (/* !pageName || */ !layout) return layout;

  return (
    layout
      // Layout applicable to this page - already filtered on the server (see getStaticPropsCommon)
      // .filter(block => !block.pages.length || block.pages.indexOf(pageName) !== -1)
      // Layout applicable to the current user - re-filtered
      .filter(
        block =>
          visibleForEverybody(block.visibility, status) ||
          block.visibility.some((visibility: string) => visibility === status),
      )
  );
};

export const useAuthFilteredCustomLayout = (customLayout: LayoutBlock[]) => {
  const status = useAuthStatus();
  return filterLayoutByAuthStatus(customLayout, status);
};

export function useUpdateCustomLayout(customLayout: LayoutBlock[]) {
  const dispatch = useAppDispatch();
  const status = useAuthStatus();

  customLayout = useMemo(
    () => filterLayoutByAuthStatus(customLayout, status),
    [customLayout, status],
  );

  useEffect(() => {
    dispatch(setCustomLayout(customLayout));
  }, [customLayout, dispatch]);

  return customLayout;
}
