import { defineMessages } from 'react-intl';

export const profileInfosMessages = defineMessages({
  missingProfile: {
    defaultMessage: "L'utilisateur n'existe pas ou n'a pas été trouvé.",
    id: 'zHh2Mg',
  },
  missingBio: {
    defaultMessage: "L'utilisateur n'a pas encore renseigné sa biographie.",
    id: 'EyoO/f',
  },
  inscriptionDate: { defaultMessage: 'Inscrit depuis le {createdAt, date, short}', id: 'iTCo1d' },
  inscriptionDateUnknown: { defaultMessage: "Date d'inscription inconnue", id: 'zouL6z' },
  messagesCount: {
    defaultMessage: `{count, plural,
    =0 {0 message}
    =1 {1 message}
    other {# messages}
  }`,
    id: 'pe05UZ',
  },
  voteSendedCount: {
    defaultMessage: `{count, plural,
    =0 {0 vote}
    =1 {1 vote}
    other {# votes}
  }`,
    id: 'cmqo1A',
  },
  voteReveivedCount: {
    defaultMessage: `{count, plural,
    =0 {0 vote reçu}
    =1 {1 vote reçu}
    other {# votes reçus}
  }`,
    id: 'aGsTmT',
  },
});
