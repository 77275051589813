import { useQuery } from '@apollo/client';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useEffect, useMemo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { useAppDispatch } from '../../../../../front/src/redux/hooks';
import { startCreatingChat } from '../../../../../front/src/redux/slices/chat-slice';
import { ListMessagesInboxQuery } from '../../../../../front/src/thread/api/message';
import type { Message, Meta, QueryType } from '../../../../../front/src/types/api';
import { debounce, uniqBy } from '../../../../../front/src/utils/common-utils';
import { useRefetchOnOpenAndBg } from '../header-utils';
import type { HeaderMenuItemProps } from '../HeaderMenuItemWrapper';
import { HeaderMenuItemWrapper, SubHeaderMenuTitle } from '../HeaderMenuItemWrapper';
import { InboxItem } from './InboxItem';

const intlMessages = defineMessages({
  yourMessages: { defaultMessage: 'Vos messages', id: 'OcAWYU' },
  newMessage: { defaultMessage: 'Nouveau message', id: 'e1rfKR' },
  noThreadYet: { defaultMessage: 'Pas encore de discussion', id: 'GVJYW3' },
});

export const Inbox = ({
  tip,
  limit = 20,
  activeColor,
  onCountChange = () => {},
}: HeaderMenuItemProps) => {
  const dispatch = useAppDispatch();

  // TODO Tests to prepare to handle chat windows thru sockets
  // const unreadChatWindows = useAppSelector(selectUnreadChatWindowsCount);

  // const prepareChatWindows = useCallback(
  //   (data: QueryType) => {
  //     const followedMessagesThreads = data?.me?.profile?.getInboxList?.messages
  //       ?.filter(message => !['deleted', 'refused'].includes(message?.status ?? ''))
  //       .reduce((acc: ChatWindowDescriptor[], message) => {
  //         const threadId = message?.thread?.id;
  //         if (threadId)
  //           acc.push({ threadId, isAlreadyView: !!message.isAlreadyView, status: 'closed' });
  //         return acc;
  //       }, []);

  //     if (followedMessagesThreads) {
  //       dispatch(initChatWindows(followedMessagesThreads));
  //       WebsocketService.joinThread(followedMessagesThreads.map(thread => thread.threadId));
  //     }
  //   },
  //   [dispatch],
  // );

  const { data, loading, fetchMore, refetch } = useQuery<QueryType>(ListMessagesInboxQuery, {
    variables: { offset: 0, limit },
    returnPartialData: true,
    // onCompleted: prepareChatWindows,
  });

  useRefetchOnOpenAndBg(tip, refetch);

  const { messages: rawMessages = [], meta = {} } = data?.me?.profile?.getInboxList || {};

  const messages = useMemo(
    () =>
      (rawMessages as Message[]).filter(
        message => ['deleted', 'refused'].indexOf(message.status ?? '') < 0,
      ),
    [rawMessages],
  );

  const { offset, count } = meta as Meta;

  const canLoadMore = !loading && messages.length > 0 && (offset as number) < (count as number);

  const unreadMessagesCount = useMemo(
    () => messages.filter(message => !message.isAlreadyView).length,
    [messages],
  );

  const fetchMoreData = useCallback(async () => {
    if (!canLoadMore) {
      return;
    }

    await fetchMore({
      variables: { limit, offset: rawMessages.length },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const result = cloneDeep(fetchMoreResult);
        if (!result?.me?.profile?.getInboxList) return previousResult;

        result.me.profile.getInboxList.messages = uniqBy(
          [
            ...(previousResult?.me?.profile?.getInboxList?.messages ?? []),
            ...(fetchMoreResult?.me?.profile?.getInboxList?.messages ?? []),
          ],
          message => message?.id,
        );

        return result;
      },
    });
  }, [canLoadMore, fetchMore, limit, rawMessages.length]);

  const debouncedFetch = debounce(fetchMoreData, 500);

  const handleScrollBottomReached = useCallback(() => {
    if (canLoadMore) {
      debouncedFetch();
    }
  }, [canLoadMore, debouncedFetch]);

  const startNewThread = useCallback(() => {
    dispatch(startCreatingChat());
    tip?.hide();
  }, [tip, dispatch]);

  // useEffect(() => onCountChange(unreadChatWindows), [onCountChange, unreadChatWindows]);
  useEffect(() => onCountChange(unreadMessagesCount), [onCountChange, unreadMessagesCount]);

  return (
    <HeaderMenuItemWrapper
      onScrollBottomReached={handleScrollBottomReached}
      activeColor={activeColor}
      loading={loading}
      hasMore={canLoadMore}
      headerContent={
        <div className="w-full flex items-center justify-between">
          <SubHeaderMenuTitle>
            <FormattedMessage {...intlMessages.yourMessages} />
          </SubHeaderMenuTitle>
          <span className="font-bold text-gray uppercase text-xs font-source tracking-4" />
          <button
            type="button"
            onClick={() => startNewThread()}
            className="hover:underline tracking-4 text-sm"
          >
            <FormattedMessage {...intlMessages.newMessage} />
          </button>
        </div>
      }
      content={
        <>
          {messages.map(message => (
            <InboxItem key={message.id} inbox={message} tip={tip} />
          ))}

          {loading ? (
            <div className="loader loader-centered" />
          ) : (
            messages.length === 0 && (
              <div className="p-2 text-center text-gray-400 bg-white">
                <FormattedMessage {...intlMessages.noThreadYet} />
              </div>
            )
          )}
        </>
      }
    />
  );
};
