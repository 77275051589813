import { defineMessages, FormattedMessage } from 'react-intl';

import { RemoveButton } from '../../../components/Button/RemoveButton';
import type { TopicRemoverProps } from './TopicRemover';
import { TopicRemover } from './TopicRemover';

const messages = defineMessages({
  deleteLabel: { defaultMessage: 'supprimer le sujet', id: 'a1Qoj1' },
});

/**
 *
 * @param props
 * @constructor
 */
export const TopicRemoveButton = (props: Omit<TopicRemoverProps, 'children'>) => (
  <TopicRemover {...props}>
    <RemoveButton content={<FormattedMessage {...messages.deleteLabel} />} />
  </TopicRemover>
);
