import type { NextComponentType } from 'next';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import dynamic from 'next/dynamic';
import type { ReactElement } from 'react';
import { memo, useCallback, useEffect } from 'react';
import { IntlProvider } from 'react-intl';

import { BodyModifier } from '../../../front/src/components/BodyModifier';
import { PopoverProvider } from '../../../front/src/components/GraphModal/popoverContext';
import { frontConfig } from '../../../front/src/config';
import { CleanRoutesProvider } from '../../../front/src/hooks/useCleanRoutes';
import { ExposedDataProvider } from '../../../front/src/hooks/useExposedData';
import { HeaderHeightProvider } from '../../../front/src/hooks/useHeaderHeight';
import { WebsiteProvider } from '../../../front/src/hooks/useWebsite';
import { ReduxProvider } from '../../../front/src/redux/ReduxProvider';
import { setIsBot, setPageConfig } from '../../../front/src/redux/slices/utils-slice';
import type { AppStore } from '../../../front/src/redux/store';
import { makeStore } from '../../../front/src/redux/store';
import { setHeadings } from '../../../front/src/thread/ThreadMessage/topics-slice';
import type { DebateAlwaysProps, DebateInitialProps } from '../../../front/src/types/debate-types';
import type { SemioNextAppContext, SemioNextLayoutPage } from '../../../front/src/types/layout';
import { gdRoutes } from '../../../front/src/utils/gdRoutes';
import {
  ensureLogRocketIsInitialized,
  IdentifyUsersForLogRocket,
} from '../../../front/src/utils/logrocket';
import { isBrowser } from '../../../front/src/utils/web';
import { debateConfig } from '../../config';
import '../../styles/globals.scss';
import { AuthManager, useApolloInBrowser } from '../components/auth/AuthManager';
import { LoadGraphLogin } from '../components/auth/LoadGraphLogin';
import type { GraphDebateLayoutProps } from '../components/Layout/MainLayout';
import { MainLayout } from '../components/Layout/MainLayout';
import { MetaStatic } from '../components/Meta/MetaStatic';
import { MetaWebsite } from '../components/Meta/MetaWebsite';
import { isbCookieName, useCookieDeserialized } from '../utils/middleware-utils';
import { sendToGoogleAnalytics } from '../utils/trackers/gAnalytics';
import { initGa4 } from '../utils/trackers/initGa4';

// import { InitPushNotification } from '../components/push-notification/InitPushNotification';
const InitPushNotification = dynamic(
  () => import('../components/push-notification/InitPushNotification'),
  { ssr: false },
);

const InitWebsocketService = dynamic(() => import('../components/websocket/InitWebsocketService'), {
  ssr: false,
});

if (debateConfig.enableLogRocket) {
  ensureLogRocketIsInitialized();
}

const store = makeStore();

type GraphDebateProps = DebateInitialProps &
  AppProps & {
    store: AppStore;
    Component?: SemioNextLayoutPage;
  };

const GraphDebate: NextComponentType<
  SemioNextAppContext,
  GraphDebateLayoutProps,
  GraphDebateProps
> = memo(function GraphDebate(context) {
  const { Component, ...rest } = context;
  // See store.ts if we need to retrieve the redux state from the SSR.
  // const { store, props } = reduxWrapper.useWrappedStore(rest);
  const props = rest;

  const pageProps: DebateAlwaysProps = props.pageProps; // contains the props from getStaticProps
  const commonProps = pageProps?.isStatic ? pageProps : props;

  if (!commonProps.propsFromRequest) {
    throw new Error(
      'The common page properties have not been prepared as they should. There is likely an issue with common static properties, e.g. the website public key not found, or an error thrown while rendering server-side. Ensure the page includes a getStaticProps function that is at least defaultServerSidePropsForLayout. The default layout relies on those props.',
    );
  }

  const handleComponentLayout = Component.getPageLayout || ((page: ReactElement) => page);

  let {
    // ===> initial
    // externalData,
    messages,
    propsFromRequest: { publicKey, locale },
    dataExposer,
    initialExposedData,
    jsonLds,

    // ===> initial & next
    customLayout,
    headings,
    website,
    noIndex,

    // ===> next
    // router,

    // ===> redux
    // store: rawStore,
  } = commonProps as GraphDebateProps;

  const { pageConfig } = pageProps;

  const shouldInitWebsocket = website.graphdebate?.websocket?.notification;

  const isB = useCookieDeserialized<boolean>(isbCookieName) ?? commonProps.isB;

  const { apolloClient, auth, getToken } = useApolloInBrowser(pageProps, publicKey, locale);

  const handleErrorIntl = useCallback(err => {
    if (err.code !== 'MISSING_TRANSLATION') throw err;
    if (process.env.NODE_ENV === 'development') console.warn(err);
  }, []);

  // Send INP to Google Analytics (client-side) and api
  useEffect(() => {
    if (isBrowser) {
      initGa4(website.id, isB);
    }
  }, [website.id, isB]);

  useEffect(() => {
    if (!isBrowser) return undefined;

    // prevent drag/drop file from opening it in the browser
    const listener = (event: DragEvent) => event.preventDefault();
    window.addEventListener('dragover', listener, false);
    window.addEventListener('drop', listener, false);

    return () => {
      window.removeEventListener('dragover', listener, false);
      window.removeEventListener('drop', listener, false);
    };
  }, []);

  // Ideally, it should be done on server-side, but it will require more work.
  store.dispatch(setIsBot(isB));

  useEffect(() => {
    store.dispatch(setPageConfig(pageConfig));
    store.dispatch(setHeadings(headings?.website));
  }, [headings?.website, pageConfig]);

  return (
    <>
      {/* <FontFamily /> */}
      <LoadGraphLogin />
      <ReduxProvider store={store}>
        <WebsiteProvider publicKey={publicKey} website={website}>
          <IntlProvider
            onError={handleErrorIntl}
            locale={locale}
            defaultLocale={frontConfig.defaultLocale}
            messages={messages}
            key={locale}
          >
            <ExposedDataProvider dataExposer={dataExposer} initialExposedData={initialExposedData}>
              <BodyModifier>
                <HeaderHeightProvider>
                  <PopoverProvider>
                    <AuthManager apolloClient={apolloClient} auth={auth} getToken={getToken}>
                      <CleanRoutesProvider routes={gdRoutes}>
                        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                        <MetaStatic jsonLds={jsonLds} />
                        <MetaWebsite noIndex={noIndex} />
                        <InitPushNotification />
                        {shouldInitWebsocket && <InitWebsocketService websiteId={website.id!} />}
                        {debateConfig.enableLogRocket && <IdentifyUsersForLogRocket />}
                        <MainLayout pageConfig={pageConfig} customLayout={customLayout}>
                          {Component ? handleComponentLayout(<Component {...pageProps} />) : null}
                        </MainLayout>
                      </CleanRoutesProvider>
                    </AuthManager>
                  </PopoverProvider>
                </HeaderHeightProvider>
              </BodyModifier>
            </ExposedDataProvider>
          </IntlProvider>
        </WebsiteProvider>
      </ReduxProvider>
      {/* graphlogin script import was moved to _document. Let's remove this comment in 1-2 weeks once we are sure it's stable. */}
      {/* <script type="text/javascript" src={debateConfig.integrationUrl} /> */}
    </>
  );
});

// export default reduxWrapper.withRedux(GraphDebate);
export default GraphDebate;

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (metric.label === 'web-vital') {
    sendToGoogleAnalytics(metric, { withDevLogs: true });
  }
}
