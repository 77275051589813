import type { Subscription } from 'final-form';
import { useMemo } from 'react';
import type { FieldRenderProps, UseFieldConfig } from 'react-final-form';
import { useField } from 'react-final-form';

import type { ValidationFnType } from '../../utils/validation';
import { composeValidations, validateRequired } from '../../utils/validation';

const FIELD_SUBSCRIPTION = {
  value: true,
  error: true,
  submitFailed: true,
  submitError: true,
  modified: true,
};

export type UseGraphFieldArgument<T> = {
  config?: UseFieldConfig<T>;
  validate?: ValidationFnType<T>;
  required?: boolean;
  subscription?: Subscription;
  name: string;
};

export function useGraphField<T, U extends HTMLElement>({
  config,
  validate,
  required,
  subscription,
  name,
}: UseGraphFieldArgument<T>): {
  computedError: Error | string | undefined;
  input: FieldRenderProps<T, U>['input'];
} {
  const computedConfig = useMemo<UseFieldConfig<T>>(() => {
    const validations = [required && validateRequired, validate].filter(
      elt => elt,
    ) as ValidationFnType[];
    return {
      subscription: {
        ...FIELD_SUBSCRIPTION,
        ...subscription,
      },
      validate: validations.length ? composeValidations(validations) : undefined,
      ...config,
    };
  }, [config, validate, required, subscription]);

  const {
    meta: { error, submitError, modified },
    input,
  } = useField(name, computedConfig);

  const computedError = (modified && error) || submitError || undefined;

  return {
    computedError,
    input,
  };
}
