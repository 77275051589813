import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import merge from 'deepmerge';
import { useMemo } from 'react';

import { frontConfig } from '../../config';
import { useChanged } from '../../hooks/useChanged';
import { isBrowser } from '../../utils/fragments/isBrowser';
import { isEqual } from '../../utils/fragments/isEqual';
import { createApolloClient } from './createApolloClient';
import type { GenerateSemiologicLinkArgs } from './links/semiologicLink';

// Below utilities are for the recommended approach to use Apollo with Next.
// Inspired by the official example:
// https://github.com/vercel/next.js/tree/canary/examples/with-apollo
//
// What we should NOT do: https://www.apollographql.com/docs/react/performance/server-side-rendering/#executing-queries-with-getdatafromtree
// But it is already done in this project. Let's migrate progressively to the recommended usage. (It should increase perfs by avoiding useless renders and processings.)

export type ApolloClientArgs = GenerateSemiologicLinkArgs;

const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

type ApolloStateSave = {
  [APOLLO_STATE_PROP_NAME]: NormalizedCacheObject;
};

// Used by GraphDebate. back-office and graphlogin use ApolloProvider instead (not refactored yet?)
export function useApollo<P extends ApolloStateSave>(
  { graphUrl, getPublicKey, getToken, triggerRefreshToken, logout }: ApolloClientArgs,
  pageProps: P,
) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  if (!state && frontConfig.IS_DEV) {
    console.warn('No server-side Apollo state to restore.');
  }
  const hasChanged = useChanged(graphUrl, getPublicKey, getToken);
  const store = useMemo(
    () =>
      initializeApollo(
        {
          graphUrl,
          getPublicKey,
          getToken,
          triggerRefreshToken,
          logout,
        },
        hasChanged,
        state,
      ),
    [getPublicKey, getToken, graphUrl, hasChanged, logout, state, triggerRefreshToken],
  );
  return store;
}

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined = undefined;

export function initializeApollo(
  apolloClientArgs: ApolloClientArgs,
  apolloClientArgsChanged: boolean,
  initialState: NormalizedCacheObject | null = null,
) {
  const _apolloClient =
    !apolloClientArgsChanged && apolloClient ? apolloClient : createApolloClient(apolloClientArgs);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState && !apolloClient) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (!isBrowser) return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient || apolloClientArgsChanged) apolloClient = _apolloClient;

  return _apolloClient;
}
