import type { ExposedData } from '../services/communication/dataExposer';

export type PageNames = 'home' | 'thread' | 'tag';

export type ComputationConfigType = {
  prefixUnit: string;
  pageNameSuffixe: Partial<Record<PageNames, string>>;
  defaultSuffixe: string;
  pageNameCategories: Partial<Record<PageNames, string>>;
  defaultCategories: string;
};
export type OptiDigitalParamsType = {
  adUnit: string;
  pageTargeting: {
    issafe: 0 | 1;
    tags: string[];
  };
};
export const getContentOptiDigitalDesktop = (index: number) =>
  `<div style="display:none;" class="Content_${(index % 8) + 1}"></div>`;
export const getContentOptiDigitalMobile = (index: number) =>
  `<div style="display:none;" class="Mobile_Pos${(index % 8) + 1}"></div>`;

export const generateTaggingPlan =
  ({
    prefixUnit,
    pageNameSuffixe,
    defaultSuffixe,
    pageNameCategories,
    defaultCategories,
  }: ComputationConfigType) =>
  (values: ExposedData): OptiDigitalParamsType => {
    const { pageName, tag, tags } = values;
    const adUnit = `${prefixUnit}${pageNameSuffixe?.[pageName as PageNames] ?? defaultSuffixe}`;
    const configCategories = (
      pageNameCategories?.[pageName as PageNames] || defaultCategories
    ).split(',');
    switch (pageName) {
      case 'home':
        return {
          adUnit,
          pageTargeting: {
            issafe: 0,
            tags: configCategories,
          },
        };
      case 'tag':
        return {
          adUnit,
          pageTargeting: {
            issafe: tag?.isAdsSafe ? 1 : 0,
            tags: [tag?.title as string],
          },
        };
      case 'thread':
        return {
          adUnit,
          pageTargeting: {
            issafe: tags?.every(elt => elt.isAdsSafe) ? 1 : 0,
            tags: tags?.map(elt => elt.title as string) ?? [],
          },
        };
      default:
        return {
          adUnit, // `${prefixUnit}divers`,
          pageTargeting: {
            issafe: 0,
            tags: configCategories, // ['divers'],
          },
        };
    }
  };
