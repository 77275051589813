import type { FC, ReactNode } from 'react';
import { isValidElement, memo, useContext } from 'react';

import { useWebsite } from '../../../../hooks/useWebsite';
import type { DivElement, ImgOrStr } from '../../../../types/common-models';
import { embedRegex, getEmbedsEnabled, isImageUrl } from '../../../../utils/embed';
import { EmbedBlock } from '../Embed/EmbedLink';
import { SimpleUrl } from '../Embed/SimpleUrl';
import { isEditorjsContent } from '../message-utils';
// import { embedRegex } from 'services/embed';
import { LimiterContext } from './ElementLimiter';
import { imagesFormatter } from './imagesFormatter';
import { stringReplacer } from './stringFormatter';

function isImageTag(node: Exclude<ReactNode, string>) {
  return isValidElement(node) && node.type === 'img' && isImageUrl(node.props?.src);
}

function isUrlOrImageTag(node: ReactNode): node is ImgOrStr {
  return (typeof node === 'string' && isImageUrl(node)) || isImageTag(node);
}

export const embedOrImageFormatter =
  (shallRenderEmbed: boolean, version: string | null) => (node: ReactNode) => {
    const isImg = isUrlOrImageTag(node);

    if (!isImg && !shallRenderEmbed) return node;
    return isImg ? (
      imagesFormatter(node, version)
    ) : isLinkEmbed(node) ? (
      <EmbedBlockEditorjs node={node} />
    ) : typeof node === 'string' && !isEditorjsContent(version) ? (
      embedFormatter(node)
    ) : (
      node
    );
  };

function isLinkEmbed(node: ReactNode): node is DivElement {
  return isValidElement(node) && node.props['data-linkpreview'] != null;
}

interface EmbedBlockEditorjsProps {
  node: DivElement;
}

const EmbedBlockEditorjs: FC<EmbedBlockEditorjsProps> = memo(function EmbedBlockEditorjs(props) {
  const { node } = props;
  const website = useWebsite();
  const isEmbedEnabled = getEmbedsEnabled(website);
  if (isEmbedEnabled) {
    return node;
  } else {
    const rawLink: string = (node.props as any)['data-linkpreview'];
    return <SimpleUrl url={rawLink} />;
  }
});

export const embedFormatter = (str: ImgOrStr) =>
  stringReplacer({
    str,
    regex: embedRegex,
    factory: url => {
      return <EmbedFormatter url={url} />;
    },
  });

interface Props {
  url: string;
}

const EmbedFormatter: FC<Props> = memo(({ url }) => {
  const { isEmbedEnabled, registeredEmbeds } = useContext(LimiterContext);

  return (
    <>
      <SimpleUrl url={url} />
      {isEmbedEnabled && <EmbedBlock blockProps={{ url, registeredEmbeds }} />}
    </>
  );
});
