import classNames from 'classnames';
import React from 'react';

export type TextAreaPropTypes = {
  name: string;
  error?: Error | string;
  rows?: number;
  value?: string | number;
  placeholder?: string;
  className?: string;
};
const defaultClassName =
  'resize-none appearance-none outline-none rounded border border-solid p-4 bg-input-bg text-input-text text-base';

export const TextArea: React.FC<TextAreaPropTypes> = ({ error, className, rows = 5, ...other }) => {
  return (
    <textarea
      {...other}
      rows={rows}
      className={classNames(
        defaultClassName,
        error ? 'border-red-400' : 'border-input-border',
        className,
      )}
    />
  );
};
