import type { FieldSubscription } from 'final-form';
import type { ReactElement } from 'react';
import type { FieldRenderProps } from 'react-final-form';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import { AutoHeight } from '../AutoHeight';

const errorSubscription = {
  error: true,
  pristine: true,
  submitError: true,
} satisfies FieldSubscription;

/** To get more fields, you must subscribe above (errorSubscription) */
type MetaFieldsSubscribed = Pick<FieldRenderProps<any>['meta'], keyof typeof errorSubscription>;

const errorRender = (
  {
    meta: { pristine, error, submitError },
  }: {
    meta: MetaFieldsSubscribed;
  },
  validatePristine?: boolean,
) => {
  let content: any = null;
  error = error || submitError;

  if ((!validatePristine && pristine) || !error) {
    content = null;
  } else if (typeof error === 'string') {
    content = error;
  } else {
    content = <FormattedMessage {...error} />;
  }

  return (
    <AutoHeight>
      {content ? (
        <div className="text-failure text-xs truncate pt-1 first-letter:uppercase">{content}</div>
      ) : null}
    </AutoHeight>
  );
};

export const ErrorField = ({
  name,
  validatePristine,
}: {
  name: string;
  validatePristine?: boolean;
}): ReactElement => {
  return (
    <Field
      name={name}
      subscription={errorSubscription}
      render={props => errorRender(props, validatePristine)}
    />
  );
};
