import type { FC, HTMLAttributes } from 'react';
import { memo } from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { Tooltip } from '../../components/GraphModal/Tooltip';
import { ProfilePopover } from '../../contributor/Profile/ProfilePopover';
import { useWebsite } from '../../hooks/useWebsite';
import { useAppSelector } from '../../redux/hooks';
import { selectCustomDensity } from '../../redux/slices/utils-slice';
import type { Topic, User } from '../../types/api';
import { getLocaleDateDigits } from '../../utils/date';
import { isMobile } from '../../utils/web';
import { getAuthorPseudo } from '../thread-utils';
import { TopicItemInteractions } from './children/v3/TopicItemInteractions';
import { TopicItemLastMessageBlock } from './children/v3/TopicItemLastMessageBlock';
import TopicItemTags from './children/v3/TopicItemTags';
import TopicItemTitle from './children/v3/TopicItemTitle';
import { TopicRemoveButton } from './Remove/TopicRemoveButton';
import { TopicListItemActions } from './TopicListItemActions';

interface TopicListItemV3Props extends HTMLAttributes<HTMLDivElement> {
  topic: Topic;
  withRemove?: boolean;
  withActions?: boolean;
  isResponsive?: boolean;
  isSidebar?: boolean;
  itemTitleClassName?: string;
}

export const TopicListItemV3: FC<TopicListItemV3Props> = memo(function TopicListItemV3(props) {
  const {
    topic,
    withRemove,
    withActions,
    className,
    isResponsive = true,
    isSidebar = false,
    itemTitleClassName = '',
    ...otherProps
  } = props;
  const { graphdebate } = useWebsite();
  // @ts-ignore
  const shouldIndexDiscussion = graphdebate?.meta?.topic?.index ?? 0;

  const { locale } = useIntl();

  const displayedDate = getLocaleDateDigits(topic.createdAt, locale);

  const currentDensity = useAppSelector(selectCustomDensity);
  const isCompactDensity = currentDensity === 'compact';

  const isMobileDisplay = isMobile();

  return (
    <div
      className={twMerge(
        'TopicListItemV3 p-3 flex text-gray-darkv4',
        isResponsive && 'md:p5',
        isSidebar && 'px-0',
        className,
      )}
      {...otherProps}
    >
      <div className="infos flex-1 overflow-hidden">
        <div className="head flex">
          <div
            className={twMerge(
              'post-infos flex gap-x-1 text-[13px]',
              isSidebar && 'md:text-xs',
              isResponsive && 'md:text-[15px]',
            )}
          >
            <ProfilePopover
              userId={(topic.author as User).id}
              trigger={isMobileDisplay ? 'click' : 'mouseenter'}
              delay={[500, 0]}
            >
              <div
                className={twMerge(
                  'author font-medium cursor-pointer hover:text-primary',
                  isResponsive && 'md:font-semibold',
                )}
              >{`${getAuthorPseudo(topic.author)}, `}</div>
            </ProfilePopover>

            <div
              className={twMerge(
                'date text-gray-soft',
                isSidebar && 'md:text-xs md:text-gray-400 md:font-medium',
              )}
            >
              <Tooltip
                content={
                  <FormattedDate
                    value={new Date(topic.createdAt)}
                    year="numeric"
                    month="long"
                    day="numeric"
                    weekday="long"
                    hour="numeric"
                    minute="numeric"
                  />
                }
              >
                <span>{displayedDate}</span>
              </Tooltip>
            </div>
          </div>
        </div>

        <TopicItemTitle
          topic={topic}
          isResponsive={isResponsive}
          itemTitleClassName={itemTitleClassName}
          className="font-medium"
        />
        <TopicItemInteractions topic={topic} isResponsive={isResponsive} isSidebar={isSidebar} />
        {!isCompactDensity && <TopicItemTags topic={topic} isResponsive={isResponsive} />}
      </div>

      <TopicItemLastMessageBlock
        topic={topic}
        className={twMerge(isResponsive ? '_md:hidden' : 'hidden')}
        isResponsive={isResponsive}
        isSidebar={isSidebar}
      />

      {(withRemove || withActions) && (
        <div className="flex flex-col justify-between items-center ml-auto">
          {withRemove && (
            <div className="my-4">
              <TopicRemoveButton topicId={topic.id as string} content={topic.content as string} />
            </div>
          )}

          {withActions && <TopicListItemActions topic={topic} className="ml-auto" />}
        </div>
      )}
    </div>
  );
});
