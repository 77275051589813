export const QUERY_PARAMS = {
  originUrl: 'originUrl',
  urlFrom: 'urlFrom',
  magicLink: '__semio_ml__',
  resetToken: 'reset_token',
  emailValidationToken: '__semio__validation__token__',
  auth: 'new',
};

export const TOKEN_NAME = '__semio__gc__auth__';
