import { gql } from '@apollo/client';

import axios from '../../services/axios';
import type { VoteResp } from '../Vote/vote-model';

export const AuthorFragment = gql`
  fragment AuthorFragment on Author {
    ... on User {
      id
      profile {
        id
        pseudo
        picture
        status
        proStatus
        meta {
          gradeLabel
          grade
        }
      }
    }
    ... on Guest {
      username
      picture
    }
  }
`;

export type VoteData = { boost?: boolean | number; score?: number };
export const postVote = async (url: string, payload: VoteData) => {
  const { data } = await axios.post<VoteResp>(url, payload);
  return data;
};

export const deleteVote = async (url: string) => {
  const { data } = await axios.delete<VoteResp>(url);
  return data;
};
