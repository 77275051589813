import classNames from 'classnames';
import type { FC } from 'react';
import { memo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import type { AvatarPropsType } from '../components/Avatar';
import type { RequestFriendCallback } from '../contributor/api/relation';
import { GradeBadge } from '../contributor/Profile/ProfileData/GradeBadge';
import type { ProfilePopoverProps } from '../contributor/Profile/ProfilePopover';
import { ProfilePopover } from '../contributor/Profile/ProfilePopover';
import type { Author, Profile } from '../types/api';
import { isMobile } from '../utils/web';
import './AuthorPseudo.scss';
import { getAuthorPseudo } from './thread-utils';

const messages = defineMessages({
  nonAuthUser: {
    defaultMessage: `Invité`,
    id: '/kyr6f',
  },
});

type AuthorPseudoProps = {
  author: Author | undefined;
  userId: string | undefined;
  profile: Profile | undefined;
  withProfilePopover?: boolean;
  withStatusBadge?: boolean;
  inviteCallback?: RequestFriendCallback;
  isGroup?: boolean;
} & Omit<AvatarPropsType, 'picture'> &
  Omit<Partial<ProfilePopoverProps>, 'userId'>;

export const AuthorPseudo: FC<AuthorPseudoProps> = memo(function AuthorPseudo(props) {
  const {
    author,
    userId,
    profile,
    withProfilePopover,
    withStatusBadge,
    inviteCallback,
    isGroup,
    ...others
  } = props;
  if (!profile) return <FormattedMessage {...messages.nonAuthUser} />;
  const pseudo = getAuthorPseudo(author, profile);

  const isMobileDisplay = isMobile();

  return (
    // Wrap in a span to avoid a tippy warning
    <span
      className={classNames(
        'AuthorPseudo flex items-center gap-x-2 overflow-hidden text-gray-darkv4',
      )}
    >
      {withProfilePopover ? (
        <ProfilePopover
          userId={userId}
          trigger={isMobileDisplay ? 'click' : 'mouseenter'}
          appendToBody
          inviteCallback={inviteCallback}
          {...others}
        >
          <span
            className={classNames(
              'font-semibold cursor-pointer truncate leading-[20px]',
              isGroup ? 'text-base2' : 'text-[14px]',
            )}
          >
            {pseudo}
          </span>
        </ProfilePopover>
      ) : (
        <>{pseudo}</>
      )}
      {withStatusBadge && <GradeBadge profile={profile} />}
    </span>
  );
});
