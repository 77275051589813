import Head from 'next/head';
import { memo } from 'react';

import { WebsiteStyleTheme } from '../../../../front/src/components/layout/WebsiteStyleTheme';
import { apiUrl } from '../../../../front/src/config';
import { useWebsite } from '../../../../front/src/hooks/useWebsite';
import { html } from '../../../../front/src/utils/dom';
import { useIsAlphaCircleOrTestDebate } from '../../utils/common';
import { MetaPage } from './MetaPage';

type MetaWebsiteProps = {
  noIndex?: boolean;
};

export const MetaWebsite = memo(({ noIndex }: MetaWebsiteProps) => {
  const website = useWebsite();
  const { common = {} } = website?.graphdebate?.meta ?? {};
  const { name, custom = {} } = website ?? {};
  const { image, faviconArrayHtml = [] } = custom;

  // Temporary patch to have a valid manifest.json until the API updates its version.
  const isAC = useIsAlphaCircleOrTestDebate();

  let favicons = (faviconArrayHtml as string[])
    .map(fav => {
      fav = fav.replace('/images', `${apiUrl}/images`);
      if (isAC && fav.includes('manifest')) return undefined;
      return fav;
    })
    .filter(f => f)
    .join('')
    .replace(/>$/, '/>');

  if (isAC) {
    favicons += '<link rel="manifest" href="/manifest.json">';
  }

  // Hack to avoid _app to apply indexing to the page if noIndex prop present during SSR
  const metaPageIndexingProps = {
    index: !!!noIndex,
    follow: !!!noIndex,
  };

  return (
    <>
      <WebsiteStyleTheme />

      <Head>
        <meta key="twitter:card" name="twitter:card" content="summary" />
        <meta key="twitter:site" name="twitter:site" content="@graphcomment" />

        <meta
          key="article:publisher"
          property="article:publisher"
          content="https://www.facebook.com/graphcomment"
        />

        <meta key="og:type" property="og:type" content="website" />
        {name ? (
          <>
            <meta key="og:title" property="og:title" content={name} />
            <meta key="og:description" property="og:description" content={name} />
            <meta key="og:site_name" property="og:site_name" content={name} />
            <meta
              key="og:image"
              property="og:image"
              content={
                !image
                  ? '/img/graphcomment.png'
                  : image.startsWith('/')
                  ? `${apiUrl}${image}`
                  : image
              }
            />
            <meta key="twitter:title" name="twitter:title" content={name} />
            <meta key="twitter:domain" name="twitter:domain" content={name} />
          </>
        ) : null}

        {html(favicons)}
      </Head>
      <MetaPage canonicalPath={undefined} {...common} {...metaPageIndexingProps} />
    </>
  );
});
