import type { RouteConfig2 } from '../hooks/useConnectedRouter';

// Paths are prefixed with it, e.g. "/[website]/t/[slug]/d/[messageId]"
export const pathPrefixName = 'website';

const queryParamsToSetSeparately = new Set(['key', pathPrefixName]);

export type RoutesType = Record<string, string> & { home: string };

export type PathQuerySimplified = Record<string, string | number | boolean | undefined | string[]>;

// inspired from UrlObject, from 'url' module, which is used by Nextjs behind the scene.
export type SimplifiedUrlObject = {
  pathname?: string | keyof RoutesType;
  query?: PathQuerySimplified;
};
export type SimplifiedUrlObject2 = {
  pathname: string | keyof RoutesType;
  query?: PathQuerySimplified;
};

type SimplifiedUrlObjectRequired = Required<SimplifiedUrlObject>;

export interface CleanRoute {
  href: SimplifiedUrlObjectRequired;
  as: SimplifiedUrlObjectRequired;
}

// This is an implementation detail of useConnectedRouter() and our own <Link />.
// In case we want to use the Router instead of Link. This utility returns the path, query and full URL that can be passed to the useRouter().push() method.
// Example:
// const router = useRouter();
// ...
// const { href, as } = makeCleanRoute(routes, {
//   pathname: 'profile' /* to */,
//   query: {
//     pseudo,
//     tab: 'friends',
//     action: 'searchUsers',
//   } as Record<string, string> /* params */,
// }).as;
// router.push(href, as);
export function makeCleanRoute2(
  routes: RoutesType,
  target: RouteConfig2,
  websiteKey: string,
): CleanRoute {
  const isStr = typeof target === 'string';
  const pathname = isStr ? target : target.pathname;
  const query = isStr ? {} : target.query || {};
  if (typeof query === 'string') {
    throw new Error(
      'Unsupported query as string to build routing target. Either pass an object or implement the conversion from a string to an object in makeCleanRoute.',
    );
    // We could use parseUrl().
  }

  let route = pathname in routes ? routes[pathname] : pathname;
  if (!route.startsWith('/')) route = `/${route}`;
  const prefix = `/[${pathPrefixName}]`;
  if (route.startsWith(prefix)) {
    route = route.slice(prefix.length);
  }

  const cleanQuery = Object.fromEntries(
    Object.entries(query ?? {}).filter(
      ([param, value]) => !!value && !queryParamsToSetSeparately.has(param),
    ),
  );
  const href: SimplifiedUrlObjectRequired = {
    pathname: `${prefix}${route}`,
    query: { ...cleanQuery, [pathPrefixName]: websiteKey },
  };

  const paramRegex = /\[(\w+)\]/gim;
  const asPathname = route.replace(
    paramRegex,
    (_match, $1) => cleanQuery[$1]?.toString() ?? 'missing_param',
  );
  const asQuery = Object.fromEntries(
    Object.entries(cleanQuery).filter(([param]) => !route.includes(`[${param}]`)),
  );
  const as: SimplifiedUrlObjectRequired = {
    pathname: asPathname || '/',
    query: asQuery,
  };

  return { href, as };
}

/**
 * Ensure to keep pathname without [website] variable
 *
 * @example
 * const pathnameFromRouter = '/[website]/[tag]'
 * getCleanPathName(pathnameFromRouter)
 * // '/[tag]'
 */
export const getCleanPathname = (routerPathname: string) =>
  routerPathname
    .split('/')
    .filter(item => item !== `[${pathPrefixName}]`)
    .join('/') || '/';
