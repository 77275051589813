import { useQuery } from '@apollo/client';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import type { ControlProps, GroupBase, MenuProps, StylesConfig } from 'react-select';
import Select, { components } from 'react-select';

import { Avatar } from '../../../../front/src/components/Avatar';
import { ChatIconButton } from '../../../../front/src/components/chat/ChatIconButton';
import { chatMessages } from '../../../../front/src/components/chat/chatMessages';
import { frontConfig } from '../../../../front/src/config';
import { userRelationsQuery } from '../../../../front/src/contributor/api/relation';
import type { FriendElementType } from '../../../../front/src/redux/slices/chat-slice';
import type { QueryType, Relation, User } from '../../../../front/src/types/api';
import { normalizeStr } from '../../../../front/src/utils/common-utils';

const UserRenderer: React.FC<{ user: User }> = ({ user }) => {
  return (
    <div className="flex items-center text-base font-bold">
      <Avatar className="w-6 h-6 mr-3" picture={user?.profile?.picture} legacyImage />
      {user?.profile?.pseudo}
    </div>
  );
};

const CustomOption: React.FC<{
  innerProps?: React.HTMLAttributes<HTMLDivElement>;
  isDisabled?: boolean;
  label: User;
  isFocused?: boolean;
}> = ({ innerProps, isDisabled, label, isFocused }) => {
  return !isDisabled ? (
    <div {...innerProps}>
      <div
        className={`cursor-pointer p-3${
          isFocused ? ' sm:bg-secondary-variant-tmpop bg-opacity-15' : ''
        }`}
      >
        <UserRenderer user={label} />
      </div>
    </div>
  ) : null;
};

const CustomMultiValue: React.FC<{
  innerProps?: React.HTMLAttributes<HTMLDivElement>;
  data?: { label: User };
}> = ({ innerProps, data }) => {
  return (
    <div {...innerProps}>
      <div className="text-base font-bold mr-1">{data?.label?.profile?.pseudo},</div>
    </div>
  );
};

const CustomControl: React.FC<ControlProps<FriendElementType, false>> = props => {
  const { formatMessage } = useIntl();
  return (
    <div className="flex items-center pl-4 pr-1 py-1">
      <div className="text-base font-bold mr-1">{formatMessage(chatMessages.to)}</div>
      <components.Control {...props} />
    </div>
  );
};

const CustomMenu: React.FC<MenuProps<User, false>> = props => {
  return (
    <components.Menu {...props}>
      <>
        <div className="text-gray-medium text-sm font-bold p-3">
          <FormattedMessage defaultMessage="Suggestions" id="Hv0XJn" />
        </div>
        {props.children}
      </>
    </components.Menu>
  );
};

const customComponents = {
  Option: CustomOption,
  MultiValue: CustomMultiValue,
  IndicatorsContainer: function IndicatorsContainer() {
    return (
      <ChatIconButton
        className="sm:hidden"
        iconClassName="icon-add-friend sm:text-sm"
        arial={chatMessages.addFriend}
        onClick={() => null}
      />
    );
  },
  IndicatorSeparator: () => null,
  DropdownIndicator: () => null,
  Control: CustomControl,
  Menu: CustomMenu,
  LoadingMessage: function LoadingMessage() {
    return (
      <div className="flex justify-center">
        <div className="loader" />
      </div>
    );
  },
  NoOptionsMessage: function NoOptionsMessage() {
    return (
      <div className="p-3">
        <FormattedMessage {...chatMessages.noFriends} />
      </div>
    );
  },
} as const;

type ReactSelectStyles = StylesConfig<any, true, GroupBase<any>>;

const customStyles: ReactSelectStyles = {
  menu: provided => ({
    ...provided,
    border: 'none',
    width: '100%',
    margin: 0,
    padding: 0,
    boxShadow: 'none',
    position: 'initial',
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
  }),
  control: () => ({
    border: 'none',
    flex: 1,
    display: 'flex',
  }),
  input: provided => ({
    ...provided,
    fontSize: 16, // To avoid the iOS auto-zoom
  }),
};

export type FriendsSelectPropTypes = {
  value: FriendElementType[];
  onChange: (arg: FriendElementType[]) => void;
  showMenu: (show: boolean) => void;
};

export const FriendsSelect: React.FC<FriendsSelectPropTypes> = ({
  value = [],
  onChange,
  showMenu,
}) => {
  const { formatMessage } = useIntl();
  const { data, loading } = useQuery<QueryType>(userRelationsQuery, {
    variables: { limit: 50, page: 0, status: 'approved' },
    ssr: false,
    fetchPolicy: frontConfig.disableCache ? 'network-only' : undefined,
  });

  const relations = useMemo<Relation[]>(
    () => (data?.me?.profile?.relationSearch?.relations ?? []) as Relation[],
    [data],
  );

  const options = useMemo(
    () =>
      relations
        .filter(relation => relation?.member?.id)
        .map(
          relation =>
            ({
              value: relation?.member?.id as string,
              label: relation?.member as User,
            } as FriendElementType),
        ),
    [relations],
  );

  const onChangeSafe = useCallback(newValue => onChange(newValue || []), [onChange]);

  const handleFilterOptions = useCallback((candidate, input: string) => {
    const pseudo = candidate?.label?.profile?.pseudo;
    return pseudo ? normalizeStr(pseudo).includes(normalizeStr(input)) : false;
  }, []);

  return (
    // <FriendElementType, true, GroupBase<FriendElementType>>
    <Select
      className="border-none rounded-none text-base"
      // @ts-ignore
      components={customComponents}
      defaultMenuIsOpen
      isLoading={loading}
      isMulti
      onChange={onChangeSafe as any}
      options={options}
      placeholder={formatMessage(chatMessages.addOneFriendPlaceholder)}
      filterOption={handleFilterOptions}
      styles={customStyles}
      value={value}
      onMenuOpen={() => showMenu(true)}
      onMenuClose={() => showMenu(false)}
    />
  );
};
