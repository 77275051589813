import { FormattedMessage } from 'react-intl';

import { ButtonV3 } from '../../../../../front/src/components/Button/ButtonV3';
import { useLoginWithPromise } from '../../../../../front/src/utils/graphlogin/AuthWithPromise-context';

export const GraphLoginConnectButton = () => {
  const login = useLoginWithPromise();

  return (
    <>
      <ButtonV3
        onClick={login}
        variant="contained"
        className="GraphLoginConnectButton h-10 px-2 md:px-3 bg-white shadow text-gray-darkv4 hover:text-white hover:bg-primary hover:font-semibold"
        radius="rounded-md"
      >
        <FormattedMessage id="EhUb+m" defaultMessage="Connexion" />
      </ButtonV3>
    </>
  );
};
