import classNames from 'classnames';
import type { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import type {
  EjsBlockEmbed,
  EjsBlockGoogleForm,
  EjsBlockImage,
  EjsBlockLinkTool,
  EjsBlockQuote,
} from './@types';

// https://github.com/pavittarx/editorjs-html/blob/master/src/transforms.ts

export function googleFormParserRender(block: EjsBlockGoogleForm) {
  const { url, height } = block.data;
  const isValidUrl = /^https:\/\/docs\.google\.com\/forms\/d\/.+\/viewform(\?embedded=true)?$/.test(
    url,
  );
  if (!isValidUrl) {
    return '<p>URL de Google Forms invalide.</p>';
  }
  return `<div class="gform-tool"><iframe src="${url}" width="100%" height="${height}" allowfullscreen></iframe></div>`;
}

export function googleFormParserSerialize(block: EjsBlockGoogleForm) {
  const url = block.data.url;
  const isValidUrl = /^https:\/\/docs\.google\.com\/forms\/d\/.+\/viewform(\?embedded=true)?$/.test(
    url,
  );
  if (!isValidUrl) {
    return '<p>URL de Google Forms invalide.</p>';
  }
  return `<p class="gform-tool"><a href="${url}" target="_blank">Google Form</a></p>`;
}

export function linkToolParser(block: EjsBlockLinkTool) {
  const { link, meta } = block.data || {};

  const { title, site_name, description, image } = meta || {};
  const hasPreview = !!(title || site_name || description || image);
  const { url } = image || {};

  if (!hasPreview) {
    return `<a href="${link}" target="_blank">${link}</a>`;
  }

  return `<div class="link-tool paragraph not-prose" data-linkpreview="${link}">
  <a class="link-tool__content link-tool__content--rendered" target="_blank" rel="nofollow noindex noreferrer" href="${link}">
    <div class="link-tool__image embed-link__image" style="background-image: url(&quot;${url}&quot;);"></div>
    <div class="link-tool__infos embed-link__infos">
      <div class="link-tool__title">${title}</div>
      <p class="link-tool__description">${description}</p>
      <p class="link-tool__anchor">${link}</p>
    </div>
  </a>
</div>`;
}

interface LinkReactCompForLegacyProps {
  title: ReactNode;
  description: ReactNode;
  link: string | undefined;
  imageUrl: string;
}

export const LinkReactCompForLegacy: FC<LinkReactCompForLegacyProps> = props => {
  const { title, description, link, imageUrl } = props;
  return (
    <div className="link-tool paragraph p-1 mb-3">
      <a
        className="link-tool__content link-tool__content--rendered"
        target="_blank"
        rel="nofollow noindex noreferrer"
        href={link}
      >
        <div className="link-tool__image" style={{ backgroundImage: `url("${imageUrl}")` }}></div>
        <div className="link-tool__title">{title}</div>
        <p className="link-tool__description">{description}</p>
        {link && <span className="link-tool__anchor">{new URL(link).hostname}</span>}
      </a>
    </div>
  );
};

export function quoteParser(block: EjsBlockQuote) {
  const { text, caption } = block.data || {};
  let quote = `<blockquote><p>${text}</p></blockquote>`;
  if (caption) {
    quote = `<figure>${quote}<figcaption>${caption}</figcaption></figure>`;
  }
  return quote;
}

export function imageParserSerialize(block: EjsBlockImage) {
  const { file } = block.data || {};
  const { url } = file || {};
  return url;
}

export function imageParserRender(block: EjsBlockImage) {
  const { caption, file, stretched, withBackground, withBorder, width } = block.data || {};
  const { alignAll } = block.tunes || {};
  const { alignment } = alignAll || {};

  const hasWrapper = !!caption;

  const { url } = file || {};
  const usedUrl = url || '';
  const usedCaption = caption || '';

  const containerClasses = classNames(
    stretched && 'image-stretched',
    withBackground && 'image-withBackground',
    withBorder && 'image-withBorder',
    alignment === 'right' ? 'text-right' : alignment === 'center' ? 'text-center' : undefined,
  );

  const imgClasses = classNames(
    !hasWrapper && containerClasses,
    alignment === 'right' ? 'ml-auto' : alignment === 'center' ? 'mx-auto' : undefined,
  );
  const fullImgClasses = twMerge('EditorImage rounded', imgClasses);
  const styleHtml = width ? ` style="width: ${width}px;"` : '';

  let imgHtml = `<img class="${fullImgClasses}" src="${usedUrl}" alt="${usedCaption}"${styleHtml} />`;
  if (hasWrapper) {
    const containerClassHtml = containerClasses ? ` class="${containerClasses}"` : '';
    imgHtml = `<figure${containerClassHtml}>${imgHtml}<figcaption>${
      caption || ''
    }</figcaption></figure>`;
  }
  return imgHtml;
}

export const embedParser = (block: EjsBlockEmbed) => {
  const {
    data: { service, embed, source, caption },
  } = block;

  if (!embed) return source;

  const videoPlayer = `<iframe width="100%" height="320" src="${embed}" title="${service} video player" frameborder="0" allowfullscreen></iframe>`;
  const displayedCaption = caption && `<p class="!mt-2 italic">${caption}</p>`;

  return `<div class="flex flex-col items-center mb-4">${videoPlayer}${displayedCaption}</div>`;
};
