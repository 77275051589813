import classnames from 'classnames';
import { useState } from 'react';
import type { MessageDescriptor } from 'react-intl';
import { defineMessages, FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import type { ALLOWED_SORTS } from '../thread/ThreadMessages/query';
import { sortKeyMapApiToFront } from '../thread/ThreadMessages/query';
import type { SortType } from '../types/layout';
import { warnOrThrow } from '../utils/common-utils2';
import { NoSSR } from '../utils/ssr';
import GradientBorderContainer from './GradientBorderContainer';
import { IconArrowDown } from './icons/Icons';
import SortIcon from './icons/SortIcon';
import { Menu } from './Menu';
import CustomRadioButton from './Radio/CustomRadioButton';

const messages = defineMessages({
  relevance: { defaultMessage: 'Pertinence', id: 'rlmuiF' },
  newest: { defaultMessage: 'Plus récent', id: 'Xar1MP' },
  oldest: { defaultMessage: 'Plus ancien', id: 'IuXt7z' },
});

const messagesSelected = defineMessages({
  relevance: { defaultMessage: 'Trier par pertinence', id: 'TaDF8x' },
  newest: { defaultMessage: 'Trier par le plus récent', id: 'a1dNKd' },
  oldest: { defaultMessage: 'Trier par le plus ancien', id: 'euf2YU' },
});

export type SortMenuPropTypes = {
  options?: [string, MessageDescriptor][];
  sortType: SortType;
  changeSort: (elt: string) => void;
  isResponsive?: boolean;
};

/**
 *
 * @param options
 * @param sortType
 * @param changeSort
 * @constructor
 */
export const SortMenu = function SortMenu({
  options = Object.entries(messages),
  sortType,
  changeSort,
  isResponsive,
}: SortMenuPropTypes) {
  const [menuOpen, setMenuOpen] = useState(false);

  let sortKey: ALLOWED_SORTS =
    sortType in sortKeyMapApiToFront ? sortKeyMapApiToFront[sortType] : sortType;

  if (!(sortKey in messagesSelected)) {
    warnOrThrow(`The sort key is invalid: ${sortKey}`);
    sortKey = 'relevance';
  }

  return (
    <NoSSR>
      <Menu
        onShow={() => setMenuOpen(true)}
        onHide={() => setMenuOpen(false)}
        content={options.map(([optionKey, message]) => (
          <button key={optionKey} type="button" onClick={() => changeSort(optionKey)}>
            <div className="flex items-center gap-4">
              <CustomRadioButton isSelected={sortType === optionKey} />
              <span className={classnames(sortType === optionKey && 'font-semibold')}>
                <FormattedMessage {...message} />
              </span>
            </div>
          </button>
        ))}
        className="border border-primary"
      >
        <button type="button">
          <GradientBorderContainer
            from={sortType === 'relevance' ? 'from-[#E96C0B]' : 'from-[#7D8EBF]'}
            via={sortType === 'relevance' ? 'via-[#E9990B]' : undefined}
            to={sortType === 'relevance' ? 'to-[#F6C919]' : 'to-[#8AB6E2]'}
            contentContainerClass="group px-2 md:px-3 hover:bg-transparent"
          >
            <div className="flex gap-x-3 items-center">
              <span className={classnames(menuOpen && 'text-primary')}>
                <SortIcon
                  sortType={sortType}
                  className="group-hover:[&_path]:fill-white group-hover:[&_rect]:fill-white"
                />
              </span>
              <span
                className={twMerge(
                  'hidden font-semibold text-[14px] text-gray-soft group-hover:text-white',
                  isResponsive && 'sm:flex',
                )}
              >
                <FormattedMessage {...messages[sortKey]} />
              </span>
              <IconArrowDown />
            </div>
          </GradientBorderContainer>
        </button>
      </Menu>
    </NoSSR>
  );
};
