import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { Link } from '../../../../front/src/components/Link';
// import { IconAllTags } from '../../../../front/src/components/icons/IconAllTags';
import { ReactComponent as IconAllTagsSvg } from '../../../../front/src/icons/icon-tag-all.svg';
import { useAppSelector } from '../../../../front/src/redux/hooks';
import { selectHeadings } from '../../../../front/src/thread/ThreadMessage/topics-slice';
import type { GraphdebateHeading } from '../../../../front/src/types/api';
import { isMobile } from '../../../../front/src/utils/web';
import { objectKeySwap } from '../../utils/objects';
import { CircularPicker } from '../../utils/WebsiteColorsPicker';
import { headingsMessages } from '../Layout/BlockFeatured';
import { fixedTwBgColors } from '../Layout/BlockSidebarFeatured';
import HeadingsMenuCategory from './children/HeadingsMenuCategory';
import { HeadingsMenuItem } from './children/HMChildren';

interface NewHeadingsMenuProps {
  headings?: GraphdebateHeading[];
  className?: string;
  onTagClick?: () => void;
  isModalDisplay?: boolean;
}

const FixedColorsPicker = new CircularPicker(fixedTwBgColors);

export const NewHeadingsMenu: FC<NewHeadingsMenuProps> = memo(function NewHeadingsMenu(props) {
  const { isModalDisplay = false, onTagClick, className } = props;
  const headings = useAppSelector(selectHeadings);

  const coloredHeadings = useMemo(
    () =>
      headings?.map(heading => ({
        ...heading,
        color: heading.color || FixedColorsPicker.pick(),
      })),
    [headings],
  );

  return (
    <div
      className={twMerge(
        'NewHeadingsMenu flex flex-col gap-y-2 md:gap-y-4',
        isModalDisplay && !isMobile() && 'md:gap-y-8',
        className,
      )}
    >
      {coloredHeadings?.map(heading => {
        const headingProps = objectKeySwap(heading, 'children', 'tags');

        return (
          <HeadingsMenuCategory
            key={headingProps.uid}
            {...headingProps}
            isModalDisplay={isModalDisplay}
            onTagClick={onTagClick}
          />
        );
      })}
      <hr />
      <Link to="tags">
        <a>
          <HeadingsMenuItem
            className="all-tags-limit-handler cursor-pointer mt-2 rounded-lg px-2 -mx-2 hover:bg-gray-lighter"
            icon={<IconAllTagsSvg className="h-7 w-7 stroke-primary bg-white" />}
            content={
              <div className="bold">
                <FormattedMessage {...headingsMessages.allCategories} />
              </div>
            }
          />
        </a>
      </Link>
    </div>
  );
});
