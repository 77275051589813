import type { FC, HTMLAttributes } from 'react';
import { memo } from 'react';
import { twMerge } from 'tailwind-merge';

import type { Category, Topic } from '../../../../types/api';
import { CategoryBadge } from '../../../Category/CategoryBadge';

type TopicItemCategoriesProps = HTMLAttributes<HTMLDivElement> & {
  topic: Topic;
  isResponsive?: boolean;
};

const TopicItemTags: FC<TopicItemCategoriesProps> = memo(function TopicItemTags(props) {
  const { topic, className, isResponsive = true, ...otherProps } = props;
  const { categories } = topic;

  return categories?.length ? (
    <div
      className={twMerge(
        'TopicItemTags mt-3 flex flex-wrap gap-2',
        isResponsive && 'md:gap-4 md:mt-4',
        className,
      )}
      {...otherProps}
    >
      {((topic.categories ?? []) as Category[])
        .reduce<Category[]>((acc, c) => (acc.some(cat => cat.id === c.id) ? acc : [...acc, c]), [])
        .map(cat => (
          <CategoryBadge
            key={cat.id}
            slug={cat.slug}
            title={cat.title}
            variant="contained"
            className={twMerge(
              'w-fit text-[11px] text-gray-soft bg-gray-60/100 hover:text-primary-dark hover:bg-red-60/100',
              isResponsive && 'text-[12px] md:text-[15px]',
            )}
          />
        ))}
    </div>
  ) : null;
});

export default TopicItemTags;
