import { dispatchOther } from '../redux/redux.utils';
import { setIsSignedIn } from '../redux/slices/graphlogin-slice';
import type { AccessToken, AuthResponse, RefreshToken } from '../types/auth';
import { isSafari } from '../utils/web';
import { sendToken } from './communication/authentication';
import {
  generateSharedStorageKey,
  generateWebsiteStorageKey,
  generateWebsiteStorageRefreshKey,
  localStorage,
} from './storage/localStorage';

export const clearLocalToken = (key: string) => {
  localStorage.removeItem(generateWebsiteStorageKey(key));
  localStorage.removeItem(generateWebsiteStorageRefreshKey(key));
};
export const storeLocalToken = (key: string, token: AccessToken, refreshToken: RefreshToken) => {
  localStorage.setItem(generateWebsiteStorageKey(key), token || '');
  if (refreshToken) {
    localStorage.setItem(generateWebsiteStorageRefreshKey(key), refreshToken);
  } else if (refreshToken !== undefined) {
    localStorage.removeItem(generateWebsiteStorageRefreshKey(key));
  }
};
export const getLocalToken = (key: string) => localStorage.getItem(generateWebsiteStorageKey(key));

export function getLocalRefreshToken(key: string) {
  return localStorage.getItem(generateWebsiteStorageRefreshKey(key));
}
export function setLocalRefreshToken(key: string) {
  return localStorage.getItem(generateWebsiteStorageRefreshKey(key));
}

export const setGlobalToken = (key: string, token: AccessToken) => {
  // Because Safari partitions storage for 3rd parties
  if (isSafari()) {
    // There is no need to share value between contexts
    const oldValue = localStorage.getItem(generateWebsiteStorageKey(key));
    // Only update this context's storage if token is new and valid
    if (token && token !== oldValue) {
      localStorage.setItem(generateWebsiteStorageKey(key), token);
    }
  } else {
    if (token) {
      localStorage.setItem(generateSharedStorageKey(key), token);
      dispatchOther(setIsSignedIn(true));
    }
  }
};
export const getGlobalToken = (key: string) =>
  localStorage.getItem(isSafari() ? generateWebsiteStorageKey(key) : generateSharedStorageKey(key));

export function clearGlobalToken(key: string) {
  localStorage.removeItem(generateSharedStorageKey(key));
  dispatchOther(setIsSignedIn(false));
}

export function saveTokenInGL(key: string, authResponse: AuthResponse) {
  const { token, refreshToken } = authResponse;
  setGlobalToken(key, token);
  storeLocalToken(key, token, refreshToken);
}

export function saveAndBroadcastTokenInGL(
  key: string,
  authResponse: AuthResponse,
  targetOrigin: string,
) {
  saveTokenInGL(key, authResponse);
  sendToken(authResponse, targetOrigin);
}
