import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import type { FormEvent, PointerEvent, ReactNode } from 'react';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import type {
  ChangeEvent,
  GetSectionSuggestions,
  GetSuggestionValue,
  InputProps,
  OnSuggestionSelected,
  RenderInputComponent,
  RenderSectionTitle,
  RenderSuggestion,
  SuggestionsFetchRequestedParams,
  Theme,
} from 'react-autosuggest';
import Autosuggest from 'react-autosuggest';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useDebounce, useDebouncedCallback } from 'use-debounce';

import { useConnectedRouter } from '../../hooks/useConnectedRouter';
import { useManualHistory } from '../../hooks/useManualHistory';
import { useRefIntercept } from '../../hooks/useRefIntercept';
import { ReactComponent as IconCommentBlue } from '../../icons/icon-comment-blue.svg';
import { ReactComponent as IconEnterKey } from '../../icons/icon-enter-key.svg';
import { ReactComponent as IconSubjectBlue } from '../../icons/icon-subjects-blue.svg';
import { ReactComponent as IconTagBlue } from '../../icons/icon-tag-blue.svg';
import { quickSearchQuery } from '../../thread/api/search';
import { CategoryBadge } from '../../thread/Category/CategoryBadge';
import { MessageSuggestion } from '../../thread/ThreadMessage/MessageSuggestion';
import type { Category, Message, QueryType, Topic } from '../../types/api';
import { handleError } from '../../utils/common-utils';
import { NoSSR } from '../../utils/ssr';
import { isBrowser, isMobile } from '../../utils/web';
import { AutoHeight } from '../AutoHeight';
import { inputWrapperClasses } from '../Form/Input';
import styles from './quickSearch.module.scss';
import { QuickSearchInput } from './QuickSearchInput';
import type { SearchInputRefProps } from './SearchInput';
import { TopicSuggestion } from './TopicSuggestion';

export interface QuickSearchGenericProps {
  suggestionsPlaceholder?: TSearchSection[];
  show: boolean;
  onShow?: () => void;
  onHide?: () => void;
}

const messages = defineMessages({
  search: { defaultMessage: 'Rechercher', id: 'rE/YZk' },
  publishedIn: { defaultMessage: 'publié dans', id: 'W5vNse' },
  tags: { defaultMessage: 'Étiquettes', id: '6TdTHK' },
  topics: { defaultMessage: 'Sujets', id: '57Cwo0' },
  messages: { defaultMessage: 'Messages', id: 'hMzcSq' },
  enter: { defaultMessage: 'ENTRER', id: 'FH1p/M' },
  advancedSearch: { defaultMessage: 'pour une recherche avancée', id: 'IKqcIq' },
});

type TSuggestion = {
  pathname: string;
  query: Record<string, string | number | boolean | undefined>;
  element: JSX.Element;
};

export type TSearchSection = {
  title: {
    icon: ReactNode;
    title: ReactNode;
  } | null;
  results: TSuggestion[];
};

const getSectionSuggestions: GetSectionSuggestions<TSuggestion, TSearchSection> = value =>
  value?.results ?? [];

const generateSections = ({
  persistedData,
  debouncedSearch,
}: {
  persistedData: QueryType;
  debouncedSearch: string;
}): TSearchSection[] =>
  [
    {
      title: {
        icon: <IconTagBlue className="scale-75" />,
        title: <FormattedMessage {...messages.tags} />,
      },
      results: ((persistedData?.website?.categorySearch?.categories ?? []) as Category[]).map(
        catProps => ({
          pathname: '/[tag]',
          query: {
            tag: catProps.slug as string,
          },
          element: <CategoryBadge color="primary" variant="contained" {...catProps} />,
        }),
      ),
    },
    {
      title: {
        icon: <IconSubjectBlue className="scale-75" />,
        title: <FormattedMessage {...messages.topics} />,
      },
      results: ((persistedData?.website?.topicSearch?.topics ?? []) as Topic[]).map(topic => ({
        pathname: '/t/[slug]',
        query: {
          slug: topic.slug as string,
        },
        element: <TopicSuggestion topic={topic} />,
      })),
    },
    {
      title: {
        icon: <IconCommentBlue className="scale-75" />,
        title: <FormattedMessage {...messages.messages} />,
      },
      results: ((persistedData?.website?.messageSearch?.messages ?? []) as Message[]).map(
        message => ({
          pathname: '/t/[slug]/d/[messageId]',
          query: {
            slug: message.topic?.slug as string,
            messageId: message.id as string,
          },
          element: <MessageSuggestion message={message} wordsToHighlight={debouncedSearch} />,
        }),
      ),
    },
  ].filter(elt => elt?.results?.length > 0);

const renderSuggestion: RenderSuggestion<TSuggestion> = (elt, { isHighlighted }) => (
  <div
    className={classNames(
      'cursor-pointer transition-colors py-2 px-3 duration-75',
      isHighlighted ? 'bg-gray-50/15' : 'bg-white',
    )}
  >
    <div className="pointer-events-none">{elt?.element}</div>
  </div>
);

const renderSectionTitle: RenderSectionTitle = (section: { title: TSearchSection['title'] }) => {
  const { title } = section;

  if (title) {
    return (
      <div className="flex items-center gap-1 p-2 bg-white border-solid border-b border-gray-lighter text-gray">
        {title.icon && <span>{title.icon}</span>}
        <span className="font-bold uppercase text-xs text-gray-medium font-source tracking-4">
          {title.title}
        </span>
      </div>
    );
  }

  return null;
};

const noop = () => null;

const theme: Theme = {
  suggestionsList: 'divide-y divide-gray-lighter',
  container: classNames('h-full flex-1', inputWrapperClasses),
};

export type AutosuggestElement = Autosuggest<TSuggestion, TSearchSection>;

export const QuickSearchWithSuggestion = forwardRef<AutosuggestElement, QuickSearchGenericProps>(
  function QuickSearchWithSuggestion({ suggestionsPlaceholder, show, onShow, onHide }, refFn) {
    const { formatMessage } = useIntl();

    const [refToForward, ref] = useRefIntercept(refFn);

    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const router = useConnectedRouter();
    const [debouncedSearch] = useDebounce(search, 500, { trailing: true, leading: true });
    const canSearch = search?.length > 1;

    const handleOpenMenu = useCallback(() => {
      onShow?.();
      ref.current?.input?.focus();
    }, [onShow, ref]);
    const handleCloseMenu = useCallback(() => {
      onHide?.();
      ref.current?.input?.blur();
    }, [onHide, ref]);

    const { push, pop } = useManualHistory(handleOpenMenu, handleCloseMenu);

    const {
      data,
      previousData,
      loading: rawLoading,
      refetch,
    } = useQuery<QueryType>(quickSearchQuery, {
      variables: {
        search: debouncedSearch,
      },
      skip: !canSearch,
      notifyOnNetworkStatusChange: true,
      // prevent fetching for any other reason search change
      fetchPolicy: isBrowser ? 'cache-only' : 'cache-first',
    });

    const computedLoading = canSearch && (rawLoading || debouncedSearch !== search);

    const persistedData = data || (previousData as QueryType);

    const suggestions = useMemo(() => {
      if (!debouncedSearch && suggestionsPlaceholder) {
        return suggestionsPlaceholder;
      }

      return generateSections({ persistedData, debouncedSearch });
    }, [debouncedSearch, persistedData, suggestionsPlaceholder]);

    const getSuggestionValue: GetSuggestionValue<TSuggestion> = useCallback(() => search, [search]);

    const handleSearchChange = useCallback(
      (_event: FormEvent<HTMLElement>, { newValue }: ChangeEvent) => {
        const newValueWithoutSlash = newValue.replace(/\//g, '');

        if (newValueWithoutSlash !== search) {
          setSearch(newValueWithoutSlash);
        }
      },
      [search],
    );

    const reset = useCallback(() => {
      setSearch('');

      pop();
    }, [pop]);

    const onSuggestionSelected = useCallback<OnSuggestionSelected<TSuggestion>>(
      (e, foo) => {
        const { suggestion, method, suggestionValue } = foo;
        const { pathname, query } = suggestion;

        pop();

        if (method === 'enter') {
          if (!(suggestionValue?.length >= 2)) {
            return;
          }
          router.push({ pathname: '/search/[term]', query: { term: suggestionValue } });
          return;
        }

        // Avoid triggering the click event listener that would re-set the focus on the input.
        // Focusing is fine, but Autosuggest has a bug in that case that: the suggestions remain
        // closed, with a focus already set, which makes re-opening the suggestions hard
        // (we need to blur/focus again - bad for UX)
        e.stopPropagation();

        router.push({ pathname, query });
      },
      [pop, router],
    );

    const inputProps = useMemo<InputProps<TSuggestion>>(() => {
      const props: SearchInputRefProps = {
        value: search,
        showBackButton: show,
        onFocus: push,
        onChange: handleSearchChange as any,
        onClickBack: reset,
        className: classNames(
          'h-full _md:w-0 _md:placeholder:text-transparent transition text-[14px]',
          show ? 'w-[281px]' : 'w-80',
        ),
        placeholder: formatMessage(messages.search),
        overrideWidth: true,
        overrideHeight: true,
      };
      return props as unknown as InputProps<TSuggestion>;
    }, [search, show, push, handleSearchChange, reset, formatMessage]);

    // Used to be (value ?? '').trim().length > 1
    const shouldRenderSuggestions = useCallback((_value: string) => show, [show]);

    useEffect(() => {
      setSearch('');
    }, [router.asPath]);

    useEffect(() => {
      if (computedLoading) {
        setLoading(true);
        return undefined;
      }

      const timer = setTimeout(() => setLoading(false), 200);
      return () => clearTimeout(timer);
    }, [computedLoading]);

    const renderInputComponent: RenderInputComponent = props => {
      return <QuickSearchInput {...props} />;
    };

    const preventDefault = useCallback((e: PointerEvent<HTMLDivElement>) => e.preventDefault(), []);

    const renderSuggestionsContainer = useCallback(
      ({ containerProps, children }) =>
        !!children && (
          <div className="z-10 absolute left-0 right-0 bottom-0" onPointerDown={preventDefault}>
            <div className="absolute top-0 left-0 right-0">
              <div
                {...containerProps}
                className={classNames(
                  'shadow',
                  styles.suggestionsContainerCommon,
                  isMobile() ? styles.suggestionsContainerMobile : styles.suggestionsContainer,
                )}
              >
                {children}
                <AutoHeight className="duration-300 bg-white">
                  {loading && (
                    <div className="flex justify-center py-4">
                      <div className="loader" />
                    </div>
                  )}
                </AutoHeight>
                <div className="flex flex-row py-1 flex-wrap justify-center items-center space-x-1 bg-gray-lighter text-gray-medium text-sm">
                  <div className="flex items-center gap-1">
                    <b>
                      <FormattedMessage {...messages.enter} />{' '}
                    </b>
                    <IconEnterKey className="h-5 w-5 px-1" />
                  </div>
                  <span>
                    <FormattedMessage {...messages.advancedSearch} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        ),
      [loading, preventDefault],
    );

    const fetchAndSetEmbedDebounced = useDebouncedCallback(
      async ({ value }: SuggestionsFetchRequestedParams) => {
        try {
          if (value?.length >= 2) {
            refetch({ search: value });
          }
        } catch (error) {
          handleError(error);
        }
      },
      300,
    );

    return (
      <NoSSR>
        <Autosuggest<TSuggestion, TSearchSection>
          theme={theme}
          multiSection
          focusInputOnSuggestionClick={!isMobile}
          getSectionSuggestions={getSectionSuggestions}
          getSuggestionValue={getSuggestionValue}
          inputProps={inputProps}
          suggestions={suggestions}
          onSuggestionHighlighted={noop}
          onSuggestionSelected={onSuggestionSelected}
          onSuggestionsFetchRequested={fetchAndSetEmbedDebounced}
          onSuggestionsClearRequested={noop}
          renderInputComponent={renderInputComponent}
          renderSectionTitle={renderSectionTitle}
          renderSuggestion={renderSuggestion}
          renderSuggestionsContainer={renderSuggestionsContainer}
          shouldRenderSuggestions={shouldRenderSuggestions}
          alwaysRenderSuggestions={show}
          ref={refToForward}
        />
      </NoSSR>
    );
  },
);
