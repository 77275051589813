export function isEqual<T>(x: T, y: T): boolean {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y;
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length &&
        ok(x).every(key => isEqual(x[key as keyof T], y[key as keyof T]))
    : x === y;
}

// console.log(isEqual({ a: 1, b: 2 }, { a: 1, b: 2 }));
// console.log(isEqual({ a: 1, b: 2 }, { a: 1, b: 3 }));
// console.log(isEqual({ a: 1, b: 2 }, { a: 1 }));
// console.log(isEqual({ a: 1, b: 2 }, { a: 1, b: 2, c: 2 }));
// console.log(isEqual('aa', 'aa'));
// console.log(isEqual('aa', 'ab'));
// console.log(isEqual(3, 3));
// console.log(isEqual(3, 43));
// console.log(isEqual([1, 2], [1, 2]));
// console.log(isEqual([1, 2], [2, 1]));

// cloneDeep
// https://stackoverflow.com/questions/4459928/how-to-deep-clone-in-javascript
// structuredClone
