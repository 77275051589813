import classNames from 'classnames';
import type { PropsWithChildren } from 'react';
import React, {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useBodyModifier } from '../../hooks/useBodyModifier';

type ClickHandler = VoidFunction | undefined;

interface PopoverContext {
  ids: string[];
  nodeReference: React.MutableRefObject<unknown> | React.RefObject<unknown> | null;
  add: (id: string, onClick?: ClickHandler) => void;
  remove: (id: string, onClick?: ClickHandler) => void;
}

export const popoverContext = createContext<PopoverContext>({
  ids: [],
  nodeReference: null,
  add: () => null,
  remove: () => null,
});

export const PopoverProvider: React.FC<PropsWithChildren<{}>> = memo(function PopoverProvider({
  children,
}) {
  const [ids, setIds] = useState<string[]>([]);
  const clickHandlersRef = useRef<VoidFunction[]>([]);

  const add = useCallback<PopoverContext['add']>(
    (id, onClick) => {
      if (onClick) {
        clickHandlersRef.current.push(onClick);
      }
      setIds(v => [...v, id]);
    },
    [setIds],
  );

  const remove = useCallback<PopoverContext['remove']>(
    (id, onClick) => {
      setIds(v => v.filter(elt => elt !== id));
      if (onClick) {
        const i = clickHandlersRef.current.indexOf(onClick);
        if (i !== -1) {
          clickHandlersRef.current.splice(i, 1);
        }
      }
    },
    [setIds],
  );

  const clickAllHandlers = useCallback(() => {
    for (const handler of clickHandlersRef.current) {
      handler();
    }
  }, []);

  const nodeReference = useRef(null);
  const value = useMemo(
    () => ({
      ids,
      add,
      remove,
      nodeReference,
    }),
    [ids, add, remove, nodeReference],
  );

  const { Provider } = popoverContext;

  return (
    <Provider value={value}>
      {children}
      <div
        className={classNames(
          'fixed z-popover top-0 left-0 w-full h-full pointer-events-none transition-colors duration-300',
          ids.length ? 'pointer-events-auto bg-black/30' : 'bg-black/0',
        )}
        ref={nodeReference}
        onClick={clickAllHandlers}
      />
    </Provider>
  );
});

export const usePopoverOverlayContext = (enabled = false, onClick: ClickHandler = undefined) => {
  const { ids, add, remove } = useContext(popoverContext);

  useEffect(() => {
    if (enabled) {
      const id = `${Date.now()}___${Math.random().toString(36).slice(2)}`;
      add(id, onClick);
      return () => remove(id, onClick);
    }
    return () => {};
  }, [add, enabled, onClick, remove]);

  useBodyModifier({ preventScroll: enabled });

  return { ids };
};
