import type { FC } from 'react';
import { memo } from 'react';

import { html } from '../../utils/dom';
import { replaceEmojis } from '../../utils/emoji';

interface Props {
  subject: string;
  className?: string;
}

export const TopicSubject: FC<Props> = memo(function TopicSubject({ subject, className }) {
  return <span className={className}>{html(replaceEmojis(subject))}</span>;
});
