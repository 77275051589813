import React from 'react';

import { LinkReactCompForLegacy } from '../../../../editor/editorjs/editorjs-parsers';

export type EmbedOGProps = {
  image: string;
  initialUrl: string;
  title: React.ReactNode;
  description: React.ReactNode;
  provider_url?: string;
  provider_name?: string;
};

export type EmbedOGProps2 = { type: 'opengraph' } & EmbedOGProps;

export const EmbedOpenGraph: React.FC<EmbedOGProps> = ({
  image,
  // initialUrl, // Not displayed
  title,
  description,
  provider_url,
}) => {
  return (
    <LinkReactCompForLegacy
      title={title}
      description={description}
      link={provider_url}
      imageUrl={image}
    />
  );
};
