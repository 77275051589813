import type { ForwardedRef } from 'react';
import React, { forwardRef } from 'react';

import { isMobile } from '../../utils/web';
import type { ModalInstance, ModalPropTypes } from './Modal';
import { Modal } from './Modal';
import type { PopoverPropTypes, TippyInstance } from './Popover';
import { Popover } from './Popover';

export type GraphModalInstance = ModalInstance | TippyInstance | undefined;

type GraphModalProps = React.PropsWithChildren<{ forcePopover?: boolean; forceModal?: boolean }> &
  (
    | ({ forceModal: true } & ModalPropTypes)
    | ({ forcePopover: true } & PopoverPropTypes)
    | ({ forceModal?: undefined | false; forcePopover?: undefined | false } & ModalPropTypes &
        PopoverPropTypes)
  );

export const GraphModal = forwardRef<HTMLDivElement | ModalInstance, GraphModalProps>(
  ({ forcePopover, forceModal, ...props }, ref) => {
    if (forcePopover)
      return <Popover ref={ref as ForwardedRef<HTMLDivElement>} {...(props as PopoverPropTypes)} />;

    if (forceModal)
      return <Modal ref={ref as ForwardedRef<ModalInstance>} {...(props as ModalPropTypes)} />;

    return isMobile() ? (
      <Modal ref={ref as ForwardedRef<ModalInstance>} {...(props as ModalPropTypes)} />
    ) : (
      <Popover ref={ref as ForwardedRef<HTMLDivElement>} {...(props as PopoverPropTypes)} />
    );
  },
);
