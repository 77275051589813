import { useRef } from 'react';

function usePrevious<T>(value: T) {
  const ref = useRef<T>();

  const prev = ref.current;
  ref.current = value;
  return prev;
}

export default usePrevious;
