import { ApolloLink } from '@apollo/client';

import { WITH_TIMER } from '../../../config';
import { generateAuthLink } from './authLink';
import type { GenerateErrorLinkArgs } from './errorLink';
import { generateErrorLink } from './errorLink';
import { generateHttpLink } from './httpLink';
import { timeLink } from './timeLink';

const IS_DEV = process.env.NODE_ENV === 'development';

export type GenerateSemiologicLinkArgs = GenerateErrorLinkArgs & { graphUrl?: string };

export const generateSemiologicLink = ({
  getToken,
  getPublicKey,
  graphUrl,
  triggerRefreshToken,
  logout,
}: GenerateSemiologicLinkArgs) =>
  ApolloLink.from(
    [
      IS_DEV && WITH_TIMER ? timeLink : null,
      generateErrorLink({ getToken, getPublicKey, triggerRefreshToken, logout }),
      generateAuthLink({
        getToken,
        getPublicKey,
        triggerRefreshToken,
      }),
      generateHttpLink(graphUrl),
    ].filter((elt): elt is ApolloLink => !!elt),
  );
